import axios from '../javascript/custom-axios';
const RESOURCE_NAME = '/demand';

export default {
  sendDemand(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },

  getDemands(Page = 1, PageSize = 15, status = null) {
    return axios.get(`${RESOURCE_NAME}/get`, {
      params: { page: Page, pageSize: PageSize, Type: status },
    });
  },

  deleteDemand(id) {
    return axios.delete(`${RESOURCE_NAME}/delete`, { params: { id: id } });
  },

  closeTicket(body) {
    return axios.put(`${RESOURCE_NAME}/close-ticket`, body);
  },

  getDemandMessage(chatId, Id, Page = 1, PageSize = 15) {
    return axios.get(`${RESOURCE_NAME}-message/get`, {
      params: { DemandChatId: chatId, Id: Id, page: Page, pageSize: PageSize },
    });
  },

  sendDemandMessage(body) {
    return axios.post(`${RESOURCE_NAME}-message/add`, body);
  }
};
