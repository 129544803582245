<template>
  <v-card elevation="1" class="mt-0 py-lg-4" style="margin: 0 !important">
    <ConfirmationModal
      v-model="confirmationModal"
      :title="$t('labels.warning')"
      :message="$t('messages.changeTheExcelFile')"
      :confirm="finishExcelFileOperation"
    />
    <v-dialog
      v-model="updateModal"
      max-width="400"
      content-class="short-content"
    >
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('buttons.update') }}
          </div>
        </v-card-title>
        <v-divider class="mb-4" />

        <ValidationObserver
          ref="updatePriceListObserver"
          v-slot="{ handleSubmit }"
        >
          <form @submit.prevent="handleSubmit(update)">
            <v-card-text>
              <FormElement
                v-for="(element, index) in formItems"
                :key="
                  element.key.parent + '_' + element.key.child + '_' + index
                "
                :vertical="element.vertical"
                v-model="$data[element.key.parent][element.key.child]"
                :validationLabel="$t(element.validationLabel)"
                :rules="element.rules"
                :formLabel="$t(element.formLabel)"
                :onChange="element.onChange"
                :formType="element.formType"
                :cols="element.cols"
                :sm="element.sm"
                :md="element.md"
                :lg="element.lg"
                :light="false"
                :hidden="element.hidden"
                :labelShown="element.labelShown"
                :topLabel="element.topLabel"
                :active="element.active"
                :disabled="element.disabled"
                :placeholder="$t(element.placeholder)"
                :classes="element.classes"
              />
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;

                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                type="submit"
                >{{ $t('buttons.save') }}</v-btn
              >
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                @click="updateModal = false"
                >{{ $t('buttons.cancel') }}</v-btn
              >
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-card-title style="color: var(--darkBlue)">
      <v-row>
        <v-col
          class="text-lg-left text-center d-flex align-center"
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
        >
          <div class="d-flex mb-2 mb-lg-0">
            <v-icon
              large
              style="color: #0177d4 !important; margin-right: 5px"
              id="menu-activator"
              @click="goBack"
            >
              mdi-arrow-left
            </v-icon>
            <h4>{{ $t('buttons.backNavigate') }}</h4>
          </div>

          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                style="font-size: 32px"
                v-bind="attrs"
                v-on="on"
                color="var(--primaryColor)"
                @click="updateModal = true"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('buttons.update') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <v-card>
      <v-card-text style="padding: 0px; margin: 0px">
        <v-row class="px-4 pt-4">
          <v-col
            cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
            class="d-flex"
            style="padding: 0px"
          >
            <FormElement
              key="createdAt"
              :vertical="true"
              v-model="item.CreatedAt"
              :validationLabel="$t('title.tableHeaderCreateDate')"
              :topLabel="true"
              :labelShown="false"
              :cols="12"
              :lg="12"
              :xl="12"
              :disabled="true"
              :formType="{
                name: 'text',
              }"
              :placeholder="$t('title.tableHeaderCreateDate')"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
            class="d-flex"
            style="padding: 0px"
          >
            <FormElement
              key="logisticTypeName"
              :vertical="true"
              v-model="logisticTypeName"
              :validationLabel="$t('titles.subsupplier')"
              :topLabel="true"
              :labelShown="false"
              :cols="12"
              :lg="12"
              :xl="12"
              :disabled="true"
              :formType="{
                name: 'text',
              }"
              :placeholder="$t('titles.subsupplier')"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
            class="d-flex"
            style="padding: 0px"
          >
            <FormElement
              key="ENTRY_EXPIRY_DATE_START"
              :vertical="true"
              v-model="item.ValidityStartDate"
              :validationLabel="$t('labels.expiryDateStart')"
              rules="required"
              :topLabel="true"
              :labelShown="false"
              :disabled="true"
              :cols="12"
              :lg="12"
              :xl="12"
              :formType="{
                name: 'date',
                dateMode: 'date',
              }"
              :placeholder="$t('labels.expiryDateStart')"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
            class="d-flex"
            style="padding: 0px"
          >
            <FormElement
              key="ENTRY_EXPIRY_DATE_END"
              :vertical="true"
              v-model="item.ValidityEndDate"
              :validationLabel="$t('labels.expiryDateEnd')"
              rules="required"
              :topLabel="true"
              :labelShown="false"
              :disabled="true"
              :cols="12"
              :lg="12"
              :xl="12"
              :formType="{
                name: 'date',
                dateMode: 'date',
              }"
              :placeholder="$t('labels.expiryDateEnd')"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div style="position: relative; width: 100%">
      <div
        v-if="
          ($vuetify.breakpoint.xl ||
            $vuetify.breakpoint.lg ||
            $vuetify.breakpoint.md) &&
          isSupplier
        "
        class="d-flex mt-6 px-4"
        style="position: absolute; right: 0; top: 0; z-index: 1"
      >
        <v-btn
          style="border-radius: 12px !important"
          elevation="0"
          color="primary"
          class="button"
          dark
          :href="item.FileUrl"
          depressed
          download
          ><v-icon left> mdi-download </v-icon
          >{{ $t('buttons.downloadFile') }}</v-btn
        >
        <div class="ml-2">
          <v-btn
            style="border-radius: 12px !important"
            elevation="0"
            color="primary"
            class="button"
            dark
            depressed
            @click="onButtonClick"
          >
            <v-icon left> mdi-upload </v-icon>
            {{ $t('buttons.changeExcelFile') }}
          </v-btn>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="onFileChanged"
          />
        </div>
      </div>
      <TabScreen :slots="slots" class="px-4 pt-4">
        <template v-slot:periodic>
          <div
            v-if="data"
            style="
              border-radius: 0;
              border-style: solid;
              border-bottom-width: 1px;
              border-left-width: 2px;
              border-right-width: 2px;
              border-top-width: 0px;
              border-color: lightgray;
            "
          >
            <v-data-table
              :items="data"
              @update:sort-desc="sort"
              :no-data-text="$t('messages.noData')"
              hide-default-footer
              :headers="[
                {
                  text: $t('labels.country'),
                  value: 'CountryName',
                  sortable: true,
                  groupable: false,
                  align: 'center',
                },
                {
                  text: $t('labels.pricePerKg'),
                  value: 'PricePerKg',
                  sortable: true,
                  groupable: false,
                  align: 'center',
                },
              ]"
              :loading="$store.state.loadingStatus"
              :options="{
                itemsPerPage: itemsPerPageSecondary,
                page: pageSecondary,
                sortBy: ['CoutryName', 'Price'],
                sortDesc: [sortDesc],
              }"
            >
              <template v-slot:footer>
                <div class="d-flex justify-center mt-8">
                  <v-row>
                    <v-col cols="0" sm="1" md="1" lg="1" xl="1"> </v-col>
                    <v-col
                      cols="12"
                      sm="8"
                      md="8"
                      lg="9"
                      xl="9"
                      class="mx-auto"
                    >
                      <v-pagination
                        class="my-4"
                        @input="onPageChangeSecondary"
                        :value="pageSecondary"
                        :length="pageLengthSecondary"
                        circle
                      ></v-pagination>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      md="3"
                      lg="2"
                      xl="2"
                      class="ml-md-auto d-flex align-center pr-sm-6 px-6 px-sm-0 pb-6 pb-sm-0"
                    >
                      <v-select
                        v-model="itemsPerPageSecondary"
                        :items="[5, 10, 15, 20, 50, 100, 1000]"
                        :label="$t('labels.selectItemsPerPage')"
                        item-value="value"
                        item-text="name"
                        outlined
                        dense
                        hide-details
                        @change="changeitemsPerPageSecondary"
                        rounded
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </div>
              </template>
              <template v-slot:no-data>
                <div class="my-10 d-flex flex-column align-center">
                  <h4>{{ $t('messages.noSearch') }}</h4>
                  <v-btn
                    style="color: white; background-color: var(--darkBlue)"
                    class="button my-2"
                    @click="showCreateOffer = true"
                  >
                    {{ $t('buttons.search') }}
                    <v-icon dark right> mdi-magnify </v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </div>
          <div v-else-if="isSupplier" class="mt-4">
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              @change="onFileChangedOutOfArea"
            />
            <div class="d-flex justify-center">
              <v-btn
                style="border-radius: 12px !important"
                elevation="0"
                color="primary"
                class="button"
                dark
                depressed
                @click="addNewOutOfArea"
              >
                <v-icon left> mdi-upload </v-icon>
                {{ $t('labels.addNewFile') }}
              </v-btn>
            </div>
          </div>
        </template>
      </TabScreen>
    </div>
  </v-card>
</template>
<script>
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';
import TabScreen from '../components/TabScreen.vue';
import ConfirmationModal from '../components/General/ConfirmationModal.vue';
import ExpressCargoServices from '../services/ExpressCargoServices';
import { logisticsTypes } from '../utils/constants';

export default {
  data() {
    return {
      item: {},
      slots: [],
      data: null,
      logisticsTypes,
      logisticTypeName: '',
      isSupplier: false,
      itemsPerPageSecondary: 10,
      updateModal: false,

      itemsPerPage: 10,
      pageSecondary: 1,
      itemsPerPageSecondary: 10,
      sortDesc: null,
      confirmationModal: false,
      file: null,
    };
  },
  components: { FormElement, TabScreen, ConfirmationModal },
  mounted() {
    this.isSupplier =
      this.$store.state.Type === 'Supplier' ||
      this.$store.state.Type === 'PendingSupplier';

    this.item = this.$route.params.item;
    if (!this.item) this.$router.push('transfer-entry');
    else {
      this.data = this.item.PeriodicCosts;
      this.logisticTypeName = logisticsTypes.find(
        (x) => x.Value === this.item.LogisticType
      ).Name;
      this.slots = [
        {
          name: 'periodic',
          title: this.$t('labels.periodicLabel'),
          unSeenCount: 0,
        },
      ];

      this.setup();
    }
  },
  computed: {
    formItems() {
      return [
        {
          key: {
            parent: 'item',
            child: 'IsActive',
          },
          validationLabel: 'labels.isActive',
          formType: {
            name: 'checkbox',
          },

          labelShown: false,
          topLabel: true,
          cols: 12,
          disabled: !this.isSupplier,
          placeholder: 'labels.isActive',
          classes: 'mb-4',
        },
        {
          key: {
            parent: 'item',
            child: 'ValidityStartDate',
          },
          validationLabel: 'labels.expiryDateStart',
          formType: {
            name: 'date',
            dateMode: 'date',
          },

          rules: 'required',
          labelShown: false,
          topLabel: true,
          cols: 12,
          disabled: !this.isSupplier,
          placeholder: 'labels.expiryDateStart',
          classes: 'mb-4',
        },
        {
          key: {
            parent: 'item',
            child: 'ValidityEndDate',
          },
          validationLabel: 'labels.expiryDateEnd',
          formType: {
            name: 'date',
            dateMode: 'date',
          },
          rules: 'required',
          labelShown: false,
          topLabel: true,
          cols: 12,
          disabled: !this.isSupplier,
          placeholder: 'labels.expiryDateEnd',
          classes: 'mb-4',
        },
        {
          key: {
            parent: 'item',
            child: 'LogisticType',
          },
          validationLabel: 'titles.subsupplier',
          formType: {
            name: 'multipleSelect',
            items: {
              data: logisticsTypes,
              value: 'Value',
              text: 'Name',
            },
          },
          rules: 'required',
          labelShown: false,
          topLabel: true,
          cols: 12,
          disabled: !this.isSupplier,
          placeholder: 'titles.subsupplier',
          classes: 'mb-4',
        },
      ];
    },
    pageLengthSecondary() {
      return Math.ceil(this.data.length / this.itemsPerPageSecondary);
    },
  },
  methods: {
    async setup() {},

    update() {
      this.$refs.updatePriceListObserver.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          formData.append('LogisticType', this.item.LogisticType);
          formData.append('ValidityStartDate', this.item.ValidityStartDate);
          formData.append('ValidityEndDate', this.item.ValidityEndDate);
          formData.append('IsActive', this.item.IsActive);
          formData.append('PeriodicCostExcelId', this.item.Id);

          this.$store.state.loadingStatus = true;
          ExpressCargoServices.updatePeriodicCost(formData)
            .then((response) => {
              window.showSuccess(this.$t('messages.periodicUpdateSuccessful'));
              this.$router.push({ name: 'transferEntry' });
              this.updateModal = false;
            })
            .catch(this.onServiceError)
            .finally(() => {
              this.$store.state.loadingStatus = false;
            });
        }
      });
    },

    goBack() {
      if (this.$router.getRoutes().length === 0) {
        this.$router.push({ name: 'transferEntry' });
      } else {
        this.$router.go(-1);
      }
    },

    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.file = e.target.files[0];
      this.confirmationModal = true;
    },
    finishExcelFileOperation() {
      let formData = new FormData();

      formData.append('LogisticType', this.item.LogisticType);
      formData.append('ValidityStartDate', this.item.ValidityStartDate);
      formData.append('ValidityEndDate', this.item.ValidityEndDate);
      formData.append('IsActive', this.item.IsActive);
      formData.append('File', this.file);
      formData.append('PeriodicCostExcelId', this.item.Id);

      this.$store.state.loadingStatus = true;
      ExpressCargoServices.updatePeriodicCost(formData)
        .then((response) => {
          window.showSuccess(this.$t('messages.changeFileSuccessful'));
          this.$router.push({ name: 'transferEntry' });
        })
        .catch(this.onServiceError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    getFormattedDate(date) {
      return formatDate(date, '/');
    },
    sort(item) {
      this.sortDesc = !!item;
    },
    onPageChangeSecondary(newPage) {
      this.pageSecondary = newPage;
    },
    changeitemsPerPageSecondary(newValue) {
      this.pageSecondary = 1;
      this.itemsPerPageSecondary = newValue;
    },
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },
  },
};
</script>
<style>
.container {
  margin-top: 38px;
}

.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
}
</style>
