var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mt-0 py-lg-10",staticStyle:{"margin":"0 !important","padding-right":"4%","padding-left":"4%"},attrs:{"elevation":"1"}},[_c('v-card-title',{staticStyle:{"color":"var(--darkBlue)"}},[_c('v-row',[_c('v-col',{staticClass:"text-lg-left text-center",attrs:{"lg":"3","cols":"12"}},[_c('h5',[_vm._v(_vm._s(_vm.$t('navigationDrawerMenu.users')))])])],1),_c('RefreshButton',{attrs:{"refreshFunction":_vm.getUsers}}),(_vm.selectedItems.length > 0)?_c('v-btn',{staticClass:"button ml-2",staticStyle:{"color":"white !important"},attrs:{"elevation":"0","color":"#FB773D","text-color":"white","loading":_vm.$store.state.loadingStatus},on:{"click":function($event){return _vm.openDeleteModal()}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.deleteSelected'))+" ")]):_vm._e()],1),_c('v-divider'),_c('ConfirmationModal',{ref:"confirmationModal",attrs:{"title":_vm.$t('labels.warning'),"message":_vm.$t('messages.updateUserStatus'),"confirm":_vm.updateStatus},model:{value:(_vm.updateStatusModal),callback:function ($$v) {_vm.updateStatusModal=$$v},expression:"updateStatusModal"}}),_c('v-data-table',{attrs:{"headers":_vm.usersHeaders,"hide-default-footer":"","loading":_vm.$store.state.loadingStatus,"options":{
      itemsPerPage: _vm.itemsPerPage,
    },"items":_vm.users,"no-data-text":_vm.$t('messages.noData')},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-center mt-8 pb-lg-0 pb-8"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"md":"2"}}),_c('v-col',{staticClass:"ml-md-auto",attrs:{"md":"8"}},[(_vm.pageLength > 0)?_c('v-pagination',{staticClass:"my-4",attrs:{"value":_vm.page,"length":_vm.pageLength,"total-visible":10,"circle":""},on:{"input":_vm.onPageChange}}):_vm._e()],1),_c('v-col',{staticClass:"ml-md-auto",attrs:{"md":"2"}},[_c('v-select',{attrs:{"items":_vm.perPageValues,"label":_vm.$t('labels.selectItemsPerPage'),"item-value":"value","item-text":"name","outlined":"","dense":"","rounded":""},on:{"change":_vm.changeItemsPerPage},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1)],1)]},proxy:true},{key:"item.IsActive",fn:function({ item }){return [_c('v-chip',{attrs:{"dark":"","color":item.IsActive ? 'green' : 'red'}},[_vm._v(" "+_vm._s(_vm.$t('labels.' + (item.IsActive ? 'isActive' : 'tooltipPassive')))+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"my-10 d-flex flex-column align-center"},[_c('h4',[_vm._v(_vm._s(_vm.$t('messages.noUser')))])])]},proxy:true},{key:"item.actions",fn:function({ item }){return [(item.IsActive)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"button",attrs:{"dark":"","color":"var(--lightRed)"},on:{"click":function($event){_vm.updateStatusModal = true;
              _vm.activeItem.IsActive = false;
              _vm.activeItem.UserId = item.Id;}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('buttons.blockUser'))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('buttons.blockUser')))])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"button",attrs:{"dark":"","color":"green"},on:{"click":function($event){_vm.updateStatusModal = true;
              _vm.activeItem.IsActive = true;
              _vm.activeItem.UserId = item.Id;}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('labels.tooltipAccept'))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.tooltipAccept')))])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }