<template>
  <v-container grid-list-xl class="word-wrap mb-3">
    <v-dialog
      v-if="stockDialog"
      persistent
      v-model="dialogModel"
      max-width="500"
    >
      <v-card>
        <v-card-title class="">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('buttons.addStock') }}
          </div>
        </v-card-title>

        <v-card-text class="py-3">
          <div class="text-center" style="width: 100%">
            {{ $t('description.addStockDesc') }}
          </div>
          <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <form @submit.prevent="handleSubmit(addStock)">
              <ValidationProvider
                :name="$t('labels.stockCount')"
                :rules="{
                  required: true,
                  numeric: true,
                }"
                v-slot="{ errors }"
                class="d-lg-flex"
              >
                <v-text-field
                  :placeholder="$t('labels.stockCount')"
                  class="custom-border"
                  :value="stockCount"
                  v-model="stockCount"
                  :error-messages="errors[0]"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                :name="$t('labels.cost')"
                :rules="{
                  required: true,
                  numeric: true,
                }"
                v-slot="{ errors }"
                class="d-lg-flex"
              >
                <v-text-field
                  :placeholder="$t('labels.cost')"
                  class="custom-border"
                  :value="purchasePrice"
                  v-model="purchasePrice"
                  :error-messages="errors[0]"
                ></v-text-field>
              </ValidationProvider>
              <v-card-actions
                style="
                  background-color: white;
                  position: sticky;
                  bottom: 0px;
                  border-top: solid grey 2px;
                "
              >
                <v-spacer></v-spacer>
                <v-btn class="btn-save rounded-lg" type="submit">
                  {{ $t('buttons.add') }}
                </v-btn>
                <v-btn
                  class="btn-cancel rounded-lg"
                  @click="clearStatusModel()"
                >
                  {{ $t('buttons.cancel') }}
                </v-btn>
              </v-card-actions>
            </form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="swapDialog" v-model="dialogModel" max-width="500">
      <v-card>
        <v-card-title class="">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('title.stockTransfer') }}
          </div>
        </v-card-title>

        <v-card-text class="py-3">
          <div class="text-center" style="width: 100%">
            {{ $t('description.stockTransferDesc') }}
          </div>
          <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <form @submit.prevent="handleSubmit(changeStock)">
              <ValidationProvider
                :name="$t('labels.warehouse')"
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
                class="d-lg-flex"
              >
                <v-combobox
                  v-model="from"
                  :items="filteredStocks"
                  :label="$t('labels.warehouse')"
                  item-text="WarehouseName"
                  item-value="WarehouseId"
                  :error-messages="errors[0]"
                ></v-combobox>
              </ValidationProvider>
              <ValidationProvider
                :name="$t('labels.stockCount')"
                :rules="{
                  required: true,
                  numeric: true,
                  max_value: from == null ? 0 : from.StockCount,
                }"
                v-slot="{ errors }"
                class="d-lg-flex"
              >
                <v-text-field
                  :placeholder="$t('labels.stockCount')"
                  class="custom-border"
                  v-model="swapStockCount"
                  :error-messages="errors[0]"
                ></v-text-field>
              </ValidationProvider>
              <v-card-actions
                style="
                  background-color: white;
                  position: sticky;
                  bottom: 0px;
                  border-top: solid grey 2px;
                "
              >
                <v-spacer></v-spacer>
                <v-btn class="btn-save rounded-lg" type="submit">
                  {{ $t('buttons.transfer') }}
                </v-btn>
                <v-btn
                  class="btn-cancel rounded-lg"
                  @click="clearStatusModel()"
                >
                  {{ $t('buttons.cancel') }}
                </v-btn>
              </v-card-actions>
            </form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div>
      <v-card-title class="popupHeadline">
        <v-row>
          <v-row class="pl-2 py-2"
            ><p class="clickable-header" @click="navigateBack()">
              {{ $t('title.stockManagement') }}
            </p>
            <p class="font-weight-regular" style="">{{ pageTitle }}</p></v-row
          >
        </v-row>
      </v-card-title>
      <v-divider />

      <v-layout row wrap class="px-0">
        <v-flex
          style="padding: 0px !important; border-bottom: 0px !important"
          md6
        >
          <v-card-text class="px-0 py-0 add-content-card-body">
            <v-banner rounded class="mt-5">
              <v-card-title class="add-content-title">
                {{ $t('title.productInformation') }}
              </v-card-title>
              <v-layout>
                <template>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="3">
                        <v-subheader>{{ $t('labels.name') }}</v-subheader>
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                          style="margin-top: 0px; padding-top: 0px"
                          label="Isim"
                          :value="product.Name"
                          v-model="product.Name"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <v-subheader>{{
                          $t('labels.description')
                        }}</v-subheader>
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                          style="margin-top: 0px; padding-top: 0px"
                          :label="$t('labels.description')"
                          :value="product.Description"
                          v-model="product.Description"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="3">
                        <v-subheader>
                          {{ $t('labels.Barcode') }}
                        </v-subheader>
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                          style="margin-top: 0px; padding-top: 0px"
                          :label="$t('labels.Barcode')"
                          v-model="product.Barcode"
                          :value="product.Barcode"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="ml-2">
                      <v-col
                        v-for="item in product.ProductPhotos"
                        :key="item.Id"
                        class="d-flex child-flex"
                        cols="3"
                      >
                        <v-img
                          :src="item.FilePath"
                          :lazy-src="item.FilePath"
                          aspect-ratio="1"
                          cover
                          class="grey lighten-2"
                        >
                        </v-img>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
              </v-layout>
            </v-banner>
          </v-card-text>
        </v-flex>

        <v-flex md6 style="padding-top: 0px !important">
          <v-card-text class="px-0 py-0 add-content-card-body">
            <v-banner rounded class="mt-5">
              <v-card-title class="add-content-title">
                {{ $t('title.stockInformation') }}
              </v-card-title>

              <v-col>
                <v-flex>
                  <template>
                    <v-data-table
                      :headers="headers"
                      :items="stocks"
                      item-key="name"
                      class="elevation-1"
                      :items-per-page="5"
                      :no-data-text="$t('messages.noData')"
                    >
                      <!-- eslint-disable-next-line  -->
                      <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              medium
                              class="mr-2 green-icon"
                              @click="openDialog(item.WarehouseId)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-plus-circle-outline
                            </v-icon>
                          </template>
                          <span>{{ $t('buttons.addStock') }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              medium
                              class="mr-2 green-icon"
                              @click="openSwapDialog(item.WarehouseId)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-swap-horizontal
                            </v-icon>
                          </template>
                          <span>{{ $t('labels.transferFromWarehouse') }}</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </template>
                </v-flex>
              </v-col>
            </v-banner>
          </v-card-text>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import StockInventoryServices from '../../services/StockInventoryServices';
import ProductService from '../../services/ProductService';

export default {
  data: () => ({
    selectedWarehouse: '',
    stockDialog: false,
    swapDialog: false,
    stockCount: null,
    purchasePrice: null,
    dialogModel: {},
    purchasePriceCurrency: 5,
    productId: null,
    product: {},
    pageTitle: '',
    from: '',
    swapStockCount: null,
    warehouses: [],
    filteredStocks: [],
    stocks: [],
    headers: [
      { text: 'Depo Adı', value: 'WarehouseName' },
      { text: 'Stok Sayısı', value: 'StockCount' },
      { text: 'Stok Ekle', value: 'actions' },
    ],
  }),
  watch: {},
  mounted() {
    this.$route.query.id != null
      ? this.getProductById(this.$route.query.id)
      : null;
    this.setup();
  },
  components: {},
  methods: {
    //Sets up the page on page mount
    async setup() {
      this.getStocks(this.$route.query.id);
    },
    navigateBack() {
      this.$router.push({ name: 'stockmanagement' });
    },
    openDialog(id) {
      this.stockDialog = true;
      this.selectedWarehouse = id;
    },
    getProductById(id) {
      this.isUpdate = true;
      this.productId = id;
      this.$store.state.loadingStatus = true;
      ProductService.getProductById(id)
        .then((response) => {
          this.product = response.data.Result;
          this.pageTitle = ' / ' + this.product.Name;
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    addStock() {
      this.$store.state.loadingStatus = true;
      let body = {
        WarehouseId: this.selectedWarehouse,
        ProductId: this.productId,
        PurchasePrice: this.PurchasePrice,
        StockCount: this.stockCount,
        PurchasePriceCurrency: this.purchasePriceCurrency,
      };
      StockInventoryServices.addStock(body)
        .then((res) => {
          this.clearStatusModel();
          this.getStocks(this.productId);
        })
        .catch((err) => {
          window.showError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getStocks(id) {
      this.$store.state.loadingStatus = true;
      StockInventoryServices.getStocksByProdId(id)
        .then((res) => {
          this.stocks = res.data.Result;
        })
        .catch((err) => {
          window.showError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    changeStock() {
      this.$store.state.loadingStatus = true;
      let body = {
        From: this.from.WarehouseId,
        StockCount: this.swapStockCount,
        To: this.selectedWarehouse,
        ProductId: this.productId,
      };
      StockInventoryServices.swapStock(body)
        .then((res) => {
          window.showSuccess('Stok aktarma başarılı.');
          this.clearStatusModel();
          this.getStocks(this.productId);
        })
        .catch((err) => {
          window.showError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    filterAndMapList(id) {
      this.filteredStocks = this.stocks.filter((obj) => obj.WarehouseId != id);
    },
    openSwapDialog(id) {
      this.selectedWarehouse = id;
      this.filterAndMapList(id);
      this.swapDialog = true;
    },
    clearStatusModel() {
      this.stockDialog = false;
      this.swapDialog = false;
      this.stockCount = null;
      this.from = null;
      this.swapStockCount = null;
      this.purchasePrice = null;
      this.selectedWarehouse = null;
    },
  },
  computed: {},
};
</script>
