<template>
  <div>
    <AddVolume
      :onSave="onSaveVolumes"
      :calculateParameter="calculateParameter"
      v-model="packageDetailsModal"
      key="WAREHOUSE_MANAGEMENT_PACKAGE_DETAILS"
      :volumesByUser="convertedVolumes"
      v-if="shipmentBoxes.length > 0"
      :closeAfterSave="true"
      :isWarehouse="true"
    />
    <v-dialog v-model="formModal" width="700px">
      <v-card class="popup-card pb-2" elevation="1">
        <v-card-title
          class="popupHeadline d-flex justify-center"
          style="font-size: 16px"
        >
          {{ $t('buttons.add') }}
        </v-card-title>

        <v-divider></v-divider>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <v-card-text class="mt-2">
            <form @submit.prevent="handleSubmit(fileOperationSubmit)">
              <v-row>
                <FormElement
                  v-for="(element, index) in fileOperationFormElement"
                  :key="
                    element.key.parent +
                    '_' +
                    element.key.child +
                    '_' +
                    index
                  "
                  v-model="$data[element.key.parent][element.key.child]"
                  :validationLabel="$t(element.validationLabel)"
                  :rules="element.rules"
                  :formLabel="$t(element.formLabel)"
                  :onChange="element.onChange"
                  :formType="element.formType"
                  :lg="element.lg"
                  :disabled="element.disabled"
                  :cols="element.cols"
                  :hidden="element.hidden"
                  :labelShown="element.labelShown"
                  :topLabel="element.topLabel"
                  :active="element.active"
                  :placeholder="$t(element.placeholder)"
                />
              </v-row>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: #0077d5;
                    padding: 2vh 3vh;
                    border-radius: 30px !important;
                    font-weight: bolder;
                    font-size: medium;
                  "
                  elevation="0"
                  class="darken-1"
                  type="submit"
                  color="primary"
                  >{{ $t('buttons.add') }}</v-btn
                >
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: var(--red);
                    padding: 2vh 2vh;
                    border-radius: 30px !important;
                    font-weight: bolder;
                    font-size: medium;
                  "
                  elevation="0"
                  class="darken-1"
                  @click="resetFileFormDialog"
                  >{{ $t('buttons.cancel') }}</v-btn
                >
              </v-card-actions>
            </form>
          </v-card-text>
        </ValidationObserver>
      </v-card>
 
    </v-dialog>
    <v-card elevation="1">
      <v-card-title style="color: var(--darkBlue)">
        <v-row justify="space-between">
          <v-icon
            v-if="!showTabScreen"
            @click.stop="
              showTabScreen = true;
              getOrders();
            "
            style="color: #0177d4; font-size: 36px"
            class="mr-2"
          >
            mdi-arrow-left
          </v-icon>
          <v-col
            v-if="showTabScreen"
            class="text-md-left text-center pb-0 pb-sm-2"
            cols="12"
            sm="12"
            md="9"
            lg="9"
            xl="9"
          >
            <h5>{{ $t('navigationDrawerMenu.warehouse') }}</h5>
          </v-col>
          <v-col
            v-if="!showTabScreen"
            class="text-lg-left text-center pb-0 pb-sm-2"
            cols="12"
            sm="12"
            md="9"
            lg="9"
            xl="9"
          >
            <h5>{{ $t('labels.showLabel') }} / {{ selectedFile.RefNo }}</h5>
          </v-col>
        </v-row>
        <v-col
          class="d-flex align-center justify-end pb-0 pb-sm-2"
          cols="12"
          sm="12"
          md="3"
          lg="3"
          xl="3"
        >
          <v-btn
            v-if="
              selectedOrderStatus ||
              orderFrom ||
              orderTo ||
              search ||
              searchTrackingNo ||
              searchRefNo
            "
            variant="tonal"
            color="#0177d4"
            class="button"
            style="
              color: white;
              border-radius: 14px !important;
              margin-right: 4px;
            "
            @click="resetFilter"
          >
            {{ $t('buttons.clearFilter') }}
          </v-btn>

          <v-btn
            @click="downloadData"
            :style="'color: white; background-color:#0177d4'"
            class="rounded-circle mr-2"
            style="min-width: 1px; min-height: 1px; width: 36px; height: 36px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small color="white" style="font-size: 20px" v-on="on">
                  mdi-file-download
                </v-icon>
              </template>
              <span>{{ $t('labels.downloadData') }}</span>
            </v-tooltip>
          </v-btn>
          <RefreshButton :refreshFunction="getOrders"></RefreshButton>
        </v-col>
      </v-card-title>
      <div v-if="!showTabScreen" class="contract-view" style="padding: 20px">
        <iframe
          :src="selectedFile.WarehouseLabel"
          width="100%"
          height="1000"
          frameborder="0"
        ></iframe>
      </div>
      <v-divider />
      <v-card-text v-if="showTabScreen" fill-height fluid>
        <v-row align="center" justify="start" class="d-flex">
          <v-col cols="12" class="align-center">
            <v-row class="align-center pt-3">
              <FormElement
                v-model="search"
                :validationLabel="$t('labels.customerName')"
                :formLabel="$t('labels.customerName')"
                @input="getSearchDebounced"
                :formType="{
                  name: 'text',
                  dense: true,
                  appendIcon: 'mdi-magnify',
                  appendClick: () => {
                    getSearchDebounced();
                  },
                }"
                :labelShown="false"
                :cols="4"
                :sm="3"
                :md="2"
                :lg="2"
                :xl="2"
                :topLabel="true"
                :hideDetails="true"
                :placeholder="$t('labels.customerName')"
                :classes="'pb-2 pb-sm-0'"
              />
              <!-- <FormElement
                v-model="searchSenderName"
                :validationLabel="$t('labels.senderName')"
                :formLabel="$t('labels.senderName')"
                @input="getSearchDebounced"
                :formType="{
                  name: 'text',
                  dense: true,
                  appendIcon: 'mdi-magnify',
                  appendClick: () => {
                    getSearchDebounced();
                  },
                }"
                :labelShown="false"
                :cols="3"
                :sm="3"
                :md="2"
                :lg="2"
                :xl="2"
                :topLabel="true"
                :hideDetails="true"
                :placeholder="$t('labels.senderName')"
                :classes="'pb-2 pb-sm-0'"
              /> -->
              <FormElement
                v-model="searchTrackingNo"
                :validationLabel="$t('labels.cargoTrackingNumber')"
                :formLabel="$t('labels.cargoTrackingNumber')"
                @input="getSearchDebounced"
                :formType="{
                  name: 'text',
                  dense: true,
                  appendIcon: 'mdi-magnify',
                  appendClick: () => {
                    getSearchDebounced();
                  },
                }"
                :labelShown="false"
                :cols="4"
                :sm="3"
                :md="2"
                :lg="2"
                :xl="2"
                :topLabel="true"
                :hideDetails="true"
                :placeholder="$t('labels.cargoTrackingNumber')"
                :classes="'pb-2 pb-sm-0'"
              />
              <FormElement
                v-model="searchRefNo"
                :validationLabel="$t('labels.referenceNumber')"
                :formLabel="$t('labels.referenceNumber')"
                @input="getSearchDebounced"
                :formType="{
                  name: 'text',
                  dense: true,
                  appendIcon: 'mdi-magnify',
                  appendClick: () => {
                    getSearchDebounced();
                  },
                }"
                :labelShown="false"
                :cols="4"
                :sm="3"
                :md="2"
                :lg="2"
                :xl="2"
                :topLabel="true"
                :hideDetails="true"
                :placeholder="$t('labels.referenceNumber')"
                :classes="'pb-2 pb-sm-0'"
              />
              <v-col
                :cols="4"
                :sm="3"
                :md="2"
                :lg="2"
                :xl="2"
                class="py-0 pl-1 pr-1 pb-2 pb-sm-0"
              >
                <!-- <v-text-field
                  clearable
                  variant="outlined"
                  v-model="orderFrom"
                  :value="orderFrom"
                  :label="$t('labels.orderFrom')"
                  :append-icon="orderFrom ? 'mdi-arrow-right-bold' : null"
                  @click:append="filterContent"
                  @keydown.enter.prevent="filterContent"
                ></v-text-field> -->
                <v-autocomplete
                  class="custom-border"
                  v-model="orderFrom"
                  :value="orderFrom"
                  :items="
                    countryItems.concat({ Name: $t('labels.All'), Value: '' })
                  "
                  :label="$t('titles.from')"
                  item-text="Name"
                  item-value="Value"
                  @change="filterContent()"
                  clearable
                  dense
                  hide-details
                  variant="solo"
                  outlined
                >
                </v-autocomplete>
              </v-col>
              <v-col
                :cols="4"
                :sm="3"
                :md="2"
                :lg="2"
                :xl="2"
                class="py-0 pl-1 pr-1"
              >
                <!-- <v-text-field
                  clearable
                  variant="outlined"
                  v-model="orderTo"
                  :value="orderTo"
                  :label="$t('labels.orderTo')"
                  :append-icon="orderTo ? 'mdi-arrow-right-bold' : null"
                  @click:append="filterContent"
                  @keydown.enter.prevent="filterContent"
                ></v-text-field> -->
                <v-autocomplete
                  class="custom-border"
                  v-model="orderTo"
                  :value="orderTo"
                  :items="
                    countryItems.concat({ Name: $t('labels.All'), Value: '' })
                  "
                  :label="$t('titles.to')"
                  item-text="Name"
                  item-value="Value"
                  @change="filterContent()"
                  clearable
                  dense
                  hide-details
                  variant="solo"
                  outlined
                >
                </v-autocomplete>
              </v-col>
              <v-col
                :cols="4"
                :sm="3"
                :md="2"
                :lg="2"
                :xl="2"
                class="py-0 pl-1 pr-1"
              >
                <v-autocomplete
                  class="custom-border"
                  v-model="selectedOrderStatus"
                  :value="selectedOrderStatus"
                  :items="orderStatuses"
                  :label="$t('labels.orderStatus')"
                  item-text="name"
                  item-value="value"
                  @change="filterContent()"
                  clearable
                  dense
                  hide-details
                  variant="solo"
                  outlined
                >
                </v-autocomplete>
              </v-col>
              <v-col
                :cols="4"
                :sm="3"
                :md="2"
                :lg="2"
                :xl="2"
                class="py-0 pl-1 pr-1"
              >
                <v-autocomplete
                  class="custom-border"
                  v-model="selectedCostType"
                  :value="selectedCostType"
                  :items="additionalCostTypes"
                  :label="$t('labels.additionalCostType')"
                  item-text="name"
                  item-value="value"
                  @change="filterContent()"
                  clearable
                  dense
                  hide-details
                  variant="solo"
                  outlined
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="4"
                sm="3"
                md="2"
                lg="2"
                xl="2"
                class="mt-0 px-2"
                style="margin: 0px; padding: 0px"
              >
                <!-- begin start date picker -->
                <v-dialog
                  ref="startDateDialog"
                  v-model="startDateModalDialog"
                  :return-value.sync="dateModal.startDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDateFormat"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      :label="$t('labels.startDate')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateModal.startDate"
                    :locale="$i18n.locale"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="startDateModalDialog = false"
                    >
                      {{ $t('buttons.close') }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.startDateDialog.save(dateModal.startDate);
                        filterContent();
                      "
                    >
                      {{ $t('buttons.select') }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <!-- end start date picker -->
              </v-col>
              <v-col
                cols="4"
                sm="3"
                md="2"
                lg="2"
                xl="2"
                class="mt-0 px-2"
                style="margin: 0px; padding: 0px"
              >
                <!-- begin start date picker -->
                <v-dialog
                  ref="endDateDialog"
                  v-model="endDateModalDialog"
                  :return-value.sync="dateModal.endDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDateFormat"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      :label="$t('labels.endDate')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateModal.endDate"
                    :locale="$i18n.locale"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="endDateModalDialog = false"
                    >
                      {{ $t('buttons.close') }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.endDateDialog.save(dateModal.endDate);
                        filterContent();
                      "
                    >
                      {{ $t('buttons.select') }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <!-- end start date picker -->
              </v-col>
              <!-- <v-col cols="2" class="py-0">
                <v-select
                  class="custom-border"
                  v-model="selectedShipmentType"
                  :value="selectedShipmentType"
                  :items="shipmentTypes"
                  :label="$t('labels.shipmentType')"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                  hide-details
                  @change="filterContent()"
                >
                </v-select>
              </v-col> -->
            </v-row>
          </v-col>
          <v-col cols="12" class="align-center">
            <v-row class="align-center">
              <v-col cols="4" class="mb-4">
                <v-btn
                  v-if="endDateFormat || startDateFormat"
                  variant="tonal"
                  color="#0177d4"
                  class="button"
                  style="color: white; border-radius: 14px !important"
                  @click="dateReset"
                >
                  {{ $t('buttons.resetDates') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="orders"
          :options.sync="options"
          :footer-props="{
            disablePagination: true,
            disableItemsPerPage: true,
          }"
          :no-data-text="$t('description.noList')"
          style="padding: 0 16px"
          hide-default-footer
          :loading="$store.state.loadingStatus"
          ><!-- eslint-disable -->
          <template v-slot:item.Alerts="{ item }">
            <v-tooltip>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.AdditionalShipmentCosts.length > 0"
                  small
                  color="red"
                  class="mr-1"
                  style="font-size: 20px"
                  @click="openAlerts(item)"
                >
                  mdi-alert-circle
                </v-icon>
              </template>
              <span>{{ $t('labels.additionalCostType') }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.ExpressServiceType="{ item }">
            <div style="white-space: normal; word-wrap: break-word">
              {{ $t(expressServiceTypes[item.ExpressServiceType]) }}
            </div>
          </template>
          <template v-slot:item.CreatedOnUtc="{ item }">
            {{ convertToLocal(item.CreatedOnUtc) }}
          </template>
          <template v-slot:item.EntranceToWarehouseDate="{ item }">
            <v-chip
              v-if="
                (new Date(item.ExitFromWarehouseDate) > new Date() ||
                  !item.ExitFromWarehouseDate) &&
                (!item.EntranceToWarehouseDate ||
                  new Date(item.EntranceToWarehouseDate) > new Date())
              "
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.willBeComingToWarehouse') }}
            </v-chip>

            <v-chip
              v-if="
                item.EntranceToWarehouseDate &&
                new Date(item.EntranceToWarehouseDate) <= new Date() &&
                (!item.ExitFromWarehouseDate ||
                  new Date(item.ExitFromWarehouseDate) > new Date())
              "
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.inWarehouse') }}
            </v-chip>
            <v-chip
              v-if="
                item.ExitFromWarehouseDate &&
                new Date(item.ExitFromWarehouseDate) < new Date()
              "
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.outWarehouse') }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="text-start">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="#29B7B7"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="openInformation(item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>{{ $t('titles.details') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="#29B7B7"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="openFileOperation(item)"
                  >
                    mdi-file-outline
                  </v-icon>
                </template>
                <span>{{ $t('labels.tooltipFileOperations') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.WarehouseLabel != null"
                    small
                    :color="item.IsDownloaded ? '#29B7B7' : 'red'"
                    @click="navigateContract(item)"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-1"
                    style="font-size: 20px"
                  >
                    mdi-file-download-outline
                  </v-icon>
                </template>
                <span>{{ $t('labels.tooltipDownloadLabelSingular') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="#29B7B7"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="openDateTimePickerDialog(item)"
                  >
                    mdi-calendar-clock
                  </v-icon>
                </template>
                <span>{{ $t('labels.tooltipDateTime') }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:footer>
            <div class="d-flex justify-center mt-8">
              <v-row align="center" justify="center">
                <v-col
                  v-if="
                    $vuetify.breakpoint.md ||
                    $vuetify.breakpoint.lg ||
                    $vuetify.breakpoint.xl
                  "
                  md="2"
                ></v-col>
                <v-col cols="9" sm="12" md="7" lg="7" xl="7">
                  <v-pagination
                    class="my-4"
                    @input="paginationChangeHandler"
                    :value="page"
                    :length="pages"
                    circle
                    :total-visible="$vuetify.breakpoint.xs ? 10 : 10"
                  ></v-pagination>
                </v-col>
                <v-col
                  cols="11"
                  sm="11"
                  md="3"
                  lg="3"
                  xl="3"
                  class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
                >
                  <v-select
                    v-model="rowsPerPage"
                    :items="[5, 10, 15, 20, 50, 100]"
                    :label="$t('labels.selectItemsPerPage')"
                    item-value="value"
                    item-text="name"
                    outlined
                    dense
                    hide-details
                    @change="changeItemsPerPage"
                    rounded
                  >
                  </v-select>
                </v-col>
              </v-row>
            </div>
          </template>
        </v-data-table>

        <v-dialog
          v-model="showInformation"
          max-width="800"
          @input="resetDialog"
          @close="resetDialog"
        >
          <v-card class="popup-card pb-2" elevation="1">
            <v-card-title
              class="popupHeadline d-flex justify-center"
              style="font-size: 16px"
            >
              {{ $t('labels.dimensionSummary') }}
            </v-card-title>

            <v-divider></v-divider>
            <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
              <v-data-table
                :headers="shipmentBoxHeaders"
                :items="shipmentBoxes"
                hide-default-footer
              >
                <template v-slot:item.actions="{ item }">
                  <div class="text-start">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          color="#29B7B7"
                          class="mr-1"
                          style="font-size: 20px"
                          v-bind="attrs"
                          v-on="on"
                          @click="editBox(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>{{ $t('labels.tooltipEdit') }}</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
              <v-divider class="mt-4"></v-divider>
              <div class="mt-2 d-flex justify-center">
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: var(--red);
                    padding: 2vh 2vh;
                    border-radius: 30px !important;
                    font-weight: bolder;
                    font-size: medium;
                  "
                  elevation="0"
                  class="darken-1 mr-2"
                  @click="resetDialog"
                  >{{ $t('buttons.cancel') }}</v-btn
                >
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: #0077d5;
                    padding: 2vh 3vh;
                    border-radius: 30px !important;
                    font-weight: bolder;
                    font-size: medium;
                  "
                  elevation="0"
                  class="darken-1"
                  color="primary"
                  @click="saveShipmentBox"
                  >{{ $t('buttons.save') }}</v-btn
                >
              </div>
            </ValidationObserver>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="showEditBox"
          @input="resetEditDialog"
          @close="resetEditDialog"
          max-width="600"
        >
          <v-card class="popup-card" elevation="1">
            <v-card-title
              class="popupHeadline d-flex justify-center"
              style="font-size: 16px"
            >
              {{ $t('labels.editIntegration') }}
            </v-card-title>

            <v-divider></v-divider>
            <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
              <v-card-text class="mt-2">
                <form @submit.prevent="handleSubmit(saveEditBox)">
                  <v-row class="mt-5">
                    <FormElement
                      v-for="(element, index) in formElement"
                      :key="
                        element.key.parent +
                        '_' +
                        element.key.child +
                        '_' +
                        index
                      "
                      v-model="$data[element.key.parent][element.key.child]"
                      :validationLabel="$t(element.validationLabel)"
                      :rules="element.rules"
                      :formLabel="$t(element.formLabel)"
                      :onChange="element.onChange"
                      :formType="element.formType"
                      :lg="element.lg"
                      :disabled="element.disabled"
                      :cols="element.cols"
                      :hidden="element.hidden"
                      :labelShown="element.labelShown"
                      :topLabel="element.topLabel"
                      :active="element.active"
                      :placeholder="$t(element.placeholder)"
                    />
                  </v-row>
                  <v-card-actions class="d-flex justify-center">
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: #0077d5;
                        padding: 2vh 3vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      type="submit"
                      color="primary"
                      >{{ $t('buttons.save') }}</v-btn
                    >
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: var(--red);
                        padding: 2vh 2vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      @click="resetEditDialog"
                      >{{ $t('buttons.cancel') }}</v-btn
                    >
                  </v-card-actions>
                </form>
              </v-card-text>
            </ValidationObserver>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="showFileOperationDialog"
          @input="resetFileOperationDialog"
          max-width="600"
        >
          <v-card class="popup-card" elevation="1">
            <v-card-title
              class="popupHeadline d-flex justify-center"
              style="font-size: 16px"
            >
              {{ $t('labels.tooltipFileOperations') }}
            </v-card-title>
            <v-data-table
                :headers="[
                  {
                    text: $t('labels.fileType'),
                    value: 'IconSrc',
                    sortable: false,
                    align: 'center',
                  },
                  {
                    text: $t('labels.fileName'),
                    value: 'FileName',
                    sortable: false,
                    align: 'center',
                  },
                  {
                    text: $t('labels.description'),
                    value: 'Description',
                    sortable: false,
                    align: 'center',
                  },
                  {
                    text: $t('title.tableHeaderCreateDate'),
                    value: 'CreatedOnUtc',
                    sortable: false,
                    align: 'center',
                  },
                  {
                    text: $t('labels.actions'),
                    value: 'actions',
                    sortable: false,
                    align: 'center',
                  },
                ]"
                :items="fileOperationItem.files"
                hide-default-footer
                :loading="$store.state.loadingStatus"
                :item-key="'Id'"
                :no-data-text="$t('messages.noData')"
              >
              <template v-slot:no-data>
                <div class="my-10 d-flex flex-column align-center">
                  <h4>{{ $t('messages.noData') }}</h4>
                </div>
              </template>
              <template v-slot:item.IconSrc="{ item }">
                <div class="d-flex justify-center">
                  <v-img
                    :lazy-src="item.IconSrc"
                    contain
                    height="40"
                    width="40"
                    :src="item.IconSrc"
                  >
                  </v-img>
                </div>
              </template>
              <!-- eslint-disable -->
              <template v-slot:item.CreatedOnUtc="{ item }">
                {{ convertToLocal(item.CreatedOnUtc) }}
              </template>
              <template v-slot:item.Description="{ item }">
                {{ item.Description || "-" }}
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="text-start">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        elevation="0"
                        color="primary"
                        class="button"
                        dark
                        :href="item.Url"
                        depressed
                        icon
                        target="_blank"
                        download
                        >
                          <v-icon> mdi-arrow-down </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('buttons.download') }}</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
            <v-card-actions class="justify-center">
              <v-btn
                variant="tonal"
                color="var(--primary-color)"
                style="
                  color: #fff;
                  text-transform: unset !important;
                  padding: 2vh 4vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: large;
                "
                @click="formModal = true"
                elevation="0"
                class="darken-1"
                type="submit"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                {{ $t('labels.addNewFile') }}
              </v-btn>
              <v-btn
                variant="tonal"
                color="red"
                style="
                  color: #fff;
                  text-transform: unset !important;
                  padding: 2vh 4vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: large;
                "
                @click="resetFileOperationDialog"
                elevation="0"
                class="darken-1"
                type="submit"
              >
                {{ $t('buttons.close') }}
              </v-btn>
            </v-card-actions>
            
          </v-card>
        </v-dialog>
        <v-dialog
          content-class="short-content"
          v-model="dateTimeDialog"
          @input="resetDateTimeDialog()"
          @close="resetDateTimeDialog()"
          max-width="600"
        >
          <v-card class="popup-card" elevation="1">
            <v-card-title
              class="popupHeadline d-flex justify-center"
              style="font-size: 16px"
            >
              {{ $t('titles.dateTimeSettings') }}
            </v-card-title>

            <v-divider></v-divider>
            <ValidationObserver
              key="dateTimeSettings"
              v-slot="{ handleSubmit }"
              ref="dateTimeObserver"
            >
              <v-card-text class="mt-2">
                <form @submit.prevent="handleSubmit(null)">
                  <v-row v-if="dateTimeDialog">
                    <FormElement
                      v-for="(element, index) in dateTimeOperationFormElement"
                      :key="
                        element.key.parent +
                        '_' +
                        element.key.child +
                        '_' +
                        index
                      "
                      v-model="$data[element.key.parent][element.key.child]"
                      :validationLabel="$t(element.validationLabel)"
                      :rules="element.rules"
                      :formLabel="$t(element.formLabel)"
                      :onChange="element.onChange"
                      :formType="element.formType"
                      :lg="element.lg"
                      :disabled="element.disabled"
                      :cols="element.cols"
                      :hidden="element.hidden"
                      :labelShown="element.labelShown"
                      :topLabel="element.topLabel"
                      :hideDetails="element.hideDetails"
                      :active="element.active"
                      :placeholder="$t(element.placeholder)"
                    />
                    <p
                      class="mb-6"
                      style="
                        margin: 5px;
                        flex: 1;
                        color: red;
                        font-size: 16px;
                        font-weight: normal;

                        text-align: center;
                      "
                      v-if="
                        dateTimeOperationItem.PaymentTarget === 1 &&
                        dateTimeOperationItem.PaymentTime === 0 &&
                        dateTimeOperationItem.PaymentStatus !== 0
                      "
                    >
                      {{ $t('labels.cannotSelectExitDate') }}
                    </p>
                  </v-row>
                  <v-card-actions class="d-flex justify-center">
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: #0077d5;
                        padding: 2vh 3vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      color="primary"
                      @click="dateTimeOperationSubmit"
                      type="button"
                      >{{ $t('labels.tooltipEdit') }}</v-btn
                    >
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: var(--red);
                        padding: 2vh 2vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      @click="resetDateTimeDialog"
                      type="button"
                      >{{ $t('buttons.cancel') }}</v-btn
                    >
                  </v-card-actions>
                </form>
              </v-card-text>
            </ValidationObserver>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="alertModal"
          max-width="500"
          @input="resetAlertDialog"
          @close="resetAlertDialog"
        >
          <v-card class="popup-card" elevation="1">
            <v-card-title
              class="popupHeadline d-flex justify-center"
              style="font-size: 16px"
            >
              {{ $t('labels.additionalCostType') }}
            </v-card-title>
            <v-divider />
            <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
              <v-card-text class="py-0">
                <v-list v-if="alertItem">
                  <v-list-item
                    v-for="(cost, index) in alertItem.AdditionalShipmentCosts"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="text-center">{{
                        getCostLabel(cost)
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </ValidationObserver>
            <v-card-actions class="justify-center">
              <v-btn
                variant="tonal"
                class="rounded-lg"
                style="
                  color: white;
                  text-transform: none;
                  font-size: medium;
                  font-weight: bold;
                  padding: 1em;
                "
                color="var(--red)"
                @click="resetAlertDialog"
              >
                {{ $t('buttons.close') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RefreshButton from '../components/RefreshButton.vue';
import FormElement from '../components/FormElement.vue';
import OrderService from '../services/OrderService';
import ShipmentBoxService from '../services/ShipmentBoxService';
import {
  calculateVolumeWeight,
  convertBoxesToVolumes,
  convertVolumeToBoxes,
} from '../utils/constants';
import FileService from '../services/FileService';
import AddVolume from './OfferScreens/AddVolume.vue';
import ShipmentService from '../services/ShipmentService';
import TabScreen from '../components/TabScreen.vue';

export default {
  data: () => ({
    options: {
      itemsPerPage: 15,
    },
    showTabScreen: true,
    selectedFile: null,
    orders: [],
    fileTypes: [],
    headers: [],
    shipmentBoxes: [],
    shipmentBoxHeaders: [],
    orderStatuses: [],
    additionalCostTypes: [],
    shipmentTypes: [],
    countryItems: [],
    showInformation: false,
    showEditBox: false,
    showFileOperationDialog: false,
    dateTimeDialog: false,
    selectedItem: null,
    fileOperationItem: {},
    dateTimeOperationItem: null,
    itemToEdit: null,
    form: {},
    fileOperationForm: {},
    dateTimeForm: {},
    totalItems: 0,
    rowsPerPage: 15,
    page: 1,
    perPageValues: [
      {
        name: '5',
        value: 5,
      },
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],
    search: '',
    dateModal: {
      startDate: '',
      endDate: '',
    },
    searchTrackingNo: '',
    searchRefNo: '',
    startDateModalDialog: false,
    endDateModalDialog: false,
    selectedOrderStatus: null,
    selectedCostType: null,
    orderFrom: '',
    orderTo: '',

    packageDetailsModal: false,
    alertModal: false,
    alertItem: null,

    expressServiceTypes: [],

    formModal: false
  }),
  mounted() {
    console.log(new Date());
    console.log(new Date('2024-06-19T12:44:07.721'));
    console.log(new Date('2024-06-19T12:44:07.721') < new Date());
    //TODO date comparison with getTime function
    this.shipmentTypes = [
      {
        name: this.$t('labels.All'),
        value: null,
      },
      {
        name: this.$t('labels.cargo'),
        value: 1,
      },
      {
        name: this.$t('labels.express'),
        value: 2,
      },
    ];
    this.orderStatuses = [
      {
        name: this.$t('labels.All'),
        value: null,
      },
      {
        name: this.$t('labels.labelsOrderStatuses.willBeComingToWarehouse'),
        value: 0,
      },
      {
        name: this.$t('labels.labelsOrderStatuses.inWarehouse'),
        value: 1,
      },
      {
        name: this.$t('labels.labelsOrderStatuses.outWarehouse'),
        value: 2,
      },
    ];
    this.additionalCostTypes = [
      {
        name: this.$t('labels.circumference'),
        value: 0,
      },
      {
        name: this.$t('labels.ddp'),
        value: 1,
      },
      {
        name: this.$t('labels.handling'),
        value: 2,
      },
      {
        name: this.$t('labels.personalDelivery'),
        value: 3,
      },
      {
        name: this.$t('labels.oversize'),
        value: 4,
      },
      {
        name: this.$t('labels.insurance'),
        value: 5,
      },
      {
        name: this.$t('labels.signedDelivery'),
        value: 6,
      },
    ];
    this.headers = [
      {
        text: this.$t('labels.Alerts'),
        value: 'Alerts',
        sortable: true,
        align: 'center',
      },
      {
        text: this.$t('labels.customerFullName'),
        value: 'Customer',
        sortable: false,
        groupable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.sender'),
        value: 'Sender',
        sortable: false,
        groupable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.cargoTrackingNumber'),
        value: 'TrackingNo',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.referenceNumber'),
        value: 'RefNo',
        sortable: true,
        align: 'center',
      },
      {
        text: this.$t('titles.expressServiceType'),
        value: 'ExpressServiceType',
        sortable: false,
        align: 'center',
        width: '20%',
      },
      {
        text: this.$t('labels.shipmentType'),
        value: 'ShipmentType',
        sortable: false,
        align: 'center',
      },
      // {
      //   text: this.$t('labels.loadType'),
      //   value: 'LoadingType',
      //   sortable: false,
      //   align: 'center',
      // },

      {
        text: this.$t('labels.dateAsNoun'),
        value: 'CreatedOnUtc',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.orderStatus'),
        value: 'EntranceToWarehouseDate',
        sortable: true,
        align: 'center',
      },
      // {
      //   text: this.$t('labels.price'),
      //   value: 'Price',
      //   sortable: true,
      //   align: 'center',
      // },
      {
        text: this.$t('title.tableHeaderActions'),
        value: 'actions',
        sortable: false,
      },
    ];
    this.shipmentBoxHeaders = [
      {
        text: this.$t('labels.numOfContainers'),
        value: 'CapPieces',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.productType'),
        value: 'CarriageType',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.Height'),
        value: 'Height',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.Length'),
        value: 'Length',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.Width'),
        value: 'Width',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.Weight'),
        value: 'Weight',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('title.tableHeaderActions'),
        value: 'actions',
        sortable: false,
      },
    ];
    this.fileTypes = [
      {
        Name: this.$t('labels.damageFileType'),
        Id: 6,
      },
      {
        Name: this.$t('labels.otherFileType'),
        Id: 3,
      },
    ];

    this.expressServiceTypes = [
      'labels.ETGBExpress',
      'labels.ETGBEconomy',
      'labels.ExitWithDeclaration',
      'labels.Economic',
      'labels.ExpressSample',
    ];

    this.getCountries();
    this.getOrders();
  },
  components: {
    RefreshButton,
    FormElement,
    AddVolume,
    TabScreen
  },

  computed: {
    pages() {
      if (this.rowsPerPage == null || this.totalItems == null) {
        return 0;
      }
      return Math.ceil(this.totalItems / this.rowsPerPage);
    },
    calculateParameter() {
      switch (this.selectedItem.ShipmentTypeNumeric) {
        case 3:
          return 1000;
        case 1:
          return 166.67;
        case 2:
          return 333;
      }

      return 166.67;
    },
    convertedVolumes() {
      if (this.shipmentBoxes.length > 0)
        return convertBoxesToVolumes(
          this.shipmentBoxes,
          this.selectedItem.ShipmentTypeNumeric
        );
      return [];
    },
    formElement() {
      return [
        {
          key: {
            parent: 'form',
            child: 'CarriageType',
          },
          validationLabel: 'labels.productType',
          topLabel: true,
          labelShown: false,
          disabled: true,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.productType',
        },
        {
          key: {
            parent: 'form',
            child: 'CapPieces',
          },
          rules: 'required|numeric',
          validationLabel: 'labels.numOfContainers',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.numOfContainers',
        },
        {
          key: {
            parent: 'form',
            child: 'Height',
          },
          rules: 'required|numeric',
          validationLabel: 'labels.Height',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.Height',
        },
        {
          key: {
            parent: 'form',
            child: 'Length',
          },
          rules: 'required|numeric',
          validationLabel: 'labels.Length',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.Length',
        },
        {
          key: {
            parent: 'form',
            child: 'Weight',
          },
          rules: 'required|numeric',
          validationLabel: 'labels.Weight',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.Weight',
        },
        {
          key: {
            parent: 'form',
            child: 'Width',
          },
          rules: 'required|numeric',
          validationLabel: 'labels.Width',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.Width',
        },
      ];
    },
    fileOperationFormElement() {
      return [
        {
          key: {
            parent: 'fileOperationForm',
            child: 'FileType',
          },
          rules: 'required',
          validationLabel: 'labels.fileType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.fileTypes,
              value: 'Id',
              text: 'Name',
            },
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.fileType',
        },
        {
          key: {
            parent: 'fileOperationForm',
            child: 'description',
          },
          rules: 'required',
          validationLabel: 'labels.description',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.description',
        },
        {
          key: {
            parent: 'fileOperationForm',
            child: 'file',
          },
          rules: 'required',
          validationLabel: 'labels.fileUpload',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'file',
            data: {
              accept: 'image/png, image/jpeg, image/bmp, application/pdf ',
            },
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.fileUpload',
        },
      ];
    },
    dateTimeOperationFormElement() {
      return [
        {
          key: {
            parent: 'dateTimeForm',
            child: 'EntranceToWarehouseDate',
          },

          validationLabel: 'labels.entranceToWarehouseDate',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'date',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.entranceToWarehouseDate',
        },
        {
          key: {
            parent: 'dateTimeForm',
            child: 'ExitFromWarehouseDate',
          },
          disabled:
            this.dateTimeOperationItem.PaymentTarget === 1 &&
            this.dateTimeOperationItem.PaymentTime === 0 &&
            this.dateTimeOperationItem.PaymentStatus !== 0,
          validationLabel: 'labels.exitFromWarehouseDate',
          topLabel: true,
          labelShown: false,
          hideDetails:
            this.dateTimeOperationItem.PaymentTarget === 1 &&
            this.dateTimeOperationItem.PaymentTime === 0 &&
            this.dateTimeOperationItem.PaymentStatus !== 0,
          formType: {
            name: 'date',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.exitFromWarehouseDate',
        },
      ];
    },
    startDateFormat() {
      return this.dateModal.startDate
        ? convertToLocal(this.dateModal.startDate)
        : null;
    },
    endDateFormat() {
      return this.dateModal.endDate
        ? convertToLocal(this.dateModal.endDate)
        : null;
    },
  },

  methods: {
    downloadData() {
      this.$store.state.loadingStatus = true;
      OrderService.downloadOrders(
        this.dateModal.startDate,
        this.dateModal.endDate,
        this.selectedOrderStatus,
        this.selectedCostType,
        null,
        this.orderFrom,
        this.orderTo,
        true,
        this.search,
        this.searchTrackingNo,
        this.searchRefNo
      )
        .then((response) => {
          window.showSuccess(this.$t('labels.downloadSuccessfully'));
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getCostLabel(costValue) {
      const costType = this.additionalCostTypes.find(
        (type) => type.value === costValue
      );
      return costType ? costType.name : 'Unknown';
    },
    openAlerts(item) {
      this.alertItem = item;
      this.alertModal = true;
    },
    resetAlertDialog() {
      this.alertModal = false;
      this.alertItem = null;
    },
    paginationChangeHandler(pageNumber) {
      this.page = pageNumber;
      this.getOrders();
    },
    changeItemsPerPage() {
      this.page = 1;
      this.options.itemsPerPage = this.rowsPerPage;
      this.getOrders();
    },
    onSaveVolumes(volumes, parameters) {
      const { totalContainerCount, totalVolume, totalKilo, chargeableWeight } =
        parameters;

      const updateVolumes = () => {
        this.shipment.SumCapPieces = totalContainerCount;
        this.shipment.SumWeight = totalKilo;
        this.shipment.SumVolume = totalVolume;
        this.shipment.ChargeableWeight = chargeableWeight;

        this.shipment.ShipmentBoxes = convertVolumeToBoxes(volumes);

        this.shipment = Object.assign({}, this.shipment);
      };

      const onConfirm = () => {
        const converted = convertVolumeToBoxes(volumes);

        this.$store.state.loadingStatus = true;
        ShipmentBoxService.update({
          Boxes: converted,
          ChargeableWeight: chargeableWeight,
          TotalCapPieces: totalContainerCount,
          SumWeight: totalKilo,
          SumVolume: totalVolume,
          ShipmentId: this.selectedItem.ShipmentId,
        })
          .then((response) => {
            window.showSuccess(this.$t('messages.updateBoxesSuccessfully'));
            this.getOrders();
          })
          .catch((error) => {
            window.showError(error);
            this.$store.state.loadingStatus = false;
          });
      };

      onConfirm();
    },
    downloadDocument(file) {
      console.log('File >> ', file);
      const body = {
        s3Uri: file.WarehouseLabel,
      };

      OrderService.downloadFile(body, file.FileName);
    },
    getCountries() {
      this.countryItems = [];
      this.$store.state.loadingStatus = true;
      OrderService.getCountries()
        .then((res) => {
          res.data.Result.forEach((element) => {
            this.countryItems.push({
              Name: element.Name,
              Value: element.Id,
            });
          });
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },
    getSearchDebounced() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.rowsPerPage = 15;
        this.page = 1;
        this.getSearch();
      }, 300);
    },
    getSearch() {
      this.$store.state.loadingStatus = true;
      this.orders = [];
      OrderService.getWarehouseOrders(
        this.rowsPerPage,
        this.page,
        this.dateModal.startDate,
        this.dateModal.endDate,
        this.selectedOrderStatus,
        null,
        null,
        this.orderFrom,
        this.orderTo,
        true,
        this.search,
        this.searchTrackingNo,
        this.searchRefNo
      )
        .then((res) => {
          this.orders = res.data.Result == null ? [] : res.data.Result;
          this.totalItems =
            res.data.TotalItems == null ? 0 : res.data.TotalItems;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    editBox(item) {
      this.showEditBox = true;
      this.itemToEdit = item;
      this.form = {
        CarriageType: item.CarriageType,
        CapPieces: item.CapPieces,
        Height: item.Height,
        Length: item.Length,
        Weight: item.Weight,
        Width: item.Width,
      };
    },
    openInformation(item) {
      //this.showInformation = true;
      this.selectedItem = item;
      this.shipmentBoxes = [];
      this.$store.state.loadingStatus = true;
      ShipmentBoxService.getList(item.ShipmentId)
        .then((response) => {
          this.shipmentBoxes = response.data.Result;
          this.packageDetailsModal = true;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    saveEditBox() {
      this.itemToEdit.CapPieces = this.form.CapPieces;
      this.itemToEdit.Height = this.form.Height;
      this.itemToEdit.Length = this.form.Length;
      this.itemToEdit.Weight = this.form.Weight;
      this.itemToEdit.Width = this.form.Width;
      this.showEditBox = false;
    },
    saveShipmentBox() {
      this.showInformation = false;

      let totalCapPieces = 0;
      let totalVolume = 0;
      let totalWeight = 0;
      let totalChargableWeight = 0;

      this.shipmentBoxes.forEach((box) => {
        let volume =
          (box.CapPieces * box.Height * box.Length * box.Width) / 1000000;
        console.log('Volume', volume);
        let chargeableWeight = calculateVolumeWeight(
          volume,
          this.selectedItem.ShipmentType
        );

        console.log('Chargeable Weight', chargeableWeight);

        totalCapPieces += parseInt(box.CapPieces);
        totalVolume += parseInt(volume);
        totalWeight += parseInt(box.Weight);
        totalChargableWeight += parseInt(chargeableWeight);
      });

      if (totalWeight > totalChargableWeight) {
        totalChargableWeight = totalWeight;
      }

      let body = {
        Boxes: this.shipmentBoxes,
        ChargeableWeight: totalChargableWeight,
        TotalCapPieces: totalCapPieces,
        SumWeight: totalWeight,
        SumVolume: totalVolume,
        ShipmentId: this.selectedItem.ShipmentId,
      };

      this.$store.state.loadingStatus = true;
      ShipmentBoxService.update(body)
        .then(() => {
          this.$store.state.loadingStatus = false;
          window.showSuccess(this.$t('messages.shipmentBoxUpdateSuccess'));
          this.getOrders();
        })
        .catch((error) => {
          this.$store.state.loadingStatus = false;
          console.log(error);
          window.showError(error);
        });
    },
    openFileOperation(item) {
      this.fileOperationItem = item;
      this.getOrderFiles();
    },
    async getOrderFiles(){
      this.$store.state.loadingStatus = true;
      await OrderService.getFileList(this.fileOperationItem.Id)
      .then(response => {
        const files = response.data.Result.OrderFiles;
        this.fileOperationItem.files = files || [];
        this.showFileOperationDialog = true;
      })
      .catch(window.showError)
      .finally(() => {
        this.$store.state.loadingStatus = false;
      });
    },

    openDateTimePickerDialog(item) {
      this.dateTimeOperationItem = item;
      this.dateTimeForm = {
        EntranceToWarehouseDate: item.EntranceToWarehouseDate,
        ExitFromWarehouseDate: item.ExitFromWarehouseDate,
      };
      this.dateTimeDialog = true;
      console.log('Item =>', item.EntranceToWarehouseDate);
    },
    fileOperationSubmit() {
      this.$store.state.loadingStatus = true;

      let fileName =
        this.fileOperationForm.FileType == 6
          ? this.$t('labels.damageNotification')
          : this.fileOperationForm.FileType == 3
          ? this.$t('labels.otherNotification')
          : '';

      let body = {
        OrderId: this.fileOperationItem.Id,
        FileId: null,
        Description: this.fileOperationForm.description,
        FileName: fileName,
        OrderFileType: this.fileOperationForm.FileType,
      };

      let formData = new FormData();
      if (this.fileOperationForm.file == 'application/pdf') {
        formData.append('Files', document);
        formData.append('Type', 'Document');
      } else {
        formData.append('Files', this.fileOperationForm.file);
        formData.append('Type', 'Image');
      }
      console.log('BODY >> ', body);
      FileService.uploadFile(formData).then((response) => {
        body.FileId = response.data.Result[0].Id;
        OrderService.addOrderFile(body)
          .then(async () => {
            window.showSuccess(this.$t('messages.fileAddSuccess'));
            this.getOrders();
            await this.getOrderFiles();
            this.resetFileFormDialog();
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      });
    },
    dateTimeOperationSubmit() {
      this.$store.state.loadingStatus = true;
      if (
        this.dateTimeForm.EntranceToWarehouseDate == null &&
        this.dateTimeForm.ExitFromWarehouseDate == null
      ) {
        window.showWarning(this.$t('labels.atLeastOneDate'));
        this.$store.state.loadingStatus = false;
        return;
      }
      let body = {
        Id: this.dateTimeOperationItem.ShipmentId,
        EntranceToWarehouseDate: this.dateTimeForm.EntranceToWarehouseDate,
        ExitFromWarehouseDate: this.dateTimeForm.ExitFromWarehouseDate,
        UpdateOrder: false,
      };

      ShipmentService.updateShipment(body)
        .then((response) => {
          window.showSuccess(this.$t('messages.dateTimeOfWarehouse'));
          this.getOrders();
          this.resetDateTimeDialog();
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getOrders() {
      this.$store.state.loadingStatus = true;
      this.orders = [];
      OrderService.getWarehouseOrders(
        this.rowsPerPage,
        this.page,
        this.dateModal.startDate,
        this.dateModal.endDate,
        this.selectedOrderStatus,
        this.selectedCostType,
        null,
        this.orderFrom,
        this.orderTo,
        true,
        this.search,
        this.searchTrackingNo,
        this.searchRefNo
      )
        .then((response) => {
          this.orders = response.data.Result;
          this.totalItems = response.data.TotalItems;
          this.$store.state.loadingStatus = false;
        })
        .catch((error) => {
          this.$store.state.loadingStatus = false;
          console.log(error);
        });
    },
    filterContent() {
      this.tableData = [];
      this.rowsPerPage = 15;
      this.options.itemsPerPage = 15;
      this.page = 1;
      this.getOrders();
    },
    dateReset() {
      this.dateModal.startDate = '';
      this.dateModal.endDate = '';
      this.filterContent();
    },
    resetFilter() {
      this.selectedOrderStatus = null;
      this.orderFrom = '';
      this.orderTo = '';
      this.search = '';
      this.searchTrackingNo = '';
      this.searchRefNo = '';
      this.filterContent();
    },
    resetDialog() {
      this.showInformation = false;
      this.selectedItem = null;
      this.shipmentBoxes = [];
    },
    resetEditDialog() {
      this.showEditBox = false;
      this.itemToEdit = null;
    },
    resetFileOperationDialog() {
      this.showFileOperationDialog = false;
      this.fileOperationForm = {};
    },
    resetFileFormDialog() {
      this.formModal = false;
      this.fileOperationForm = {};
      if(this.$refs.observer) this.$refs.observer.reset();
    },
    resetDateTimeDialog() {
      this.dateTimeDialog = false;
      this.dateTimeOperationItem = null;
      this.dateTimeForm = {};
      this.$refs.dateTimeObserver.reset();
    },
    navigateContract(file) {
      console.log('File >> ', file);

      this.$store.state.loadingStatus = true;
      let body = {
        OrderId: file.Id,
        IsDownloaded: true,
      };

      OrderService.updateWarehouseDownloadStatus(body)
        .then((res) => {
          this.selectedFile = file;
          this.showTabScreen = false;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
  },
};
</script>
<style>
.short-content {
  overflow: visible !important;
}
</style>
