var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mt-0",attrs:{"elevation":"1"}},[_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.showCreateOffer),callback:function ($$v) {_vm.showCreateOffer=$$v},expression:"showCreateOffer"}},[_c('CreateOfferForm',{attrs:{"add":true}})],1),_c('div',{staticClass:"flex w-full rounded-tl-sm rounded-tr-sm"},[_c('v-card-title',{staticStyle:{"color":"var(--darkBlue)"}},[_c('v-row',[_c('v-col',{staticClass:"text-lg-left text-center",attrs:{"lg":"5","cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('titles.spotPriceRequests')))])])],1),_c('div',[_c('RefreshButton',{attrs:{"refreshFunction":_vm.getOfferPrices}})],1)],1)],1),_c('v-divider'),_c('v-data-table',{key:"SPOT_OFFERS_PAGE",staticStyle:{"overflow-x":"hidden"},attrs:{"headers":_vm.offersHeaders,"hide-default-footer":"","options":{
      itemsPerPage: _vm.itemsPerPage,
    },"loading":_vm.$store.state.loadingStatus,"items":_vm.filteredItems,"no-data-text":_vm.$t('messages.noData')},scopedSlots:_vm._u([{key:"item.FromToWhere",fn:function({ item }){return [_c('div',{staticStyle:{"white-space":"normal","word-wrap":"break-word"}},[_vm._v(" "+_vm._s(item.From)+" ")]),_c('span',[_vm._v("-")]),_c('div',{staticStyle:{"white-space":"normal","word-wrap":"break-word"}},[_vm._v(" "+_vm._s(item.To)+" ")])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-center mt-8"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[(
              _vm.$vuetify.breakpoint.md ||
              _vm.$vuetify.breakpoint.lg ||
              _vm.$vuetify.breakpoint.xl
            )?_c('v-col',{attrs:{"md":"2"}}):_vm._e(),_c('v-col',{attrs:{"cols":"9","sm":"12","md":"7","lg":"7","xl":"7"}},[(_vm.pageLength > 0)?_c('v-pagination',{staticClass:"my-4",attrs:{"value":_vm.page,"length":_vm.pageLength,"circle":""},on:{"input":_vm.onPageChange}}):_vm._e()],1),_c('v-col',{staticClass:"ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6",attrs:{"cols":"11","sm":"11","md":"3","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"items":_vm.perPageValues,"label":_vm.$t('labels.selectItemsPerPage'),"item-value":"value","item-text":"name","outlined":"","dense":"","rounded":""},on:{"change":_vm.changeItemsPerPage},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"my-10 d-flex flex-column align-center"},[_c('h4',[_vm._v(_vm._s(_vm.$t('messages.noOffer')))])])]},proxy:true},{key:"item.IsReadReceipt",fn:function({ item }){return [(!item.IsReadReceipt)?_c('v-badge',{attrs:{"content":item.NewOfferCount,"color":"error"}},[_c('span',{staticClass:"badge-cell bold-cell"},[_vm._v(_vm._s(_vm.$t('titles.new')))])]):_vm._e()]}},{key:"item.price",fn:function({ item }){return [_vm._v(" "+_vm._s(item.Price + ' ' + _vm.currencyTypes.find((element) => element.Id === item.CurrencyTypeId) .Code)+" ")]}},{key:"item.createdDate",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getFormattedDate(new Date(item.CreatedOnUtc)))+" ")]}},{key:"item.actions",fn:function({ item }){return [(
          _vm.$store.state.Type === 'Customer' ||
          _vm.$store.state.Type === 'PendingCustomer'
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",staticStyle:{"font-size":"24px"},attrs:{"small":"","color":"#29B7B7"},on:{"click":function($event){return _vm.viewTheResult(item.Id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('buttons.viewTheResults')))])]):_vm._e(),(
          _vm.$store.state.Type === 'Supplier' ||
          _vm.$store.state.Type === 'PendingSupplier'
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"button my-2",staticStyle:{"color":"white","background-color":"green"},on:{"click":function($event){_vm.showCreateOffer = true}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('labels.tooltipAccept'))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.tooltipAccept')))])]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }