/**
 * Format Date
 * @param {Date} t
 * @param {String} s "-"
 * @param {String} formatLanguage "en"
 * @param {Array} a [{day: 'numeric'}, {month: 'numeric'}, {year: 'numeric'}]

 */
export default (t = new Date(), s = "-", formatLanguage = 'en', a = null) => {
  a = a ? a : [{day: '2-digit'}, {month: '2-digit'}, {year: 'numeric'}];
  
  if(!t){
    t = new Date()
  }

  function format(m) {
    let f = new Intl.DateTimeFormat(formatLanguage, m);
    return f.format(t);
  }
  return a.map(format).join(s);
};
