import Vue from 'vue';
import store from '../store/store';
const _showError = (error, message = '') => {
  error = error?.response;

  let defaultMsg =
    message ||
    'Beklenmeyen bir hata oluştu, lütfen daha sonra yeniden deneyiniz.';
  if (error) {
    if (error.status == 401 || error.status == 403 || error.status == 407) {
      defaultMsg =
        error?.data?.Message ||
        error.data.ErrorDetail ||
        'Oturumunuz sonlanmıştır, lütfen tekrar giriş yapınız.' ||
        defaultMsg;
      store.dispatch('logout');
    } else if (error.status == 402) {
      console.log('STATE FORCE LOGOUT ALERTJS >> ', store.state.forceLogout);
      store.state.forceLogout = true;
    } else {
      defaultMsg =
        error?.data?.Message || error.data.ErrorDetail || message || defaultMsg;
    }
  } else {
    defaultMsg = message || defaultMsg;
  }

  store.state.alertObject = {
    message: defaultMsg,
    color: error.status === 409 ? 'cyan' : 'error',
    y: 'top',
  };

  if (error) {
    if (error.status === 421 || error?.data?.NoSignature)
      store.state.router.push({
        name: 'profile',
        params: { noSignature: true },
      });
  }
};

const _showSuccess = (msg) => {
  msg = msg ? msg : 'İşleminiz başarılı bir şekilde tamamlandı.';
  store.state.alertObject = { message: msg, color: 'success', y: 'top' };
};

const _showInfo = (msg, type = 'standart', color = 'info') => {
  switch (type) {
    case 'standart':
      store.state.alertObject = { message: msg, color, y: 'top' };
      break;
    case 'notification':
      store.state.alertObject = {
        message: msg,
        color,
        y: 'bottom',
        x: 'right',
      };
      break;
  }
};

const _showWarning = (msg) => {
  store.state.alertObject = { message: msg, color: 'warning', y: 'top' };
};

const _toggleLoading = (val = null) => {
  if (!val) store.state.actionLoading = !store.state.actionLoading;
  else store.state.actionLoading = val;
};

const _showNewContent = (msg) => {
  store.state.newContentButtonObject = {
    message: msg,
    color: 'info',
    y: 'top',
  };
};

window.showError = Vue.prototype.showError = _showError;
window.showSuccess = Vue.prototype.showSuccess = _showSuccess;
window.showInfo = Vue.prototype.showInfo = _showInfo;
window.showWarning = Vue.prototype.showWarning = _showWarning;
window.showNewContent = Vue.prototype.showNewContent = _showNewContent;
window.toggleLoading = Vue.prototype.toggleLoading = _toggleLoading;
window.setLoading = Vue.prototype.setLoading = (isLoading) => {
  store.state.loadingStatus = isLoading;
};
