var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tabs',{staticClass:"custom-tabs",model:{value:(_vm.localActiveTab),callback:function ($$v) {_vm.localActiveTab=$$v},expression:"localActiveTab"}},_vm._l((_vm.slots),function(slot,index){return _c('v-tab',{key:index,staticStyle:{"border-radius":"0px","background-color":"#fff","border-width":"1px","border-bottom-width":"0px","border-color":"lightgray","border-style":"solid"},style:({
        'border-top-left-radius': index === 0 ? '10px' : '0',
        'border-top-right-radius': index === _vm.slots.length - 1 ? '10px' : '0',

        'border-bottom':
          _vm.localActiveTab !== index ? '2px solid lightgray' : 'none',

        'border-top-width': index === _vm.localActiveTab ? '2px' : '1px',

        'border-right-width':
          index === _vm.localActiveTab && index === _vm.slots.length - 1
            ? '2px'
            : index === _vm.localActiveTab
            ? '1px'
            : index === _vm.slots.length - 1
            ? '1px'
            : '0px',

        'border-left-width': index === 0 ? '1px' : '1px',
        'border-left-width': index === _vm.localActiveTab ? '2px' : '1px',

        'font-weight': index === _vm.localActiveTab ? 'black' : 'normal',
        'font-size': index === _vm.localActiveTab ? '14px' : '12px',
      }),on:{"click":function($event){return _vm.changeTab(index)}}},[_vm._v(" "+_vm._s(slot.title)+" "),(slot.unSeenCount)?_c('v-badge',{staticStyle:{"cursor":"pointer","margin-bottom":"45px !important"},attrs:{"overlap":"","content":slot.unSeenCount,"left":"","offset-x":"24","offset-y":"8","color":"#1d2e5b"}}):_vm._e()],1)}),1),_c('v-card',{staticStyle:{"border-radius":"0"}},[_vm._l((_vm.slots),function(slot,index,key){return (_vm.localActiveTab === index)?_vm._t(slot.name):_vm._e()})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }