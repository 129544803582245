<template>
  <div class="d-flex flex-column">
    <v-col cols="12" class="d-flex justify-end align-center">
      <v-btn
        v-if="isAdmin"
        @click="openEditDialog"
        class="ml-auto rounded-circle mr-4"
        style="
          min-width: 1px;
          min-height: 1px;
          width: 36px;
          height: 36px;
          background-color: #0177d4;
        "
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon small color="white" style="font-size: 24px" v-on="on">
              mdi-plus
            </v-icon>
          </template>
          <span>{{ $t('buttons.add') }}</span>
        </v-tooltip>
      </v-btn>
    </v-col>
    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="faq in frequentlyAskedQuestions"
          :key="faq.Id"
        >
          <v-expansion-panel-header>
            <v-row>
              <v-col class="popupHeadline" cols="12">
                <h5>{{ faq.Question }}</h5>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <p>{{ faq.Answer }}</p>
            <v-btn
              v-if="isAdmin"
              @click="editFAQ(faq)"
              color="primary"
              class="rounded mr-5"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              v-if="isAdmin"
              @click="deleteFAQ(faq.Id)"
              color="error"
              class="rounded"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>

    <v-dialog v-model="editDialog" max-width="500">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-center popupHeadline" style="width: 100%">
            <h2 class="headline">
              {{ editMode ? $t('labels.EditFAQ') : $t('labels.AddFAQ') }}
            </h2>
          </div>
        </v-card-title>

        <v-divider class="mb-4" />

        <v-card-text class="pt-4 pb-0">
          <ValidationObserver ref="observer">
            <v-form ref="faqForm" @submit.prevent="submitForm">
              <v-row>
                <FormElement
                  v-model="editedFAQ.Question"
                  :validationLabel="$t('labels.questionHr')"
                  :rules="{ required: true }"
                  :formLabel="$t('labels.questionHr')"
                  :formType="{
                    name: 'text',
                  }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="12"
                  :placeholder="$t('labels.questionHr')"
                />
                <FormElement
                  v-model="editedFAQ.Answer"
                  :validationLabel="$t('labels.questionAnswer')"
                  :rules="{ required: true }"
                  :formLabel="$t('labels.questionAnswer')"
                  :formType="{ name: 'textarea' }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="12"
                  :placeholder="$t('labels.questionAnswer')"
                />
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>

        <v-card-actions
          class="justify-center"
          style="background-color: white; position: sticky; bottom: 0px"
        >
          <v-btn
            style="
              color: #fff;
              text-transform: unset !important;
              background-color: var(--red);
              padding: 1vh 1vh;
              border-radius: 30px !important;
              font-weight: bolder;
              font-size: medium;
            "
            elevation="0"
            class="darken-1"
            type="submit"
            @click="submitForm"
          >
            {{ editMode ? $t('buttons.save') : $t('buttons.add') }}
          </v-btn>
          <v-btn
            style="
              color: #fff;
              text-transform: unset !important;
              background-color: #0077d5;
              padding: 1vh 1vh;
              border-radius: 30px !important;
              font-weight: bolder;
              font-size: medium;
            "
            elevation="0"
            class="darken-1"
            @click="closeEditDialog"
          >
            {{ $t('buttons.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FAQService from '../services/FAQService';
import FormElement from '../components/FormElement.vue';

export default {
  data() {
    return {
      frequentlyAskedQuestions: [],
      editDialog: false,
      editMode: false,

      editedFAQ: {
        Id: null,
        Question: '',
        Answer: '',
      },
    };
  },
  components: {
    FormElement,
  },
  computed: {
    isAdmin() {
      return this.$store.getters.getUserType === 'Admin';
    },
  },
  created() {
    this.fetchFAQs();
  },
  methods: {
    async fetchFAQs() {
      this.$store.state.loadingStatus = true;
      try {
        const response = await FAQService.getFAQs();
        this.frequentlyAskedQuestions = response.data.Result;
      } catch (error) {
        console.error('Error fetching FAQs', error);
      } finally {
        this.$store.state.loadingStatus = false;
      }
    },

    async addFAQ(faq) {
      this.$store.state.loadingStatus = true;
      try {
        await FAQService.addFAQ(faq);
        this.fetchFAQs();
      } catch (error) {
        console.error('Error adding FAQ', error);
      } finally {
        this.$store.state.loadingStatus = false;
      }
    },

    async updateFAQ(faq) {
      this.$store.state.loadingStatus = true;
      try {
        await FAQService.updateFAQ(faq);
        this.fetchFAQs();
      } catch (error) {
        console.error('Error updating FAQ', error);
      } finally {
        this.$store.state.loadingStatus = false;
      }
    },

    async deleteFAQ(id) {
      this.$store.state.loadingStatus = true;
      try {
        await FAQService.deleteFAQ(id);
        this.fetchFAQs();
      } catch (error) {
        console.error('Error deleting FAQ', error);
      } finally {
        this.$store.state.loadingStatus = false;
      }
    },

    openEditDialog() {
      this.editMode = false;
      this.editedFAQ = {
        Id: null,
        Question: '',
        Answer: '',
      };
      this.editDialog = true;
    },

    editFAQ(faq) {
      this.editMode = true;
      this.editedFAQ = { ...faq };
      this.editDialog = true;
    },

    closeEditDialog() {
      this.editDialog = false;
      this.editedFAQ = {
        Id: null,
        Question: '',
        Answer: '',
      };
      this.$refs.observer.reset();
    },

    async submitForm() {
      const { Id, Question, Answer } = this.editedFAQ;

      if (this.editMode) {
        await this.updateFAQ({ Id, Question, Answer });
      } else {
        await this.addFAQ({ Question, Answer });
      }

      this.closeEditDialog();
    },
  },
};
</script>
