var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"unauthenticatedPage"},[_c('v-layout',{attrs:{"wrap":"","align-center":"","justify-center":"","fill-height":""}},[_c('v-container',[_c('v-layout',{attrs:{"wrap":"","align-center":"","justify-center":"","fill-height":""}},[_c('v-card',{staticStyle:{"border-radius":"20px"},style:({
              padding: _vm.$vuetify.breakpoint.xs
                ? '8vh 1.5vh'
                : _vm.$vuetify.breakpoint.sm
                ? '10vh 3.5vh'
                : _vm.$vuetify.breakpoint.md
                ? '6vh 1.5vh'
                : _vm.$vuetify.breakpoint.lg
                ? '6vh 1.5vh'
                : _vm.$vuetify.breakpoint.xl
                ? '6vh 1.5vh'
                : '6vh 1.5vh',
            }),attrs:{"max-width":_vm.$vuetify.breakpoint.xs
                ? '100%'
                : _vm.$vuetify.breakpoint.sm
                ? '75%'
                : _vm.$vuetify.breakpoint.md
                ? '50%'
                : _vm.$vuetify.breakpoint.lg
                ? '40%'
                : _vm.$vuetify.breakpoint.xl
                ? '35%'
                : '35%',"min-width":_vm.$vuetify.breakpoint.xs
                ? '100%'
                : _vm.$vuetify.breakpoint.sm
                ? '75%'
                : _vm.$vuetify.breakpoint.md
                ? '50%'
                : _vm.$vuetify.breakpoint.lg
                ? '40%'
                : _vm.$vuetify.breakpoint.xl
                ? '35%'
                : '35%'}},[_c('v-row',[_c('v-col',{attrs:{"md":"12","xs":"12"}},[_c('div',[(_vm.successful == 2)?_c('v-img',{staticClass:"bg-grey-lighten-2",staticStyle:{"max-width":"200px","margin":"auto"},attrs:{"src":require('@/../public/img/successfull.png')}}):_vm._e(),(_vm.successful == 1)?_c('v-img',{staticClass:"bg-grey-lighten-2",staticStyle:{"max-width":"200px","margin":"auto"},attrs:{"src":require('@/../public/img/close.png')}}):_vm._e(),(_vm.successful != 0)?_c('div',{staticClass:"text-center",style:('font-weight: 700; font-size: 24px; margin-top: 30px;' +
                      (_vm.successful === 1
                        ? 'color: #f35050;'
                        : 'color: #10ad4e;'))},[_vm._v(" "+_vm._s(_vm.successful == 2 ? _vm.$t('messages.successfullLogin') : _vm.$t('messages.failedLogin'))+" ")]):_vm._e()],1)])],1)],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }