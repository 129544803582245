<template>
  <div>
    <div class="unauthenticatedPage">
      <v-layout wrap align-center justify-center fill-height>
        <v-container>
          <v-layout wrap align-center justify-center fill-height>
            <v-card
              v-if="!this.$store.state.loadingStatus"
              style="padding: 10vh 5vh; border-radius: 20px"
              max-width="55%"
              min-width="20%"
              elevation="6"
            >
              <v-row class="text-center">
                <v-col md="12" lg="12" class="d-flex flex-column align-center">
                  <v-icon
                    color="white"
                    x-large
                    :style="
                      'border-radius: 50%; padding: 10px; margin-bottom: 1.75vh; background-color:' +
                      getStyle.backgroundColor
                    "
                    >{{ getStyle.icon }}</v-icon
                  >
                  <div :class="'text-center common ' + getStyle.textClass">
                    {{ getStyle.title }}
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                  style="font-size: 18px; text-wrap: balance"
                  >{{ getStyle.text }}</v-col
                >
                <v-col v-if="isSuccess" cols="12" lg="12"
                  ><v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: #0077d5;
                      padding: 2vh 4vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                    "
                    elevation="0"
                    class="darken-1 mt-4"
                    type="submit"
                    @click="$router.push({ name: 'login' })"
                    color="var(--darkBlue)"
                    dark
                  >
                    Giriş Yap
                  </v-btn></v-col
                >
                <v-col
                  cols="12"
                  lg="12"
                  style="font-weight: bold; color: rgb(225, 172, 172)"
                  v-if="serviceProblem"
                >
                  {{ $t('messages.unexpectedError') }}
                </v-col>
              </v-row>
            </v-card>
          </v-layout>
        </v-container>
      </v-layout>
    </div>
  </div>
</template>
<script>
import AccountService from '../services/AccountService';

export default {
  data() {
    return {
      isSuccess: false,
      serviceProblem: false,
    };
  },
  computed: {
    getStyle() {
      return this.isSuccess
        ? {
            backgroundColor: '#10ad4e',
            textClass: 'succeed',
            text: this.$t('messages.activationSuccessful'),
            icon: 'mdi-check',
            title: this.$t('titles.activationSuccessful'),
          }
        : {
            backgroundColor: '#f35050',
            textClass: 'failed',
            text: this.$t('messages.activationFailed'),
            title: this.$t('titles.activationFailed'),
            icon: 'mdi-close',
          };
    },
  },
  async mounted() {
    const userId = this.$route.query.userId,
      code = this.$route.query.code;

    this.$store.state.loadingStatus = true;
    AccountService.confirmEmail({ userId, code: code.replaceAll(' ', '+') })
      .then((response) => {
        const data = response.data;
        this.isSuccess = !!data.Result;
      })
      .catch((error) => {
        window.showError(error);
        this.serviceProblem = true;
      })
      .finally(() => {
        this.$store.state.loadingStatus = false;
      });
  },
  methods: {},
};
</script>
<style>
.common {
  font-weight: 700;
  font-size: 28px;
}

.failed {
  color: #f35050;
}

.succeed {
  color: #10ad4e;
}
</style>
