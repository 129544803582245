<template>
  <v-card elevation="1">
    <v-dialog v-model="weeklyAverageModal" max-width="400">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-center popupHeadline" style="width: 100%">{{ $t('titles.updateWeeklyAverages') }}</div>
        </v-card-title>
        <v-divider class="mb-4" />
        <v-card-text>
          <FormElement
            key="WEEKLY_AVERAGE_LOAD_COUNT"
            :vertical="true"
            v-model="selectedItem.AverageWeeklyUploadCount"
            :validationLabel="$t('labels.averageWeeklyLoadCount')"
            :topLabel="true"
            :labelShown="false"
            :cols="12"
            :lg="12"
            :xl="12"
            :disabled="true"
            :placeholder="$t('labels.averageWeeklyLoadCount')"
          />
          <FormElement
            key="WEEKLY_AVERAGE_LOAD_WEIGHT"
            :vertical="true"
            v-model="selectedItem.AverageWeeklyUploadWeight"
            :validationLabel="$t('labels.averageWeeklyLoadWeight')"
            :topLabel="true"
            :labelShown="false"
            :cols="12"
            :lg="12"
            :xl="12"
            :disabled="true"
            :placeholder="$t('labels.averageWeeklyLoadCount')"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCustomerNote" max-width="500">
      <v-card class="popup-card" elevation="1">
        <v-card-title
          class="popupHeadline d-flex justify-center"
          style="font-size: 16px"
        >
          <div class="text-center popupHeadline" style="width: 100%">
            {{
              isSupplier ? $t('labels.customerNote') : $t('labels.supplierNote')
            }}
          </div>
        </v-card-title>
        <v-divider class="mb-4" />
        <v-card-text
          style="padding-right: 5%; padding-left: 5%; font-size: 16px"
        >
          {{ customerNote }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center rounded-lg card-action">
          <v-btn
            dark
            color="var(--lightRed)"
            class="button rounded-lg"
            @click="showCustomerNote = false"
          >
            {{ $t('buttons.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showCreateOffer" max-width="1200px">
      <CreateOfferForm
        v-if="showCreateOffer"
        :demandOnFinally="onFinally"
        :demand="true"
        :body="activeBody"
        :showDemandOptions="showDemandOptions"
        :params="{
          airports,
          countries,
          hazmatCodes,
          currencyTypes,
          equipmentTypes,
        }"
      />
    </v-dialog>

    <div class="flex w-full rounded-tl-sm rounded-tr-sm mb-4">
      <v-card-title style="color: var(--darkBlue)">
        <v-row>
          <v-col
            class="text-lg-left text-center pb-0 pb-sm-2"
            cols="12"
            sm="12"
            md="9"
            lg="9"
            xl="9"
          >
            <h3>{{ $t('titles.spotPriceRequests') }}</h3>
          </v-col>
          <v-col
            class="d-flex align-center justify-end pb-0 pb-sm-2"
            cols="12"
            sm="12"
            md="3"
            lg="3"
            xl="3"
          >
            <v-btn
              v-if="
                filters.SHIPMENT_TYPE != -1 ||
                filters.LOAD_TYPE != -1 ||
                filters.SERVICE_TYPE != -1 ||
                filters.FROM != -1 ||
                filters.TO != -1 ||
                filters.STATUS != -1 ||
                filters.CUSTOMER_COMPANY != ''
              "
              variant="tonal"
              color="#0177d4"
              class="button mr-4"
              style="color: white"
              @click="resetFilter"
            >
              {{ $t('buttons.clearFilter') }}
            </v-btn>
            <v-btn
              v-if="!isSupplier && status === 0"
              style="background-color: orange"
              :disabled="isRevisionDisabled"
              class="action-button button my-2 mr-2"
              @click="getActiveForm()"
            >
              {{ $t('buttons.updateOffer') }}
            </v-btn>
            <RefreshButton :refreshFunction="getOfferPrices"></RefreshButton>
          </v-col>
        </v-row>
      </v-card-title>
    </div>

    <v-row style="padding-right: 3%; padding-left: 3%">
      <v-col
        v-if="isSupplier"
        style="background-color: var(--lightGray)"
        cols="12"
        class="my-7"
      >
        <v-row class="align-center py-4 px-2">
          <v-col
            style="margin: 0; padding: 0 10px"
            class="my-1"
            v-if="countries.length > 0"
            v-for="element in filterItems"
            :key="'COLUMN_SELECT_' + element.key"
            :cols="6"
            :sm="6"
            :md="4"
            :lg="2"
            :xl="2"
          >
            <template v-if="!element.items">
              <v-text-field
                @keyup.enter="element.onChange"
                :key="'TEXT_FIELD_' + element.key"
                :items="element.items"
                :placeholder="$t(element.placeholder)"
                :label="$t(element.placeholder)"
                class="custom-border filter-select-item"
                :color="element.color"
                dense
                outlined
                v-model="filters[element.key]"
                hide-details
                variant="underlined"
                :no-data-text="$t('messages.listEmpty')"
              ></v-text-field>
            </template>
            <template
              v-else-if="element.key === 'FROM' || element.key === 'TO'"
            >
              <v-autocomplete
                @change="element.onChange"
                :key="'AUTOCOMPLETE_' + element.key"
                :items="element.items"
                :placeholder="$t(element.placeholder)"
                :label="$t(element.placeholder)"
                class="custom-border filter-select-item"
                :color="element.color"
                :item-color="element.itemColor"
                :item-value="element.value"
                :item-text="element.text"
                dense
                outlined
                v-model="filters[element.key]"
                hide-details
                variant="underlined"
                append-icon="keyboard_arrow_down"
                :no-data-text="$t('messages.listEmpty')"
              ></v-autocomplete>
            </template>
            <template v-else>
              <v-select
                @change="element.onChange"
                :key="'SELECT_' + element.key"
                :items="element.items"
                :placeholder="$t(element.placeholder)"
                :label="$t(element.placeholder)"
                class="custom-border filter-select-item"
                :color="element.color"
                :item-color="element.itemColor"
                :item-value="element.value"
                :item-text="element.text"
                dense
                outlined
                v-model="filters[element.key]"
                hide-details
                variant="underlined"
                append-icon="keyboard_arrow_down"
                :no-data-text="$t('messages.listEmpty')"
              ></v-select>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-data-table
      :headers="spotOfferPricesHeaders"
      hide-default-footer
      :loading="$store.state.loadingStatus"
      :items="offerPrices"
      :items-per-page="100000"
      id="spotPriceRequests"
      :no-data-text="$t('messages.noData')"
      class="auto-width-table"
    >
      <template v-slot:item="{ item }">
        <tr
          @click="
            showDemandOptions = false;
            changeColorItem(item.Id);
            getActiveForm(item);
          "
          :style="{
            backgroundColor: !item.IsSupplierRead
              ? 'rgb(229, 229, 181)'
              : 'white',
          }"
        >
          <td class="regular-cell text-center bold-cell">
            <div class="d-flex flex-column align-center">
              <template v-if="item.SupplierCompanyLogo">
                <img
                  :src="item.SupplierCompanyLogo"
                  alt="Supplier Logo"
                  style="width: 50px; height: auto"
                />
              </template>
              <template v-else>
                {{ item.SupplierCompanyName }}
              </template>

              <v-rating
                v-if="!isSupplier && item.IsRate"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half-full"
                hover
                dense
                readonly
                length="5"
                half-increments
                color="#FFCD06"
                background-color="#FFCD06"
                size="12"
                v-model="item.SupplierRatingAvg"
                style="width: fit-content"
                class="align-self-center"
              >
              </v-rating>
            </div>
          </td>

          <td class="regular-cell text-center bold-cell">
            <div class="d-flex flex-column align-center">
              <span>
                {{
                  item.CreatedOnUtc
                    ? getFormattedDate(new Date(item.CreatedOnUtc))
                    : $t('labels.noDate')
                }}
              </span>
            </div>
          </td>

          <td class="regular-cell text-center bold-cell">
            <div class="d-flex flex-column align-center">
              <span>
                {{ getShipmentType(item) }}
              </span>
            </div>
          </td>

          <td class="regular-cell text-center bold-cell">
            <div class="d-flex flex-column align-center">
              <span>
                {{ getLoadingType(item) }}
              </span>
            </div>
          </td>

          <td
            class="regular-cell text-center bold-cell"
            style="width: max-content"
          >
            <div style="white-space: normal; word-wrap: break-word">
              {{ item.From }}
            </div>
            <span>-</span>
            <div style="white-space: normal; word-wrap: break-word">
              {{ item.To }}
            </div>
          </td>

          <td class="regular-cell text-center bold-cell">
            <div class="d-flex flex-column align-center">
              <span style="white-space: nowrap">
                {{ item.Load }}
              </span>
            </div>
          </td>

          <td class="regular-cell text-center bold-cell">
            <div class="d-flex flex-column align-end">
              <span v-if="item.TargetPrice && item.TargetPriceCurrency">
                {{ $getFormattedFloatValue(item.TargetPrice) + ' ' + item.TargetPriceCurrency }}
              </span>
              <span v-else> - </span>
            </div>
          </td>
          <!-- {{ $t('labels.noPrice') }} -->
          <td class="regular-cell text-center bold-cell">
            <div class="d-flex flex-column align-end">
              <span v-if="item.Price !== 0">
                {{ $getFormattedFloatValue(item.Price) + ' ' + item.CurrencyType }}
              </span>
              <span v-else> - </span>
            </div>
          </td>

          <td class="regular-cell text-center bold-cell">
            <div class="d-flex flex-column align-center">
              <span v-if="item.TransitTime !== 0">
                {{ item.TransitTime + ' ' + $t('labels.day.singular') }}
              </span>
              <span v-else> - </span>
            </div>
          </td>

          <td class="regular-cell text-center bold-cell">
            <div class="d-flex flex-column align-center">
              <span>
                {{
                  item.ExpiryDate
                    ? getFormattedDate(new Date(item.ExpiryDate))
                    : $t('labels.noDate')
                }}
              </span>
            </div>
          </td>

          <td class="regular-cell bold-cell">
            <div class="d-flex flex-column">
              <div class="d-flex align-center">
                <v-tooltip
                  style="background-color: orange; width: 65%"
                  top
                  v-if="item.SupplierOfferStatus === 2"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ConfirmationModal
                      :title="$t('labels.warning')"
                      :message="$t('messages.areYouSureToApprove')"
                      :confirm="() => approveOffer(item.Id)"
                      v-model="offerConfirmationModal"
                    />
                    <v-btn
                      style="background-color: green; width: 65%"
                      class="action-button button my-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t('labels.approved') }}
                    </v-btn>
                  </template>

                  <span>{{ $t('labels.approved') }}</span>
                </v-tooltip>
                <v-tooltip top v-if="item.SupplierOfferStatus === 2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      color="#29C7CE"
                      v-bind="attrs"
                      v-on="on"
                      class="ml-1"
                      style="font-size: 24px"
                      @click.stop="goOrderDetail(item)"
                      >mdi-arrow-right</v-icon
                    >
                  </template>

                  <span>{{ $t('labels.goToOrder') }}</span>
                </v-tooltip>
                <v-tooltip
                  top
                  v-if="item.SupplierOfferStatus === 4 && !isSupplier"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="background-color: #6d6e16; width: 65%"
                      class="action-button button my-2"
                    >
                      {{ $t('buttons.expired') }}
                    </v-btn>
                  </template>
                  <span>{{ $t('buttons.expired') }}</span>
                </v-tooltip>
                <v-tooltip
                  top
                  v-if="item.SupplierOfferStatus === 4 && isSupplier"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click.stop="getActiveForm(item)"
                      style="background-color: #6d6e16; width: 65%"
                      class="action-button button my-2"
                    >
                      {{ $t('buttons.expired') }}
                    </v-btn>
                  </template>
                  <span>{{ $t('buttons.expired') }}</span>
                </v-tooltip>
                <v-tooltip
                  top
                  v-if="item.SupplierOfferStatus === 3 && isSupplier"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="background-color: #e44655; width: 65%"
                      class="action-button button my-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t('buttons.lost') }}
                    </v-btn>
                  </template>
                  <span>{{ $t('buttons.lost') }}</span>
                </v-tooltip>
                <v-tooltip
                  top
                  v-if="item.SupplierOfferStatus === 3 && !isSupplier"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ConfirmationModal
                      :title="$t('labels.warning')"
                      :message="$t('messages.areYouSureToApprove')"
                      :confirm="() => approveOffer(item.Id)"
                      v-model="offerConfirmationModal"
                    />
                    <v-btn
                      :disabled="true"
                      style="background-color: green; width: 65%"
                      class="action-button button my-2"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="offerConfirmationModal = true"
                    >
                      {{ $t('labels.tooltipAccept') }}
                    </v-btn>
                  </template>
                </v-tooltip>
                <v-tooltip
                  style="background-color: orange"
                  top
                  v-if="
                    item.SupplierOfferStatus === 1 && isSupplier && !item.Price
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="background-color: #03a6ad; width: 65%"
                      class="action-button button my-2"
                      @click.stop="getActiveForm(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t('buttons.offerPrice') }}
                    </v-btn>
                  </template>
                  <span>{{ $t('buttons.offerPrice') }}</span>
                </v-tooltip>
                <v-tooltip
                  top
                  v-if="
                    item.SupplierOfferStatus === 1 && isSupplier && item.Price
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="background-color: orange; width: 65%"
                      class="action-button button my-2"
                      @click.stop="getActiveForm(item)"
                    >
                      {{ $t('buttons.updateOffer') }}
                    </v-btn>
                  </template>
                  <span>{{ $t('buttons.updateOffer') }}</span>
                </v-tooltip>
                <v-tooltip
                  top
                  v-if="!isSupplier && item.SupplierOfferStatus === 1"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ConfirmationModal
                      :title="$t('labels.warning')"
                      :message="$t('messages.areYouSureToApprove')"
                      :confirm="() => approveOffer(item.Id)"
                      v-model="offerConfirmationModal"
                    />
                    <v-btn
                      style="background-color: green; width: 65%"
                      class="action-button button my-2"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="offerConfirmationModal = true"
                    >
                      {{ $t('labels.tooltipAccept') }}
                    </v-btn>
                  </template>
                  <span>{{ $t('labels.tooltipAccept') }}</span>
                </v-tooltip>

                <v-tooltip
                  v-if="isSupplier ? item.CustomerNote : item.SupplierNote"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      color="#29C7CE"
                      v-bind="attrs"
                      v-on="on"
                      class="ml-1"
                      style="font-size: 24px"
                      @click.stop="showNote(item)"
                      >mdi-receipt-text-outline</v-icon
                    >
                  </template>

                  <span>{{ $t('labels.showNotes') }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="item.Price != 0 && item.SupplierOfferStatus !== 2"
                      small
                      color="#29C7CE"
                      v-bind="attrs"
                      v-on="on"
                      class="ml-1"
                      style="font-size: 24px"
                      @click="messageToUser(item.Id)"
                      >mdi-email-outline
                    </v-icon>
                  </template>

                  <span>{{ $t('buttons.sendAMessage') }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      color="#29C7CE"
                      v-bind="attrs"
                      v-on="on"
                      class="ml-1"
                      style="font-size: 24px"
                      @click.stop="selectedItem = item
                      weeklyAverageModal = true"
                      >mdi-numeric
                    </v-icon>
                  </template>

                  <span>{{ $t('titles.updateWeeklyAverages') }}</span>
                </v-tooltip>
              </div>
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:footer>
        <v-pagination
          v-if="false"
          class="my-4"
          @input="onPageChange"
          :value="page"
          :length="pageLength"
          :total-visible="15"
          circle
        ></v-pagination>
      </template>
      <template v-slot:no-data>
        <div class="my-10 d-flex flex-column align-center">
          <h4>
            {{
              $t(
                'messages.' +
                  (isSupplier ? 'noRequestSupplier' : 'noOfferSpotShipping')
              )
            }}
          </h4>
          <v-btn
            v-if="false"
            style="color: white; background-color: var(--softRed)"
            class="button my-2"
            @click="getActiveForm()"
          >
            {{ $t('buttons.demandPriceFromSupplier') }}
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import {
  ITEMS_PER_PAGE_OPTIONS,
  convertToFormBody,
  getCountries,
  getCurrencyTypes,
  getCompanies,
  getAirports,
  getHazmatCodes,
  getEquipmentTypes,
  getSeaports,
} from '../utils/constants';
import CreateOfferForm from './OfferScreens/CreateOfferForm.vue';
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';
import OfferServices from '../services/OfferServices';
import ConfirmationModal from '../components/General/ConfirmationModal.vue';
import SpotShippingServices from '../services/SpotShippingServices';
import MessageServices from '../services/MessageServices';
import RefreshButton from '../components/RefreshButton.vue';

export default {
  data() {
    return {
      currencyTypes: [],
      isRevisionDisabled: false,
      filters: {
        SHIPMENT_TYPE: -1,
        LOAD_TYPE: -1,
        SERVICE_TYPE: -1,
        FROM: -1,
        TO: -1,
        STATUS: -1,
        CUSTOMER_COMPANY: '',
      },
      countries: [],
      spotOfferPricesHeaders: [],
      offerPrices: [],
      spotShippingId: '',
      activeBody: {},
      sort: {
        by: 'targetPrice',
        desc: false,
      },

      // States
      pageLoading: false,
      itemsPerPageOptions: [],
      itemsPerPage: 1000000000,
      page: 1,
      totalItems: 0,
      showCreateOffer: false,
      showCreateOffer: false,
      offerConfirmationModal: false,
      spotShipping: null,
      status: 0,
      showCustomerNote: false,
      customerNote: '',
      isSupplier: false,
      showDemandOptions: true,
      customerCompanies: [],

      // Usable
      airports: [],
      hazmatCodes: [],
      equipmentTypes: [],
      seaways: [],

      weeklyAverageModal: false,
      selectedItem: {}
    };
  },
  components: {
    CreateOfferForm,
    FormElement,
    ConfirmationModal,
    RefreshButton,
  },
  async mounted() {
    this.isSupplier =
      this.$store.state.Type === 'Supplier' ||
      this.$store.state.Type === 'PendingSupplier';

    this.spotOfferPricesHeaders = [
      {
        text: this.$t(
          'labels.' + (this.isSupplier ? 'customerFullName' : 'supplier')
        ),

        value: 'customerFullName',
        sortable: false,
        groupable: false,
        cellClass: ['regular-cell', 'bold-cell'],
        align: 'center',
      },
      {
        text: this.$t('labels.requestDate'),
        value: 'CreatedOnUtc',
        sortable: false,
        groupable: false,
        cellClass: ['regular-cell'],
        align: 'center',
      },
      {
        text: this.$t('titles.shipmentType'),
        value: 'ShipmentTypeAsString',
        sortable: false,
        groupable: false,
        cellClass: ['regular-cell'],
        align: 'center',
      },

      {
        text: this.$t('labels.loadType'),
        value: 'LoadingTypeAsString',
        sortable: false,
        groupable: false,
        cellClass: ['regular-cell'],
        align: 'center',
      },
      {
        text: this.$t('titles.from') + '/' + this.$t('titles.to'),
        value: 'FromToWhere',
        sortable: false,
        groupable: false,
        cellClass: ['regular-cell', 'bold-cell'],
        align: 'center',
      },
      {
        text: this.$t('titles.load'),
        value: 'Load',
        sortable: false,
        groupable: false,
        cellClass: ['regular-cell'],
        align: 'center',
      },
      {
        text: this.$t('labels.targetPrice'),
        value: 'targetPrice',
        sortable: false,
        groupable: false,
        cellClass: ['regular-cell', 'bold-cell'],
        align: 'end',
      },
      {
        text: this.$t('labels.offeredPrice'),
        value: 'price',
        sortable: true,
        groupable: false,
        cellClass: ['regular-cell', 'bold-cell'],
        align: 'end',
      },
      {
        text: this.$t('titles.transferTime'),
        value: 'transferTime',
        sortable: false,
        groupable: false,
        cellClass: ['regular-cell', 'bold-cell'],
        align: 'center',
      },
      {
        text: this.$t('labels.expiryDate'),
        value: 'expiryDate',
        sortable: false,
        groupable: false,
        cellClass: ['regular-cell', 'bold-cell'],
        align: 'center',
      },
      {
        text: this.$t('labels.tableActions'),
        value: 'actions',
        sortable: false,
        groupable: false,
        align: 'center',
      },
    ];

    this.spotShippingId = this.$route.params.id;
    if (this.spotShippingId) {
      this.getActiveForm(null, false);
    }

    this.getDataForForm();

    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;

    this.setup();
  },
  computed: {
    filterItems() {
      return [
        {
          key: 'CUSTOMER_COMPANY',
          items: null,
          placeholder: 'labels.customerCompanyName',
          color: 'var(--turquoise)',
          itemColor: 'pink',
          value: 'Id',
          text: 'Name',
          onChange: this.getOfferPrices,
        },
        {
          key: 'SHIPMENT_TYPE',
          items: [
            ...this.$root.shipmentTypes,
            {
              Id: -1,
              Name: this.$t('labels.allGroupTypes'),
            },
          ],
          placeholder: 'titles.shipmentType',
          color: 'var(--turquoise)',
          itemColor: 'pink',
          value: 'Id',
          text: 'Name',
          onChange: this.getOfferPrices,
        },
        {
          key: 'LOAD_TYPE',
          items: [
            ...this.$root.loadTypes,
            {
              Id: -1,
              Name: this.$t('labels.allGroupTypes'),
            },
          ],
          placeholder: 'labels.loadType',
          color: 'var(--turquoise)',
          itemColor: 'pink',
          value: 'Id',
          text: 'Name',
          onChange: this.getOfferPrices,
        },
        {
          key: 'SERVICE_TYPE',
          items: [
            ...this.$root.serviceTypes,
            {
              Id: -1,
              Name: this.$t('labels.allGroupTypes'),
            },
          ],
          placeholder: 'labels.serviceType',
          color: 'var(--turquoise)',
          itemColor: 'pink',
          value: 'Id',
          text: 'Name',
          onChange: this.getOfferPrices,
        },
        {
          key: 'FROM',
          items: [
            {
              Id: -1,
              Name: this.$t('labels.allGroupTypes'),
            },
            ...this.countries,
          ],
          placeholder: 'titles.from',
          color: 'var(--turquoise)',
          itemColor: 'pink',
          value: 'Id',
          text: 'Name',
          onChange: this.getOfferPrices,
        },
        {
          key: 'TO',
          items: [
            {
              Id: -1,
              Name: this.$t('labels.allGroupTypes'),
            },
            ...this.countries,
          ],
          placeholder: 'titles.to',
          color: 'var(--turquoise)',
          itemColor: 'pink',
          value: 'Id',
          text: 'Name',
          onChange: this.getOfferPrices,
        },
      ];
    },
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  watch: {
    showCreateOffer(newValue) {
      if (!newValue) {
        this.showDemandOptions = true;
      }
    },
  },
  methods: {
    changeColorItem(id) {
      const found = this.offerPrices.find((item) => item.Id === id);
      found.IsSupplierRead = true;
    },
    resetFilter() {
      this.filters.SHIPMENT_TYPE = -1;
      this.filters.LOAD_TYPE = -1;
      this.filters.SERVICE_TYPE = -1;
      this.filters.FROM = -1;
      this.filters.TO = -1;
      this.filters.STATUS = -1;
      this.filters.CUSTOMER_COMPANY = '';
      this.getOfferPrices();
    },
    showNote(item) {
      this.showCustomerNote = true;
      this.customerNote = this.isSupplier
        ? item.CustomerNote
        : item.SupplierNote;
    },
    goOrderDetail(item) {
      this.$router.push({
        name: `OrderDetail`,
        query: {
          orderId: item.OrderId,
        },
      });
    },

    getShipmentType(item) {
      let type =
        this.$root.shipmentTypes.find((x) => x.Id === item.ShipmentType)
          ?.Name || 'No Transfer';
      item.TransportType == 2 ? (type = type + ' (Express)') : type;
      return type;
    },
    getLoadingType(item) {
      let type =
        this.$root.loadTypes.find((x) => x.Id === item.LoadingType)?.Name ||
        'No Transfer';
      return type;
    },
    async messageToUser(id) {
      this.$store.state.loadingStatus = true;
      await MessageServices.getById(id)
        .then(async (response) => {
          const data = response.data.Result;
          let chatId = data.Id;
          if (chatId) {
            this.$router.push({
              name: 'messageDetail',
              query: {
                id: chatId,
              },
            });
          }
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Approve the offer given by suppliers through using this function.
     * @param {*} supplierPriceOfferId
     */
    approveOffer(supplierPriceOfferId) {
      this.$store.state.loadingStatus = true;
      OfferServices.approveOffer(supplierPriceOfferId)
        .then((response) => {
          window.showSuccess(this.$t('messages.approvedSuccessfully'));
          this.$router.push({
            name: `OrderDetail`,
            query: {
              orderId: response.data.Result,
            },
          });
        })
        .catch(window.showError)
        .finally((res) => {
          this.$store.state.loadingStatus = false;
        });
    },

    isOfferDisabled(item) {
      if (item.ExpiryDate === null) return false;
      const today = this.getFormattedDate(new Date(), '-', [
        { year: 'numeric' },
        { month: '2-digit' },
        { day: '2-digit' },
      ]);
      const expiryDate = this.getFormattedDate(new Date(item.ExpiryDate), '-', [
        { year: 'numeric' },
        { month: '2-digit' },
        { day: '2-digit' },
      ]);
      return today > expiryDate;
    },

    async onFinally() {
      this.$store.state.loadingStatus = false;
      this.showCreateOffer = false;
      await this.getOfferPrices();

      if (this.spotShippingId) {
        this.spotShipping = null;
        await this.getActiveForm(null, false);
      }
    },

    /**
     * You can create a offer form like in the home through using item that has been sent.
     * @param {*} item
     */
    async getActiveForm(item = null, willBeOpened = true) {
      if (item) this.changeColorItem(item.Id);
      if (item || !this.spotShipping) {
        this.$store.state.loadingStatus = true;
        let temporaryItem = item;

        await SpotShippingServices.getSpotShippingById(
          item?.SpotShippingId ? item.SpotShippingId : this.spotShippingId
        )
          .then((response) => {
            const responseData = response.data;
            this.status = responseData.Status;
            this.activeBody.item = {
              note: responseData.Note,
            };

            if (this.spotShippingId) {
              this.activeBody.item.spotShippingId = this.spotShippingId;
            } else {
              this.activeBody.item = {
                ...this.activeBody.item,
                id: item.Id,
                spotShippingId: responseData.Id,
              };
            }

            item = responseData;
            this.spotShipping = item;
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });

        // Creating Active Body
        if (temporaryItem) {
          item = {
            ...item,
            ...temporaryItem,
          };
        }

        this.activeBody = convertToFormBody(
          item,
          this.activeBody,
          this.isSupplier
        );
      }

      if (willBeOpened) {
        this.showCreateOffer = true;
      }
    },
    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(
      date,
      splitter = '.',
      format = [{ day: '2-digit' }, { month: '2-digit' }, { year: 'numeric' }]
    ) {
      return formatDate(date, splitter, 'tr', format);
    },

    /**
     * Trigger when page changed.
     */
    onPageChange(newPage) {},

    setup() {
      this.getOfferPrices();
    },

    /**
     * Get all offer prices from service by filters.
     */
    getOfferPrices() {
      this.$store.state.loadingStatus = true;
      const shipmentTypeFilter = this.filters.SHIPMENT_TYPE;
      const loadTypeFilter = this.filters.LOAD_TYPE;
      const serviceTypeFilter = this.filters.SERVICE_TYPE;
      const fromFilter = this.filters.FROM;
      const toFilter = this.filters.TO;
      const customerCompanyFilter = this.filters.CUSTOMER_COMPANY;

      const statusFilter = this.filters.STATUS;

      let promise = null;
      if (this.isSupplier) {
        promise = OfferServices.getOffersForSuppliers;
      } else {
        promise = OfferServices.getOffersForCustomer;
      }
      promise = promise(
        this.spotShippingId,
        null,
        shipmentTypeFilter,
        loadTypeFilter,
        serviceTypeFilter,
        fromFilter,
        toFilter,
        customerCompanyFilter,
        this.page,
        this.itemsPerPage
      );

      if (promise) {
        promise
          .then((response) => {
            this.offerPrices = response.data.Result;

            this.offerPrices.map((x) => {
              if (x.SupplierOfferStatus === 2) {
                this.isRevisionDisabled = true;
              }
            });
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },

    getDataForForm() {
      getCountries().then((response) => {
        this.countries = response || [];
      });
      getAirports().then((response) => {
        this.airports = response || [];
      });
      getHazmatCodes().then((response) => {
        this.hazmatCodes = response || [];
      });
      getEquipmentTypes().then((response) => {
        this.equipmentTypes = response || [];
      });
      getCurrencyTypes().then((response) => {
        this.currencyTypes = response || [];
      });
      getCompanies().then((response) => {
        this.customerCompanies = response || [];
      });
      getSeaports().then((response) => {
        this.seaways = response || [];
      });
    },
  },
};
</script>
<style>
#spotPriceRequests tr:hover td {
  background-color: rgb(245, 245, 245);
  cursor: pointer;
}
.tableRow {
  padding: 1.5vh !important;
  border-bottom: 1px solid rgb(219, 219, 219);
}
.v-data-table__wrapper {
  overflow-x: auto !important;
}

.container {
  margin-top: 38px;
}
.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
}

.filter-label {
  font-size: 14px;
  color: gray;
}
.filter-select-item {
  font-size: 12px;
}
.column-select-item {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.column-select-item:first-child {
  margin-left: 0 !important;
}
.column-select-item:last-child {
  margin-right: 0 !important;
}
.auto-width-table th {
  white-space: nowrap;
}
</style>
