// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "/product";

export default {
  getProducts(pageSize=15, page = 1,brandId,categorId,creatorId,status) {
    return axios.get(`${RESOURCE_NAME}/getProducts`, {params:{page,pageSize,BrandId:brandId,CategoryId:categorId,CreatorId:creatorId,Status:status}});
  },
  getProducts(pageSize=15, page = 1,brandId,categorId,creatorId,status) {
    return axios.get(`${RESOURCE_NAME}/getProducts`, {params:{page,pageSize,BrandId:brandId,CategoryId:categorId,CreatorId:creatorId,Status:status}});
  },
  getProductById(id) {
    return axios.get(`${RESOURCE_NAME}/getProductById`,{params:{Id:id}});
  },
  getProductMarketplacesById(id) {
    return axios.get(`${RESOURCE_NAME}/getProductMarketplacesByProductId`,{params:{ProductId:id}});
  },
  addProduct(body) {
    return axios.post(`${RESOURCE_NAME}/addProduct`,body);
  },
  updateProduct(body) {
    return axios.put(`${RESOURCE_NAME}/update`,body);
  },
  changeStatusProduct(body) {
    return axios.put(`${RESOURCE_NAME}/changeStatus`,body);
  },
  getProductPhotos(id) {
    return axios.get(`productPhoto/getByProductId/${{id}}`);
  },
  addProductPhoto(body) {
    return axios.post(`productPhoto/add/`,body);
  },
  deleteProductPhoto(id) {
    return axios.delete(`productPhoto/delete/`,{params:{PhotoId:id}});
  },
  
};
