<template>
  <v-dialog v-model="modalStatus" max-width="350">
    <v-card class="popup-card" elevation="1">
      <v-card-title class="popupHeadline" style="font-size: 24px">
        <div class="text-center popupHeadline" style="width: 100%">
          {{ title ? title : $t('description.deleteModalTitle') }}
        </div>
      </v-card-title>

      <v-divider />

      <v-card-text class="py-3">
        <div class="text-center" style="width: 100%">
          {{
            description ? description : $t('description.deleteModalDescription')
          }}
        </div>
      </v-card-text>

      <v-card-actions
        class="justify-center"
        style="background-color: white; position: sticky; bottom: 0px"
      >
        <v-btn
          style="
            color: #fff;
            text-transform: unset !important;
            background-color: var(--red);
            padding: 1vh 1vh;
            border-radius: 30px !important;
            font-weight: bolder;
            font-size: medium;
          "
          elevation="0"
          class="darken-1"
          type="submit"
          @click="yesFunc()"
        >
          {{ $t('buttons.yes') }}
        </v-btn>
        <v-btn
          style="
            color: #fff;
            text-transform: unset !important;
            background-color: #0077d5;
            padding: 1vh 1vh;
            border-radius: 30px !important;
            font-weight: bolder;
            font-size: medium;
          "
          elevation="0"
          class="darken-1"
          @click="modalStatus = false"
        >
          {{ $t('buttons.no') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      //default: () => this.$t('description.deleteModalTitle'),
    },
    description: {
      type: String,
      //default: () => this.$t('description.deleteModalDescription'),
    },
    yesFunc: {
      type: Function,
      default: () => true,
    },
  },
  data: () => ({
    modalStatus: false,
  }),
  mounted() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
