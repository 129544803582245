<template>
  <v-card elevation="1" class="mt-0">
    <div class="flex w-full rounded-tl-sm rounded-tr-sm">
      <v-card-title style="color: var(--darkBlue)">
        <v-row>
          <v-col class="text-lg-left text-center" lg="5" cols="12">
            <h3>{{ $t('navigationDrawerMenu.approvePayment') }}</h3>
          </v-col>
        </v-row>
        <div>
          <RefreshButton :refreshFunction="getPayments"></RefreshButton>
        </div>
      </v-card-title>
    </div>
    <v-divider />

    <v-dialog
      v-if="showInvoiceModal"
      v-model="showInvoiceModal"
      max-width="850"
      @close="showInvoiceModal = false"
    >
      <v-card class="popup-card" elevation="1">
        <v-row>
          <v-col class="d-flex justify-center items-center">
            <v-card-title class="popupHeadline d-flex" style="font-size: 16px">
              <div style="font-size: 16px">
                {{ $t('labels.invoice') }}
              </div>
            </v-card-title></v-col
          >
        </v-row>

        <v-divider />

        <v-data-table
          :headers="receiptItemsHeaders"
          :items="receiptItems"
          hide-default-footer
          :no-data-text="$t('messages.noData')"
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.Amount="{ item }">
            <span v-if="item.Amount && item.CurrencyType">{{
              item.Amount + ' ' + item.CurrencyType.Code
            }}</span>
          </template>
          <!-- eslint-disable -->
          <template v-slot:item.actions="{ item }">
            <div
              v-if="item.PaymentStatus !== 0 || isSupplier"
              class="text-center"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="#29B7B7"
                    @click="getReceiptDetail(item.Id)"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-1"
                    style="font-size: 20px"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>{{ $t('labels.showInvoiceDetail') }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="short-content"
      v-model="addInvoiceModal"
      max-width="850"
      @close="addInvoiceModal = false"
    >
      <v-card class="popup-card" elevation="1">
        <v-card-title
          class="popupHeadline d-flex justify-center"
          style="font-size: 16px"
          >{{ $t('labels.receiptDetail') }}</v-card-title
        >
        <v-divider class="mb-4" />

        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <v-card-text>
            <form @submit.prevent="handleSubmit(null)">
              <v-row>
                <FormElement
                  :disabled="true"
                  v-model="fullName"
                  :validationLabel="$t('labels.fullName')"
                  :formLabel="$t('labels.fullName')"
                  :rules="'required|min:3|onlyStringText'"
                  :formType="{
                    name: 'text',
                  }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="6"
                  :placeholder="$t('labels.fullName')"
                />
                <FormElement
                  v-model="transferDate"
                  :validationLabel="$t('labels.transferDate')"
                  :formLabel="$t('labels.transferDate')"
                  :rules="'required'"
                  :formType="{
                    name: 'date',
                    dateMode: 'date',
                  }"
                  :disabled="true"
                  :lg="6"
                  :labelShown="false"
                  :topLabel="true"
                  :placeholder="$t('labels.transferDate')"
                />
              </v-row>
              <v-row>
                <FormElement
                  :disabled="true"
                  v-model="bankName"
                  :validationLabel="$t('labels.bankName')"
                  :formLabel="$t('labels.bankName')"
                  :rules="'required'"
                  :formType="{
                    name: 'text',
                  }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="6"
                  :placeholder="$t('labels.bankName')"
                />

                <FormElement
                  :disabled="true"
                  v-model="accountNumber"
                  :validationLabel="$t('labels.accountNumber')"
                  :formLabel="$t('labels.accountNumber')"
                  :rules="'required|numeric'"
                  :formType="{
                    name: 'text',
                  }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="6"
                  :placeholder="$t('labels.accountNumber')"
                />
              </v-row>
              <v-row>
                <FormElement
                  :disabled="true"
                  v-model="amount"
                  :validationLabel="$t('labels.amount')"
                  :formLabel="$t('labels.amount')"
                  :rules="'required|min_value:1'"
                  :formType="{
                    name: 'text',
                  }"
                  :lg="6"
                  :labelShown="false"
                  :topLabel="true"
                  :placeholder="$t('labels.amount')"
                />
                <FormElement
                  v-model="selectedCurrency"
                  :validationLabel="$t('labels.currency')"
                  :formLabel="$t('labels.currency')"
                  :rules="'required'"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: currencyOptions,
                      value: 'Id',
                      text: 'Code',
                    },
                  }"
                  :disabled="true"
                  :lg="6"
                  :labelShown="false"
                  :topLabel="true"
                  :placeholder="$t('labels.currency')"
                />
              </v-row>
              <v-row>
                <v-col
                  v-for="(file, index) in oldDocuments"
                  :key="index"
                  cols="3"
                >
                  <v-img
                    src="../../public/img/zemin-login.png"
                    @click="openImagePreview(file.Url)"
                    contain
                    style="cursor: pointer"
                  ></v-img>
                </v-col>
              </v-row>
              <v-card-actions style="justify-content: center">
                <v-btn
                  class="add mt-6"
                  size="x-large"
                  style="
                    text-transform: uppercase !important;
                    border-radius: 30px !important;
                    background-color: var(--red) !important;
                    padding: 2vh 3vh !important;
                    font-weight: bold;
                    font-size: 16px !important;
                  "
                  dark
                  @click="addInvoiceModal = false"
                >
                  {{ $t('buttons.close') }}
                </v-btn>
              </v-card-actions>
            </form>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <ConfirmationModal
      v-if="selectedOrder"
      :title="$t('labels.warning')"
      :message="
        $t(
          'messages.' +
            (selectedOrder.isApproved
              ? 'sureToApprovePayment'
              : 'rejectPaymentApprovement')
        )
      "
      :confirm="approvePayment"
      ref="confirmationModal"
      v-model="approveModal"
    />
    <TabScreen
      :slots="slots"
      :changeIndexFunction="handleTabChange"
      style="
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
      "
    >
      <template v-slot:waitingForApproval>
        <v-data-table
          key="PAYMENTS_WAITING"
          :headers="paymentHeaders"
          hide-default-footer
          style="overflow-x: hidden"
          :options="{
            itemsPerPage: itemsPerPage,
          }"
          :loading="$store.state.loadingStatus"
          :items="payments"
          :no-data-text="$t('messages.noData')"
        >
          <!-- eslint-disable -->
          <template v-slot:item.CreatedOnUtc="{ item }">
            <span>{{ getFormattedDate(new Date(item.CreatedOnUtc)) }}</span>
          </template>
          <template v-slot:footer>
            <div class="d-flex justify-center mt-8 pb-lg-0 pb-8 px-4">
              <v-row justify="center">
                <v-col md="3" class="ml-md-auto">
                  <v-pagination
                    v-if="pageLength > 0"
                    class="my-4"
                    @input="onPageChange"
                    :value="page"
                    :length="pageLength"
                    circle
                  ></v-pagination>
                </v-col>
                <v-col md="2" class="ml-md-auto d-flex pt-8">
                  <v-select
                    v-model="itemsPerPage"
                    :items="perPageValues"
                    :label="$t('labels.selectItemsPerPage')"
                    item-value="value"
                    item-text="name"
                    outlined
                    dense
                    @change="changeItemsPerPage"
                    rounded
                  >
                  </v-select>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="my-10 d-flex flex-column align-center">
              <h4>{{ $t('messages.noWaitingForApprovalPayment') }}</h4>
            </div>
          </template>
          <!-- eslint-disable -->
          <template v-slot:item.FileUrl="{ item }">
            <v-btn
              v-if="item.FileUrl"
              elevation="0"
              color="primary"
              class="button"
              dark
              :href="item.FileUrl"
              depressed
              icon
              target="_blank"
              download
              ><v-icon> mdi-eye </v-icon></v-btn
            >
          </template>
          <template v-slot:item.actions="{ item }">
            <!-- eslint-enable -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="#29B7B7"
                  @click="openShowInvoiceModal(item)"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                  style="font-size: 20px"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>{{ $t('labels.showInvoice') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="#29B7B7"
                  @click="openConfirmModal(item, true)"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                  style="font-size: 20px"
                >
                  mdi-check-decagram
                </v-icon>
              </template>
              <span>{{ $t('labels.tooltipAccept') }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>

      <template v-slot:approvedPayments>
        <v-data-table
          key="PAYMENTS_APPROVED"
          :headers="paymentHeaders"
          hide-default-footer
          style="overflow-x: hidden"
          :options="{
            itemsPerPage: itemsPerPage,
          }"
          :loading="$store.state.loadingStatus"
          :items="payments"
          :no-data-text="$t('messages.noData')"
        >
          <!-- eslint-disable -->
          <template v-slot:item.CreatedOnUtc="{ item }">
            <span>{{ getFormattedDate(new Date(item.CreatedOnUtc)) }}</span>
          </template>
          <template v-slot:footer>
            <div class="d-flex justify-center mt-8 pb-lg-0 pb-8 px-4">
              <v-row justify="center">
                <v-col md="3" class="ml-md-auto">
                  <v-pagination
                    v-if="pageLength > 0"
                    class="my-4"
                    @input="onPageChange"
                    :value="page"
                    :length="pageLength"
                    circle
                  ></v-pagination>
                </v-col>
                <v-col md="2" class="ml-md-auto d-flex pt-8">
                  <v-select
                    v-model="itemsPerPage"
                    :items="perPageValues"
                    :label="$t('labels.selectItemsPerPage')"
                    item-value="value"
                    item-text="name"
                    outlined
                    dense
                    @change="changeItemsPerPage"
                    rounded
                  >
                  </v-select>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="my-10 d-flex flex-column align-center">
              <h4>{{ $t('messages.noApprovedPayment') }}</h4>
            </div>
          </template>
          <!-- eslint-disable -->
          <template v-slot:item.FileUrl="{ item }">
            <v-btn
              v-if="item.FileUrl"
              elevation="0"
              color="primary"
              class="button"
              dark
              :href="item.FileUrl"
              depressed
              icon
              target="_blank"
              download
              ><v-icon> mdi-eye </v-icon></v-btn
            >
          </template>
          <template v-slot:item.actions="{ item }">
            <!-- eslint-enable -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="#29B7B7"
                  @click="openShowInvoiceModal(item)"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                  style="font-size: 20px"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>{{ $t('labels.showInvoice') }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="red"
                  @click="openConfirmModal(item, false)"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                  style="font-size: 20px"
                >
                  mdi-refresh
                </v-icon>
              </template>
              <span>{{ $t('labels.rejectApprovement') }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </TabScreen>
  </v-card>
</template>
<script>
import { ITEMS_PER_PAGE_OPTIONS } from '../utils/constants';
import CreateOfferForm from './OfferScreens/CreateOfferForm.vue';
import FormElement from '../components/FormElement.vue';
import RefreshButton from '../components/RefreshButton.vue';
import OrderService from '../services/OrderService';
import formatDate from '../utils/formatDate';
import ReceiptService from '../services/ReceiptService';
import CurrencyService from '../services/CurrencyService';
import ConfirmationModal from '../components/General/ConfirmationModal.vue';
import AdminService from '../services/AdminService';
import TabScreen from '../components/TabScreen.vue';

export default {
  data() {
    return {
      payments: [],

      // States
      currentTab: 0,
      pageLoading: false,
      showInvoiceModal: false,
      addInvoiceModal: false,
      approveModal: false,
      selectedOrder: null,
      currencyOptions: null,
      itemsPerPageOptions: [],
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      perPageValues: [
        {
          name: '5',
          value: 5,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
        {
          name: this.$t('labels.All'),
          value: 9999,
        },
      ],
      paymentHeaders: [
        {
          text: this.$t('titles.shipmentType'),
          value: 'ShipmentType',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.loadType'),
          value: 'LoadingType',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('titles.from') + '/' + this.$t('titles.to'),
          value: 'FromToWhere',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell', 'bold-cell'],
          align: 'center',
          width: '20%',
        },
        {
          text: this.$t('labels.buyer'),
          value: 'Customer',
          sortable: true,
          groupable: false,
          cellClass: ['regular-cell', 'bold-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.referenceNumber'),
          value: 'RefNo',
          sortable: true,
          groupable: false,
          cellClass: ['regular-cell', 'bold-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.date', {}),
          value: 'CreatedOnUtc',
          sortable: false,
          groupable: false,
          cellClass: 'regular-cell',
          align: 'center',
        },
        {
          text: this.$t('labels.companyName'),
          value: 'Carrier',
          sortable: false,
          groupable: false,
          cellClass: 'regular-cell',
          align: 'center',
        },
        {
          text: this.$t('labels.price'),
          value: 'Price',
          sortable: true,
          groupable: false,
          cellClass: ['regular-cell', 'no-p-m'],
          align: 'center',
        },
        {
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
          cellClass: ['regular-cell'],
          groupable: false,
        },
      ],
      receiptItemsHeaders: [
        {
          text: this.$t('labels.bankName'),
          value: 'BankName',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.amount'),
          value: 'Amount',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
          groupable: false,
          align: 'center',
        },
      ],
      slots: [],
    };
  },
  components: {
    CreateOfferForm,
    FormElement,
    RefreshButton,
    ConfirmationModal,
    TabScreen,
  },
  async mounted() {
    this.slots = [
      {
        name: 'waitingForApproval',
        title: this.$t('messages.waitingForPaymentApproval'),
      },
      {
        name: 'approvedPayments',
        title: this.$t('messages.approvedPayments'),
      },
    ];
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
    this.getCurrencies();
    this.setup();
  },
  computed: {
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  watch: {},
  methods: {
    openConfirmModal(item, isApproved = false) {
      this.selectedOrder = item;
      this.selectedOrder.isApproved = isApproved;
      this.approveModal = true;
    },
    approvePayment() {
      let body = {
        OrderId: this.selectedOrder.Id,
        IsApproved: this.selectedOrder.isApproved,
      };

      AdminService.updateApproveOrderPayment(body)
        .then(() => {
          window.showSuccess(
            this.$t(
              'messages.' +
                (this.selectedOrder.isApproved
                  ? 'paymentApproved'
                  : 'paymentRejected')
            )
          );
          this.handleTabChange(this.selectedOrder.isApproved ? 0 : 1);
        })
        .catch((error) => {
          window.showError(error);
        });
    },
    openImagePreview(url) {
      window.open(url, '_blank');
    },
    getCurrencies() {
      CurrencyService.getCurrencies().then((response) => {
        this.currencyOptions = response.data.Result;
      });
    },
    openShowInvoiceModal(item) {
      this.showInvoiceModal = true;
      this.selectedOrder = item;
      this.receiptItems = item.Receipts;
    },
    getFormattedDate(
      date,
      splitter = '/',
      format = [{ day: '2-digit' }, { month: '2-digit' }, { year: 'numeric' }]
    ) {
      return formatDate(date, splitter, 'tr', format);
    },
    changeItemsPerPage() {
      this.page = 1;
      this.getPayments();
    },

    /**
     * Trigger when page changed.
     */
    onPageChange(newPage) {
      this.page = newPage;
      this.getPayments();
    },

    setup() {
      this.getPayments();
    },

    getReceiptDetail(item) {
      this.receiptId = item;
      this.$store.state.loadingStatus = true;
      ReceiptService.get(item)
        .then((response) => {
          this.receiptId = response.data.Result.Id;
          this.bankName = response.data.Result.BankName;
          this.fullName = response.data.Result.FullName;
          this.accountNumber = response.data.Result.AccountNumber;
          this.amount = response.data.Result.Amount;
          this.transferDate = convertToDatepicker(
            convertToLocal(response.data.Result.TransferDate)
          );
          this.oldDocuments = response.data.Result.ReceiptFileList || [];
          this.selectedCurrency = response.data.Result.CurrencyTypeId;
          this.addInvoiceModal = true;
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    getPayments() {
      this.$store.state.loadingStatus = true;
      this.payments = [];
      OrderService.getListForPayment(
        2,
        1,
        this.currentTab === 1 ? true : false,
        this.page,
        this.itemsPerPage
      )
        .then((response) => {
          this.payments = response.data.Result;
          this.totalItems = response.totalItems;
        })
        .catch((error) => {
          this.$store.dispatch('showError', error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    handleTabChange(index) {
      this.currentTab = index;
      console.log('Current tab: ', this.currentTab);
      this.getPayments();
    },
  },
};
</script>
<style>
.container {
  margin-top: 38px;
}
.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.item-header {
  color: var(--turquoise) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
}

.filter-label {
  font-size: 14px;
  color: gray;
}
.filter-select-item {
  font-size: 12px;
}
.column-select-item {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.column-select-item:first-child {
  margin-left: 0 !important;
}
.column-select-item:last-child {
  margin-right: 0 !important;
}
</style>
