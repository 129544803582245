<template>
  <v-app>
    <alert />
    <loading />
    <force-logout />
    <router-view :key="$route.fullPath" />
  </v-app>
</template>

<script>
import Alert from './components/General/Alert.vue';
import Loading from './components/General/Loading.vue';
import ForceLogout from './components/General/ForceLogout.vue';
import './assets/main.scss';

export default {
  name: 'App',
  components: {
    Alert,
    Loading,
    ForceLogout,
  },
  data() {
    return {
      colors: {
        red: '#F00',
      },
      idleInterval: null,
      idleTime: 0,
      idleRoutes: [
        'spotPriceRequests',
        'marketplaceOrders',
        'messageDetail',
        'messages',
        'payments',
      ],
    };
  },
  mounted() {
    this.setup();

    if (this.idleRoutes.includes(this.$route.name)) {
      this.idleInterval = setInterval(this.timerIncrement, 1000);
    } else {
      clearInterval(this.idleInterval);
    }
  },
  updated() {
    if (this.idleRoutes.includes(this.$route.name)) {
      this.idleInterval = setInterval(this.timerIncrement, 1000);
    } else {
      clearInterval(this.idleInterval);
    }
  },
  methods: {
    setup() {
      window.addEventListener('mousemove', this.resetIdle);
      window.addEventListener('click', this.resetIdle);
      window.addEventListener('keypress', this.resetIdle);
      window.addEventListener('scroll', this.resetIdle);
    },

    resetIdle() {
      this.idleTime = 0;
    },

    timerIncrement() {
      this.idleTime++;
      if (this.idleTime > 60) {
        window.location.reload();
      }
    },
  },
};
</script>
