var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%","padding-top":"40px","padding-bottom":"70px"},style:({
    paddingRight: !(
      _vm.$vuetify.breakpoint.xs ||
      _vm.$vuetify.breakpoint.sm ||
      _vm.$vuetify.breakpoint.md
    )
      ? '1%'
      : '0',

    paddingLeft:
      _vm.$vuetify.breakpoint.xs ||
      _vm.$vuetify.breakpoint.sm ||
      _vm.$vuetify.breakpoint.md
        ? '1%'
        : '0',
  })},[_c('v-main',[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }