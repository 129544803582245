// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
let RESOURCE_NAME = "companyMarket";

export default {
  /**
   * Creates a new company marketplace.
   * @param {*} body
   * @returns {Promise<any>}
   */
  add(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },

  /**
   * Update informations of company marketplace..
   * @param {*} body
   * @returns {Promise<any>}
   */
  update(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },

  /**
   * Delete a company marketplace.
   * @param {*} body
   * @returns {Promise<any>}
   */
  delete(companyMarketId) {
    return axios.delete(
      `${RESOURCE_NAME}/delete?CompanyMarketId=${companyMarketId}`
    );
  },

  /**
   * Returns a list of marketplace which the company have not bought yet.
   * @returns {Promise<any>}
   */
  getNonPurchasedList() {
    return axios.get(`${RESOURCE_NAME}/GetNonPurchasedMarketplaces`);
  },

  /**
   * Returns a list of marketplace which the company bought.
   * @returns {Promise<any>}
   */
  getPurchasedList() {
    return axios.get(`${RESOURCE_NAME}/GetPurchasedMarketplaces`);
  },

  /**
   * It returns company marketplace list by filter.
   * @param {*} { CompanyId, CompanyMarketId, page, pageSize }
  
   */
  get(companyId, page, pageSize) {
    return axios.get(
      `${RESOURCE_NAME}/get?CompanyId=${companyId}&Page=${page}&PageSize=${pageSize}`
    );
  },

  /**
   * Get a specific company marketplace.
   * @param {*} { Id }
   * @returns {Promise<any>}
   */
  getById(body) {
    return axios.get(`${RESOURCE_NAME}/getById`, body);
  },

  /**
   * Test any marketplace which is added.
   * @param {integer} compapnyMarketId
   * @returns {Promise<any>}
   */
  test(compapnyMarketId) {
    return axios.post(`connectMarketplace/connection-authorize-marketplace`, {
      CompanyMarketId: compapnyMarketId,
    });
  },

  /**
   * Send Mail
   * @param {integer} compapnyMarketId
   * @returns {Promise<any>}
   */
    sendMail(compapnyMarketId) {
      return axios.post(`connectMarketplace/send-marketplace-login-mail`, {
        CompanyMarketId: compapnyMarketId,
      });
    },
};
