// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'authentication';

export default {
  register(body) {
    return axios.post(`${RESOURCE_NAME}/register`, body);
  },
  login(body) {
    return axios.post(`${RESOURCE_NAME}/login`, body);
  },
  twoFA(body) {
    return axios.post(`${RESOURCE_NAME}/twoFA`, body);
  },
  resendTwoFA(body) {
    return axios.post(`${RESOURCE_NAME}/resendTwoFa`, body);
  },
  logout() {
    return axios.get(`${RESOURCE_NAME}/logout`);
  },
  forgotPassword(body) {
    return axios.post(`${RESOURCE_NAME}/send-verification-email`, body);
  },

  /**
   * You can verify your reset token which one is coming from send-verification-email service.
   * @param {*} body
   * @returns {Promise<AxiosResponse<Any>>}
   */
  verifyResetToken(body) {
    return axios.post(`${RESOURCE_NAME}/verify-reset-token`, body);
  },

  /**
   * Update password after verifying.
   * @param {*} body
   * @returns {Promise<AxiosResponse<Any>>}
   */
  updatePassword(body) {
    return axios.post(`Users/update-password`, body);
  },

  getProfile(id) {
    return axios.get(`${RESOURCE_NAME}/Id`, { params: { id: id } });
  },
  updateMyProfile(body) {
    return axios.put(`${RESOURCE_NAME}/profile`, body);
  },
  getCurrentSellerAddress() {
    return axios.get(`/selleraddress`);
  },
  updateCurrentSellerAddress(body) {
    return axios.put(`/selleraddress`, body);
  },
  createCurrentSellerAddress(body) {
    return axios.post(`/selleraddress`, body);
  },
  deleteUser(id) {
    return axios.delete(`${RESOURCE_NAME}/deleteuser/${id}`);
  },
  updateUser(body) {
    return axios.put(`${RESOURCE_NAME}/updateuser/true`, body);
  },
  updateUserPermissions(body) {
    return axios.put(`${RESOURCE_NAME}/updateuserpermission`, body);
  },
  rankDownUser(id) {
    return axios.put(`${RESOURCE_NAME}/rankdownuser/${id}`);
  },
  getRedirectPages() {
    return axios.get(`${RESOURCE_NAME}/pages/direct`);
  },

  /**
   * Confirm email activation.
   * @param {*} body
   * @returns {Promise<AxiosResponse<Any>>}
   */
  confirmEmail(body) {
    return axios.put(`authentication/confirm-email`, body);
  },
  portalLogin(body) {
    return axios.post(`authentication/portal-login`, body);
  },
  adminLogin(body) {
    return axios.post(`authentication/admin-login`, body);
  },
  generatePassword() {
    return axios.get(`${RESOURCE_NAME}/generate-random-password `);
  },
};
