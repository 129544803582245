<template>
  <v-card elevation="1">
    <v-card-title class="d-flex flex-row" style="color: var(--darkBlue)">
      <v-row>
        <v-col class="text-lg-left text-center" lg="12" cols="12">
          <h3>{{ $t('navigationDrawerMenu.dynamicReports') }}</h3>
        </v-col>
      </v-row>
      <div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-3"
              style="font-size: 32px"
              v-bind="attrs"
              v-on="on"
              @click="$router.push({ name: 'addDynamicReports' })"
            >
              mdi-plus-box-outline
            </v-icon>
          </template>
          <span>{{ $t('buttons.add') }}</span>
        </v-tooltip>
        <RefreshButton :refreshFunction="getItems"></RefreshButton>
      </div>
    </v-card-title>
    <v-divider />
    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      :loading="$store.state.loadingStatus"
      :options="{
        itemsPerPage: itemsPerPage,
      }"
      :item-key="'Id'"
      :no-data-text="$t('messages.noData')"
    >
      <template v-slot:footer>
        <div class="d-flex justify-center mt-8">
          <v-row align="center" justify="center">
            <v-col
              v-if="
                $vuetify.breakpoint.md ||
                $vuetify.breakpoint.lg ||
                $vuetify.breakpoint.xl
              "
              md="2"
            ></v-col>
            <v-col cols="9" sm="12" md="7" lg="7" xl="7">
              <v-pagination
                v-if="pageLength > 0"
                class="my-4"
                @input="onPageChange"
                v-model="page"
                :length="pageLength"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col
              cols="11"
              sm="11"
              md="3"
              lg="3"
              xl="3"
              class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
            >
              <v-select
                v-model="itemsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                outlined
                dense
                @change="changeItemsPerPage"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:no-data>
        <div class="my-10 d-flex flex-column align-center">
          <h4>{{ $t('messages.noData') }}</h4>
        </div>
      </template>
      <!-- eslint-disable -->
      <template v-slot:item.SendMail="{ item }">
        {{ $t(`buttons.${item.SendMail ? 'yes' : 'no'}`) }}
      </template>
      <template v-slot:item.MailAddress="{ item }">
        {{ item.MailAddress || '-'  }}
      </template>
      <template v-slot:item.MailFrequency="{ item }">
        {{ frequencies[item.MailFrequency] || '-'  }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-start">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                color="#29B7B7"
                class="mr-1"
                style="font-size: 20px"
                v-bind="attrs"
                v-on="on"
                @click="$router.push({ name: 'updateDynamicReports', params: { id: item.Id } })"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('buttons.update') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                color="#29B7B7"
                class="mr-1"
                style="font-size: 20px"
                v-bind="attrs"
                v-on="on"
                @click="$router.push({ name: 'viewDynamicReports', params: { id: item.Id,  name: item.ReportName } })"
              >
                mdi-eye
              </v-icon>
            </template>
            <span>{{ $t('titles.details') }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    
  </v-card>
</template>
<script>
import {
  ITEMS_PER_PAGE_OPTIONS,
} from '../utils/constants';
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';
import RefreshButton from '../components/RefreshButton.vue';
import TabScreen from '../components/TabScreen.vue';
import ReportService from '../services/ReportService';

export default {
  data() {
    return {
      headers: [],
      items: [],

      // States
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      perPageValues: [
        {
          name: '5',
          value: 5,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
        {
          name: this.$t('labels.All'),
          value: 9999,
        },
      ],
      frequencies: []
    };
  },
  components: { FormElement, RefreshButton, TabScreen },
  mounted() {
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
    this.setup();

    this.frequencies = [this.$t('labels.daily'), this.$t('labels.weekly'), this.$t('labels.monthly') ];
  },
  computed: {
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(date) {
      return formatDate(date, '/');
    },

    changeItemsPerPage() {
      this.page = 1;
      this.getItems();
    },

    /**
     * Trigger when page changed.
     */
    onPageChange() {
      this.getItems();
    },

    async setup() {
      this.getItems();
      this.headers = [
        {
          text: this.$t('title.name'),
          value: 'ReportName',
          sortable: false,
          groupable: false,
          cellClass: 'regular-cell',
          align: 'center',
        },
        {
          text: this.$t('labels.isMailSent'),
          value: 'SendMail',
          sortable: false,
          groupable: false,
          cellClass: 'regular-cell',
          align: 'center',
        },
        {
          text: this.$t('labels.email'),
          value: 'MailAddress',
          sortable: false,
          groupable: false,
          cellClass: 'regular-cell',
          align: 'center',
        },
        {
          text: this.$t('labels.mailFrequency'),
          value: 'MailFrequency',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
        }
      ];
    },

    /**
     * Get search history from service.
     */
    getItems() {
      this.$store.state.loadingStatus = true;

      ReportService.getListDynamicReports({
        Page: this.page,
        PageSize: this.itemsPerPage
      })
      .then((response) => {
        this.items = response.data.Result;
        this.totalItems = response.data.TotalItems;
      })
      .catch(window.showError)
      .finally(() => {
        this.$store.state.loadingStatus = false;
      });
    },
  },
};
</script>
<style></style>
