<template>
  <v-fade-transition>
    <v-snackbar
      class="rounded-snackbar"
      elevation="0"
      :style="alertObject.y === 'top' ? 'top:85px;' : ''"
      v-model="show"
      :color="'transparent'"
      :bottom="alertObject.y === 'bottom'"
      :top="alertObject.y === 'top'"
      :left="alertObject.x === 'left'"
      :right="alertObject.x === 'right'"
      :vertical="alertObject.mode === 'vertical'"
      :timeout="
        alertObject.color === 'error' || alertObject.color === 'cyan'
          ? 0
          : timeout
      "
    >
      <v-alert dense min-height="65px" class="py-5" :type="alertObject.color">
        <v-row class="d-flex align-center" :style="rowStyle">
          <v-col
            cols="12"
            :md="
              alertObject.color === 'error' || alertObject.color === 'cyan'
                ? 10
                : 12
            "
            class="d-flex align-center justify-center justify-md-start"
          >
            <span>
              {{ alertObject.message }}
            </span>
          </v-col>
          <v-col
            v-if="alertObject.color === 'error' || alertObject.color === 'cyan'"
            cols="12"
            md="2"
            class="d-flex justify-center justify-md-end mt-0 mt-sm-0"
          >
            <v-btn
              :style="buttonStyle"
              text
              @click="closeAlert"
              class="full-height"
              >{{ $t('buttons.ok') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
    </v-snackbar>
  </v-fade-transition>
</template>

<script>
export default {
  props: {
    options: Object,
  },
  data: () => ({
    show: false,
  }),
  watch: {
    alertObject() {
      this.show = true;
    },
  },
  computed: {
    timeout() {
      let _timeout = this.alertObject.message.split(' ').length * 1000 * 0.35;
      return _timeout < 3000 ? 3000 : _timeout;
    },

    computedClass() {
      return `alert ${this.options.type}`;
    },

    alertObject() {
      return this.$store.state.alertObject;
    },

    buttonStyle() {
      let style = {
        borderRadius: '12px',
      };

      switch (this.alertObject.color) {
        case 'error':
          style.backgroundColor = 'darkred';
          style.color = 'white';
          break;
        case 'warning':
          style.backgroundColor = 'darkgoldenrod';
          style.color = 'black';
          break;
        case 'success':
          style.backgroundColor = 'darkgreen';
          style.color = 'white';
          break;
        case 'cyan':
          style.backgroundColor = '#1d2e5b';

          break;
        default:
      }
      return style;
    },

    rowStyle() {
      return {
        flexDirection: this.$vuetify.breakpoint.xs ? 'column' : 'row',
      };
    },
  },
  methods: {
    closeAlert() {
      this.show = false;
    },
  },
};
</script>

<style>
.rounded-snackbar .v-snack__content {
  min-height: 65px;
}
.rounded-snackbar .v-snack__wrapper {
  border-radius: 12px;
}
.full-height {
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
