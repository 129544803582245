<template>
  <div>
    <v-card elevation="2" style="border-radius: 0">
      <v-card-title class="popupHeadline">
        <v-row class="d-flex align-center">
          <v-col cols="12" lg="6">
            <h4 class="text-lg-left text-center">
              {{ $t("titles.management", { value: "Log" }) }}
            </h4>
          </v-col>
          <v-col cols="12" lg="3">
            <v-menu
              ref="menu"
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <div class="d-flex">
                  <v-icon class="mr-4" color="red"> mdi-calendar </v-icon>
                  <input
                    v-model="selectedDateRange"
                    v-on="on"
                    readonly
                    :placeholder="$t('labels.dateRange')"
                    class="form-input-item"
                  />
                </div>
              </template>
              <v-date-picker v-model="range" range no-title scrollable>
                <v-btn text color="primary" @click="dateMenu = false">
                  {{ $t("buttons.cancel") }}
                </v-btn>
                <v-spacer />
                <v-btn text color="primary" @click="resetDatePicker">
                  {{ $t("buttons.reset") }}
                </v-btn>

                <v-btn
                  :loading="$store.state.loadingStatus"
                  text
                  color="primary"
                  @click="
                    getItems();
                    $refs.menu.save(selectedDateRange);
                  "
                >
                  {{ $t("buttons.ok") }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="d-flex justify-center" cols="12" lg="3">
            <div
              @click="resetAll()"
              style="cursor: pointer"
              class="d-flex align-center"
            >
              <span style="font-size: 16 px">{{
                $t("buttons.clearFilter")
              }}</span>
              <v-btn
                :loading="$store.state.loadingStatus"
                color="red"
                small
                class="ma-2 white--text"
                fab
                style="border-radius: 50% !important"
                rounded
              >
                <v-icon dark> mdi-delete-circle </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-data-table
          item-key="Id"
          :items="items"
          :headers="headers"
          :loading="$store.state.loadingStatus"
          :options.sync="options"
          :items-per-page="itemsPerPage"
          :hide-default-footer="true"
          :no-data-text="$t('description.noList')"
          @click:row="viewLog"
        >
         <!-- eslint-disable-next-line  -->
          <template v-slot:header.HeaderValue="{ header }">
            <div class="d-flex justify-center" style="margin-bottom: 10px;">
              <v-autocomplete
                style="width: 50px !important; max-heigth: 50px !important; min-height: 0 !important;"  
                v-model="model"
                :items="autoCompleteItems"
                :loading="isLoading"
                :search-input.sync="search"
                clearable
                hide-details
                hide-selected
                item-text="Value"
                item-value="Key"
                label="Arama"
                filled
                append-icon="mdi-magnify"
                solo
                @change="getItems()"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content @click="changeActiveItem(item, true)">
                     <!-- eslint-disable-next-line  -->
                    <v-list-item-title v-text="item.Value"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </div>
          </template>
           <!-- eslint-disable-next-line  -->
          <template v-slot:header.LogLevelValue="{  }">
            <div class="d-flex justify-center">
              <v-select
                @change="getItems"
                v-model="logLevel"
                style="width: 50px"
                :items="logLevels"
                item-text="Value"
                item-value="Key"
                solo
              >
                <template slot="item" slot-scope="data">
                  {{ $t("labels." + data.item.Value) }}
                </template>
                <template slot="selection" slot-scope="data">
                  {{ $t("labels." + data.item.Value) }}
                </template>
              </v-select>
            </div>
          </template>

           <!-- eslint-disable-next-line  -->
          <template v-slot:item.SystemTypeValue="{ item }">
            {{ item.SystemTypeValue }}
          </template>
           <!-- eslint-disable-next-line  -->
          <template v-slot:item.LogLevelValue="{ item }">
            <v-chip small :color="colors[item.LogLevelValue]" text-color="#FFF">
              {{ $t("labels." + item.LogLevelValue) }}
            </v-chip>
          </template>
           <!-- eslint-disable-next-line  -->
          <template v-slot:item.CreatedAt="{ item }">
            {{ formatDate(new Date(item.CreatedAt)) }}
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <!-- Pagination select box start -->
          <v-row align="center">
            <v-col md="3" class="ml-md-auto">
              <v-pagination
                @input="paginationChangeHandler"
                :value="pagination.page"
                :length="pages"
                :total-visible="15"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="ml-md-auto">
              <v-select
                v-model="itemsPerPage"
                :items="$store.state.perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                @change="changeItemsPerPage()"
                outlined
                dense
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- Pagination select box end -->
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LogServices from "../services/LogServices";
import formatDate from "../utils/formatDate";

export default {
  data: () => ({
    range: [],
    dateMenu: null,
    colors: {
      Emergency: "#CF0000",
      Alert: "#DB8241",
      Critical: "#DB2020",
      Error: "#DB2020",
      Warning: "#DBC615",
      Notice: "#6A5DA8",
      Informational: "#158CA8",
      Debug: "#C0C0C0",
    },
    logLevel: "",
    logLevels: [
      {
        Key: "",
        Value: "All",
      },
    ],

    // General Models
    isLoading: false,
    model: null,
    search: null,
    items: [],

    // List options
    options: {
      itemsPerPage: 10,
    },
    headers: [],
    logHeaders: [],
    headerValue: "",
    pagination: {},
    itemsPerPage: 10,
  }),

  computed: {
    selectedDateRange() {
      let startDate =
        this.range[0] ||
        formatDate(null, "-", "en", [
          { year: "numeric" },
          { month: "2-digit" },
          { day: "2-digit" },
        ]);
      let endDate =
        this.range[1] ||
        formatDate(null, "-", "en", [
          { year: "numeric" },
          { month: "2-digit" },
          { day: "2-digit" },
        ]);

      if (startDate >= endDate) {
        return endDate + " - " + startDate;
      }
      return startDate + " - " + endDate;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    fields() {
      if (!this.model) return [];
      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    autoCompleteItems() {
      return this.logHeaders.map((entry) => {
        return Object.assign({}, entry, {
          Id: entry.Key,
          Name: entry.Value,
        });
      });
    },
  },

  watch: {
    search(val) {
      // Items have already been loaded
      if (this.autoCompleteItems.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      this.getLogHeaders();
    },
  },
  mounted() {
    this.setup();
  },
  methods: {
    /**
     * Navigates detail page of log.
     * @param {*} item
     */
    viewLog(item) {
      this.$router.push({ name: "logDetail", params: { id: item.Id } });
    },
    /**
     * Like a bridge between formatDate function and object.
     */
    formatDate(date) {
      return formatDate(new Date(date), ".");
    },

    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getItems();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
      this.getItems();
    },

    async setup() {
      this.$store.state.loadingStatus = true;
      this.pagination = {
        totalItems: 0,
        rowsPerPage: 10,
        page: 1,
      };
      this.headers = [
        {
          text: this.$t("labels.type", { value: "Sistem" }),
          value: "SystemTypeValue",
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t("labels.level", { value: "Log" }),
          value: "LogLevelValue",
          sortable: false,
          groupable: false,
          align: "center",
        },
        {
          text: this.$t("labels.type", {}),
          value: "HeaderValue",
          sortable: false,
          groupable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderCreateDate"),
          value: "CreatedAt",
          sortable: false,
          groupable: false,
          align: "center",
        },
      ];
      await this.getLogLevels();
      await this.getLogHeaders();
      await this.getItems();
      //await this.getMarketPlaceList();

      this.onFinal();
    },

    /**
     * Get Log Headers From Service
     */
    async getLogHeaders() {
      await LogServices.getHeaders({
        Value: this.headerValue || null,
      })
        .then((res) => {
          this.logHeaders = res.data.Result;
        })
        .catch((err) => {
          window.handleServiceError(err);
        });
    },

    /**
     * Get Log Level List.
     */
    async getLogLevels() {
      await LogServices.getLogLevels({
        Value: this.headerValue || null,
      })
        .then((response) => {
          this.logLevels = [...this.logLevels, ...response.data.Result];
        })
        .catch((err) => {
          window.handleServiceError(err);
        });
    },

    /**
     * Resets filter.
     */
    resetAll() {
      this.model = [];
      this.range = ["", ""];
      this.logLevel = "";
      this.getItems();
      this.pagination = {
        totalItems: 0,
        rowsPerPage: 10,
        page: 1,
      };
      this.$refs.menu.save(selectedDateRange);
    },

    /**
     * Resets date picker.
     */
    async resetDatePicker() {
      this.range = ["", ""];
      await this.getItems();
      this.dateMenu = false;
      this.$refs.menu.save(selectedDateRange);
    },

    /**
     * Get item list.
     */
    async getItems() {
      this.$store.state.loadingStatus = true;
      await LogServices.getLogs({
        HeaderValue: this.model,
        From: this.range[0],
        To: this.range[1],
        LogLevelValue: this.logLevel,
        page: this.pagination.page,
        pageSize: this.pagination.rowsPerPage,
      })
        .then((res) => {
          this.items = res.data.Result;
          this.pagination.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Final Function Of Services
     */
    onFinal() {
      this.$store.state.loadingStatus = false;
    },
  },
};
</script>

<style scoped>
.form-input-item {
  width: 100%;
  border-radius: 4px;
  min-height: 56px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  font-size: 16px !important;
  color: #245c9e !important;
}

:deep(tr:hover) {
  cursor: pointer;
}

:deep(.v-text-field__details) {
  display: none;
}

:deep(.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot) {
  min-height: 48px;
}
</style>
