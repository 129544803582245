import axios from '../javascript/custom-axios'
const RESOURCE_NAME = 'companyMarket'

export default {
  getMarkets() {
    return axios.get(`${RESOURCE_NAME}/get`)
  },
  createTags(body){
    return axios.post(`${RESOURCE_NAME}`,body)
 
  }
}