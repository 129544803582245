<template>
  <div>
    <!-- Page content -->
    <v-card style="border-radius: 10px">
      <v-card-title class="popupHeadline">
        <v-row>
          <v-col class="py-2">{{ $t('title.stockManagement') }}</v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-data-table
          :headers="headers"
          :items="tableData"
          item-key="Id"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{
            disablePagination: true,
            disableItemsPerPage: true,
          }"
          :no-data-text="$t('description.noList')"
        >
          <!-- eslint-disable-next-line  -->
          <template v-slot:item.PhotoUrl="{ item }">
            <v-avatar size="90">
              <v-img
                :lazy-src="
                  item.ProductPhotos != null
                    ? item.ProductPhotos[0].FilePath
                    : 'https://img.freepik.com/premium-vector/furniture-logo-icon-vector-flat-design_586739-2914.jpg'
                "
                max-height="200"
                max-width="200"
                :src="
                  item.ProductPhotos != null
                    ? item.ProductPhotos[0].FilePath
                    : 'https://img.freepik.com/premium-vector/furniture-logo-icon-vector-flat-design_586739-2914.jpg'
                "
              >
              </v-img>
            </v-avatar>
          </template>
          <!-- eslint-disable-next-line  -->
          <template v-slot:item.Status="{ item }">
            <v-chip
              v-if="
                item.CriticalStockCount > item.StockCount &&
                item.StockCount != 0
              "
              class="ma-2"
              small
              color="#F57C00"
              text-color="white"
            >
              {{ $t('labels.criticalStock') }}
            </v-chip>

            <v-chip
              v-if="item.CriticalStockCount <= item.StockCount"
              class="ma-2 text--center"
              small
              color="#a7fdba"
              text-color="black"
            >
              {{ $t('labels.hasStock') }}
            </v-chip>
            <v-chip
              v-if="item.StockCount == 0"
              class="ma-2 text--center"
              small
              color="#B71C1C"
              text-color="white"
            >
              {{ $t('labels.hasNotStock') }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line  -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  class="mr-2 green-icon"
                  @click="goDetail(item.Id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-file-edit
                </v-icon>
              </template>
              <span>{{ $t('buttons.addStock') }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <v-row align="center">
            <v-col md="3" class="ml-md-auto">
              <v-pagination
                @input="paginationChangeHandler"
                :value="pagination.page"
                :length="pages"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="ml-md-auto">
              <v-select
                v-model="itemsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                outlined
                dense
                @change="changeItemsPerPage()"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ProductService from '../../services/ProductService';

export default {
  data: () => ({
    // List options
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    tableData: [],
    headers: [],
    pagination: {},
    itemsPerPage: 15,
    perPageValues: [
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],
  }),

  watch: {},
  mounted() {
    this.setup();
    this.getTableList();
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
  },
  components: {},

  methods: {
    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getTableList();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
      this.getTableList();
    },

    //Page setup
    setup() {
      this.headers = [
        {
          text: this.$t('title.photo'),
          align: 'start',
          sortable: false,
          value: 'PhotoUrl',
        },
        {
          text: this.$t('title.name'),
          align: 'start',
          sortable: false,
          value: 'Name',
        },
        {
          text: 'Kritik Stok Sayısı',
          align: 'start',
          sortable: false,
          value: 'CriticalStockCount',
        },
        {
          text: 'Toplam Stok Sayısı',
          align: 'start',
          sortable: true,
          value: 'StockCount',
        },
        {
          text: this.$t('title.status'),
          align: 'start',
          sortable: false,
          value: 'Status',
        },
        {
          text: this.$t('title.tableHeaderActions'),
          align: 'start',
          sortable: false,
          value: 'actions',
        },
      ];
      this.pagination = {
        totalItems: 100,
        rowsPerPage: 15,
        page: 1,
      };
    },
    goDetail(id) {
      this.$router.push({
        name: `stockDetail`,
        query: { id },
      });
    },
    //Fill's the survey list
    getTableList() {
      this.tableData = [];
      this.$store.state.loadingStatus = true;
      ProductService.getProducts(
        this.pagination.rowsPerPage,
        this.pagination.page
      )
        .then((res) => {
          this.tableData = res.data.Result;
          this.pagination.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
  },
};
</script>
