<template>
  <div style="width: 100%; height: 100%" class="#F0F0F1">
    <NavMenu @toggle-drawer="toggleDrawer" />
    <NavigationDrawer
      v-if="drawerOpen || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl"
      @close-drawer="closeDrawer"
    />
    <Container
      :style="{
        paddingLeft: $vuetify.breakpoint.xs
          ? '0'
          : $vuetify.breakpoint.lg || $vuetify.breakpoint.xl
          ? '250px'
          : drawerOpen
          ? '250px'
          : '0',
      }"
    />
  </div>
</template>

<script>
import Container from '../components/Container';
import NavMenu from '../components/NavMenu';
import NavigationDrawer from '../components/NavigationDrawer';

export default {
  components: {
    NavMenu,
    NavigationDrawer,
    Container,
  },
  data() {
    return {
      drawerOpen: null,
    };
  },

  mounted() {
    this.$vuetify.breakpoint.lg ? (this.drawerOpen = true) : null;
  },

  methods: {
    toggleDrawer() {
      this.drawerOpen = !this.drawerOpen;
    },
    closeDrawer() {
      this.drawerOpen = false;
    },
  },
};
</script>
