<template>
  <div>
    <!-- Page content -->
    <v-dialog
      content-class="short-content"
      v-model="updateInvoiceDateModal"
      @input="resetInvoiceDateModal()"
      @close="resetInvoiceDateModal()"
      max-width="600"
    >
      <v-card class="popup-card" elevation="1">
        <v-card-title
          class="popupHeadline d-flex justify-center"
          style="font-size: 16px"
        >
          {{ $t('titles.accountingSettings') }}
        </v-card-title>

        <v-divider></v-divider>
        <ValidationObserver
          v-slot="{ handleSubmit }"
          ref="updateInvoiceDateObserver"
        >
          <v-card-text class="mt-2">
            <form @submit.prevent="handleSubmit(updateInvoiceDate)">
              <v-row v-if="updateInvoiceDateModal">
                <FormElement
                  v-for="(element, index) in invoiceDateFormItems"
                  :key="
                    element.key.parent + '_' + element.key.child + '_' + index
                  "
                  v-model="$data[element.key.parent][element.key.child]"
                  :validationLabel="$t(element.validationLabel)"
                  :rules="element.rules"
                  :formLabel="$t(element.formLabel)"
                  :onChange="element.onChange"
                  :formType="element.formType"
                  :lg="element.lg"
                  :disabled="element.disabled"
                  :cols="element.cols"
                  :hidden="element.hidden"
                  :labelShown="element.labelShown"
                  :topLabel="element.topLabel"
                  :active="element.active"
                  :classes="element.classes"
                  :placeholder="$t(element.placeholder)"
                />
                <p
                  class="mb-6"
                  style="
                    margin: 5px;
                    flex: 1;
                    color: red;
                    font-size: 16px;
                    font-weight: normal;

                    text-align: center;
                  "
                  v-if="updateItemForm.PaymentType == '2'"
                >
                  {{ $t('labels.onlyWire') }}
                </p>
              </v-row>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: #0077d5;
                    padding: 2vh 3vh;
                    border-radius: 30px !important;
                    font-weight: bolder;
                    font-size: medium;
                  "
                  elevation="0"
                  class="darken-1"
                  color="primary"
                  type="submit"
                  >{{ $t('buttons.save') }}</v-btn
                >
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: var(--red);
                    padding: 2vh 2vh;
                    border-radius: 30px !important;
                    font-weight: bolder;
                    font-size: medium;
                  "
                  elevation="0"
                  class="darken-1"
                  @click="resetInvoiceDateModal"
                  type="button"
                  >{{ $t('buttons.cancel') }}</v-btn
                >
              </v-card-actions>
            </form>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-card elevation="1">
      <div class="flex w-full rounded-tl-sm rounded-tr-sm">
        <v-card-title style="color: var(--darkBlue)">
          <v-row class="mb-1">
            <v-col
              class="text-lg-left text-center pb-0 pb-sm-2"
              cols="12"
              sm="12"
              md="9"
              lg="9"
              xl="9"
            >
              <h3>{{ $t('navigationDrawerMenu.accounting') }}</h3>
            </v-col>
            <v-col
              class="d-flex align-center justify-end pb-0 pb-sm-2"
              cols="12"
              sm="12"
              md="3"
              lg="3"
              xl="3"
            >
              <v-btn
                v-if="
                  searchCustomer ||
                  searchReferenceNumber ||
                  searchTrackingNumber ||
                  isInvoiceIssued
                "
                variant="tonal"
                color="#0177d4"
                class="button mr-2"
                style="color: white; border-radius: 14px !important"
                @click="resetFilter"
              >
                {{ $t('buttons.clearFilter') }}
              </v-btn>
              <RefreshButton :refreshFunction="getTableList"></RefreshButton>
            </v-col>
          </v-row>
        </v-card-title>
      </div>

      <v-divider />

      <v-card-text fill-height fluid>
        <v-row align="center" justify="start" class="d-flex">
          <v-col cols="12" class="align-center">
            <v-row class="align-center pt-3">
              <FormElement
                v-model="searchCustomer"
                :validationLabel="$t('labels.customer')"
                :formLabel="$t('labels.customer')"
                @input="getSearchDebounced"
                :formType="{
                  name: 'text',
                  dense: true,
                  appendIcon: 'mdi-magnify',
                  appendClick: () => {
                    getSearchDebounced();
                  },
                }"
                :labelShown="false"
                :cols="6"
                :sm="6"
                :md="3"
                :lg="3"
                :xl="3"
                :topLabel="true"
                :hideDetails="true"
                :placeholder="$t('labels.customer')"
                :classes="'mb-2 mb-sm-0'"
              />
              <FormElement
                v-model="searchReferenceNumber"
                :validationLabel="$t('labels.referenceNumber')"
                :formLabel="$t('labels.referenceNumber')"
                @input="getSearchDebounced"
                :formType="{
                  name: 'text',
                  dense: true,
                  appendIcon: 'mdi-magnify',
                  appendClick: () => {
                    getSearchDebounced();
                  },
                }"
                :labelShown="false"
                :cols="6"
                :sm="6"
                :md="3"
                :lg="3"
                :xl="3"
                :topLabel="true"
                :hideDetails="true"
                :placeholder="$t('labels.referenceNumber')"
                :classes="'mb-2 mb-sm-0'"
              />
              <FormElement
                v-model="searchTrackingNumber"
                :validationLabel="$t('labels.cargoTrackingNumber')"
                :formLabel="$t('labels.cargoTrackingNumber')"
                @input="getSearchDebounced"
                :formType="{
                  name: 'text',
                  dense: true,
                  appendIcon: 'mdi-magnify',
                  appendClick: () => {
                    getSearchDebounced();
                  },
                }"
                :labelShown="false"
                :cols="6"
                :sm="6"
                :md="3"
                :lg="3"
                :xl="3"
                :topLabel="true"
                :hideDetails="true"
                :placeholder="$t('labels.cargoTrackingNumber')"
              />

              <FormElement
                v-model="isInvoiceIssued"
                :validationLabel="$t('labels.status')"
                :formLabel="$t('labels.status')"
                :formType="{
                  name: 'select',
                  items: {
                    data: [
                      {
                        Name: $t('labels.invoiceIssued'),
                        Value: true,
                      },
                      {
                        Name: $t('labels.invoiceNotIssued'),
                        Value: false,
                      },
                    ],
                    value: 'Value',
                    text: 'Name',
                  },
                  dense: true,
                }"
                :onChange="getSearchDebounced"
                :labelShown="false"
                :cols="6"
                :sm="6"
                :md="3"
                :lg="3"
                :xl="3"
                :topLabel="true"
                :hideDetails="true"
                :placeholder="$t('labels.status')"
              />
            </v-row>
          </v-col>
          <v-col cols="6" class="align-center">
            <v-row class="align-center"> </v-row>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="$store.state.loadingStatus"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{
            disablePagination: true,
            disableItemsPerPage: true,
          }"
          :no-data-text="$t('description.noList')"
          ref="orderList"
        >
          <!-- eslint-disable -->
          <template class="w-min" v-slot:item.CustomerLogo="{ item }">
            <div>
              <img
                :src="item.CustomerLogo"
                alt="Logo"
                style="width: 50px; height: auto"
              />
            </div>
          </template>
          <template v-slot:item.InvoiceDate="{ item }">
            {{ item.InvoiceDate ? convertToLocal(item.InvoiceDate) : '-' }}
          </template>
          <template v-slot:item.OrderDate="{ item }">
            {{ item.CreatedOnUtc ? convertToLocal(item.CreatedOnUtc) : '-' }}
          </template>
          <template v-slot:item.SumVolume="{ item }">
            {{ item.SumVolume }}{{ $t('labels.m3Shorten') }} /
            {{ item.SumWeight }}{{ $t('labels.kgShorten') }}
          </template>
          <template v-slot:item.EntranceToWarehouseDate="{ item }">
            {{
              item.EntranceToWarehouseDate
                ? convertToLocal(item.EntranceToWarehouseDate)
                : '-'
            }}
            /
            {{
              item.ExitFromWarehouseDate
                ? convertToLocal(item.ExitFromWarehouseDate)
                : '-'
            }}
          </template>
          <template v-slot:item.TrackingNo="{ item }">
            {{ item.TrackingNo ? item.TrackingNo : '-' }}
          </template>
          <template v-slot:item.InvoiceNo="{ item }">
            {{ item.InvoiceNo ? item.InvoiceNo : '-' }}
          </template>

          <template v-slot:item.IsInvoiceIssued="{ item }">
            <v-chip
              style="font-weight: 600"
              class="ma-2"
              small
              :color="item.IsInvoiceIssued ? 'green' : 'red'"
              text-color="#fff"
            >
              {{
                $t(
                  'titles.' +
                    (item.IsInvoiceIssued
                      ? 'invoiceIssued'
                      : 'invoiceNotIssued')
                )
              }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex text-start">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="#29B7B7"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="openInvoiceDateModal(item)"
                  >
                    {{
                      item.IsInvoiceIssued
                        ? 'mdi-pencil'
                        : 'mdi-invoice-text-plus'
                    }}
                  </v-icon>
                </template>
                <span>{{ $t('labels.tooltipEdit') }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>

        <div class="text-xs-center pt-2 md4 lg4">
          <v-row align="center">
            <v-col md="3" class="ml-md-auto">
              <v-pagination
                @input="paginationChangeHandler"
                :value="page"
                :length="pages"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="ml-md-auto">
              <v-select
                v-model="rowsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                outlined
                dense
                @change="changeItemsPerPage()"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RefreshButton from '../components/RefreshButton.vue';
import FormElement from '../components/FormElement.vue';
import OrderServices from '../services/OrderService';
import InvoiceServices from '../services/InvoiceServices';

export default {
  data: () => ({
    tableData: [],
    headers: [],
    userRole: '',
    searchInvoiceNo: '',
    searchTrackingNumber: '',
    searchReferenceNumber: '',
    searchCustomer: '',
    totalItems: 0,
    debounceTimeout: null,
    rowsPerPage: 15,
    page: 1,
    options: {
      itemsPerPage: 15,
    },
    perPageValues: [
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],
    isSupplier: false,

    updateInvoiceDateModal: false,
    updateItemForm: {},
    selectedItem: null,
    isInvoiceIssued: null,
  }),
  components: {
    RefreshButton,
    FormElement,
  },
  mounted() {
    this.isSupplier =
      this.$store.state.Type === 'Supplier' ||
      this.$store.state.Type === 'PendingSupplier';

    this.perPageValues.push({
      name: this.$t('labels.All'),
      value: 9999,
    });

    this.userRole = this.$store.getters.getUserType;
    this.setup();
    this.getTableList();
  },
  watch: {},
  computed: {
    invoiceDateFormItems() {
      return [
        {
          key: {
            parent: 'updateItemForm',
            child: 'IsInvoiceIssued',
          },
          validationLabel: 'labels.invoiceIssued',
          topLabel: true,
          light: false,
          labelShown: false,
          formType: {
            name: 'checkbox',
          },
          classes: 'mb-5',
          lg: 12,
          cols: 12,
          placeholder: 'labels.invoiceIssued',
        },
        {
          key: {
            parent: 'updateItemForm',
            child: 'IsPaid',
          },
          validationLabel: 'messages.paymentApproved',
          topLabel: true,
          light: false,
          labelShown: false,
          disabled: this.updateItemForm.PaymentType == '2',
          formType: {
            name: 'checkbox',
          },
          classes: 'mb-5',
          lg: 12,
          cols: 12,
          placeholder: 'messages.paymentApproved',
        },
        {
          key: {
            parent: 'updateItemForm',
            child: 'InvoiceNo',
          },
          validationLabel: 'labels.invoiceNo',
          topLabel: true,
          labelShown: false,
          onChange: this.receiptInput(),
          formType: {
            name: 'text',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.invoiceNo',
        },
        {
          key: {
            parent: 'updateItemForm',
            child: 'InvoiceDate',
          },
          validationLabel: 'labels.invoiceDate',
          topLabel: true,
          labelShown: false,
          onChange: this.receiptInput(),
          formType: {
            name: 'date',
            dateMode: 'date',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.invoiceDate',
        },
      ];
    },
    pages() {
      if (this.rowsPerPage == null || this.totalItems == null) {
        return 0;
      }
      return Math.ceil(this.totalItems / this.rowsPerPage);
    },
  },
  methods: {
    receiptInput(){
      this.updateItemForm.IsInvoiceIssued = !this.selectedItem.IsInvoiceIssued ? (!!this.updateItemForm.InvoiceDate || !!this.updateItemForm.InvoiceNo) : this.updateItemForm.IsInvoiceIssued;
    },
    updateInvoiceDate() {
      this.$store.state.loadingStatus = true;
      let body = {},
        submitFunction = null;

      if (this.selectedItem.IsInvoiceIssued) {
        body.Id = this.selectedItem.InvoiceId;
        submitFunction = InvoiceServices.update;
      } else {
        body.OrderId = this.selectedItem.OrderId;
        submitFunction = InvoiceServices.create;
      }

      body = {
        InvoiceNo: this.updateItemForm.InvoiceNo,
        InvoiceDate: this.updateItemForm.InvoiceDate,
        IsPaid: this.updateItemForm.IsPaid,
        IsInvoiceIssued: this.updateItemForm.IsInvoiceIssued
          ? this.updateItemForm.IsInvoiceIssued
          : false,
        ...body,
      };
      submitFunction(body)
        .then((response) => {
          window.showSuccess(this.$t('messages.successfulUpdateGeneral'));
          this.getTableList();
          this.resetInvoiceDateModal();
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    resetInvoiceDateModal() {
      this.updateInvoiceDateModal = false;
      this.updateItemForm = {};
      this.$refs.updateInvoiceDateObserver.reset();
    },

    openInvoiceDateModal(item) {
      if (item) {
        this.updateInvoiceDateModal = true;
        this.selectedItem = item;
        this.updateItemForm = Object.assign({}, item);

        console.log(item.IsInvoiceIssued, this.updateItemForm.IsInvoiceIssued);
      }
    },

    getSearchDebounced() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.rowsPerPage = 15;
        this.itemsPerPage = 15;
        this.page = 1;
        this.getTableList();
      }, 300);
    },

    resetFilter() {
      this.searchTrackingNumber = '';
      this.searchReferenceNumber = '';
      this.searchCustomer = '';
      this.isInvoiceIssued = null;
      this.filterContent();
    },

    changeItemsPerPage() {
      this.page = 1;
      this.options.itemsPerPage = this.rowsPerPage;
      this.getTableList();
    },

    paginationChangeHandler(pageNumber) {
      this.page = pageNumber;
      this.getTableList();
    },

    setup() {
      this.headers = [
        {
          text: this.$t('labels.customer'),
          value: 'Customer',
          sortable: false,
          align: 'center',
        },
        {
          text:
            this.$t('titles.totalVolumeShorten') +
            '/' +
            this.$t('titles.totalKiloShorten'),
          value: 'SumVolume',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.price'),
          value: 'Price',
          sortable: false,
          groupable: false,
          align: 'end',
        },
        {
          text:
            this.$t('labels.entranceToWarehouseDate') +
            '/' +
            this.$t('labels.exitFromWarehouseDate'),
          value: 'EntranceToWarehouseDate',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.referenceNumber'),
          value: 'RefNo',
          sortable: false,
          groupable: false,
          align: 'center',
        },

        {
          text: this.$t('labels.cargoTrackingNumber'),
          value: 'TrackingNo',
          sortable: false,
          groupable: false,
          align: 'center',
        },

        {
          text: this.$t('labels.invoiceNo'),
          value: 'InvoiceNo',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.invoiceDate'),
          value: 'InvoiceDate',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.orderDate'),
          value: 'OrderDate',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.status'),
          value: 'IsInvoiceIssued',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
        },
      ];
    },

    filterContent() {
      this.tableData = [];
      this.rowsPerPage = 15;
      this.itemsPerPage = 15;
      this.page = 1;
      this.getTableList();
    },

    getTableList() {
      this.tableData = [];
      this.$store.state.loadingStatus = true;

      OrderServices.getListForAccounting({
        pageSize: this.rowsPerPage,
        page: this.page,
        TrackingNo: this.searchTrackingNumber,
        RefNo: this.searchReferenceNumber,
        Customer: this.searchCustomer,
        IsInvoiceIssued: this.isInvoiceIssued,
      })
        .then((res) => {
          this.tableData = res.data.Result == null ? [] : res.data.Result;
          this.totalItems = res.data.TotalItems;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
  },
};
</script>
