<template>
  <div>
    <v-dialog
      v-if="statusDialog"
      v-model="changeStatus"
      max-width="400"
      persistent
    >
      <v-card>
        <v-card-title class="">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ this.statusTitle }}
          </div>
        </v-card-title>

        <v-card-text class="py-3">
          <div class="text-center" style="width: 100%">
            {{ this.description }}
          </div>
        </v-card-text>

        <v-card-actions
          style="
            background-color: white;
            position: sticky;
            bottom: 0px;
            border-top: solid grey 2px;
          "
        >
          <v-spacer></v-spacer>
          <v-btn class="btn-save rounded-lg" @click="changeProdStatus()">
            {{ $t('buttons.yes') }}
          </v-btn>
          <v-btn class="btn-cancel rounded-lg" @click="clearStatusModel()">
            {{ $t('buttons.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="tagDialog"
      v-model="changeStatus"
      max-width="500"
      persistent
    >
      <v-card>
        <v-card-title class="">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('title.bulkAddTag') }}
          </div>
        </v-card-title>

        <v-card-text class="py-3">
          <div class="text-center" style="width: 100%">
            {{ $t('description.bulkAddTag') }}
          </div>
          <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <form @submit.prevent="handleSubmit(addTagsToProducts)">
              <ValidationProvider
                :name="$t('labels.marketplaceLabel')"
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
              >
                <v-combobox
                  v-model="selectedMarketplaces"
                  :items="companyMarketplaces"
                  :label="$t('labels.marketplaceLabel')"
                  item-text="Name"
                  item-value="Value"
                  multiple
                  chips
                  :error-messages="errors[0]"
                ></v-combobox>
              </ValidationProvider>
              <ValidationProvider
                :name="$t('labels.tags')"
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
              >
                <v-combobox
                  v-model="tagName"
                  :items="tags"
                  :search-input.sync="search"
                  hide-selected
                  :hint="$t('labels.max15')"
                  :label="$t('labels.tags')"
                  multiple
                  :disabled="tagIds.length == 15"
                  persistent-hint
                  deletable-chips
                  small-chips
                  item-text="Name"
                  item-value="Id"
                  @input="selectTag(tagName)"
                  @keyup.enter="addTag(tagName)"
                  counter="15"
                  :error-messages="errors[0]"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          "<strong>{{ search }}</strong
                          >" için bir eşleşme bulunamadı. Yeni bir tane
                          oluşturmak için <kbd>enter</kbd> tuşuna basın.
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </ValidationProvider>
              <v-spacer></v-spacer>
              <v-card-actions
                style="
                  background-color: white;
                  position: sticky;
                  bottom: 0px;
                  border-top: solid grey 2px;
                "
              >
                <v-spacer></v-spacer>
                <v-btn class="btn-save rounded-lg" type="submit">
                  {{ $t('buttons.add') }}
                </v-btn>
                <v-btn
                  class="btn-cancel rounded-lg"
                  @click="clearStatusModel()"
                >
                  {{ $t('buttons.cancel') }}
                </v-btn>
              </v-card-actions>
            </form>
          </ValidationObserver>

          <v-alert
            v-if="tagIds.length == 15"
            border="left"
            close-text="Close Alert"
            type="info"
            color="#7443a0"
            dismissible
            outlined
            prominent
            class="font-weight-black mt-3"
            style="font-size: 1.2em"
            :error-messages="errors[0]"
          >
            {{ $t('description.tagCountReached') }}
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="priceDialog"
      v-model="changeStatus"
      max-width="500"
      persistent
    >
      <v-card>
        <v-card-title class="">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('title.bulkUpdateProduct') }}
          </div>
        </v-card-title>

        <v-card-text class="py-3">
          <div class="text-center" style="width: 100%">
            {{ $t('description.bulkUpdateProduct') }}
          </div>
          <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <form @submit.prevent="handleSubmit(changePrice)">
              <ValidationProvider
                :name="$t('labels.marketplaceLabel')"
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
              >
                <v-combobox
                  v-model="selectedMarketplaces"
                  :items="companyMarketplaces"
                  :label="$t('labels.marketplaceLabel')"
                  item-text="Name"
                  item-value="Value"
                  multiple
                  chips
                  :error-messages="errors[0]"
                >
                </v-combobox>
              </ValidationProvider>
              <ValidationProvider
                :name="$t('labels.priceChange')"
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
              >
                <v-combobox
                  v-model="priceChange"
                  :items="[
                    { Name: 'Arttır', Value: true },
                    { Name: 'Azalt', Value: false },
                  ]"
                  :label="$t('labels.priceChange')"
                  item-text="Name"
                  item-value="Value"
                  chips
                  :error-messages="errors[0]"
                >
                </v-combobox>
              </ValidationProvider>
              <ValidationProvider
                :name="$t('labels.changePercentage')"
                :rules="{
                  required: true,
                  numeric: true,
                }"
                v-slot="{ errors }"
              >
                <v-text-field
                  :placeholder="$t('labels.changePercentage')"
                  class="custom-border"
                  style="padding: 10px"
                  v-model="percentage"
                  :error-messages="errors[0]"
                >
                </v-text-field>
              </ValidationProvider>
              <v-spacer></v-spacer>
              <v-card-actions
                style="
                  background-color: white;
                  position: sticky;
                  bottom: 0px;
                  border-top: solid grey 2px;
                "
              >
                <v-spacer></v-spacer>
                <v-btn class="btn-save rounded-lg" type="submit">
                  {{ $t('buttons.change') }}
                </v-btn>
                <v-btn
                  class="btn-cancel rounded-lg"
                  @click="clearStatusModel()"
                >
                  {{ $t('buttons.cancel') }}
                </v-btn>
              </v-card-actions>
            </form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Page content -->
    <v-card style="border-radius: 10px">
      <v-card-title style="color: #0177d4">
        <v-row>
          <v-col class="py-2">{{ $t('title.products') }}</v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-row align="start" class="d-flex mb-6">
          <v-col cols="2" class="pa-2 mr-auto">
            <v-select
              v-model="selectedCategory"
              :value="selectedCategory"
              :items="companyCategories.concat({ Name: 'Tümü', Value: '' })"
              :label="$t('labels.category')"
              item-text="Name"
              item-value="Value"
              outlined
              dense
              rounded
              @change="filterContent()"
            >
            </v-select>
          </v-col>
          <v-col cols="2" class="pa-2 mr-auto">
            <v-select
              v-model="selectedBrand"
              :value="selectedBrand"
              :items="companyBrands.concat({ Name: 'Tümü', Value: '' })"
              :label="$t('labels.brand')"
              item-text="Name"
              item-value="Value"
              outlined
              dense
              rounded
              @change="filterContent()"
            >
            </v-select>
          </v-col>
          <v-col cols="2" class="pa-2 mr-auto">
            <v-select
              v-model="selectedCreator"
              :value="selectedCreator"
              :items="productCreators.concat({ Name: 'Tümü', Value: '' })"
              :label="$t('labels.Creator')"
              item-text="Name"
              item-value="Value"
              outlined
              dense
              rounded
              @change="filterContent()"
            >
            </v-select>
          </v-col>
          <v-col cols="2" class="pa-2 mr-auto">
            <v-select
              v-model="selectedStatus"
              :value="selectedStatus"
              :items="productStatus"
              :label="$t('labels.status')"
              item-text="Name"
              item-value="Value"
              outlined
              dense
              rounded
              @change="filterContent()"
            >
            </v-select>
          </v-col>
          <v-col v-if="isSelected" cols="2" class="pa-2 mr-auto"> </v-col>
          <v-col v-if="!isSelected" cols="2" class="pa-2mr-auto text-right">
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="purple-icon mr-4 align-self-center"
                >
                  mdi-file-edit-outline
                </v-icon>
                <span
                  class="align-self-center mr-2"
                  style="font-size: 12px; font-weight: normal; color: #598db5"
                  >Toplu İşlemler</span
                >
              </template>
              <v-list>
                <v-list-item link @click="openTagDialog()">
                  <v-list-item-icon class="mr-3">
                    <v-icon class="green-icon">mdi-tag-plus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{
                    $t('title.addTag')
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="openPriceDialog()">
                  <v-list-item-icon class="mr-3">
                    <v-icon class="green-icon">mdi-currency-usd</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{
                    $t('title.priceChange')
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="2" class="pa-2mr-auto">
            <div style="gap: 1rem">
              <span
                class="align-self-center mr-2"
                style="font-size: 12px; font-weight: normal; color: #598db5"
                >{{ $t('buttons.addProduct') }}</span
              >
              <v-icon
                @click="addProduct()"
                class="icon-button mr-4 align-self-center"
              >
                mdi-plus
              </v-icon>
            </div>
          </v-col>
        </v-row>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="tableData"
          :single-select="singleSelect"
          item-key="Id"
          show-select
          :loading="loadings.table"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{
            disablePagination: true,
            disableItemsPerPage: true,
          }"
          :no-data-text="$t('description.noList')"
        >
          <!-- eslint-disable-next-line  -->
          <template v-slot:item.PhotoUrl="{ item }">
            <v-avatar size="90">
              <v-img
                :lazy-src="
                  item.ProductPhotos != null
                    ? item.ProductPhotos[0].FilePath
                    : 'https://img.freepik.com/premium-vector/furniture-logo-icon-vector-flat-design_586739-2914.jpg'
                "
                max-height="200"
                max-width="200"
                :src="
                  item.ProductPhotos != null
                    ? item.ProductPhotos[0].FilePath
                    : 'https://img.freepik.com/premium-vector/furniture-logo-icon-vector-flat-design_586739-2914.jpg'
                "
              >
              </v-img>
            </v-avatar>
          </template>
          <!-- eslint-disable-next-line  -->
          <template v-slot:item.Status="{ item }">
            <v-chip
              v-if="item.Status == true"
              class="ma-2"
              small
              color="#a7fdba"
              text-color="black"
            >
              Aktif
            </v-chip>

            <v-chip
              v-if="item.Status == false"
              class="ma-2 text--center"
              small
              color="#c08c99"
              text-color="black"
            >
              Pasif
            </v-chip>
          </template>
          <!-- eslint-disable-next-line  -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  class="mr-2 green-icon"
                  @click="goDetail(item.Id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-file-edit
                </v-icon>
              </template>
              <span>{{ $t('labels.tooltipEdit') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  :class="
                    item.Status == true ? 'mr-2 red-icon' : 'mr-2 blue-icon'
                  "
                  @click="openStatusDialog(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{
                    item.Status == true
                      ? 'mdi-minus-box'
                      : 'mdi-checkbox-marked'
                  }}
                </v-icon>
              </template>
              <span>{{
                item.Status == true ? 'Yayından Kaldır' : 'Yayına Al'
              }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <v-row align="center">
            <v-col md="3" class="ml-md-auto">
              <v-pagination
                @input="paginationChangeHandler"
                :value="pagination.page"
                :length="pages"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="ml-md-auto">
              <v-select
                v-model="itemsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                outlined
                dense
                @change="changeItemsPerPage()"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ProductService from '../../services/ProductService';
import DeleteModal from '../../components/General/DeleteModal';
import CategoryService from '../../services/CategoriesService';
import BrandService from '../../services/BrandService';
import CompanyMarketplaceService from '../../services/CompanyMarketplaceService';
import UserService from '../../services/UserService';
import TagService from '../../services/TagServices';
import BulkEditService from '../../services/BulkEditService';

export default {
  data: () => ({
    // List options
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    selectedStatus: '',
    statusTitle: '',
    statusDialog: false,
    newStatus: null,
    isOpen: true,
    itemId: '',
    description: '',
    selectedCategory: '',
    selectedCreator: '',
    selectedBrand: '',
    tagDialog: false,
    priceDialog: false,
    changeStatus: {},
    tableData: [],
    tagName: [],
    tags: [],
    tagIds: [],
    search: null,
    deleteItemModel: {},
    priceChange: null,
    percentage: null,
    singleSelect: false,
    selected: [],
    selectedMarketplaces: [],
    headers: [],
    //Loading states
    loadings: {
      table: false,
      select: false,
      send: false,
    },
    productStatus: [
      {
        Name: 'Aktif',
        Value: true,
      },
      {
        Name: 'Pasif',
        Value: false,
      },
      {
        Name: 'Tümü',
        Value: null,
      },
    ],
    companyCategories: [],
    companyMarketplaces: [],
    companyBrands: [],
    productCreators: [],
    pagination: {},
    itemsPerPage: 15,
    perPageValues: [
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],
  }),

  watch: {},
  mounted() {
    this.setup();
    this.getTableList();
    this.getCompanyCategories();
    this.getBrands();
    this.getCreators();
  },
  computed: {
    isSelected() {
      if (this.selected.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
  },
  components: {
    DeleteModal,
  },

  methods: {
    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getTableList();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
      this.getTableList();
    },
    getCompanyCategories() {
      this.companyCategories = [];
      this.$store.state.loadingStatus = true;
      CategoryService.getCategories()
        .then((res) => {
          res.data.Result.forEach((element) => {
            this.companyCategories.push({
              Name: element.CategoryName,
              Value: element.Id,
            });
          });
        })
        .catch((err) => {
          this.$store.state.loadingStatus = false;
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getBrands() {
      this.companyBrands = [];
      this.$store.state.loadingStatus = true;
      BrandService.getBrands(100, 1)
        .then((res) => {
          res.data.Result.forEach((element) => {
            this.companyBrands.push({ Name: element.Brand, Value: element.Id });
          });
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getCreators() {
      this.productCreators = [];
      this.$store.state.loadingStatus = true;
      UserService.getUsers(100, 1)
        .then((res) => {
          res.data.Result.Users.forEach((element) => {
            this.productCreators.push({
              Name: element.Name + ' ' + element.Surname,
              Value: element.Id,
            });
          });
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },
    //Page setup
    setup() {
      this.headers = [
        {
          text: this.$t('title.photo'),
          align: 'start',
          sortable: false,
          value: 'PhotoUrl',
        },
        {
          text: this.$t('title.name'),
          align: 'start',
          sortable: false,
          value: 'Name',
        },
        {
          text: this.$t('title.Creator'),
          align: 'start',
          sortable: false,
          value: 'CreateBy',
        },
        {
          text: this.$t('title.category'),
          align: 'start',
          sortable: false,
          value: 'Category',
        },
        {
          text: this.$t('title.status'),
          align: 'start',
          sortable: false,
          value: 'Status',
        },
        {
          text: this.$t('title.tableHeaderActions'),
          align: 'start',
          sortable: false,
          value: 'actions',
        },
      ];
      this.pagination = {
        totalItems: 100,
        rowsPerPage: 15,
        page: 1,
      };
    },
    openStatusDialog(item) {
      if (item.Status) {
        this.description = this.$t('description.StatusToInactiveDescription');
        this.statusTitle = this.$t('description.statusToInactiveModalTitle');
        this.newStatus = false;
      } else {
        this.description = this.$t('description.StatusToActiveDescription');
        this.statusTitle = this.$t('description.statusToActiveModalTitle');
        this.newStatus = true;
      }
      this.itemId = item.Id;

      this.statusDialog = true;
    },
    changeProdStatus() {
      this.statusDialog = false;
      this.$store.state.loadingStatus = true;
      let body = {
        ProductId: this.itemId,
        Status: this.newStatus,
      };
      ProductService.changeStatusProduct(body)
        .then((res) => {
          this.getTableList();
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    addProduct() {
      this.$router.push({
        name: `productDetail`,
      });
    },
    goDetail(id) {
      this.$router.push({
        name: `productDetail`,
        query: { id },
      });
    },
    openTagDialog() {
      this.tagDialog = true;
      this.$store.state.loadingStatus = true;
      if (this.companyMarketplaces.length == 0) {
        CompanyMarketplaceService.getMarkets()
          .then((res) => {
            res.data.Result.forEach((element) => {
              this.companyMarketplaces.push({
                Name: element.MarketplaceName,
                Value: element.Id,
              });
            });
            this.getTags();
          })
          .catch((err) => {
            window.handleServiceError(err);
          })
          .then(() => {
            this.$store.state.loadingStatus = false;
          });
      }
      this.$store.state.loadingStatus = false;
    },
    openPriceDialog() {
      this.priceDialog = true;
      if (this.companyMarketplaces.length == 0) {
        this.$store.state.loadingStatus = true;
        CompanyMarketplaceService.getMarkets()
          .then((res) => {
            res.data.Result.forEach((element) => {
              this.companyMarketplaces.push({
                Name: element.MarketplaceName,
                Value: element.Id,
              });
            });
          })
          .catch((err) => {
            window.handleServiceError(err);
          })
          .then(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },
    filterContent() {
      this.$store.state.loadingStatus = true;

      this.pagination.rowsPerPage = 15;
      this.itemsPerPage = 15;
      this.pagination.page = 1;
      this.getTableList();
    },
    //Fill's the survey list
    getTableList() {
      this.tableData = [];
      this.$store.state.loadingStatus = true;
      ProductService.getProducts(
        this.pagination.rowsPerPage,
        this.pagination.page,
        this.selectedBrand,
        this.selectedCategory,
        this.selectedCreator,
        this.selectedStatus
      )
        .then((res) => {
          if (res.data.Result != null) {
            this.tableData = res.data.Result;

            this.pagination.totalItems = res.data.TotalItems;
          }
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    //Clears all modal's states and item ID
    clearModal() {
      this.$refs.observer.reset();
    },
    changePrice() {
      this.priceDialog = false;
      this.$store.state.loadingStatus = true;
      let body = {
        ProductIds: this.selected.map((obj) => obj.Id),
        MarketplaceIds: this.selectedMarketplaces.map((obj) => obj.Value),
        PricePercentage: {
          Change: this.priceChange.Value,
          Percentage: this.percentage,
        },
      };

      BulkEditService.updatePrice(body)
        .then((res) => {
          if (res.data.Code == 400 || res.data.Code == 500) {
            window.showError(res.data.Message);
          } else {
            window.showSuccess(res.data.Message);
          }
        })
        .catch((err) => {
          window.showError(err);
        })
        .finally(() => {
          this.clearStatusModel();
          this.$store.state.loadingStatus = false;
        });
    },
    selectTag(tag) {
      if (tag && this.tagIds.length <= 15 && tag.length <= 15) {
        // this.tagIds = [];
        tag.forEach((x) => this.tagIds.push(x.Name));
      }
      this.tagIds = [...new Set(this.tagIds)];
      this.tagIds = this.tagIds.filter((x) => x != undefined);
    },

    getTags() {
      this.$store.state.loadingStatus = true;
      TagService.getTags()
        .then((res) => {
          this.tags = res.data.Result;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    addTag(tag) {
      if (this.tagIds.length <= 15) {
        tag.forEach((x, index) => {
          if (x.Id == undefined) {
            let body = {
              Name: x,
            };

            if (index == tag.length - 1) {
              this.$store.state.loadingStatus = true;
              TagService.addTag(body)
                .then((res) => {
                  this.tags[index] = res.data;
                  this.tagIds.push(body.Name);
                })
                .catch((err) => {
                  window.showError(err);
                })
                .finally(() => {
                  this.$store.state.loadingStatus = false;
                });
            }
          }
        });
        this.tagIds = this.tagIds.filter((x) => x != undefined);
        this.tagIds = [...new Set(this.tagIds)];
      }
    },
    addTagsToProducts() {
      this.tagDialog = false;
      this.$store.state.loadingStatus = true;
      let body = {
        ProductIds: this.selected.map((obj) => obj.Id),
        MarketplaceIds: this.selectedMarketplaces.map((obj) => obj.Value),
        Tags: this.tagIds,
      };
      BulkEditService.addManyTags(body)
        .then((res) => {
          if (res.data.Code == 400 || res.data.Code == 500) {
            window.showError(res.data.Message);
          } else {
            window.showSuccess(res.data.Message);
          }
        })
        .catch((err) => {
          window.showError(err);
        })
        .finally(() => {
          this.clearStatusModel();
          this.$store.state.loadingStatus = false;
        });
    },
    clearStatusModel() {
      this.newStatus = null;
      this.itemId = '';
      this.statusDialog = false;
      this.priceDialog = false;
      this.selected = [];
      this.selectedMarketplaces = [];
      this.tagDialog = false;
      this.priceChange = null;
      this.percentage = null;
      this.tagIds = [];
      this.tagName = [];
    },
  },
};
</script>
