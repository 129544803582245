// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
let RESOURCE_NAME = "marketlace";

export default {
  /**
   * Creates a new marketplace.
   * @param {*} body
   * @returns {Promise<any>}
   */
  add(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },

  /**
   * Update informations of marketplace..
   * @param {*} body
   * @returns {Promise<any>}
   */
  update(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },

  /**
   * Delete a marketplace.
   * @param {*} body
   * @returns {Promise<any>}
   */
  delete(body) {
    return axios.delete(`${RESOURCE_NAME}/delete`, body);
  },

  /**
   * It returns marketplace list by filter.
   * @param {*} { MarketplaceId, Name }
   * @returns {Promise<any>}
   */
  get(body = {}) {
    return axios.get(`${RESOURCE_NAME}/get`, body);
  },

  /**
   * Get a specific marketplace.
   * @param {*} { Id }
   * @returns {Promise<any>}
   */
  getById(body) {
    return axios.get(`${RESOURCE_NAME}/getById`, body);
  },

  /**
   * Get a specific marketplace's parameters.
   * @param {string} id
   * @returns {Promise<any>}
   */
  getParameters(id) {
    return axios.get(`${RESOURCE_NAME}/getParameters/${id}`);
  },
};
