import axios from '../javascript/custom-axios'
const RESOURCE_NAME = 'stockInventory'

export default {
  
  addStock(body){
    return axios.post(`${RESOURCE_NAME}/add`,body)
 
  },
  getStocksByProdId(id){
    return axios.get(`${RESOURCE_NAME}/get`,{params:{ProductId:id}})
 
  },
  swapStock(body){
    return axios.put(`${RESOURCE_NAME}/swap`,body)
 
  }
}