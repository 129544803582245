<template>
  <div>
    <NavMenu />
    <div class="forgotPasswordPage">
      <v-layout wrap align-center justify-center fill-height>
        <v-container style="max-width: 50%">
          <!-- Forgot Password START -->
          <ChangePassword
            :data="data"
            v-if="changeable"
            :isVerified="changeable"
          />
          <v-layout v-if="false" wrap align-center justify-center fill-height>
            <v-card
              style="padding: 10vh; border-radius: 20px"
              max-width="50%"
              min-width="50%"
            >
              <v-row>
                <v-col md="12" xs="12">
                  <div>
                    <div
                      style="
                        color: #fb3d6b;
                        font-weight: 700;
                        font-size: 24px;
                        margin-bottom: 22px;
                      "
                    >
                      {{ $t('description.verifyResetToken') }}
                    </div>
                    <ValidationObserver
                      ref="observer"
                      v-slot="{ handleSubmit }"
                    >
                      <form @submit.prevent="handleSubmit(verifyResetToken)">
                        <ValidationProvider
                          name="password"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="text-field-label">
                            <label>Şifre</label>
                          </div>
                          <v-text-field
                            placeholder="Şifre"
                            class="custom-border"
                            outlined
                            v-model="password"
                            :error-messages="errors[0]"
                            :counter="24"
                            :append-icon="
                              showPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                          ></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider
                          name="Şifre"
                          rules="required|confirmedPassword:password"
                          v-slot="{ errors }"
                        >
                          <div class="text-field-label">
                            <label>Tekrar Şifre</label>
                          </div>
                          <v-text-field
                            placeholder="Tekrar Şifre"
                            class="custom-border"
                            outlined
                            v-model="confirmPassword"
                            :error-messages="errors[0]"
                            :counter="24"
                            :append-icon="
                              showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="showConfirmPassword ? 'text' : 'password'"
                            @click:append="
                              showConfirmPassword = !showConfirmPassword
                            "
                          ></v-text-field>
                        </ValidationProvider>
                        <!-- Şifremi unuttum  burda -->
                        <v-row class="mt-10 justify-center">
                          <v-col
                            cols="12"
                            sm="6"
                            xl="12"
                            style="padding: 0"
                            class="my-1 d-xl-flex justify-xl-center"
                          >
                            <v-btn
                              style="
                                width: 100%;
                                color: #fff;
                                text-transform: unset !important;
                                background-color: #0077d5;
                                padding: 2vh 3vh;
                                border-radius: 30px !important;
                                font-weight: bolder;
                              "
                              elevation="0"
                              class="darken-1"
                              type="submit"
                              :loading="loading"
                              :disabled="loading"
                            >
                              {{ $t('buttons.save') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </form>
                    </ValidationObserver>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-layout>
          <!-- Forgot Password END -->
        </v-container>
      </v-layout>
    </div>
  </div>
</template>
<script>
import NavMenu from '../components/NavMenu.vue';
import ChangePassword from '../components/ChangePassword.vue';
import AccountService from '../services/AccountService';

export default {
  data() {
    return {
      token: '',
      userId: '',

      password: '',
      confirmPassword: '',
      loading: false,

      showPassword: false,
      showConfirmPassword: false,

      changeable: true,
    };
  },
  components: { NavMenu, ChangePassword },
  mounted() {
    this.token = this.$route.query.code;
    this.userId = this.$route.query.userId;

    if (!this.token && !this.userId) {
      this.$router.push({ name: 'login' });
    }

    this.token = this.token.replaceAll(' ', '+');
  },
  computed: {
    data() {
      return {
        UserId: this.userId,
        ResetToken: this.token,
      };
    },
  },
  methods: {
    /**
     * Verifying the reset token.
     */
    verifyResetToken() {
      this.loading = true;
      this.$store.state.loadingStatus = true;

      let body = {
        ResetToken: this.token,
        UserId: this.userId,
      };

      AccountService.verifyResetToken(body)
        .then((response) => {
          this.changeable = response.data.Result.State || false;
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally((response) => {
          this.loading = false;
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * After your verifying. You can change password.
     */
    changePassword() {
      this.loading = true;

      new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true);
        }, 2000);
      })
        .then((response) => {
          window.showSuccess('Şifreniz başarılı bir şekilde güncellendi.');
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally((response) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style>
.forgotPasswordPage {
  background: url('../images/6.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.text-field-label {
  color: #45b9be;
  margin-bottom: 2vh;
  font-weight: 500;
}
</style>
