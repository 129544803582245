<template>
  <div>
    <div class="unauthenticatedPage">
      <v-layout wrap align-center justify-center fill-height>
        <v-container>
          <v-layout wrap align-center justify-center fill-height>
            <v-card
              style="padding: 5vh 8vh; border-radius: 20px"
              max-width="80%"
              min-width="70%"
            >
              <v-row>
                <v-col
                  md="1"
                  xs="1"
                  class="d-flex flex-column justify-center align-center"
                >
                  <v-icon
                    color="white"
                    class="mr-4"
                    size="74"
                    :style="`border-radius: 50%; padding: 10px; background-color: ${getStyle.backgroundColor}`"
                    >{{ getStyle.icon }}</v-icon
                  >
                </v-col>
                <v-col
                  md="11"
                  xs="11"
                  class="d-flex flex-column justify-center"
                >
                  <div :class="'mb-2 text-center common ' + getStyle.textClass">
                    {{ getStyle.text }}
                  </div>
                  <div :class="'text-center common ' + getStyle.textClass">
                    {{ $t('messages.paymentRedirect') }}
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-layout>
        </v-container>
      </v-layout>
    </div>
  </div>
</template>

<script>
import OrderServices from '../services/OrderService';

export default {
  data() {
    return {
      isSuccess: false,
      serviceProblem: false,
      proccessing: true,
    };
  },
  computed: {
    getStyle() {
      if (this.proccessing)
        return {
          backgroundColor: 'gray',
          textClass: 'processing',
          text: this.$t('messages.paymentProcessing'),
          icon: 'mdi-cash-clock',
        };
      return this.isSuccess
        ? {
            backgroundColor: '#10ad4e',
            textClass: 'succeed',
            text: this.$t('messages.paymentSuccessful'),
            icon: 'mdi-check',
          }
        : {
            backgroundColor: '#f35050',
            textClass: 'failed',
            text: this.$t('messages.paymentFailed'),
            icon: 'mdi-close',
          };
    },
  },

  async mounted() {
    this.$store.state.loadingStatus = true;
    if (this.$route.query.success === 'true') {
      const id = this.$route.query.id;
      if (id) {
        await OrderServices.respondThePayment(id)
          .then((res) => {
            this.isSuccess = true;
            let redirect = 'home/' + res.data.Result;

            setTimeout(() => {
              this.$router.push(redirect);
            }, 3000);
          })
          .finally(() => {
            this.proccessing = false;
            this.$store.state.loadingStatus = false;
          });
      }
    }
  },
  methods: {},
};
</script>
<style>
.common {
  font-weight: 700;
  font-size: 28px;
}

.failed {
  color: #f35050;
}

.succeed {
  color: #10ad4e;
}

.processing {
  color: #000;
}
</style>
