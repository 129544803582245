export const phone = {
  required: true,
  numeric: true,
  max: 15,
};

export const authorizedUser = {
  required: true,
  max: 35
};

export const company = {
  required: true,
  max: 35
};

export const productType = {
  required: true,
  max: 35
}

export default {
    phone,
    authorizedUser,
    company,
    productType
};

