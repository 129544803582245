<template>
  <div>
    <!-- Add/Update Modal -->
    <v-dialog
      @click:outside="
        emptyCart();
        clearModal();
      "
      :eager="true"
      :transition="false"
      v-model="buyPackageModal"
      min-width="300"
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline update">
          <div class="text-center white--text" style="width: 100%">
            {{ currentTitle }}
          </div>
        </v-card-title>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-card-text class="py-3">
                  <v-row class="align-center pt-4">
                    <v-col
                      v-if="isUpdate"
                      class="mb-4 form-column d-flex"
                      cols="12"
                      lg="12"
                    >
                      <v-img
                        v-if="
                          item.PhotoUrl || require('../assets/no-image.png')
                        "
                        :lazy-src="
                          item.PhotoUrl || require('../assets/no-image.png')
                        "
                        max-height="50"
                        max-width="50"
                        :src="
                          item.PhotoUrl || require('../assets/no-image.png')
                        "
                      ></v-img>
                      <div class="ml-4 align-self-center d-flex flex-column">
                        <span class="form-label">
                          {{ item.WarehouseName }}
                        </span>
                        <div>
                          <span style="line-height: 2.2; color: #315a88"
                            >{{ $t(itemForm[0].formLabel) }}:
                          </span>
                          <span style="line-height: 2.2; color: #315a88">{{
                            item[itemForm[0].key.child]
                          }}</span>
                        </div>
                      </div>
                    </v-col>
                    <FormElement
                      v-for="element in itemForm"
                      :key="element.key.parent + '_' + element.key.child"
                      :vertical="element.vertical"
                      v-model="$data[element.key.parent][element.key.child]"
                      :validationLabel="$t(element.validationLabel)"
                      :rules="element.rules"
                      :formLabel="$t(element.formLabel)"
                      :onChange="element.onChange"
                      :formType="element.formType"
                      :placeholder="$t(element.placeholder)"
                    />
                  </v-row>
                </v-card-text>
              </v-window-item>
              <v-window-item :value="2">
                <v-card-text>
                  <h1 class="main-title mb-6">
                    {{ $t('titles.cart') }}
                  </h1>
                  <v-row class="align-center">
                    <CartItem
                      :key="item.id"
                      :item="item"
                      :deleteItem="deleteItem"
                      :isLast="
                        index === renderedMarketplaces.items.length - 1
                          ? true
                          : false
                      "
                      v-for="(item, index) in renderedMarketplaces.items"
                    />
                    <v-col class="text-center" cols="12">
                      <v-btn
                        :loading="$store.state.loadingStatus"
                        :disabled="$store.state.loadingStatus"
                        color="red"
                        class="ma-2 white--text"
                        @click="emptyCart"
                      >
                        {{ $t('labels.emptyCart') }}
                        <v-icon right dark> mdi-delete-circle-outline </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col class="d-sm-flex justify-space-between" cols="12">
                      <span
                        style="
                          font-size: 22px;
                          font-weight: bold;
                          color: rgb(46, 112, 15);
                        "
                      >
                        Discount:
                      </span>
                      <span style="font-size: 22px; color: rgb(2, 2, 2)">
                        -{{
                          (
                            (renderedMarketplaces.total * discount) /
                            100
                          ).toFixed(2)
                        }}$
                      </span>
                    </v-col>
                    <v-col cols="12" class="d-sm-flex justify-space-between">
                      <span
                        style="
                          font-size: 22px;
                          font-weight: bold;
                          color: rgb(46, 112, 15);
                        "
                        >Total:</span
                      >
                      <span style="font-size: 22px; color: rgb(2, 2, 2)"
                        >{{
                          (
                            renderedMarketplaces.total -
                            (renderedMarketplaces.total * discount) / 100
                          ).toFixed(2)
                        }}$</span
                      >
                    </v-col>
                    <FormElement
                      key="paymentType"
                      :disabled="isPaymentStarted"
                      :vertical="false"
                      v-model="item.PaymentTypeId"
                      :validationLabel="$t('labels.type', { value: 'Ödeme' })"
                      rules="required"
                      :formLabel="$t('labels.type', { value: 'Ödeme' })"
                      :formType="{
                        name: 'select',
                        items: {
                          data: [
                            { Id: 1, Name: 'Havale' },
                            { Id: 2, Name: 'Online' },
                          ],
                          value: 'Id',
                          text: 'Name',
                        },
                      }"
                      :placeholder="$t('labels.type', { value: 'Ödeme' })"
                    />
                  </v-row>
                  <CreditCardForm
                    :style="item.PaymentTypeId === 2 ? '' : 'display:none'"
                    ref="creditCardFormRef"
                  />
                </v-card-text>
              </v-window-item>
            </v-window>
          </form>
        </ValidationObserver>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            :disabled="step === 1 || isPaymentStarted"
            text
            @click="step--"
          >
            {{ $t('buttons.back') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="step === 2 && item.PaymentTypeId !== 2"
            elevation="0"
            class="update darken-1"
            dark
            @click="finishOperation"
            type="button"
            :loading="loadings.send"
          >
            {{ $t('titles.buy', {}) }}
          </v-btn>
          <v-btn
            :disabled="step === 2 || item.BoughtMarketplaces.length === 0"
            color="primary"
            depressed
            @click="nextButton"
          >
            {{ $t('buttons.next') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Add/Update Modal -->
    <v-card elevation="2" style="border-radius: 0">
      <v-card-title class="popupHeadline">
        <h4>{{ $t('titles.management', { value: 'Paket' }) }}</h4>
        <v-spacer />
        <div v-if="marketplaces.length !== 0">
          <span
            class="align-self-center mr-2"
            style="font-size: 12px; font-weight: normal; color: ##598db5"
            >{{ $t('titles.buy', { value: 'Paket' }) }}</span
          >
          <v-icon
            @click="buyPackageModal = true"
            class="icon-button mr-4 align-self-center"
          >
            mdi-plus
          </v-icon>
        </div>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-data-table
          key="waiting_table"
          :single-expand="true"
          item-key="Id"
          :headers="headers"
          :items="orders"
          :loading="$store.state.loadingStatus"
          :options.sync="options"
          :items-per-page="itemsPerPage"
          :hide-default-footer="true"
          :no-data-text="$t('description.noList')"
          show-expand
        >
          <!-- eslint-disable-next-line  -->
          <template v-slot:item.OrderCode="{ item }">
            {{ item.OrderCode }}
          </template>
          <!-- eslint-disable-next-line  -->
          <template v-slot:item.UserName="{ item }">
            {{ item.UserName }}
          </template>
          <!-- eslint-disable-next-line  -->
          <template v-slot:item.Status="{ item }">
            <v-chip
              small
              :color="
                item.OrderStatus === 0
                  ? '#c5f4db'
                  : item.OrderStatus === 2
                  ? '#e3e2e3'
                  : '#CF181F'
              "
              :text-color="
                item.OrderStatus === 0
                  ? '#2b3838'
                  : item.OrderStatus === 2
                  ? '#2b3838'
                  : '#FFF'
              "
            >
              {{ $t('labels.' + item.osasOrderStatusText.toLowerCase()) }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line  -->
          <template v-slot:item.TotalPrice="{ item }">
            <div style="text-align: center !important">
              <span class="mr-2">{{ item.SubTotalPrice }}$</span>
              <v-badge
                v-if="item.DiscountRate !== '0'"
                color="green"
                :content="'%' + item.DiscountRate"
              ></v-badge>
            </div>
          </template>
          <!-- eslint-disable-next-line  -->
          <template v-slot:body.append="{}">
            <tr>
              <td>
                {{ $t('titles.total') }}
              </td>
              <td></td>
              <td></td>
              <td
                style="
                  text-align: center;
                  font-weight: bold;
                  font-size: 30px;
                  padding: 30px 20px;
                "
              >
                {{ calculatePrice(orders) }}$
              </td>
              <td></td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td style="text-align: start !important" :colspan="headers.length">
              <CartItem
                :key="cartItem.BasketItemId"
                v-for="(cartItem, index) in item.BasketPackageItems"
                :isLast="
                  index === item.BasketPackageItems.length - 1 ? true : false
                "
                :item="{
                  title: cartItem.Marketplace.Name,
                  subtitle: item.OrderCode,
                  photoUrl: cartItem.Marketplace.PhotoUrl,
                  price:
                    cartItem.Marketplace.Price * (1 - item.DiscountRate / 100),
                }"
              />
            </td>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <!-- Pagination select box start -->
          <v-row align="center">
            <v-col md="3" class="ml-md-auto">
              <v-pagination
                @input="paginationChangeHandler"
                :value="pagination.page"
                :length="pages"
                :total-visible="15"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="ml-md-auto">
              <v-select
                v-model="itemsPerPage"
                :items="$store.state.perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                @change="changeItemsPerPage()"
                outlined
                dense
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- Pagination select box end -->
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DeleteModal from '../components/General/DeleteModal';
import TabScreen from '../components/TabScreen.vue';
import FormElement from '../components/FormElement.vue';
import WarehouseServices from '../services/WarehouseServices';
import MarketplaceServices from '../services/MarketplaceServices';
import CreditCardForm from '../components/CreditCardForm.vue';
import PackageBasketServices from '../services/PackageBasketServices';
import CartItem from '../components/CartItem.vue';
import CompanyMarketplaceServices from '../services/CompanyMarketplaceServices';

export default {
  data: () => ({
    // General Models
    isLoading: false,
    isUpdate: false,
    file: null,
    step: 1,
    discount: 0,

    itemId: '',
    item: {
      BoughtMarketplaces: [],
      PaymentTypeId: 1,
    },
    model: null,
    search: null,
    items: {
      GetPackageBasketItems: [],
    },
    orders: [],
    deletedId: -1,
    marketplaces: [],

    buyPackageModal: false,

    // List options
    options: {
      itemsPerPage: 10,
    },
    headers: [],
    //Loading states
    loadings: {
      send: false,
      item: false,
    },
    pagination: {},
    itemsPerPage: 10,
  }),

  computed: {
    isPaymentStarted() {
      return this.$store.state.globalOperation;
    },
    currentTitle() {
      switch (this.step) {
        case 1:
          return this.$t('titles.buy', { value: 'Paket' });
        case 2:
          return this.$t('titles.summary');
        default:
          return this.$t('titles.summary');
      }
    },
    itemForm() {
      return [
        {
          key: {
            parent: 'item',
            child: 'BoughtMarketplaces',
          },
          rules: 'requiredArray',
          validationLabel: 'labels.marketplace.title',
          vertical: false,
          formLabel: 'labels.marketplace.title',
          formType: {
            name: 'multipleSelect',
            items: {
              specialized: true,
              data: this.marketplaces,
              value: 'Id',
              text: 'Name',
              subtitle: 'Price',
              avatar: 'PhotoUrl',
            },
          },
          placeholder: 'labels.marketplace.title',
        },
      ];
    },
    renderedMarketplaces() {
      let temporary = [];
      let total = 0;
      if (this.items.GetPackageBasketItems.length > 0) {
        this.items.GetPackageBasketItems.forEach((element) => {
          const photoUrl = this.marketplaces.find(
            (marketplace) => marketplace.Name === element.Name
          ).PhotoUrl;
          temporary.push({
            id: element.BasketItemId,
            photoUrl: photoUrl,
            title: element.Name,
            subtitle: element.BasketItemId,
            price: element.Price,
          });

          total += element.Price;
        });
      }

      return {
        items: temporary,
        total: total,
      };
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    fields() {
      if (!this.model) return [];
      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || 'n/a',
        };
      });
    },
    autoCompleteItems() {
      return this.orders.map((entry) => {
        return Object.assign({}, entry, {
          Id: entry.Id,
          Name: entry.Name,
        });
      });
    },
  },

  watch: {
    search(val) {
      // Items have already been loaded
      if (this.autoCompleteItems.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      this.getOrders();
    },
  },
  mounted() {
    this.setup();
  },
  components: {
    DeleteModal,
    TabScreen,
    FormElement,
    CreditCardForm,
    CartItem,
  },
  methods: {
    /**
     * Get Order List
     */
    async getOrders() {
      await PackageBasketServices.getPackageOrders({
        Page: this.pagination.page,
        Size: this.pagination.rowsPerPage,
      })
        .then((res) => {
          this.orders = res.data.Result.Orders;
          this.pagination.totalItems = res.data.Result.TotalOrderCount;
          this.discount = res.data.Result.DiscountRate;
        })
        .catch((err) => {
          window.handleServiceError(err);
        });
    },

    /**
     * When clicked 'Next Button'
     */
    nextButton() {
      this.step++;

      if (this.step === 2) {
        this.addItemsToCart();
      }
    },

    /**
     * Trigger when marketplace has been changed.
     */
    onChangeMarketplace(newMarketplaces) {
      var final = this.item.BoughtMarketplaces.filter(function (item) {
        return !newMarketplaces.includes(item);
      });

      if (final.length > 0) {
        const deletedId = this.items.GetPackageBasketItems.find(
          (element) =>
            element.Name ===
            this.marketplaces.find(
              (anotherElemennt) => anotherElemennt.Id === final[0]
            ).Name
        ).BasketItemId;
        PackageBasketServices.delete(deletedId);
      } else {
        PackageBasketServices.add({
          MarketPlaceIds: newMarketplaces,
        })
          .then((response) => {})
          .catch((error) => {
            window.showError(error);
          })
          .finally((response) => {
            this.$store.state.loadingStatus = false;
          });
      }

      this.getItems();
    },

    /**
     * Calculate prices in the list.
     * @param {array} items
     */
    calculatePrice(items) {
      if (items) {
        let total = 0;
        items.map((item) => (total += parseFloat(item.SubTotalPrice)));
        return total;
      }
    },

    /**
     * Finishes to buy packages.
     */
    finishOperation() {
      this.$store.state.loadingStatus = true;
      PackageBasketServices.postPackageOrders({
        Description: '',
      })
        .then((response) => {
          window.showSuccess(response.data.Message);
          this.clearModal();
          this.getItems();
          this.getOrders();
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally((response) => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Adds items to cart.
     */
    addItemsToCart() {
      this.$store.state.loadingStatus = true;
      PackageBasketServices.add({
        MarketPlaceIds: this.item.BoughtMarketplaces,
      })
        .then(async (response) => {
          this.getItems();
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally((response) => {
          this.$store.state.loadingStatus = false;
        });
    },

    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getOrders();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
      this.getOrders();
    },

    /**
     * Empty the cart.
     */
    emptyCart() {
      this.$store.state.loadingStatus = true;
      PackageBasketServices.emptyCart()
        .then((response) => {
          this.getItems();
        })
        .catch((error) => window.showError(error))
        .finally(this.onFinal);
    },

    /**
     * Get role list.
     */
    async getMarketPlaceList() {
      await CompanyMarketplaceServices.getNonPurchasedList()
        .then((res) => {
          this.marketplaces = res.data;
        })
        .catch((err) => {
          window.handleServiceError(err);
        });
    },

    /**
     * Adds a new item integraton for company.
     */
    addItem() {
      this.loadings.send = true;
      this.item.Id = this.itemId;
      WarehouseServices.add(this.item)
        .then((res) => {
          this.buyPackageModal = false;
          window.showSuccess(
            this.$t('messages.successfulAdd', { value: 'Paket' })
          );
          this.clearModal();
          this.getItems();
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.loadings.send = false;
        });
    },

    /**
     * Update an api integration for company.
     */
    updateItem() {
      this.loadings.send = true;
      WarehouseServices.update({
        WarehouseId: this.item.Id,
        Name: this.item.Name,
        Address: this.item.Address,
        Country: this.item.Country,
        City: this.item.City,
        District: this.item.District,
        Postcode: this.item.Postcode,
      })
        .then((res) => {
          this.buyPackageModal = false;
          window.showSuccess(
            this.$t('messages.successfulUpdate', { value: 'Paket' })
          );
          this.clearModal();
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(() => {
          this.loadings.send = false;
        });
    },

    //Page setup
    async setup() {
      this.$store.state.loadingStatus = true;
      this.pagination = {
        totalItems: 0,
        rowsPerPage: 10,
        page: 1,
      };
      this.headers = [
        {
          text: this.$t('titles.number', { value: 'Sipariş' }),
          value: 'OrderCode',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('title.tableHeaderUsername'),
          value: 'UserName',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('labels.status'),
          value: 'Status',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.price'),
          value: 'TotalPrice',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        { text: 'Detay', value: 'data-table-expand', align: 'center' },
      ];
      await this.getItems();
      await this.getOrders();
      await this.getMarketPlaceList();

      this.onFinal();
    },

    //Clears all modal's states and item ID
    clearModal() {
      this.buyPackageModal = false;
      setTimeout(() => {
        this.resetForm();
        this.isUpdate = false;
        this.itemId = '';
        this.step = 1;
        this.item = {
          BoughtMarketplaces: [],
          PaymentTypeId: 1,
        };

        if (this.$refs.creditCardFormRef) {
          this.$refs.creditCardFormRef.creditCardUrl = '';
          this.$refs.creditCardFormRef.item = {};
        }
      }, 500);
    },

    /**
     * Change active item of the page.
     * @param {*} item
     * @param {boolean} isUpdate
     */
    async changeActiveItem(item, isUpdate = false) {
      this.itemId = item.Id;
      this.buyPackageModal = true;
      this.item = item;
      this.isUpdate = isUpdate;
    },

    /**
     * Get item list.
     */
    async getItems() {
      await PackageBasketServices.get()
        .then((res) => {
          this.items = res.data.Result;
          this.discount = res.data.Result.DiscountRate;

          if (this.items.GetPackageBasketItems.length === 0) {
            this.step = 1;
          }
        })
        .catch((err) => {
          window.handleServiceError(err);
        });
    },

    /**
     * Final Function Of Services
     */
    onFinal() {
      this.$store.state.loadingStatus = false;
    },

    /**
     * Add or update a item.
     */
    sendForm() {
      if (this.isUpdate) {
        this.updateItem();
      } else {
        this.addItem();
      }
    },

    /**
     * Delete a item.
     */
    deleteItem(basketItemId) {
      this.$store.state.loadingStatus = true;
      PackageBasketServices.delete(basketItemId)
        .then((response) => {
          window.showSuccess(
            this.$t('messages.successfulDelete', { value: 'Paket' })
          );

          this.getItems();
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Shows delete modal.
     * @param {*} item
     */
    openDeleteModal(item) {
      this.deletedId = item.Id;
      this.$refs.deleteModalRef.modalStatus = true;
    },

    /**
     * Resetting forms' validation.
     */
    async resetForm() {
      this.$refs.observer.reset();
    },
  },
};
</script>
