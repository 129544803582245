<template>
  <v-card elevation="1" class="mt-0">
    <v-dialog v-model="showCreateOffer" max-width="800px">
      <CreateOfferForm :add="true" />
    </v-dialog>

    <div class="flex w-full rounded-tl-sm rounded-tr-sm">
      <v-card-title style="color: var(--darkBlue)">
        <v-row>
          <v-col class="text-lg-left text-center" lg="5" cols="12">
            <h3>{{ $t('titles.spotPriceRequests') }}</h3>
          </v-col>
        </v-row>
        <div>
          <RefreshButton :refreshFunction="getOfferPrices"></RefreshButton>
        </div>
      </v-card-title>
    </div>
    <v-divider />

    <v-data-table
      key="SPOT_OFFERS_PAGE"
      :headers="offersHeaders"
      hide-default-footer
      style="overflow-x: hidden; !important "
      :options="{
        itemsPerPage: itemsPerPage,
      }"
      :loading="$store.state.loadingStatus"
      :items="filteredItems"
      :no-data-text="$t('messages.noData')"
    >
      <template v-slot:item.FromToWhere="{ item }">
        <div style="white-space: normal; word-wrap: break-word">
          {{ item.From }}
        </div>
        <span>-</span>
        <div style="white-space: normal; word-wrap: break-word">
          {{ item.To }}
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-center mt-8">
          <v-row align="center" justify="center">
            <v-col
              v-if="
                $vuetify.breakpoint.md ||
                $vuetify.breakpoint.lg ||
                $vuetify.breakpoint.xl
              "
              md="2"
            ></v-col>
            <v-col cols="9" sm="12" md="7" lg="7" xl="7">
              <v-pagination
                v-if="pageLength > 0"
                class="my-4"
                @input="onPageChange"
                :value="page"
                :length="pageLength"
                circle
              ></v-pagination>
            </v-col>
            <v-col
              cols="11"
              sm="11"
              md="3"
              lg="3"
              xl="3"
              class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
            >
              <v-select
                v-model="itemsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                outlined
                dense
                @change="changeItemsPerPage"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:no-data>
        <div class="my-10 d-flex flex-column align-center">
          <h4>{{ $t('messages.noOffer') }}</h4>
        </div>
      </template>
      <!-- eslint-disable -->

      <template v-slot:item.IsReadReceipt="{ item }">
        <v-badge
          v-if="!item.IsReadReceipt"
          :content="item.NewOfferCount"
          color="error"
        >
          <span class="badge-cell bold-cell">{{ $t('titles.new') }}</span>
        </v-badge>
      </template>
      <template v-slot:item.price="{ item }">
        {{
          item.Price +
          ' ' +
          currencyTypes.find((element) => element.Id === item.CurrencyTypeId)
            .Code
        }}
      </template>
      <template v-slot:item.createdDate="{ item }">
        {{ getFormattedDate(new Date(item.CreatedOnUtc)) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- eslint-enable -->
        <v-tooltip
          top
          v-if="
            $store.state.Type === 'Customer' ||
            $store.state.Type === 'PendingCustomer'
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              color="#29B7B7"
              class="mr-1"
              style="font-size: 24px"
              v-bind="attrs"
              v-on="on"
              @click="viewTheResult(item.Id)"
            >
              mdi-eye
            </v-icon>
          </template>

          <span>{{ $t('buttons.viewTheResults') }}</span>
        </v-tooltip>
        <v-tooltip
          top
          v-if="
            $store.state.Type === 'Supplier' ||
            $store.state.Type === 'PendingSupplier'
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="color: white; background-color: green"
              class="button my-2"
              v-bind="attrs"
              v-on="on"
              @click="showCreateOffer = true"
            >
              {{ $t('labels.tooltipAccept') }}
            </v-btn>
          </template>
          <span>{{ $t('labels.tooltipAccept') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import {
  ITEMS_PER_PAGE_OPTIONS,
  triggerLoading,
  getCountries,
  getCurrencyTypes,
  truckTypes,
  containerTypes,
  carriageTypes,
} from '../utils/constants';
import CreateOfferForm from './OfferScreens/CreateOfferForm.vue';
import FormElement from '../components/FormElement.vue';
import RefreshButton from '../components/RefreshButton.vue';
import formatDate from '../utils/formatDate';
import SpotShippingService from '../services/SpotShippingServices';

export default {
  data() {
    return {
      currencyTypes: [],
      offerType: 1,
      filters: {
        SHIPMENT_TYPE: null,
        LOAD_TYPE: null,
        SERVICE_TYPE: null,
        FROM: null,
        TO: null,
        STATUS: null,
      },
      countries: [],
      offersHeaders: [
        {
          text: this.$t('labels.dateAsNoun'),
          value: 'createdDate',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: '',
          value: 'IsReadReceipt',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('titles.shipmentType'),
          value: 'ShipmentType',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.loadType'),
          value: 'LoadingType',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('titles.from') + '/' + this.$t('titles.to'),
          value: 'FromToWhere',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
          width: '20%',
        },
        {
          text: this.$t('titles.load'),
          value: 'Load',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },

        {
          text: this.$t('labels.offerCount'),
          value: 'OfferCount',
          sortable: true,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },

        {
          text: '',
          value: 'actions',
          sortable: false,
          groupable: false,
        },
      ],
      offers: [],

      // States
      pageLoading: false,
      itemsPerPageOptions: [],
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      perPageValues: [
        {
          name: '5',
          value: 5,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
        {
          name: this.$t('labels.All'),
          value: 9999,
        },
      ],
      showCreateOffer: false,
      isSupplier: true,
      showCreateOffer: false,
    };
  },
  components: { CreateOfferForm, FormElement, RefreshButton },
  async mounted() {
    this.currencyTypes = await getCurrencyTypes();
    this.isSupplier = this.$store.state.Type === 'Supplier';
    this.countries = await getCountries();
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
    this.setup();
  },
  computed: {
    filteredItems() {
      let items = [];
      this.offers.forEach((element, index) => {
        let object = element.FastShippingSearch;

        if (object) {
          let to = '',
            from = '';
          switch (object.ShipmentType) {
            case 3:
              from = object.SeaLoadingPortId;
              to = object.SeaUnloadingPortId;
              break;
            case 2:
              from = object.LoadingCountryId;
              to = object.DestinationCountryId;
              break;
            case 1:
              from = object.AirloadingPortId;
              to = object.AirUnloadingPortId;
              break;
            case 5:
            case 5:
              from = object.RailwayloadingId;
              to = object.RailwayUnloadingId;
              break;
          }
          object.fromCountry = from || 'No Data';
          object.toCountry = to || 'No Data';

          object.ShipmentType =
            this.$root.shipmentTypes.find(
              (item) => item.Id === object.ShipmentType
            )?.Name || 'No Transfer';
          object.LoadingType =
            this.$root.loadTypes.find((item) => item.Id === object.LoadingType)
              ?.Name || 'No Transfer';

          if (object.SumCapPieces && object.SumVolume && object.SumWeight) {
            object.load = `${object.SumCapPieces || 0} x ${
              object.SumVolume || 0
            }m³ x ${object.SumWeight | 0} kg  `;
          } else if (object.ContainerCount && object.ContainerType) {
            object.load = `${object.ContainerCount} x ${
              containerTypes.find((item) => item.Id === object.ContainerType)
                .Name
            }`;
          } else if (object.TruckCount && object.TruckType) {
            object.load = `${object.TruckCount} x ${
              truckTypes.find((item) => item.Id === object.TruckType).Name
            }`;
          } else if (object.CarriageCount && object.CarriageType) {
            object.load = `${object.CarriageCount} x ${
              carriageTypes.find((item) => item.Id === object.CarriageType).Name
            }`;
          }
          element.FastShippingSearch = object;
        }

        items.push(element);
      });
      return items;
    },
    filterItems() {
      return [
        {
          key: 'SHIPMENT_TYPE',
          items: [
            ...this.$root.shipmentTypes,
            {
              Id: -1,
              Name: this.$t('labels.allGroupTypes'),
            },
          ],
          placeholder: 'titles.shipmentType',
          color: 'var(--turquoise)',
          itemColor: 'pink',
          value: 'Id',
          text: 'Name',
          onChange: this.getOfferPrices,
        },
        {
          key: 'LOAD_TYPE',
          items: [
            ...this.$root.loadTypes,
            {
              Id: -1,
              Name: this.$t('labels.allGroupTypes'),
            },
          ],
          placeholder: 'labels.loadType',
          color: 'var(--turquoise)',
          itemColor: 'pink',
          value: 'Id',
          text: 'Name',
          onChange: this.getOfferPrices,
        },
        {
          key: 'SERVICE_TYPE',
          items: [
            ...this.$root.serviceTypes,
            {
              Id: -1,
              Name: this.$t('labels.allGroupTypes'),
            },
          ],
          placeholder: 'labels.serviceType',
          color: 'var(--turquoise)',
          itemColor: 'pink',
          value: 'Id',
          text: 'Name',
          onChange: this.getOfferPrices,
        },
        {
          key: 'FROM',
          items: [
            {
              Id: -1,
              Name: this.$t('labels.allGroupTypes'),
            },
            ...this.countries,
          ],
          placeholder: 'titles.from',
          color: 'var(--turquoise)',
          itemColor: 'pink',
          value: 'Id',
          text: 'Name',
          onChange: this.getOfferPrices,
        },
        {
          key: 'TO',
          items: [
            {
              Id: -1,
              Name: this.$t('labels.allGroupTypes'),
            },
            ...this.countries,
          ],
          placeholder: 'titles.to',
          color: 'var(--turquoise)',
          itemColor: 'pink',
          value: 'Id',
          text: 'Name',
          onChange: this.getOfferPrices,
        },
        {
          key: 'STATUS',
          items: [
            {
              Id: 1,
              Name: 'Onay Bekliyor',
            },
            {
              Id: 2,
              Name: 'Reddedildi',
            },
            {
              Id: 3,
              Name: 'Arşiv',
            },
            {
              Id: -1,
              Name: this.$t('labels.allGroupTypes'),
            },
          ],
          placeholder: 'labels.status',
          color: 'var(--turquoise)',
          itemColor: 'pink',
          value: 'Id',
          text: 'Name',
          onChange: this.getOfferPrices,
        },
      ];
    },
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  watch: {},
  methods: {
    changeItemsPerPage() {
      this.page = 1;
      this.getOfferPrices();
    },
    /**
     * Navigate the spot price requests page with out spot shipping id.
     * @param {string} id
     */
    viewTheResult(id) {
      this.$router.push({ name: 'spotPriceRequests', params: { id } });
    },

    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(date) {
      return formatDate(date, '/');
    },

    /**
     * Trigger when page changed.
     */
    onPageChange(newPage) {
      this.page = newPage;
      this.getOfferPrices();
    },

    setup() {
      this.getOfferPrices();
    },

    /**
     * Get all offer prices from service by filters.
     */
    getOfferPrices() {
      triggerLoading();
      SpotShippingService.getSpotShippingList(this.page, this.itemsPerPage)
        .then((response) => {
          this.offers = response.data.Result || [];
          this.totalItems = response.data.TotalItems;
        })
        .catch((error) => {
          this.onServiceError(error);
        })
        .finally(() => {
          triggerLoading();
        });
    },

    /**
     * Resetting forms' validation.
     */

    /**
     * This will trigger a function on service error.
     * @param {Function} special
     * @param {*} error
     */
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },

    /**
     * Reset all models in View.
     */
    resetAllModels() {
      this.resetForm();
    },
    /**
     * Save the volumes.
     */
    save() {
      this.onSave(this.volumes);
      this.openModal = false;
    },
    /**
     * Deletes an item from volumes.
     */
    deleteItem(index) {
      this.volumes = this.volumes.filter((item) => item.index !== index);
    },
    /**
     * Calculate prices in the list.
     * @param {array} items
     */
    calculatePrice(items, key) {
      let total = 0;
      if (items) {
        items.map((item) => (total += parseFloat(item[key])));
      }
      return total;
    },

    /**
     * Add a new volume.
     */
    addVolume() {
      this.$store.state.loadingStatus = true;
      setTimeout(() => {
        this.volumeItem.index = this.volumes.length;
        this.volumes.unshift(this.volumeItem);
        this.$refs.addVolumeObserver.reset();
        this.volumeItem = {};
        window.showSuccess(
          this.$t('messages.successfulAdd', {
            value: this.$t('labels.volume').replaceAll('(m3)', ''),
          })
        );
        this.$store.state.loadingStatus = false;
      }, 1000);
    },
  },
};
</script>
<style>
.container {
  margin-top: 38px;
}
.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.item-header {
  color: var(--turquoise) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
}

.filter-label {
  font-size: 14px;
  color: gray;
}
.filter-select-item {
  font-size: 12px;
}
.column-select-item {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.column-select-item:first-child {
  margin-left: 0 !important;
}
.column-select-item:last-child {
  margin-right: 0 !important;
}
</style>
