import axios from '../javascript/custom-axios'
const RESOURCE_NAME = 'BulkEdit'

export default {
  
  addManyTags(body){
    return axios.post(`${RESOURCE_NAME}/addTags`,body)
 
  },
  updatePrice(body){
    return axios.put(`${RESOURCE_NAME}/updateProducts`,body)
 
  },
}