import FileService from '../services/FileService';
export default class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          // Dosya yükleme işlemini burada gerçekleştirin
          // Bu örnekte, dosya yüklemek için Axios kullanıldı.
          let formData = new FormData();
          if (file.type == 'image/jpeg' || file.type == 'image/png') {
            formData.append('Files', file);
            formData.append('Type', 'media');
          }

          FileService.uploadFile(formData)
            .then((uploadFileResponse) => {
              resolve({ default: uploadFileResponse.data.Result[0].Url });
            })
            .catch((error) => {
              console.log('errr', error);
              reject(error.response.data.message);
            });
        })
    );
  }

  _uploadFile(file) {
    let formData = new FormData();
    if (file.type == 'image/jpeg' || file.type == 'image/png') {
      formData.append('Files', file);
      formData.append('Type', 'media');
    }
    FileService.uploadFile(formData)
      .then((uploadFileResponse) => {
        resolve({ default: uploadFileResponse.data.Result[0].Url });
      })
      .catch((error) => {
        console.log('errr', error);
        reject(error.response.data.message);
      });
  }
}
