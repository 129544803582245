<template>
  <div>
    <v-card
      elevation="1"
      class="mt-0 py-lg-10"
      style="margin: 0 !important; padding-right: 4%; padding-left: 4%"
    >
      <v-card-title class="popupHeadline">
        <v-row>
          <v-col class="text-left" lg="3" cols="12">
            <h5>{{ $t('navigationDrawerMenu.library') }}</h5>
          </v-col>
        </v-row>
        <RefreshButton :refreshFunction="getList"></RefreshButton>
      </v-card-title>
      <v-divider />

      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="10"
        :options="{
          itemsPerPage: itemsPerPage,
        }"
        style="padding: 0 16px"
        hide-default-footer
        :loading="$store.state.loadingStatus"
        :no-data-text="$t('messages.noData')"
      >
        <template v-slot:item.actions="{ item }">
          <div class="text-start">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :href="item.ExcelPath" download target="_blank">
                  <v-icon
                    small
                    color="#29B7B7"
                    style="font-size: 30px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-download-box
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('buttons.download') }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import RefreshButton from '../components/RefreshButton.vue';
import FormElement from '../components/FormElement.vue';
import SampleExcelServices from '../services/SampleExcelServices';

export default {
  data: () => ({
    headers: [],
    itemsPerPageOptions: [],
    itemsPerPage: 10,
    page: 1,
    totalItems: 0,
    perPageValues: [
      {
        name: '5',
        value: 5,
      },
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],

    list: [],
  }),
  mounted() {
    this.headers = [
      {
        text: this.$t('labels.name'),
        value: 'Name',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.actions'),
        value: 'actions',
        sortable: false,
      },
    ];
    this.getList();
  },
  components: {
    RefreshButton,
    FormElement,
  },
  computed: {
    formElement() {
      return [
        {
          key: {
            parent: 'form',
            child: 'ClientId',
          },
          rules: 'required',
          validationLabel: 'labels.ClientId',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.ClientId',
        },
        {
          key: {
            parent: 'form',
            child: 'ClientSecretKey',
          },
          rules: 'required',
          validationLabel: 'labels.ClientSecretKey',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.ClientSecretKey',
        },
        {
          key: {
            parent: 'form',
            child: 'ChildKey',
          },
          validationLabel: 'labels.ChildKey',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.ChildKey',
        },
        {
          key: {
            parent: 'form',
            child: 'ChildSecret',
          },
          validationLabel: 'labels.ChildSecret',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.ChildSecret',
        },
        {
          key: {
            parent: 'form',
            child: 'AccountNumber',
          },
          rules: 'required',
          validationLabel: 'labels.AccountNumber',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.AccountNumber',
        },
        {
          key: {
            parent: 'form',
            child: 'GrantType',
          },
          rules: 'required',
          validationLabel: 'labels.GrantType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.grantTypes,
              value: 'Id',
              text: 'Name',
            },
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.GrantType',
        },
      ];
    },
    pageLength() {
      //   return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    getList() {
      this.$store.state.loadingStatus = true;
      SampleExcelServices.get()
        .then((response) => {
          this.list = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
  },
};
</script>
