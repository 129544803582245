// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
let RESOURCE_NAME = "Roles";

export default {
  /**
   * Creates a new role.
   * @param {*} body { Name }
   * @returns {Promise<any>}
   */
  add(body) {
    return axios.post(`${RESOURCE_NAME}`, body);
  },

  /**
   * Update informations of role.
   * @param {*} body { Id, Name }
   * @returns {Promise<any>}
   */
  update(body) {
    return axios.put(`${RESOURCE_NAME}`, {
      params: body
    });
  },

  /**
   * Delete a role.
   * @param {*} body { Id }
   * @returns {Promise<any>}
   */
  delete(body) {
    return axios.delete(`${RESOURCE_NAME}`, {
      params: body
    });
  },

  /**
   * It returns role list by filter.
   * @param {*} { Id, Page, Size }
   * @returns {Promise<any>}
   */
  get(body = null) {
    return axios.get(`${RESOURCE_NAME}`, {
      params: body
    });
  },

  /**
   * It deletes user that is ID given.
   * @param {*} { Id }
   * @returns {Promise<any>}
   */
  delete(Id) {
    return axios.delete(`${RESOURCE_NAME}/${Id}`);
  }
  
}