// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'market-place-transaction';

export default {
  /**
   * Get Integration Order List.
   * @param {*} params
   * @returns {Promise<any>}
   */
  getAll(params) {
    return axios.get(`${RESOURCE_NAME}/getOrder`, { params });
  },

  /**
   * Update Order List In Integration.
   * @returns {Promise<any>}
   */
  addOrder() {
    return axios.post(`${RESOURCE_NAME}/addOrder`);
  },

  /**
   * Get Order By Id.
   * @param {string} id
   * @returns {Promise<any>}
   */
  getOrderById(id) {
    return axios.get(`${RESOURCE_NAME}/getOrderById`, {
      params: {
        Id: id
      }
    });
  },
};
