<template>
  <v-card elevation="1" class="hide-scroll">
    <v-card-title
      class="popupHeadline"
      style="font-size: 24px; position: relative"
    >
      <div class="text-sm-center popupHeadline" style="width: 100%">
        {{ $t('labels.subscriptionManagement') }}
      </div>
      <v-btn
        icon
        @click="triggerSubscriptionModalUpdate"
        style="position: absolute; top: 10px; right: 10px"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-divider class="mb-4" />

    <v-row
      v-if="subscriptionPackages.length > 0"
      class="pb-4 px-4 justify-center"
    >
      <v-col
        v-for="packageItem in subscriptionPackages"
        :key="packageItem.Id"
        cols="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
      >
        <v-card
          class="popup-card d-flex flex-column space-between"
          elevation="3"
          style="height: 100%"
        >
          <v-card-title class="d-flex align-center">
            <v-img
              v-if="packageItem.PhotoUrl"
              :src="packageItem.PhotoUrl"
              alt="Package Image"
              class="mr-3 rounded"
              min-width="150"
              max-width="150"
              min-height="150"
              max-height="150"
            ></v-img>
            <v-img
              v-else
              src="@/assets/no-image.png"
              alt="Package Image"
              class="mr-3 rounded"
              min-width="150"
              max-width="150"
              min-height="150"
              max-height="150"
            ></v-img>
            <div class="text-center flex-grow-1">
              <div>{{ packageItem.Name }}</div>
              <v-card-subtitle class="text-center">
                {{ packageItem.Description }}
              </v-card-subtitle>
            </div>
          </v-card-title>

          <v-card-text>
            <div class="d-flex text-h5 justify-center text-center">
              <!-- Monthly Price Option -->
              <div
                class="d-flex flex-column position-relative"
                :style="{
                  width: '100%',
                  borderWidth: '1px',
                  borderColor:
                    packageItem.selectedOption === 'monthly'
                      ? '#29B7B7'
                      : '#f0f0f4',
                  borderStyle: 'solid',
                  borderRadius: '20px',
                  padding: '10px',
                  position: 'relative',
                  marginRight: '10px',
                  cursor: 'pointer',
                }"
                @click="toggleSelection(packageItem.Id, 'monthly')"
              >
                <div style="text-align: center">
                  <span
                    v-if="packageItem.IsFreePackage"
                    style="font-weight: bold; font-size: 24px"
                  >
                    {{ $t('labels.free') }}
                  </span>
                  <span v-else style="font-weight: bold; font-size: 24px">
                    {{ packageItem.MonthlyPriceWithCurrency }}
                  </span>
                </div>
                <div v-if="!packageItem.IsFreePackage">
                  <span style="font-style: italic; font-size: 18px">
                    {{ $t('labels.monthlyPrice') }}
                  </span>
                </div>
              </div>

              <!-- Annual Price Option -->
              <div
                v-if="!packageItem.IsFreePackage"
                class="d-flex flex-column position-relative"
                :style="{
                  width: '100%',
                  borderWidth: '1px',
                  borderColor:
                    packageItem.selectedOption === 'annual'
                      ? '#29B7B7'
                      : '#f0f0f4',
                  borderStyle: 'solid',
                  borderRadius: '20px',
                  padding: '10px',
                  position: 'relative',
                  cursor: 'pointer',
                }"
                @click="toggleSelection(packageItem.Id, 'annual')"
              >
                <div style="text-align: center">
                  <span style="font-weight: bold; font-size: 24px">
                    {{ packageItem.AnnualPriceWithCurrency }}
                  </span>
                </div>
                <div>
                  <span style="font-style: italic; font-size: 18px">
                    {{ $t('labels.annualPrice') }}
                  </span>
                </div>
              </div>
            </div>

            <!-- Loop through infoItems of each package -->
            <v-row class="mt-4 ml-4">
              <v-col
                v-for="(item, idx) in createInfoItems(packageItem)"
                :key="idx"
                cols="6"
                style="padding: 0px !important; padding-bottom: 5px !important"
              >
                <v-icon
                  small
                  color="#29B7B7"
                  class="mr-1"
                  style="font-size: 20px"
                >
                  mdi-check-decagram
                </v-icon>
                {{ item.label }}:
                <span style="font-weight: bold">{{ item.value }}</span>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="justify-center mt-auto">
            <v-btn
              style="
                color: #fff;
                text-transform: unset !important;
                background-color: #0077d5;
                padding: 1vh 1vh;
                border-radius: 30px !important;
                font-weight: bolder;
                font-size: medium;
              "
              elevation="0"
              class="darken-1"
              type="submit"
              @click="selectPackage(packageItem)"
              :disabled="
                currentSubscriptionId === packageItem.Id &&
                currentSubscriptionPeriod === packageItem.selectedOption
              "
            >
              {{
                currentSubscriptionId === packageItem.Id &&
                currentSubscriptionPeriod === packageItem.selectedOption
                  ? $t('labels.currentSubscription')
                  : $t('labels.subscribe')
              }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <div
      v-else
      class="no-data d-flex justify-center align-center"
      style="width: 100%"
    >
      <v-icon color="red">mdi-alert-circle</v-icon>
      <span class="ml-2">{{ $t('messages.noData') }}</span>
    </div>
  </v-card>
</template>

<script>
import CompanyPackageService from '../services/CompanyPackageService';
import CompanyServices from '../services/CompanyServices';

export default {
  data() {
    return {
      subscriptionPackages: [],
      selectedPackageId: null,
      currentSubscriptionId: null,
      currentSubscriptionPeriod: null,
    };
  },
  props: {
    isVerified: false,
    data: {},
    dialogOpen: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    dialogOpen(newValue) {
      if (newValue) {
        this.fetchSubscriptionPackages();
      }
    },
  },
  components: {},
  computed: {
    infoItems() {
      const items = [];

      if (!this.packageItem) return items;

      if (this.packageItem.OrderCount !== null) {
        items.push({
          label: this.$t('labels.orderCount'),
          value: this.packageItem.OrderCount,
        });
      }

      if (this.packageItem.MonthlyPriceWithCurrency !== null) {
        items.push({
          label: this.$t('labels.monthlyPrice'),
          value: this.packageItem.MonthlyPriceWithCurrency,
        });
      }

      return items;
    },
  },
  mounted() {
    this.fetchSubscriptionPackages();
  },

  methods: {
    createInfoItems(packageItem) {
      let items = [];

      if (packageItem.SearchCount !== null) {
        items.push({
          label: this.$t('labels.monthlyQueryCount'),
          value:
            packageItem.SearchCount === -1
              ? this.$t('labels.limitless')
              : packageItem.SearchCount,
        });
      }
      if (packageItem.OrderCount !== null) {
        items.push({
          label: this.$t('labels.orderCount'),
          value:
            packageItem.OrderCount === -1
              ? this.$t('labels.limitless')
              : packageItem.OrderCount,
        });
      }
      if (packageItem.IntegrationOrderUnitCost !== null) {
        items.push({
          label: this.$t('labels.integrationUnitPrice'),
          value:
            packageItem.IntegrationOrderUnitCost +
            ' ' +
            packageItem.CommissionPriceCurrency,
        });
      }
      if (packageItem.MaxPortalCount !== null) {
        items.push({
          label: this.$t('labels.maxPortalCount'),
          value: packageItem.MaxPortalCount,
        });
      }
      if (packageItem.PortalMonthlyPrice !== null) {
        items.push({
          label: this.$t('labels.portalMonthlyPrice'),
          value:
            packageItem.PortalMonthlyPrice +
            ' ' +
            packageItem.CommissionPriceCurrency,
        });
      }
      if (packageItem.PortalCommissionDiscountRate !== null) {
        items.push({
          label: this.$t('packageModal.portalCommission'),
          value: packageItem.PortalCommissionDiscountRate + '%',
        });
      }
      if (packageItem.AirlineCommission !== null) {
        items.push({
          label: this.$t('packageModal.airlineCommission'),
          value:
            packageItem.AirlineCommissionType === 1
              ? packageItem.AirlineCommission + '%'
              : packageItem.AirlineCommission +
                ' ' +
                packageItem.CommissionPriceCurrency,
        });
      }
      if (packageItem.SeawayCommission !== null) {
        items.push({
          label: this.$t('packageModal.seawayCommission'),
          value:
            packageItem.SeawayCommissionType === 1
              ? packageItem.SeawayCommission + '%'
              : packageItem.SeawayCommission +
                ' ' +
                packageItem.CommissionPriceCurrency,
        });
      }
      if (packageItem.ExpressCommission !== null) {
        items.push({
          label: this.$t('packageModal.expressCommission'),
          value:
            packageItem.ExpressCommissionType === 1
              ? packageItem.ExpressCommission + '%'
              : packageItem.ExpressCommission +
                ' ' +
                packageItem.CommissionPriceCurrency,
        });
      }
      if (packageItem.RoadwayCommission !== null) {
        items.push({
          label: this.$t('packageModal.roadCommission'),
          value:
            packageItem.RoadwayCommissionType === 1
              ? packageItem.RoadwayCommission + '%'
              : packageItem.RoadwayCommission +
                ' ' +
                packageItem.CommissionPriceCurrency,
        });
      }
      if (packageItem.RailwayCommission !== null) {
        items.push({
          label: this.$t('packageModal.railwayCommission'),
          value:
            packageItem.RailwayCommissionType === 1
              ? packageItem.RailwayCommission + '%'
              : packageItem.RailwayCommission +
                ' ' +
                packageItem.CommissionPriceCurrency,
        });
      }
      if (packageItem.DomesticCommission !== null) {
        items.push({
          label: this.$t('packageModal.domesticCommission'),
          value:
            packageItem.DomesticCommissionType === 1
              ? packageItem.DomesticCommission + '%'
              : packageItem.DomesticCommission +
                ' ' +
                packageItem.CommissionPriceCurrency,
        });
      }

      return items;
    },
    async fetchSubscriptionPackages() {
      this.$store.state.loadingStatus = true;
      try {
        const response = await CompanyPackageService.getForUsers();
        this.subscriptionPackages = response.data.Result.Packages;
        this.currentSubscriptionId = response.data.Result.MyPackageId;
        this.currentSubscriptionPeriod =
          response.data.Result.PackagePeriod === 1 ? 'monthly' : 'annual';

        this.subscriptionPackages.forEach((packageItem) => {
          if (packageItem.Id === this.currentSubscriptionId) {
            packageItem.selectedOption =
              response.data.Result.PackagePeriod === 1 ? 'monthly' : 'annual';
          } else {
            packageItem.selectedOption = 'monthly';
          }
        });
      } catch (error) {
        console.error('Error fetching subscription packages:', error);
      } finally {
        this.$store.state.loadingStatus = false;
      }
    },
    triggerSubscriptionModalUpdate() {
      this.$emit('close-modal');
      this.$store.state.loadingStatus = true;
      CompanyServices.updatePackagePopup()
        .then((res) => {
          this.$store.state.IsPackagePopupVisible = false;
        })
        .catch((error) => {
          console.error('Error updating package popup:', error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    toggleSelection(packageId, option) {
      this.subscriptionPackages.forEach((packageItem) => {
        if (packageItem.Id === packageId) {
          packageItem.selectedOption = option;
        }
      });
      this.$forceUpdate();
    },

    selectPackage(packageItem) {
      this.$store.state.loadingStatus = true;

      if (packageItem.IsFreePackage) {
        let body = {
          CompanyId: this.$store.state.CompanyId,
          CompanyPackageId: packageItem.Id,
        };

        CompanyPackageService.setPackage(body)
          .then((res) => {
            this.triggerSubscriptionModalUpdate();
          })
          .catch((error) => {
            console.error('Error setting package:', error);
          })
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      } else {
        let body = {
          PackageId: packageItem.Id,
          PackagePeriod: packageItem.selectedOption === 'monthly' ? 1 : 2,
        };

        CompanyPackageService.buyPackage(body)
          .then((res) => {
            window.open(
              `https://${process.env.VUE_APP_PAYMENT_SERVICE_URL}/Transaction/CurrencyCompanyPackageSelection?id=${res.data.Result}`,
              '_blank'
            );
          })
          .catch((error) => {
            console.error('Error buying package:', error);
          })
          .finally(() => {
            this.triggerSubscriptionModalUpdate();
            this.$store.state.loadingStatus = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.popup-card {
  max-width: 800px;
  margin: auto;
}
.popupHeadline {
  font-weight: bold;
}
.no-data {
  font-size: 16px;
  color: red;
}
.hide-scroll {
  overflow: hidden;
}
</style>
