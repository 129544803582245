<template>
  <div class="wrapper">
    <NavMenu :fromLogin="true" />
    <v-layout wrap align-center justify-center fill-height>
      <v-container style="padding-top: 10vh">
        <v-layout wrap align-center justify-center fill-height>
          <v-card
            style="padding: 2vh; border-radius: 20px"
            :style="{
              maxWidth: $vuetify.breakpoint.xl
                ? '75%'
                : $vuetify.breakpoint.lg
                ? '80%'
                : $vuetify.breakpoint.md
                ? '85%'
                : $vuetify.breakpoint.sm
                ? '80%'
                : $vuetify.breakpoint.xs
                ? '95%'
                : '100%',
            }"
          >
            <v-card-title>
              <h1 class="mb-4">{{ title }}</h1>
            </v-card-title>
            <v-card-text v-html="content"></v-card-text>
          </v-card>
        </v-layout>
      </v-container>
    </v-layout>
  </div>
</template>

<script>
import NavMenu from '../components/NavMenu.vue';
import MembershipService from '../services/MembershipService';
export default {
  data() {
    return {
      title: '',
      content: '',
    };
  },
  watch: {
    $route(to, from) {
      this.updateContent(to.params.type);
    },
  },
  components: { NavMenu },
  mounted() {
    this.updateContent(this.$route.params.type);
  },
  methods: {
    updateContent(type) {
      switch (type) {
        case 'terms':
          this.title = this.$t('labels.termsOfUse');
          this.getKVKK();
          break;
        case 'privacy':
          this.title = this.$t('labels.privacyPolicy');
          this.getRegisterContract();
          break;
        case 'consent':
          this.title = this.$t('labels.consent');
          this.getConsent();
          break;
        default:
          this.title = '';
          this.content = '';
      }
    },
    getKVKK() {
      MembershipService.getKVKK().then((response) => {
        this.content = response.data.Text;
      });
    },
    getRegisterContract() {
      MembershipService.getRegisterContract().then((response) => {
        this.content = response.data.Text;
      });
    },
    getConsent() {
      MembershipService.getConsent().then((response) => {
        this.content = response.data.Text;
      });
    },
  },
};
</script>
<style scoped>
.wrapper {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background: url('../images/8.jpg') !important;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: scroll;
}
</style>
