<template>
  <v-btn
    @click="handleClick"
    :style="'color: white; background-color:#' + bgColor"
    class="rounded-circle"
    style="min-width: 1px; min-height: 1px; width: 36px; height: 36px"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon small color="white" style="font-size: 24px" v-on="on">
          mdi-refresh
        </v-icon>
      </template>
      <span>{{ $t('buttons.reload') }}</span>
    </v-tooltip>
  </v-btn>
</template>

<script>
export default {
  name: 'RefreshButton',
  props: {
    refreshFunction: {
      type: Function,
      default: () => {
        location.reload(true);
      },
    },
    bgColor: {
      type: String,
      default: '0177d4',
    },
  },
  methods: {
    handleClick() {
      this.refreshFunction();
    },
  },
};
</script>
