<template>
  <v-card
    elevation="1"
    class="mt-0 py-lg-10"
    style="margin: 0 !important; padding-right: 4%; padding-left: 4%"
  >
    <v-card-title style="color: var(--darkBlue)">
      <v-row>
        <v-col class="text-lg-left text-center" lg="3" cols="12">
          <h5>{{ $t('navigationDrawerMenu.users') }}</h5>
        </v-col>
      </v-row>
      <RefreshButton :refreshFunction="getUsers"></RefreshButton>
      <v-btn
        elevation="0"
        v-if="selectedItems.length > 0"
        style="color: white !important"
        color="#FB773D"
        text-color="white"
        class="button ml-2"
        @click="openDeleteModal()"
        :loading="$store.state.loadingStatus"
      >
        {{ $t('buttons.deleteSelected') }}
      </v-btn>
    </v-card-title>
    <v-divider />

    <ConfirmationModal
      :title="$t('labels.warning')"
      :message="$t('messages.updateUserStatus')"
      :confirm="updateStatus"
      ref="confirmationModal"
      v-model="updateStatusModal"
    />

    <v-data-table
      :headers="usersHeaders"
      hide-default-footer
      :loading="$store.state.loadingStatus"
      :options="{
        itemsPerPage: itemsPerPage,
      }"
      :items="users"
      :no-data-text="$t('messages.noData')"
    >
      <template v-slot:footer>
        <div class="d-flex justify-center mt-8 pb-lg-0 pb-8">
          <v-row justify="space-between">
            <v-col md="2"></v-col>
            <v-col md="8" class="ml-md-auto">
              <v-pagination
                v-if="pageLength > 0"
                class="my-4"
                @input="onPageChange"
                :value="page"
                :length="pageLength"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="ml-md-auto">
              <v-select
                v-model="itemsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                outlined
                dense
                @change="changeItemsPerPage"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:item.IsActive="{ item }">
        <v-chip dark :color="item.IsActive ? 'green' : 'red'">
          {{ $t('labels.' + (item.IsActive ? 'isActive' : 'tooltipPassive')) }}
        </v-chip>
      </template>
      <template v-slot:no-data>
        <div class="my-10 d-flex flex-column align-center">
          <h4>{{ $t('messages.noUser') }}</h4>
        </div>
      </template>
      <!-- eslint-disable -->

      <template v-slot:item.actions="{ item }">
        <v-tooltip top v-if="item.IsActive">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="button"
              dark
              color="var(--lightRed)"
              v-bind="attrs"
              v-on="on"
              @click="
                updateStatusModal = true;
                activeItem.IsActive = false;
                activeItem.UserId = item.Id;
              "
            >
              {{ $t('buttons.blockUser') }}
            </v-btn>
          </template>
          <span>{{ $t('buttons.blockUser') }}</span>
        </v-tooltip>
        <v-tooltip top v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="button"
              dark
              color="green"
              v-bind="attrs"
              v-on="on"
              @click="
                updateStatusModal = true;
                activeItem.IsActive = true;
                activeItem.UserId = item.Id;
              "
            >
              {{ $t('labels.tooltipAccept') }}
            </v-btn>
          </template>
          <span>{{ $t('labels.tooltipAccept') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import RefreshButton from '../components/RefreshButton.vue';
import { ITEMS_PER_PAGE_OPTIONS, triggerLoading } from '../utils/constants';
import CreateOfferForm from './OfferScreens/CreateOfferForm.vue';
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';
import DeleteModal from '../components/General/DeleteModal';
import UserService from '../services/UserService';
import ConfirmationModal from '../components/General/ConfirmationModal.vue';

export default {
  data() {
    return {
      showCreateOffer: false,
      openDeleteDialog: false,
      offerType: 1,
      usersHeaders: [
        {
          text: this.$t('labels.fullName'),
          value: 'Name',
          sortable: false,
          groupable: false,
          cellClass: 'color-pink',
          align: 'center',
        },
        {
          text: this.$t('labels.companyName'),
          value: 'CompanyName',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.status'),
          value: 'IsActive',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          groupable: false,
        },
      ],
      users: [],

      // States
      pageLoading: false,
      itemsPerPageOptions: [],
      selectedItems: [],
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      perPageValues: [
        {
          name: '5',
          value: 5,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
      ],
      showCreateOffer: false,
      deleteId: null,

      activeItem: {
        IsActive: false,
        UserId: '',
      },
      updateStatusModal: false,
    };
  },
  components: {
    CreateOfferForm,
    FormElement,
    RefreshButton,
    ConfirmationModal,
  },
  mounted() {
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
    this.setup();
  },
  computed: {
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  watch: {},
  methods: {
    updateStatus() {
      this.updateStatusModal = true;
      this.$store.state.loadingStatus = true;
      UserService.updateUserStatus(this.activeItem)
        .then((response) => {
          window.showSuccess(this.$t('messages.updateUserStatusSuccessful'));
        })
        .catch(window.showError)
        .finally(() => {
          this.updateStatusModal = false;
          this.$store.state.loadingStatus = false;
          this.activeItem = {
            UserId: '',
            IsActive: false,
          };
        });
    },
    changeItemsPerPage() {
      this.page = 1;
      this.getUsers();
    },

    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(date) {
      return formatDate(date, '/');
    },

    /**
     * Trigger when page changed.
     */
    onPageChange(newPage) {
      this.page = newPage;
      this.getUsers();
    },

    setup() {
      this.getUsers();
    },

    /**
     * Get search history from service.
     */
    getUsers() {
      triggerLoading();
      UserService.getApprovedUsers({
        page: this.page,
        pageSize: this.itemsPerPage,
      })
        .then((response) => {
          this.users = response.data.Result;
          this.totalItems = response.data.TotalItems;
        })
        .catch(window.showError)
        .finally(() => {
          triggerLoading();
        });
    },

    /**
     * This will trigger a function on service error.
     * @param {Function} special
     * @param {*} error
     */
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },

    /**
     * Reset all models in View.
     */
    resetAllModels() {
      this.resetForm();
    },
  },
};
</script>
<style>
.container {
  margin-top: 38px;
}
.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
}
</style>
