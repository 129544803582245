var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-title',{staticClass:"d-flex flex-row justify-center",staticStyle:{"color":"var(--darkBlue)"}},[_c('v-row',[_c('v-col',{staticClass:"text-lg-left text-center",attrs:{"lg":"12","cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('navigationDrawerMenu.fixedReports')))])])],1),(_vm.reportKey)?_c('div',[_c('v-btn',{staticClass:"rounded-circle mr-2",staticStyle:{"min-width":"1px","min-height":"1px","width":"36px","height":"36px"},style:('color: white; background-color:var(--primary-color)'),on:{"click":_vm.downloadExcel}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticStyle:{"font-size":"24px"},attrs:{"small":"","color":"white"}},on),[_vm._v(" mdi-arrow-down ")])]}}],null,false,1409757984)},[_c('span',[_vm._v(_vm._s(_vm.$t('buttons.excelExport')))])])],1),_c('vue-excel-xlsx',{staticStyle:{"display":"none"},attrs:{"data":_vm.excelData,"columns":_vm.excelHeaders,"file-name":`${_vm.reportKey}-${Date.now().toString()}`,"file-type":'xlsx',"sheet-name":Date.now().toString()}},[_c('v-btn',{ref:"excelButton",staticClass:"ma-2 mr-4",attrs:{"small":"","elevation":"0","outlined":"","color":"info"}},[_vm._v(" "+_vm._s(_vm.$t('buttons.excelExport'))+" ")])],1),_c('RefreshButton',{attrs:{"refreshFunction":_vm.getItems}})],1):_vm._e()],1),(!_vm.reportKey)?_c('v-alert',{staticStyle:{"margin-bottom":"0"},attrs:{"dense":"","type":"warning"}},[_vm._v(_vm._s(_vm.$t('messages.warningSelectReportKey')))]):_vm._e(),_c('v-divider'),_c('v-row',{staticClass:"my-4 px-4 d-flex justify-start"},[_c('FormElement',{key:"REPORT_KEY_FILTER",attrs:{"vertical":true,"hide-details":"","validationLabel":_vm.$t('labels.reportType'),"topLabel":true,"labelShown":false,"cols":6,"sm":4,"lg":3,"onChange":_vm.getItems,"formType":{
          dense: true,
          name: 'multipleSelect',
          items: {
            data: _vm.keys,
            text: 'Value',
            value: 'Key',
            disableAutoSelection: true
          },
        },"placeholder":_vm.$t('labels.reportType')},model:{value:(_vm.reportKey),callback:function ($$v) {_vm.reportKey=$$v},expression:"reportKey"}}),(_vm.reportKey)?_c('FormElement',{key:"START_DATE_FILTER",attrs:{"vertical":true,"hide-details":"","validationLabel":_vm.$t('labels.startDate'),"topLabel":true,"labelShown":false,"cols":6,"sm":4,"lg":3,"formType":{
          dense: true,
          name: 'date',
          dateMode: 'date',
          maximum: _vm.dateFilter.maximum
        },"placeholder":_vm.$t('labels.startDate')},on:{"input":_vm.getItems},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}):_vm._e(),(_vm.reportKey)?_c('FormElement',{key:"End_DATE_FILTER",attrs:{"vertical":true,"hide-details":"","validationLabel":_vm.$t('labels.endDate'),"topLabel":true,"labelShown":false,"cols":6,"sm":4,"lg":3,"formType":{
          dense: true,
          name: 'date',
          dateMode: 'date',
          minimum: _vm.dateFilter.minimum
        },"placeholder":_vm.$t('labels.endDate')},on:{"input":_vm.getItems},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}):_vm._e()],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","loading":_vm.$store.state.loadingStatus,"options":{
        itemsPerPage: _vm.itemsPerPage,
      },"item-key":'Id',"no-data-text":_vm.$t('messages.noData')},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-center mt-8"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[(
                _vm.$vuetify.breakpoint.md ||
                _vm.$vuetify.breakpoint.lg ||
                _vm.$vuetify.breakpoint.xl
              )?_c('v-col',{attrs:{"md":"2"}}):_vm._e(),_c('v-col',{attrs:{"cols":"9","sm":"12","md":"7","lg":"7","xl":"7"}},[(_vm.pageLength > 0)?_c('v-pagination',{staticClass:"my-4",attrs:{"length":_vm.pageLength,"total-visible":10,"circle":""},on:{"input":_vm.onPageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1),_c('v-col',{staticClass:"ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6",attrs:{"cols":"11","sm":"11","md":"3","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"items":_vm.perPageValues,"label":_vm.$t('labels.selectItemsPerPage'),"item-value":"value","item-text":"name","outlined":"","dense":"","rounded":""},on:{"change":_vm.changeItemsPerPage},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"my-10 d-flex flex-column align-center"},[_c('h4',[_vm._v(_vm._s(_vm.$t('messages.noData')))])])]},proxy:true},{key:"item.Rate",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$getFormattedFloatValue(item.Rate))+" ")]}},{key:"item.Date",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.convertToLocal(item.ValidityDate ? item.ValidityDate : item.CreatedOnUtc))+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('div',{staticClass:"text-start"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",staticStyle:{"font-size":"20px"},attrs:{"small":"","color":"#29B7B7"},on:{"click":function($event){_vm.actionsModal = true
                _vm.isEditMode = true
                _vm.form = Object.assign({}, item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('buttons.search')))])])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }