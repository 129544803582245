import axios from '../javascript/custom-axios';
const RESOURCE_NAME = '';

export default {
  async getCounties(id, name, selectId) {
    return axios.get(`country/getCountries`, {
      params: { Id: id, Name: name, SelectId: selectId },
    });
  },

  async getRailways(name, selectId) {
    return axios.get(`railroad/get`, {
      params: { Name: name, SelectId: selectId },
    });
  },

  async getCities(countryId, selectId, name) {
    return axios.get(`city/getCities`, {
      params: { CountryId: countryId, SelectId: selectId, Name: name },
    });
  },

  /**
   * Get the seaports.
   * @param {string} name
   * @param {number} page
   * @param {number} pageSize
   * @returns {Promise<any>}
   */
  getSeaports(name, page, pageSize) {
    return axios.get(`seaport/get`, {
      params: { Name: name, page, pageSize },
    });
  },

  /**
   * Get the airports.
   * @param {string} name
   * @param {number} page
   * @param {number} pageSize
   * @returns {Promise<any>}
   */
  getAirports(name, countryId, cityId, page, pageSize) {
    return axios.get(`airport/get`, {
      params: {
        Name: name,
        page,
        pageSize,
        CountryId: countryId,
        CityId: cityId,
      },
    });
  },
};
