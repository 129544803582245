// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "/tag";

export default {
  
  getTags() {
    return axios.get(`${RESOURCE_NAME}/get`);
  },
  addTag(body) {
    return axios.post(`${RESOURCE_NAME}/add`,body);
  },
 
};
