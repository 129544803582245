<template>
  <div
    style="height: 100%; padding-top: 40px; padding-bottom: 70px"
    :style="{
      paddingRight: !(
        $vuetify.breakpoint.xs ||
        $vuetify.breakpoint.sm ||
        $vuetify.breakpoint.md
      )
        ? '1%'
        : '0',

      paddingLeft:
        $vuetify.breakpoint.xs ||
        $vuetify.breakpoint.sm ||
        $vuetify.breakpoint.md
          ? '1%'
          : '0',
    }"
  >
    <v-main>
      <router-view />
    </v-main>
  </div>
</template>
<script>
export default {
  name: 'Container',
  data() {
    return {};
  },
  methods: {},
};
</script>
