import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'Receipt';

export default {
  add(body) {
    return axios.post(`${RESOURCE_NAME}`, body);
  },
  get(id) {
    return axios.get(`${RESOURCE_NAME}/getByReceiptId?Id=${id}`);
  },
  delete(id) {
    return axios.delete(`${RESOURCE_NAME}/${id}/delete`);
  },
  deleteFile(id) {
    return axios.delete(`${RESOURCE_NAME}/${id}/deleteReceiptFile`);
  },
  update(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },
};
