<template>
  <div>
    <v-card elevation="1">
      <div class="flex w-full rounded-tl-sm rounded-tr-sm">
        <v-card-title style="color: var(--darkBlue)">
          <v-row>
            <v-col class="text-lg-left text-center" lg="5" cols="12">
              <h3>{{ $t('labels.notifications') }}</h3>
            </v-col>
          </v-row>
        </v-card-title>
      </div>

      <v-data-table
        style="padding: 0 16px"
        :headers="tableHeaders"
        :items="tableItems"
        v-model="tableItems"
        hide-default-footer
        :no-data-text="$t('messages.noData')"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td v-for="header in tableHeaders" :key="header.value">
              <template v-if="typeof item[header.value] === 'boolean'">
                <v-checkbox
                  v-model="item[header.value]"
                  @change="updatePermissions(item, header.value, header.type)"
                ></v-checkbox>
              </template>
              <template v-else>
                {{ item[header.value] }}
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <div style="height: 10px"></div>

    <v-card elevation="1">
      <div class="flex w-full rounded-tl-sm rounded-tr-sm">
        <v-card-title style="color: var(--darkBlue)">
          <v-row>
            <v-col class="text-lg-left text-center" lg="5" cols="12">
              <h3>{{ $t('labels.messageLanguagePreference') }}</h3>
            </v-col>
          </v-row>
        </v-card-title>
      </div>

      <v-data-table
        style="padding: 0 16px"
        :headers="languageTableHeaders"
        :items="languageTableItems"
        v-model="languageTableItems"
        hide-default-footer
        :no-data-text="$t('messages.noData')"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td v-for="header in languageTableHeaders" :key="header.value">
              <template v-if="typeof item[header.value] === 'boolean'">
                <v-radio-group
                  v-model="selectedLanguage"
                  @change="updateLanguagePermissions"
                >
                  <v-radio
                    class="justify-center pl-3"
                    :value="header.value"
                  ></v-radio>
                </v-radio-group>
              </template>
              <template v-else>
                {{ item[header.value] }}
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <div style="height: 10px"></div>

    <!-- <v-card elevation="1">
      <div class="flex w-full rounded-tl-sm rounded-tr-sm">
        <v-card-title style="color: var(--darkBlue)">
          <v-row>
            <v-col class="text-lg-left text-center" lg="5" cols="12">
              <h3>{{ $t('titles.blacklist') }}</h3>
            </v-col>
          </v-row>
        </v-card-title>
      </div>

      <v-data-table
        style="padding: 0 16px"
        :headers="blacklistHeaders"
        :items="blacklistedUsers"
        hide-default-footer
        :no-data-text="$t('messages.noData')"
      >
      <template v-slot:item.FileUrl="{ item }">
        <v-btn
          v-if="item.FileUrl"
          elevation="0"
          color="primary"
          class="button"
          dark
          :href="item.FileUrl"
          depressed
          target="_blank"
          icon
          download
          ><v-icon> mdi-eye </v-icon></v-btn
        >
      </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-start">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="red"
                  class="mr-1"
                  style="font-size: 20px"
                  v-bind="attrs"
                  v-on="on"
                  @click="removeUserFromBlacklist(item.CustomerCompany.Id)"
                >
                  mdi-close
                </v-icon>
              </template>
              <span>{{ $t('buttons.removeUserFromBlacklist') }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card> -->
  </div>
</template>

<script>
import BlacklistServices from '../services/BlacklistServices';
import NotificationPermissionService from '../services/NotificationPermissionService';

export default {
  data: () => ({
    selectedLanguage: null,
    permissionId: null,
    tableHeaders: [
      { text: '', value: 'label', sortable: false },

      {
        text: 'SMS',
        value: 'sms',
        type: 0,
        sortable: false,
        align: 'center',
        width: '20%',
      },
      {
        text: 'Email',
        value: 'email',
        type: 1,
        sortable: false,
        align: 'center',
        width: '20%',
      },
    ],
    tableItems: [],
    languageTableHeaders: [
      { text: '', value: 'label', sortable: false },
      {
        text: 'Türkçe',
        value: 'tr',
        sortable: false,
        align: 'center',
        width: '20%',
      },
      {
        text: 'English',
        value: 'en',
        sortable: false,
        align: 'center',
        width: '20%',
      },
    ],
    languageTableItems: [],

    // Blacklist
    blacklistHeaders: [],
    blacklistedUsers: [],
  }),
  watch: {},
  computed: {},
  beforeMount() {
    this.tableItems = [
      {
        label: this.$t('labels.order'),
        sms: false,
        email: false,
      },
      {
        label: this.$t('labels.spotOffer'),
        sms: false,
        email: false,
      },
      {
        label: this.$t('titles.payments'),
        sms: false,
        email: false,
      },
    ];
    this.languageTableItems = [
      {
        label: 'SMS - Mail',
        tr: false,
        en: false,
      },
    ];

    this.blacklistHeaders = [
      {
        text: this.$t('labels.companyTitle'),
        value: 'CustomerCompany.CompanyName',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('titles.reason'),
        value: 'Reason',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.file'),
        value: 'FileUrl',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('title.tableHeaderActions'),
        value: 'actions',
        sortable: false,
      },
    ];
  },
  mounted() {
    this.getPermissions();
    this.getSMSPermissions();
    this.getBlacklistedUsers();
  },
  components: {},
  methods: {
    removeUserFromBlacklist(id) {
      this.$store.state.loadingStatus = true;
      BlacklistServices.delete({
        CustomerId: id,
      })
        .then((response) => {
          window.showSuccess(this.$t('messages.removeBlacklistSuccessfully'));
          this.getBlacklistedUsers();
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getBlacklistedUsers() {
      this.$store.state.loadingStatus = true;
      BlacklistServices.getForSuppliers()
        .then((response) => {
          this.blacklistedUsers = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    updateLanguagePermissions(item) {
      this.$store.state.loadingStatus = true;

      let body = {
        Language: item,
      };
      NotificationPermissionService.updateSMSLanguagePref(body)
        .then((response) => {
          this.getPermissions();
          this.getSMSPermissions();
          window.showSuccess(this.$t('messages.notifictionUpdateSuccess'));
        })
        .catch((error) => {
          window.showError(error);
        });

      this.$store.state.loadingStatus = false;
    },

    updatePermissions(item, permissionType, type) {
      this.$store.state.loadingStatus = true;
      const body = {
        Id: this.permissionId,
        Permission: item.permission,
        PermissionValue: item[permissionType],
        Type: type,
      };

      NotificationPermissionService.updatePermission(body)
        .then((response) => {
          this.getPermissions();
          window.showSuccess(this.$t('messages.notifictionUpdateSuccess'));
        })
        .catch((error) => {
          window.showError(error);
        });
    },
    getPermissions() {
      this.$store.state.loadingStatus = true;
      NotificationPermissionService.getPermissions().then((response) => {
        this.permissionId = response.data.Result.Id;

        this.tableItems.forEach((item) => {
          if (item.label === this.$t('labels.order')) {
            item.sms = response.data.Result.SmsPermissions.order;
            item.email = response.data.Result.MailPermissions.order;
            item.permission = 0;
          } else if (item.label === this.$t('labels.spotOffer')) {
            item.sms = response.data.Result.SmsPermissions.spotOffer;
            item.email = response.data.Result.MailPermissions.spotOffer;
            item.permission = 1;
          } else if (item.label === this.$t('titles.payments')) {
            item.sms = response.data.Result.SmsPermissions.payments;
            item.email = response.data.Result.MailPermissions.payments;
            item.permission = 2;
          }
        });

        this.$store.state.loadingStatus = false;
      });
    },
    getSMSPermissions() {
      this.$store.state.loadingStatus = true;
      NotificationPermissionService.getSMSLanguagePref().then((response) => {
        let responseLanguage = response.data.Result;

        switch (responseLanguage) {
          case 'en':
            this.selectedLanguage = 'en';
            break;
          case 'tr':
            this.selectedLanguage = 'tr';
            break;
          default:
            break;
        }
        this.$store.state.loadingStatus = false;
      });
    },
  },
};
</script>
