<template>
  <div>
    <!-- Add/Update Modal -->
    <v-dialog
      @click:outside="clearModal()"
      v-model="buyPackageModal"
      max-width="800"
    >
      <v-card>
        <v-card-title class="headline update">
          <div class="text-center white--text" style="width: 100%">
            {{ currentTitle }}
          </div>
        </v-card-title>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-card-text class="py-3">
                  <v-row class="align-center pt-4">
                    <v-col
                      v-if="isUpdate"
                      class="mb-4 form-column d-flex"
                      cols="12"
                      lg="12"
                    >
                      <v-img
                        v-if="
                          item.PhotoUrl ||
                          base64 ||
                          require('../assets/no-image.png')
                        "
                        :lazy-src="
                          base64
                            ? base64
                            : item.PhotoUrl || require('../assets/no-image.png')
                        "
                        max-height="50"
                        max-width="50"
                        :src="base64 ? base64 : item.PhotoUrl"
                      ></v-img>
                      <div class="ml-4 align-self-center d-flex flex-column">
                        <span class="form-label">
                          {{ item.WarehouseName }}
                        </span>
                        <div>
                          <span style="line-height: 2.2; color: #315a88"
                            >{{ $t(itemForm[0].formLabel) }}:
                          </span>
                          <span style="line-height: 2.2; color: #315a88">{{
                            item[itemForm[0].key.child]
                          }}</span>
                        </div>
                      </div>
                    </v-col>
                    <FormElement
                      v-for="element in itemForm"
                      :key="element.key.parent + '_' + element.key.child"
                      :vertical="element.vertical"
                      v-model="$data[element.key.parent][element.key.child]"
                      :validationLabel="$t(element.validationLabel)"
                      :rules="element.rules"
                      :formLabel="$t(element.formLabel)"
                      :onChange="element.onChange"
                      :formType="element.formType"
                      :placeholder="$t(element.placeholder)"
                    />
                  </v-row>
                </v-card-text>
              </v-window-item>
              <v-window-item :value="2">
                <v-card-text>
                  <v-list>
                    <v-subheader key="listTitle">
                      <h1 class="main-title">{{ $t('titles.boughtItems') }}</h1>
                    </v-subheader>

                    <v-list-item
                      v-for="item in renderedMarketplaces"
                      :key="item.title"
                    >
                      <v-list-item-avatar>
                        <v-img :src="item.avatar"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <!-- eslint-disable-next-line  --><v-list-item-title
                          v-text="item.title"
                        ></v-list-item-title>

                        <!-- eslint-disable-next-line  --><v-list-item-subtitle
                          v-text="item.subtitle"
                        ></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon color="green lighten-1"
                            >mdi-information</v-icon
                          >
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-row class="align-center pt-4">
                    <FormElement
                      key="paymentType"
                      :disabled="isPaymentStarted"
                      :vertical="false"
                      v-model="item.PaymentTypeId"
                      :validationLabel="$t('labels.type', { value: 'Ödeme' })"
                      rules="required"
                      :formLabel="$t('labels.type', { value: 'Ödeme' })"
                      :formType="{
                        name: 'select',
                        items: {
                          data: [
                            { Id: 1, Name: 'Havale' },
                            { Id: 2, Name: 'Online' },
                          ],
                          value: 'Id',
                          text: 'Name',
                        },
                      }"
                      :placeholder="$t('labels.type', { value: 'Ödeme' })"
                    />
                  </v-row>
                  <CreditCardForm
                    :style="item.PaymentTypeId === 2 ? '' : 'display:none'"
                    ref="creditCardFormRef"
                  />
                </v-card-text>
              </v-window-item>
            </v-window>
          </form>
        </ValidationObserver>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            :disabled="step === 1 || isPaymentStarted"
            text
            @click="step--"
          >
            {{ $t('buttons.back') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="step === 2 && item.PaymentTypeId !== 2"
            elevation="0"
            class="update darken-1"
            dark
            @click="finishOperation"
            type="button"
            :loading="loadings.send"
          >
            {{ $t('titles.buy', {}) }}
          </v-btn>
          <v-btn
            :disabled="step === 2 || item.BoughtMarketplaces.length === 0"
            color="primary"
            depressed
            @click="step++"
          >
            {{ $t('buttons.next') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Add/Update Modal -->
    <TabScreen :slots="slots">
      <!-- Information Template -->
      <template v-slot:waiting>
        <v-card elevation="2" style="border-radius: 0">
          <v-card-title class="popupHeadline">
            <h4>{{ $t('titles.requests', { value: 'Bekleyen' }) }}</h4>
          </v-card-title>

          <v-divider />

          <v-card-text class="pt-4">
            <v-data-table
              key="waiting_table"
              :single-expand="true"
              item-key="Id"
              :headers="headers"
              :items="items.Orders"
              :loading="$store.state.loadingStatus"
              :options.sync="options"
              :items-per-page="itemsPerPage"
              :hide-default-footer="true"
              :no-data-text="$t('description.noList')"
              show-expand
            >
              <!-- eslint-disable-next-line  -->
              <template v-slot:item.OrderCode="{ item }">
                {{ item.OrderCode }}
              </template>
              <!-- eslint-disable-next-line  -->
              <template v-slot:item.UserName="{ item }">
                {{ item.UserName }}
              </template>
              <!-- eslint-disable-next-line  -->
              <template v-slot:item.Status="{ item }">
                <v-chip
                  small
                  :color="
                    item.OrderStatus === 0
                      ? '#c5f4db'
                      : item.OrderStatus === 2
                      ? '#e3e2e3'
                      : '#CF181F'
                  "
                  :text-color="
                    item.OrderStatus === 0
                      ? '#2b3838'
                      : item.OrderStatus === 2
                      ? '#2b3838'
                      : '#FFF'
                  "
                >
                  {{ $t('labels.' + item.osasOrderStatusText.toLowerCase()) }}
                </v-chip>
              </template>
              <!-- eslint-disable-next-line  -->
              <template v-slot:item.TotalPrice="{ item }">
                <div style="text-align: center !important">
                  {{ item.SubTotalPrice }}$
                </div>
              </template>
              <!-- eslint-disable-next-line  -->
              <template v-slot:item.actions="{ item }">
                <v-tooltip v-if="item.OrderStatus !== 0" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      middle
                      class="mr-1"
                      color="green"
                      @click="operationOnPackage(item.Id, true)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-check-circle
                    </v-icon>
                  </template>
                  <span>{{ $t('labels.tooltipAccept') }}</span>
                </v-tooltip>
                <v-tooltip v-if="item.OrderStatus !== 0" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      middle
                      class="ml-1"
                      color="red"
                      @click="operationOnPackage(item.Id, false)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-close-circle
                    </v-icon>
                  </template>
                  <span>{{ $t('labels.tooltipDeny') }}</span>
                </v-tooltip>
              </template>
              <!-- eslint-disable-next-line  -->
              <template v-slot:body.append="{ tableItems }">
                <tr>
                  <td>
                    {{ $t('titles.total') }}
                  </td>
                  <td></td>
                  <td></td>
                  <td
                    style="
                      text-align: center;
                      font-weight: bold;
                      font-size: 30px;
                      padding: 30px 20px;
                    "
                  >
                    {{ calculatePrice(items.Orders) }}$
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  style="text-align: start !important"
                  :colspan="headers.length"
                >
                  <CartItem
                    :key="cartItem.BasketItemId"
                    v-for="(cartItem, index) in item.BasketPackageItems"
                    :isLast="
                      index === item.BasketPackageItems.length - 1
                        ? true
                        : false
                    "
                    :item="{
                      title: cartItem.Marketplace.Name,
                      subtitle: item.OrderCode,
                      photoUrl: cartItem.Marketplace.PhotoUrl,
                      price:
                        cartItem.Marketplace.Price *
                        (1 - item.DiscountRate / 100),
                    }"
                  />
                </td>
              </template>
            </v-data-table>
            <div class="text-xs-center pt-2 md4 lg4">
              <!-- Pagination select box start -->
              <v-row align="center">
                <v-col md="3" class="ml-md-auto">
                  <v-pagination
                    @input="paginationChangeHandler"
                    :value="pagination.page"
                    :length="pages"
                    :total-visible="15"
                    circle
                  ></v-pagination>
                </v-col>
                <v-col md="2" class="ml-md-auto">
                  <v-select
                    v-model="itemsPerPage"
                    :items="$store.state.perPageValues"
                    :label="$t('labels.selectItemsPerPage')"
                    item-value="value"
                    item-text="name"
                    @change="changeItemsPerPage()"
                    outlined
                    dense
                    rounded
                  >
                  </v-select>
                </v-col>
              </v-row>
              <!-- Pagination select box end -->
            </div>
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:history>
        <v-card elevation="2" style="border-radius: 0">
          <v-card-title style="color: #595959">
            <h4>{{ $t('titles.requests', { value: 'Geçmiş' }) }}</h4>
          </v-card-title>

          <v-divider />

          <v-card-text class="pt-4">
            <v-data-table
              key="history_table"
              :single-expand="true"
              item-key="Id"
              :headers="headersHistory"
              :items="historyItems"
              :loading="$store.state.loadingStatus"
              :options.sync="options"
              :items-per-page="itemsPerPage"
              :hide-default-footer="true"
              :no-data-text="$t('description.noList')"
              show-expand
            >
              <!-- eslint-disable-next-line  -->
              <template v-slot:item.OrderCode="{ item }">
                {{ item.OrderCode }}
              </template>
              <!-- eslint-disable-next-line  -->
              <template v-slot:item.UserName="{ item }">
                {{ item.UserName }}
              </template>
              <!-- eslint-disable-next-line  -->
              <template v-slot:item.Status="{ item }">
                <v-chip
                  small
                  :color="
                    item.OrderStatus === 0
                      ? '#c5f4db'
                      : item.OrderStatus === 2
                      ? '#e3e2e3'
                      : '#CF181F'
                  "
                  :text-color="
                    item.OrderStatus === 0
                      ? '#2b3838'
                      : item.OrderStatus === 2
                      ? '#2b3838'
                      : '#FFF'
                  "
                >
                  {{ $t('labels.' + item.osasOrderStatusText.toLowerCase()) }}
                </v-chip>
              </template>
              <!-- eslint-disable-next-line  -->
              <template v-slot:item.TotalPrice="{ item }">
                <div style="text-align: center !important">
                  {{ item.SubTotalPrice }}$
                </div>
              </template>
              <!-- eslint-disable-next-line  -->
              <template v-slot:body.append="{}">
                <tr>
                  <td>
                    {{ $t('titles.total') }}
                  </td>
                  <td></td>
                  <td></td>
                  <td
                    style="
                      text-align: center;
                      font-weight: bold;
                      font-size: 30px;
                      padding: 30px 20px;
                    "
                  >
                    {{ calculatePrice(historyItems) }}$
                  </td>
                  <td></td>
                </tr>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  style="text-align: start !important"
                  :colspan="headers.length"
                >
                  <CartItem
                    :key="cartItem.BasketItemId"
                    v-for="(cartItem, index) in item.BasketPackageItems"
                    :isLast="
                      index === item.BasketPackageItems.length - 1
                        ? true
                        : false
                    "
                    :item="{
                      title: cartItem.Marketplace.Name,
                      subtitle: item.OrderCode,
                      photoUrl: cartItem.Marketplace.PhotoUrl,
                      price:
                        cartItem.Marketplace.Price *
                        (1 - item.DiscountRate / 100),
                    }"
                  />
                </td>
              </template>
            </v-data-table>
            <div class="text-xs-center pt-2 md4 lg4">
              <!-- Pagination select box start -->
              <v-row align="center">
                <v-col md="3" class="ml-md-auto">
                  <v-pagination
                    @input="paginationChangeHandler"
                    :value="pagination.page"
                    :length="pages"
                    :total-visible="15"
                    circle
                  ></v-pagination>
                </v-col>
                <v-col md="2" class="ml-md-auto">
                  <v-select
                    v-model="itemsPerPage"
                    :items="$store.state.perPageValues"
                    :label="$t('labels.selectItemsPerPage')"
                    item-value="value"
                    item-text="name"
                    @change="changeItemsPerPage()"
                    outlined
                    dense
                    rounded
                  >
                  </v-select>
                </v-col>
              </v-row>
              <!-- Pagination select box end -->
            </div>
          </v-card-text>
        </v-card>
      </template>
    </TabScreen>
  </div>
</template>

<script>
import DeleteModal from '../components/General/DeleteModal';
import TabScreen from '../components/TabScreen.vue';
import FormElement from '../components/FormElement.vue';
import WarehouseServices from '../services/WarehouseServices';
import MarketplaceServices from '../services/MarketplaceServices';
import CreditCardForm from '../components/CreditCardForm.vue';
import PackageBasketServices from '../services/PackageBasketServices';
import CartItem from '../components/CartItem.vue';

export default {
  data: () => ({
    // General Models
    isLoading: false,
    isUpdate: false,
    isUploading: false,
    file: null,
    base64: '',
    step: 1,

    slots: [
      { name: 'waiting', title: 'Bekleyen İstekler' },
      { name: 'history', title: 'Geçmiş İstekler' },
    ],

    parameters: [],

    itemId: '',
    item: {
      BoughtMarketplaces: [],
      PaymentTypeId: 1,
    },
    model: null,
    search: null,
    items: [],
    historyItems: [],
    deletedId: -1,
    company: {},
    marketplaces: [],

    buyPackageModal: false,

    // List options
    options: {
      itemsPerPage: 10,
    },
    headers: [],
    headersHistory: [],
    //Loading states
    loadings: {
      send: false,
      item: false,
    },
    pagination: {},
    itemsPerPage: 10,
  }),

  computed: {
    isPaymentStarted() {
      return this.$store.state.globalOperation;
    },
    currentTitle() {
      switch (this.step) {
        case 1:
          return this.$t('titles.buy', { value: 'Paket' });
        case 2:
          return this.$t('titles.choose', { value: 'Ödeme Yöntemini' });
        default:
          return this.$t('titles.summary');
      }
    },
    itemForm() {
      return [
        {
          key: {
            parent: 'item',
            child: 'BoughtMarketplaces',
          },
          rules: 'requiredArray',
          validationLabel: 'labels.marketplace.title',
          vertical: false,
          formLabel: 'labels.marketplace.title',
          formType: {
            name: 'multipleSelect',
            items: {
              specialized: true,
              data: this.marketplaces,
              value: 'Id',
              text: 'Name',
              subtitle: 'Price',
              avatar: 'PhotoUrl',
            },
          },
          placeholder: 'labels.marketplace.title',
        },
      ];
    },
    renderedMarketplaces() {
      let temporary = [];
      this.item.BoughtMarketplaces.forEach((element) => {
        let item = this.marketplaces.find((item) => item.Id === element);
        if (item) {
          temporary.push({
            avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
            title: item.Name,
            subtitle: element,
          });
        }
      });

      return temporary;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    fields() {
      if (!this.model) return [];
      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || 'n/a',
        };
      });
    },
    autoCompleteItems() {
      return this.items.map((entry) => {
        return Object.assign({}, entry, {
          Id: entry.Id,
          WarehouseName: entry.Name,
        });
      });
    },
  },

  watch: {
    search(val) {
      // Items have already been loaded
      if (this.autoCompleteItems.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      this.getItems();
    },
  },
  watch: {},
  mounted() {
    this.setup();
  },
  components: {
    CartItem,
    DeleteModal,
    TabScreen,
    FormElement,
    CreditCardForm,
  },
  methods: {
    /**
     * Calculate prices in the list.
     * @param {array} items
     */
    calculatePrice(items) {
      let total = 0;
      if (items) {
        items.map((item) => (total += parseFloat(item.SubTotalPrice)));
      }
      return total;
    },
    /**
     * Finishes to buy packages.
     */
    finishOperation() {
      this.$store.state.loadingStatus = true;
      new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            data: {
              Message: 'Satın alma işlemi gerçekleştirildi.',
            },
          });
        }, 2000);
      })
        .then((response) => {
          window.showSuccess(response.data.Message);
          //this.clearModal();
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally((response) => {
          this.$store.state.loadingStatus = false;
        });
    },
    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getItems();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
      this.getItems();
    },

    /**
     * Get role list.
     */
    getMarketPlaceList() {
      this.$store.state.loadingStatus = true;
      MarketplaceServices.get()
        .then((res) => {
          this.marketplaces = res.data.Result;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(this.onFinal);
    },

    /**
     * Adds a new item integraton for company.
     */
    addItem() {
      this.loadings.send = true;
      this.item.Id = this.itemId;
      WarehouseServices.add(this.item)
        .then((res) => {
          this.buyPackageModal = false;
          window.showSuccess(
            this.$t('messages.successfulAdd', { value: 'Paket' })
          );
          this.clearModal();
          this.getItems();
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.loadings.send = false;
        });
    },

    /**
     * Update an api integration for company.
     */
    updateItem() {
      this.loadings.send = true;
      WarehouseServices.update({
        WarehouseId: this.item.Id,
        Name: this.item.Name,
        Address: this.item.Address,
        Country: this.item.Country,
        City: this.item.City,
        District: this.item.District,
        Postcode: this.item.Postcode,
      })
        .then((res) => {
          this.buyPackageModal = false;
          window.showSuccess(
            this.$t('messages.successfulUpdate', { value: 'Paket' })
          );
          this.clearModal();
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(() => {
          this.loadings.send = false;
        });
    },

    //Page setup
    setup() {
      this.getMarketPlaceList();
      this.pagination = {
        totalItems: 0,
        rowsPerPage: 10,
        page: 1,
      };
      this.getItems();

      this.headersHistory = [
        {
          text: this.$t('titles.number', { value: 'Sipariş' }),
          value: 'OrderCode',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('title.tableHeaderUsername'),
          value: 'UserName',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('labels.status'),
          value: 'Status',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.price'),
          value: 'TotalPrice',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        { text: 'Detay', value: 'data-table-expand', align: 'center' },
      ];

      this.headers = [
        ...this.headersHistory,
        {
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
          groupable: false,
          align: 'center',
        },
      ];
    },

    //Clears all modal's states and item ID
    clearModal() {
      this.buyPackageModal = false;
      setTimeout(() => {
        this.resetForm();
        this.isUpdate = false;
        this.itemId = '';
        this.step = 1;
        this.item = {
          BoughtMarketplaces: [],
          PaymentTypeId: 1,
        };
        this.$refs.creditCardFormRef.creditCardUrl = '';
        this.$refs.creditCardFormRef.item = {};
      }, 500);
    },

    /**
     * Change active item of the page.
     * @param {*} item
     * @param {boolean} isUpdate
     */
    async changeActiveItem(item, isUpdate = false) {
      this.itemId = item.Id;
      this.buyPackageModal = true;
      this.item = item;
      this.isUpdate = isUpdate;
    },

    /**
     * Get item list.
     */
    getItems() {
      this.$store.state.loadingStatus = true;
      PackageBasketServices.getPackageOrders()
        .then((res) => {
          this.items = res.data.Result;
          this.pagination.totalItems = res.data.Result.TotalOrderCount;
          this.discount = res.data.Result.DiscountRate;
          this.historyItems = this.items.Orders.filter(
            (element) => element.OrderStatus !== 2
          );
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(this.onFinal);
    },

    /**
     * Final Function Of Services
     */
    onFinal() {
      this.$store.state.loadingStatus = false;
    },

    /**
     * Add or update a item.
     */
    sendForm() {
      if (this.isUpdate) {
        this.updateItem();
      } else {
        this.addItem();
      }
    },

    /**
     * onChangeImage trigger.
     */
    onChangeImage() {
      if (this.file) {
        var reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
          this.base64 = reader.result;
        };
        this.isUploading = true;
      } else {
        this.isUploading = false;
      }
    },

    /**
     * Delete a item.
     */
    deleteItem() {
      this.$store.state.loadingStatus = true;
      WarehouseServices.delete(this.deletedId)
        .then(() => {
          this.$refs.deleteModalRef.modalStatus = false;
          window.showSuccess(
            this.$t('messages.successfulDelete', { value: 'Paket' })
          );
          this.getItems();
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Shows delete modal.
     * @param {*} item
     */
    openDeleteModal(item) {
      this.deletedId = item.Id;
      this.$refs.deleteModalRef.modalStatus = true;
    },

    /**
     * The function can make you approve or reject any package basket.
     * @param {integer} id
     * @param {boolean} isApprove
     */
    operationOnPackage(id, isApprove = true) {
      this.$store.state.loadingStatus = true;
      if (isApprove) {
        PackageBasketServices.completePackageOrder(id)
          .then((response) => {
            window.showSuccess(this.$t('messages.completePackageOrder'));
          })
          .catch((err) => {
            window.handleServiceError(err);
          })
          .finally(() => {
            this.$store.state.loadingStatus = false;
            this.getItems();
          });
      } else {
        PackageBasketServices.cancelPackageOrder(id)
          .then((response) => {
            window.showError(
              null,
              this.$t('messages.completePackageOrderError')
            );
          })
          .catch((err) => {
            window.handleServiceError(err);
          })
          .finally(() => {
            this.$store.state.loadingStatus = false;
            this.getItems();
          });
      }
    },

    /**
     * Resetting forms' validation.
     */
    async resetForm() {
      this.$refs.observer.reset();
    },
  },
};
</script>
