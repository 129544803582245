<template>
  <div>
    <v-card style="border-radius: 10px">
      <v-card-title class="popupHeadline">
        <v-row>
          <v-col class="py-2">{{ $t('title.importTitle') }}</v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-row align="start" class="d-flex mb-6">
          <v-col cols="4" class="">
            <v-file-input
              :placeholder="$t('labels.file')"
              accept=".xlsx"
              v-model="file"
              prepend-icon=""
              append-icon="mdi-file-excel"
              :label="$t('labels.file')"
              class="custom-border"
              outlined
              @change="onChangeImage()"
            ></v-file-input>
          </v-col>
          <v-col v-if="photoSelected" cols="2" class="mt-3">
            <div style="gap: 1rem">
              <span
                class="align-self-center mr-2"
                style="font-size: 12px; font-weight: normal; color: #598db5"
                >{{ $t('buttons.add') }}</span
              >
              <v-icon
                @click="importExcel()"
                class="icon-button mr-4 align-self-center"
              >
                mdi-plus
              </v-icon>
            </div>
          </v-col>
          <v-col v-if="!photoSelected" cols="2" class="mt-3"> </v-col>
          <v-col cols="2" class="mt-3"> </v-col>
          <v-col cols="" class="mt-3">
            <v-btn
              color="accent"
              elevation="2"
              outlined
              rounded
              @click="downloadExample()"
              >{{ $t('buttons.dowloadTempFile') }}</v-btn
            >
          </v-col>
        </v-row>
        <div
          style="border: 1px solid #0077d5; padding: 10px; border-radius: 10px"
        >
          <h1 style="color: #0077d5">
            {{ $t('title.importFileInstructions') }}
          </h1>
          <v-carousel class="mt-5">
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.src"
              reverse-transition="fade-transition"
              transition="fade-transition"
              contain
            >
              <div
                v-if="item.text != null"
                style="
                  background-color: white;
                  margin-top: 400px;
                  border-radius: 10px;
                  padding: 2px;
                  font-size: x-small;
                  text-align: center;
                "
              >
                <h1>{{ item.text }}</h1>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import OperationServices from '../../services/OperationService';

export default {
  data: () => ({
    items: [
      {
        src: 'https://expadoortest.s3.amazonaws.com/Media/2.05.2023%2014:48:04Silde0.png?AWSAccessKeyId=AKIA5TWV25RSSAFBF64U&Expires=1714650484&Signature=JGPagwcE%2B2Frzj5rIlyu6NrZ2tE%3D',
        text: null,
      },
      {
        src: 'https://expadoortest.s3.amazonaws.com/Media/2.05.2023%2014:13:34slide1.png?AWSAccessKeyId=AKIA5TWV25RSSAFBF64U&Expires=1714648415&Signature=ChZNcMd%2Bt6Rz0f%2BqiRSIec%2BGs44%3D',
        text: "1. Örnek excel dosyasını indirip ürünlerinizi her satıra bir tane olacak şekilde excel'e giriniz.",
      },
      {
        src: 'https://expadoortest.s3.amazonaws.com/Media/2.05.2023%2014:25:21slide2.png?AWSAccessKeyId=AKIA5TWV25RSSAFBF64U&Expires=1714649121&Signature=QHQ0h7aQTMT2TU69S8%2Bj7Un1C%2Bk%3D',
        text: "2. Hazırladğınız dosyayı yükleyerek 'Ekle' butonuna tıklayınız.",
      },
    ],
    file: null,
    isUploading: false,
  }),

  watch: {},
  mounted() {},
  computed: {
    photoSelected() {
      return this.isUploading;
    },
  },
  components: {},

  methods: {
    importExcel() {
      if (this.file) {
        const media = this.file;

        let formData = new FormData();
        formData.append('File', media);
        this.$store.state.loadingStatus = true;
        OperationServices.bulkImport(formData)
          .then((uploadFileResponse) => {
            window.showSuccess(
              this.$t('messages.successfulAdd', {
                value: 'Ürünler ',
              })
            );
          })
          .catch((error) => {
            window.showError(error.response.data.Message, 'import');
          })
          .finally(() => {
            this.$store.state.loadingStatus = false;
            this.file = null;
            this.isUploading = false;
          });
      }
    },
    onChangeImage() {
      if (this.file) {
        var reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
          this.base64 = reader.result;
        };
        this.isUploading = true;
      } else {
        this.isUploading = false;
      }
    },
    downloadExample() {
      let body = {
        s3Uri: 'expadoortest/Documents/ExcelExports/bulkImport.xlsx',
      };
      this.$store.state.loadingStatus = true;
      OperationServices.downloadImportExampleFile(body, 'OrnekDosya')
        .then((res) => {})
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
  },
};
</script>
