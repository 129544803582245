var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"#F0F0F1",staticStyle:{"width":"100%","height":"100%"}},[_c('NavMenu',{on:{"toggle-drawer":_vm.toggleDrawer}}),(_vm.drawerOpen || _vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.xl)?_c('NavigationDrawer',{on:{"close-drawer":_vm.closeDrawer}}):_vm._e(),_c('Container',{style:({
      paddingLeft: _vm.$vuetify.breakpoint.xs
        ? '0'
        : _vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.xl
        ? '250px'
        : _vm.drawerOpen
        ? '250px'
        : '0',
    })})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }