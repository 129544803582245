<template>
  <v-card elevation="1">
    <div class="flex w-full rounded-tl-sm rounded-tr-sm">
      <v-card-title style="color: var(--darkBlue)">
        <v-row>
          <v-col class="text-lg-left text-center" cols="11">
            <h3>{{ $t('navigationDrawerMenu.dictionary') }}</h3>
          </v-col>

          <v-col cols="1" class="d-flex justify-end align-center">
            <v-btn
              v-if="isAdmin"
              @click="openAddDialog"
              class="rounded-circle mr-4"
              style="
                min-width: 1px;
                min-height: 1px;
                width: 36px;
                height: 36px;
                background-color: #0177d4;
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon small color="white" style="font-size: 24px" v-on="on">
                    mdi-plus
                  </v-icon>
                </template>
                <span>{{ $t('buttons.add') }}</span>
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
    </div>
    <hr />
    <div>
      <v-row
        class="justify-center items-center py-4"
        style="padding: 0px; margin: 0px"
      >
        <FormElement
          v-model="search"
          :validationLabel="$t('buttons.search')"
          :formLabel="$t('buttons.search')"
          @input="getSearchDebounced"
          :formType="{
            name: 'text',
            appendIcon: 'mdi-magnify',
            appendClick: () => {
              getSearchDebounced();
            },
          }"
          :labelShown="false"
          :topLabel="true"
          :lg="4"
          :hideDetails="true"
          :placeholder="$t('buttons.search')"
        />
      </v-row>
    </div>
    <v-card-text>
      <v-row v-if="glossaryOfTerms.length > 0">
        <v-col
          v-for="(term, index) in glossaryOfTerms"
          :key="term.Id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-expansion-panels variant="accordion">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-row>
                  <v-col class="popupHeadline" cols="12">
                    <h3>{{ term.Term }}</h3>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>{{ term.Definition }}</p>
                <v-btn
                  v-if="isAdmin"
                  @click="editTerm(term)"
                  color="primary"
                  class="rounded mr-5"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  v-if="isAdmin"
                  @click="deleteTerm(term.Id)"
                  color="error"
                  class="rounded"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" class="text-center">
          <h3>{{ $t('messages.noData') }}</h3>
        </v-col>
      </v-row>
      <div class="d-flex justify-center mt-8">
        <v-row align="center" justify="center">
          <v-col
            v-if="
              $vuetify.breakpoint.md ||
              $vuetify.breakpoint.lg ||
              $vuetify.breakpoint.xl
            "
            md="2"
          ></v-col>
          <v-col cols="9" sm="12" md="7" lg="7" xl="7">
            <v-pagination
              @input="paginationChangeHandler"
              :value="page"
              :length="pages"
              :total-visible="10"
              circle
            ></v-pagination>
          </v-col>
          <v-col
            cols="11"
            sm="11"
            md="3"
            lg="3"
            xl="3"
            class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
          >
            <v-select
              v-model="rowsPerPage"
              :items="perPageValues"
              :label="$t('labels.selectItemsPerPage')"
              item-value="value"
              item-text="name"
              hide-details
              outlined
              dense
              @change="changeItemsPerPage()"
              rounded
            >
            </v-select>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-dialog v-model="editDialog" max-width="500">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-center popupHeadline" style="width: 100%">
            <h2 class="headline">
              {{ editMode ? $t('labels.EditTerm') : $t('labels.AddTerm') }}
            </h2>
          </div>
        </v-card-title>

        <v-divider class="mb-4" />

        <v-card-text style="padding-bottom: 0px">
          <v-row class="mb-4">
            <v-col cols="6" class="d-flex">
              <input
                type="radio"
                id="zero"
                v-model="selectedLanguage"
                :value="0"
                style="
                  width: 20px;
                  height: 20px;
                  accent-color: var(--mainColor);
                  margin-right: 10px;
                "
              />
              <label
                style="
                  display: inline-block;
                  padding: 5px 10px;
                  border-radius: 15px;
                  background-color: var(--mainColor);
                  color: white;
                  cursor: pointer;
                "
                class="custom-label-form-element"
                for="one"
                >Türkçe</label
              >
            </v-col>
            <v-col cols="6" class="d-flex">
              <input
                type="radio"
                id="one"
                v-model="selectedLanguage"
                :value="1"
                style="
                  width: 20px;
                  height: 20px;
                  accent-color: var(--mainColor);
                  margin-right: 10px;
                "
              />
              <label
                style="
                  display: inline-block;
                  padding: 5px 10px;
                  border-radius: 15px;

                  background-color: var(--mainColor);
                  color: white;
                  cursor: pointer;
                "
                class="custom-label-form-element"
                for="one"
                >English</label
              >
            </v-col>
          </v-row>
          <ValidationObserver ref="observer">
            <v-form ref="dictForm" @submit.prevent="submitForm">
              <v-row>
                <FormElement
                  v-model="editedTerm.Term"
                  :validationLabel="$t('labels.Term')"
                  :rules="{ required: true }"
                  :formLabel="$t('labels.Term')"
                  :formType="{
                    name: 'text',
                  }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="12"
                  :placeholder="$t('labels.Term')"
                />
                <FormElement
                  v-model="editedTerm.Definition"
                  :validationLabel="$t('labels.Meaning')"
                  :rules="{ required: true }"
                  :formLabel="$t('labels.Meaning')"
                  :formType="{ name: 'textarea' }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="12"
                  :placeholder="$t('labels.Meaning')"
                />
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>

        <v-card-actions
          class="justify-center"
          style="background-color: white; position: sticky; bottom: 0px"
        >
          <v-btn
            style="
              color: #fff;
              text-transform: unset !important;
              background-color: var(--red);
              padding: 1vh 1vh;
              border-radius: 30px !important;
              font-weight: bolder;
              font-size: medium;
            "
            elevation="0"
            class="darken-1"
            type="submit"
            @click="submitForm"
          >
            {{ editMode ? $t('buttons.save') : $t('buttons.add') }}
          </v-btn>
          <v-btn
            style="
              color: #fff;
              text-transform: unset !important;
              background-color: #0077d5;
              padding: 1vh 1vh;
              border-radius: 30px !important;
              font-weight: bolder;
              font-size: medium;
            "
            elevation="0"
            class="darken-1"
            @click="closeEditDialog"
          >
            {{ $t('buttons.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import DictionaryService from '../services/DictionaryService';
import FormElement from '../components/FormElement.vue';
export default {
  data() {
    return {
      glossaryOfTerms: [],
      search: '',
      editDialog: false,
      editMode: false,
      editedTerm: {
        Term: '',
        Definition: '',
      },
      activePanel: -1,
      selectedLanguage: 0,
      debounceTimeout: null,
      totalItems: null,
      rowsPerPage: 15,
      page: 1,
      options: {
        itemsPerPage: 15,
      },
      perPageValues: [
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
        {
          name: this.$t('labels.All'),
          value: 9999,
        },
      ],
    };
  },
  components: {
    FormElement,
  },
  watch: {
    drawer(newValue) {
      if (!newValue) {
        this.panel = -1;
      }
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters.getUserType === 'Admin';
    },
    pages() {
      console.log('totalItems', this.totalItems);
      console.log('rowsPerPage', this.rowsPerPage);
      if (this.rowsPerPage == null || this.totalItems == null) {
        return 0;
      }
      return Math.ceil(this.totalItems / this.rowsPerPage);
    },
  },
  mounted() {
    this.getGlossaryOfTerms();
  },
  methods: {
    getGlossaryOfTerms() {
      this.$store.state.loadingStatus = true;
      DictionaryService.getGlossaryOfTerms(this.page, this.rowsPerPage)
        .then((response) => {
          this.glossaryOfTerms = response.data.Result;
          this.totalItems = response.data.TotalItems;
        })
        .catch((error) => {
          console.error('Error getting glossary of terms', error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    async addTerm(term) {
      this.$store.state.loadingStatus = true;
      try {
        await DictionaryService.addTerm(term);
        this.getGlossaryOfTerms();
      } catch (error) {
        console.error('Error adding term', error);
      } finally {
        this.$store.state.loadingStatus = false;
      }
    },

    async updateTerm(term) {
      this.$store.state.loadingStatus = true;
      try {
        await DictionaryService.updateTerm(term);
        this.getGlossaryOfTerms();
      } catch (error) {
        console.error('Error updating term', error);
      } finally {
        this.$store.state.loadingStatus = false;
      }
    },

    async deleteTerm(id) {
      this.$store.state.loadingStatus = true;
      try {
        await DictionaryService.deleteTerm(id);
        this.getGlossaryOfTerms();
      } catch (error) {
        console.error('Error deleting term', error);
      } finally {
        this.$store.state.loadingStatus = false;
      }
    },
    openAddDialog() {
      this.editMode = false;
      this.selectedLanguage = 0;
      this.editedTerm = {
        Term: '',
        Definition: '',
      };
      this.editDialog = true;
    },

    openEditDialog() {
      this.editMode = true;
      this.selectedLanguage = 0;
      this.editedTerm = {
        Id: null,
        Question: '',
        Answer: '',
      };

      this.editDialog = true;
    },

    editTerm(term) {
      this.editMode = true;
      this.editedTerm = { ...term };
      this.editDialog = true;
    },

    closeEditDialog() {
      this.selectedLanguage = 0;
      this.editedTerm = {
        Term: '',
        Definition: '',
      };
      this.$refs.observer.reset();
      this.editDialog = false;
    },

    getSearchDebounced() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.rowsPerPage = 15;
        this.page = 1;
        this.getSearch();
      }, 300);
    },

    getSearch() {
      this.$store.state.loadingStatus = true;

      DictionaryService.search(this.search, this.page, this.rowsPerPage)
        .then((response) => {
          this.glossaryOfTerms = response.data.Result;
          this.totalItems = response.data.TotalItems;
        })
        .catch((error) => {
          console.error('Error searching term', error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    async submitForm() {
      const { Id, Term, Definition } = this.editedTerm;

      if (this.editMode) {
        await this.updateTerm({ Id, Term, Definition });
      } else {
        await this.addTerm({
          Term,
          Definition,
          Language: this.selectedLanguage,
        });
      }

      this.closeEditDialog();
    },
    changeItemsPerPage() {
      this.page = 1;
      this.options.itemsPerPage = this.rowsPerPage;
      this.getGlossaryOfTerms();
    },
    paginationChangeHandler(pageNumber) {
      this.page = pageNumber;
      this.getGlossaryOfTerms();
    },
  },
};
</script>
<style scoped>
.v-text-field input {
  font-size: 1.2em;
}
</style>
