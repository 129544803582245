<template>
  <div>
    <!-- Page content -->
    <v-card style="border-radius: 10px">
      <div class="flex w-full rounded-tl-sm rounded-tr-sm">
        <v-card-title style="color: var(--darkBlue)">
          <v-row>
            <v-col class="text-lg-left text-center" lg="5" cols="12">
              <h3>
                {{ $t('labels.welcomeText') + this.$store.state.FullName }}
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
      </div>
      <v-row v-if="showCards" class="pt-4 px-5">
        <v-col
          v-for="card in filteredCards"
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          :key="card.id"
          class="d-flex"
          style="padding: 0"
        >
          <div
            class="d-flex border w-full"
            style="
              width: 100%;
              border-width: 1px;
              border-color: #f0f0f4;
              border-style: solid;
              border-radius: 20px;
              cursor: pointer;
              margin-right: 5px;
              margin-bottom: 5px;
            "
            @click="navigateToRoute(card.direct)"
          >
            <div class="d-flex py-4">
              <div
                :style="{
                  alignSelf: 'center',
                  backgroundColor: card.color,
                  borderRadius: '100%',
                }"
                class="px-4 py-4 mx-2"
              >
                <v-icon large :color="card.iconColor">
                  {{ card.icon }}
                </v-icon>
              </div>
              <div class="d-flex flex-column justify-center">
                <div class="text-header-style">
                  {{ $t(card.title) }}
                </div>
                <div v-if="card.newCount > 0" class="text-style">
                  {{ $t(card.message, { value: card.newCount }) }}
                </div>
                <div v-else class="text-style">
                  {{ $t(card.message2) }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" max-width="600" persistent>
        <v-card class="popup-card" elevation="1">
          <v-card-title class="popupHeadline" style="font-size: 24px">
            <div class="text-center popupHeadline" style="width: 100%">
              {{ $t('labels.changePassword') }}
            </div>
          </v-card-title>

          <v-divider class="mb-4" />

          <v-card-text>
            <ValidationObserver ref="observer">
              <v-row>
                <FormElement
                  v-for="element in formItems"
                  :key="element.key.parent + '_' + element.key.child"
                  :vertical="element.vertical"
                  v-model="$data[element.key.parent][element.key.child]"
                  :validationLabel="$t(element.validationLabel)"
                  :rules="element.rules"
                  :onChange="element.onChange"
                  :formType="element.formType"
                  :lg="element.lg"
                  :cols="element.cols"
                  :placeholder="$t(element.placeholder)"
                />
              </v-row>
            </ValidationObserver>
          </v-card-text>

          <v-card-actions class="d-flex justify-center">
            <v-btn
              variant="tonal"
              class="rounded-lg"
              style="
                color: white;
                text-transform: none;
                font-size: large;
                font-weight: bold;
                padding: 1em;
              "
              color="#0077d5"
              @click="changePassword"
            >
              {{ $t('buttons.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import UserService from '../services/UserService';
import DashboardService from '../services/DashboardService';
import ReportService from '../services/ReportService';
import FormElement from '../components/FormElement.vue';

import { mapActions } from 'vuex';
export default {
  data: () => ({
    dialog: false,
    body: {},
    showPassword: false,
    showConfirmPassword: false,
    showNewPassword: false,
    showCards: true,
    generalInformation: {},
    cards: [],
    adminCards: null,
    // Change password Model
    item: {
      Password: '',
      PasswordConfirm: '',
    },
  }),
  components: { FormElement },
  computed: {
    formItems() {
      return [
        {
          key: {
            parent: 'item',
            child: 'Password',
          },
          rules: 'required|max:24',
          rules: {
            required: true,
            max: 24,
            min: 6,
            regex:
              /^(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
          },
          validationLabel: 'labels.password',
          formLabel: 'labels.password',
          formType: {
            name: this.showPassword ? 'seePassword' : 'password',
            counter: '24',
            appendIcon: this.showPassword ? 'mdi-eye' : 'mdi-eye-off',
            appendClick: () => {
              this.showPassword = !this.showPassword;
            },
          },
          lg: 6,
          placeholder: 'labels.password',
        },
        {
          key: {
            parent: 'item',
            child: 'PasswordConfirm',
          },
          rules:
            'required|max:24|confirmedPassword:' + this.$t('labels.password'),
          validationLabel: 'labels.userPasswordAgain',
          formLabel: 'labels.userPasswordAgain',
          formType: {
            name: this.showConfirmPassword ? 'text' : 're-password',
            appendIcon: this.showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off',
            appendClick: () => {
              this.showConfirmPassword = !this.showConfirmPassword;
            },
          },
          lg: 6,
          placeholder: 'labels.userPasswordAgain',
        },
      ];
    },
    filteredCards() {
      return this.cards.filter((card) => {
        if (this.$store.state.Type === 'Admin') {
          return card.id === 5 || card.id === 6;
        } else if (
          this.userRole == 'Supplier' ||
          this.userRole == 'PendingSupplier'
        ) {
          return card.id === 0 || card.id === 1 || card.id === 3;
        } else if (
          this.userRole == 'Customer' ||
          this.userRole == 'PendingCustomer'
        ) {
          return card.id === 0 || card.id === 2 || card.id === 4;
        }
      });
    },
  },

  mounted() {
    this.userRole = this.$store.state.Type;
    this.dialog = this.$store.state.IsChangePassword == true ? false : true;
    if (this.userRole == 'Admin') {
      this.getAdminCards();
    } else {
      DashboardService.getInfo().then((res) => {
        this.generalInformation = res.data;

        this.cards = [
          {
            // Hem tedarikçi hem müşteri için mesajlar
            id: 0,
            title: 'title.messages',
            message: 'labels.newMessage',
            message2: 'labels.noNewMessage',
            newCount: this.generalInformation.Messages,
            color: '#E3FBFF',
            direct: 'messages',
            icon: 'mdi-message-badge-outline',
            iconColor: '#44AFC1',
          },
          {
            // Sadece Tedarikçi için yeni teklif sayısı
            id: 1,
            title: 'labels.spotOffers',
            message: 'labels.spotOrderMessage',
            message2: 'labels.noNewSpotOrder',
            newCount: this.generalInformation.NewShippings,
            color: '#F2F4FF',
            direct: 'spotPriceRequestsSupplier',
            icon: 'mdi-truck-cargo-container',
            iconColor: '#4962E2',
          },
          {
            // Sadece Müşteri için yeni teklif sayısı
            id: 2,
            title: 'labels.newOffer',
            message: 'labels.newOfferMessage',
            message2: 'labels.noNewOffer',
            newCount: this.generalInformation.NewOffers,
            color: '#F2F4FF',
            direct: 'spotOffers',
            icon: 'mdi-email-open-outline',
            iconColor: '#4962E2',
          },
          {
            // Sadece Tedarikçi için ödeme onayı bekleyen sipariş sayısı
            id: 3,
            title: 'labels.ordersWaitingApproval',
            message: 'labels.ordersWaitingApprovalMessage',
            message2: 'labels.noNewApproval',

            newCount: this.generalInformation.PaymentAccepts,
            color: '#FEF2E0',
            direct: 'payments',
            icon: 'mdi-check-decagram-outline',
            iconColor: '#F49827',
          },
          {
            // Sadece Müşteri için ödeme bekleyen sipariş sayısı
            id: 4,
            title: 'labels.ordersWaitingPayment',
            message: 'labels.ordersWaitingPaymentMessage',
            message2: 'labels.noNewPending',
            newCount: this.generalInformation.Payments,
            color: '#FEF2E0',
            direct: 'payments',
            icon: 'mdi-credit-card-outline',
            iconColor: '#F49827',
          },
        ];
      });
    }
  },

  methods: {
    getAdminCards() {
      this.$store.state.loadingStatus = true;
      ReportService.GeneralSummaryReport().then((res) => {
        this.adminCards = res.data;
        this.cards = [
          {
            // Sadece Admin için onay bekleyen şirket sayısı
            id: 5,
            title: 'labels.PendingApprovalCompanyCount',
            message: 'labels.PendingApprovalCompanyCountMessage',
            message2: 'labels.noNewPendingApprovalCompanyCount',
            newCount: this.adminCards.PendingApprovalCompanyCount,
            color: '#E3FBFF',
            direct: 'companies',
            icon: 'mdi-domain',
            iconColor: '#44AFC1',
          },
          {
            // Sadece Admin için çözülmeyen talep sayısı
            id: 6,
            title: 'labels.UnSolvedDemandCount',
            message: 'labels.UnSolvedDemandCountMessage',
            message2: 'labels.noNewUnSolvedDemandCount',
            newCount: this.adminCards.UnSolvedDemandCount,
            color: '#F2F4FF',
            direct: 'support',
            icon: 'mdi-ticket-confirmation-outline',
            iconColor: '#4962E2',
          },
        ];
        this.showCards = this.$store.state.CompanyId ? true : false;
        this.$store.state.loadingStatus = false;
      });
    },
    navigateToRoute(routeName) {
      this.$router.push({
        name: routeName,
      });
    },
    changePassword() {
      this.$store.state.loadingStatus = true;
      this.item.UserId = this.$store.state.Id;
      console.log('ITEM >> ', this.item);
      UserService.updatePasswordWithOld(this.item)
        .then((res) => {
          this.$store.state.loadingStatus = false;
          this.dialog = false;
          this.updateChangePassword(true);
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    ...mapActions(['updateChangePassword']),
  },
};
</script>

<style>
.text-header-style {
  @media only screen and (min-width: 600px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 1281px) {
    font-size: 20px;
  }
  font-weight: bold;
}
.text-style {
  @media only screen and (min-width: 600px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 1281px) {
    font-size: 16px;
  }
}
</style>
