var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-title',{staticStyle:{"color":"var(--darkBlue)"}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"text-lg-left text-center pb-0 pb-sm-2",attrs:{"cols":"12","sm":"12","md":"9","lg":"9","xl":"9"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('navigationDrawerMenu.plannerManagement')))])]),_c('v-col',{staticClass:"d-flex align-center justify-end pb-0 pb-sm-2",attrs:{"cols":"12","sm":"12","md":"3","lg":"3","xl":"3"}},[(_vm.searchCustomer || _vm.searchTrackingNo || _vm.searchRefNo)?_c('v-btn',{staticClass:"button mr-1",staticStyle:{"color":"white","border-radius":"14px !important"},attrs:{"variant":"tonal","color":"#0177d4"},on:{"click":_vm.resetFilter}},[_vm._v(" "+_vm._s(_vm.$t('buttons.clearFilter'))+" ")]):_vm._e(),_c('RefreshButton',{attrs:{"refreshFunction":_vm.getPlannerList}})],1)],1)],1),_c('v-divider'),_c('v-card-text',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"d-flex mb-5",attrs:{"align":"center","justify":"start"}},[_c('v-col',{staticClass:"align-center",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"align-center pt-3 justify-space-between"},[_c('v-col',[_c('v-row',{staticClass:"align-center pt-3"},[_c('FormElement',{attrs:{"validationLabel":_vm.$t('labels.cargoTrackingNumber'),"formLabel":_vm.$t('labels.cargoTrackingNumber'),"formType":{
                    name: 'text',
                    dense: true,
                    appendIcon: 'mdi-magnify',
                    appendClick: () => {
                      _vm.getSearchDebounced();
                    },
                  },"labelShown":false,"cols":6,"sm":6,"md":3,"lg":3,"xl":3,"topLabel":true,"hideDetails":true,"placeholder":_vm.$t('labels.cargoTrackingNumber')},on:{"input":_vm.getSearchDebounced},model:{value:(_vm.searchTrackingNo),callback:function ($$v) {_vm.searchTrackingNo=$$v},expression:"searchTrackingNo"}}),_c('FormElement',{attrs:{"validationLabel":_vm.$t('labels.referenceNumber'),"formLabel":_vm.$t('labels.referenceNumber'),"formType":{
                    name: 'text',
                    dense: true,
                    appendIcon: 'mdi-magnify',
                    appendClick: () => {
                      _vm.getSearchDebounced();
                    },
                  },"labelShown":false,"cols":6,"sm":6,"md":3,"lg":3,"xl":3,"topLabel":true,"hideDetails":true,"placeholder":_vm.$t('labels.referenceNumber')},on:{"input":_vm.getSearchDebounced},model:{value:(_vm.searchRefNo),callback:function ($$v) {_vm.searchRefNo=$$v},expression:"searchRefNo"}}),_c('FormElement',{attrs:{"validationLabel":_vm.$t('labels.customer'),"formLabel":_vm.$t('labels.customer'),"formType":{
                    name: 'text',
                    dense: true,
                    appendIcon: 'mdi-magnify',
                    appendClick: () => {
                      _vm.getSearchDebounced();
                    },
                  },"labelShown":false,"cols":6,"sm":6,"md":2,"lg":2,"xl":2,"topLabel":true,"hideDetails":true,"placeholder":_vm.$t('labels.customer')},on:{"input":_vm.getSearchDebounced},model:{value:(_vm.searchCustomer),callback:function ($$v) {_vm.searchCustomer=$$v},expression:"searchCustomer"}}),_c('v-col',{staticStyle:{"padding":"4px"},attrs:{"cols":6,"sm":6,"md":2,"lg":2,"xl":2}},[_c('v-autocomplete',{staticClass:"custom-border",attrs:{"value":_vm.planFlag,"items":_vm.planFilterList.concat({
                        Text: _vm.$t('labels.All'),
                        Value: null,
                      }),"label":_vm.$t('labels.status'),"item-text":"Text","item-value":"Value","dense":"","hide-details":"","variant":"solo","outlined":""},on:{"change":function($event){return _vm.getPlannerList()}},model:{value:(_vm.planFlag),callback:function ($$v) {_vm.planFlag=$$v},expression:"planFlag"}})],1),_c('v-col',{staticStyle:{"padding":"4px"},attrs:{"cols":6,"sm":6,"md":2,"lg":2,"xl":2}},[_c('v-autocomplete',{staticClass:"custom-border",attrs:{"value":_vm.orderStatus,"items":_vm.orderStatuses.concat({
                        Text: _vm.$t('labels.All'),
                        Value: null,
                      }),"label":_vm.$t('titles.orderStatus'),"item-text":"Text","item-value":"Value","dense":"","hide-details":"","variant":"solo","outlined":""},on:{"change":function($event){return _vm.getPlannerList()}},model:{value:(_vm.orderStatus),callback:function ($$v) {_vm.orderStatus=$$v},expression:"orderStatus"}})],1)],1)],1),(_vm.selectedPlans.length > 0)?_c('v-col',{staticClass:"d-flex justify-center justify-lg-end",attrs:{"cols":"12","sm":"12","md":"4","lg":"4","xl":"3"}},[_c('v-row',{staticClass:"align-center pt-0 pt-md-3"},[_c('FormElement',{attrs:{"validationLabel":_vm.$t('labels.status'),"formLabel":_vm.$t('labels.status'),"formType":{
                    name: 'select',
                    dense: true,
                    items: {
                      data: [
                        {
                          Name: _vm.$t('labels.planned'),
                          Value: true,
                        },
                        {
                          Name: _vm.$t('labels.notPlanned'),
                          Value: false,
                        },
                      ],
                      value: 'Value',
                      text: 'Name',
                    },
                  },"labelShown":false,"cols":6,"sm":6,"md":6,"lg":6,"xl":6,"topLabel":true,"hideDetails":true,"placeholder":_vm.$t('labels.status')},model:{value:(_vm.planStatus),callback:function ($$v) {_vm.planStatus=$$v},expression:"planStatus"}}),_c('v-col',{staticStyle:{"padding":"4px"},attrs:{"cols":6,"sm":6,"md":6,"lg":6,"xl":6}},[_c('v-btn',{staticClass:"button mr-1",staticStyle:{"color":"white","border-radius":"14px !important","width":"100%"},attrs:{"variant":"tonal","color":"#0177d4"},on:{"click":_vm.planSelecteds}},[_vm._v(" "+_vm._s(_vm.$t('buttons.planSelecteds'))+" ")])],1)],1)],1):_vm._e()],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.planners,"options":_vm.options,"hide-default-footer":true,"footer-props":{
          disablePagination: true,
          disableItemsPerPage: true,
        },"loading":_vm.$store.state.loadingStatus,"no-data-text":_vm.$t('messages.noData'),"item-key":'Id'},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Checkbox",fn:function({ item, select, isSelected }){return [_c('v-checkbox',{attrs:{"large":"","color":"green","off-icon":'mdi-checkbox-blank-outline',"on-icon":'mdi-checkbox-marked-outline'},on:{"change":function($event){return _vm.updateSelectedPlans(item)}}})]}},{key:"item.PhotoUrl",fn:function({ item }){return [_c('img',{staticStyle:{"width":"50px","height":"50px","object-fit":"contain"},attrs:{"src":item.PhotoUrl,"alt":item.Title}})]}},{key:"item.CreatedOnUtc",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.convertToLocal(item.CreatedOnUtc))+" ")]}},{key:"item.WatedLoadingDate",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.convertToLocal(item.WatedLoadingDate))+" ")]}},{key:"item.LoadingAddress",fn:function({ item }){return [_vm._v(" "+_vm._s(item.LoadingAddress + ' ' + item.Country + '/' + item.City)+" ")]}},{key:"item.Planned",fn:function({ item }){return [(
              !item.Planned && new Date(item.WatedLoadingDate) < new Date()
            )?_c('v-chip',{staticClass:"ma-2",staticStyle:{"font-weight":"600"},attrs:{"small":"","color":"red","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t('labels.notPlannedLabel'))+" ")]):_vm._e(),(
              !item.Planned && new Date(item.WatedLoadingDate) >= new Date()
            )?_c('v-chip',{staticClass:"ma-2",staticStyle:{"font-weight":"600"},attrs:{"small":"","color":"#FA6400","text-color":"#2b3838"}},[_vm._v(" "+_vm._s(_vm.$t('labels.notPlannedLabel'))+" ")]):_vm._e(),(
              item.Planned &&
              (item.EntranceToWarehouseDate == null ||
                new Date(item.EntranceToWarehouseDate) > new Date())
            )?_c('v-chip',{staticClass:"ma-2",staticStyle:{"font-weight":"600"},attrs:{"small":"","color":"#0177d4","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t('labels.plannedLabel'))+" ")]):_vm._e(),(
              item.EntranceToWarehouseDate &&
              item.Planned &&
              new Date(item.EntranceToWarehouseDate) <= new Date()
            )?_c('v-chip',{staticClass:"ma-2",staticStyle:{"font-weight":"600"},attrs:{"small":"","color":"#c5f4db","text-color":"#2b3838"}},[_vm._v(" "+_vm._s(_vm.$t('labels.plannedLabel'))+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedPlans),callback:function ($$v) {_vm.selectedPlans=$$v},expression:"selectedPlans"}}),_c('div',{staticClass:"text-xs-center pt-2 md4 lg4"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"ml-md-auto",attrs:{"md":"3"}},[_c('v-pagination',{attrs:{"value":_vm.page,"length":_vm.pages,"total-visible":10,"circle":""},on:{"input":_vm.paginationChangeHandler}})],1),_c('v-col',{staticClass:"ml-md-auto",attrs:{"md":"2"}},[_c('v-select',{attrs:{"items":_vm.perPageValues,"label":_vm.$t('labels.selectItemsPerPage'),"item-value":"value","item-text":"name","outlined":"","dense":"","rounded":""},on:{"change":function($event){return _vm.changeItemsPerPage()}},model:{value:(_vm.rowsPerPage),callback:function ($$v) {_vm.rowsPerPage=$$v},expression:"rowsPerPage"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }