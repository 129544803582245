<template>
  <v-card
    elevation="1"
    class="mt-0 py-lg-10"
    style="margin: 0 !important; padding-right: 4%; padding-left: 4%"
  >
    <v-card-title style="color: var(--turquoise)">
      <v-row>
        <v-col
          class="d-flex align-center justify-space-between text-lg-left text-center"
          lg="12"
          cols="12"
        >
          <h5>{{ $t('titles.paymentSummary') }}</h5>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <v-card-text style="background-color: #f5f5f5">
      <v-row class="py-10 justify-center">
        <v-col cols="8" class="d-flex flex-column align-center">
          <h2 style="color: var(--pink)" class="mb-4">
            {{
              $t('titles.total') +
              ': ' +
              totalPrice +
              ' ' +
              currencyType +
              '(' +
              $t('labels.including', { value: $t('labels.tax.plural') }) +
              ')'
            }}
          </h2>

          <v-btn @click="completePayment" class="gradient-button button" dark>
            <v-icon color="white" left>mdi-credit-card</v-icon>
            {{ $t('buttons.completePayment') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
  </v-card>
</template>
<script>
import PaymentServices from '../services/PaymentServices';
import formatDate from '../utils/formatDate';

export default {
  data() {
    return {
      spotShippingId: '',
      activeBody: {},
      payments: [],

      currencyType: 'TL',

      // States
      pageLoading: false,
      isSupplier: true,
      openedWindow: null,
    };
  },
  async mounted() {
    this.payments = this.$route.params.payments || [];

    if (this.payments.length === 0) {
      this.$router.go(-1);
    }

    if (this.$route.params.currencyType) {
      this.currencyType = this.$route.params.currencyType;
    }

    this.isSupplier = this.$store.state.Type === 'Supplier';
  },
  computed: {
    orderList() {
      return this.payments.flatMap((item) => item.Id);
    },
    totalPrice() {
      const payments = this.payments;
      if (payments?.length > 1) {
        return payments.length > 1
          ? this.payments.reduce(
              (previous, next) =>
                (previous.PriceAsNumber || 0) + next.PriceAsNumber
            )
          : payments.length === 1
          ? payments[0].PriceAsNumber
          : 0;
      }
    },
  },
  methods: {
    /**
     * Complete the current payment.
     */
    completePayment() {
      this.$store.state.loadingStatus = true;
      PaymentServices.completePayment(this.orderList)
        .then((response) => {
          const url = response.data.URL;

          const opened = window.open(`${url}`, 'Popup', 'width=600,height=400');

          var timer = setInterval(function () {
            if (opened.closed) {
              window.setLoading(false);
              clearInterval(timer);
            }
          }, 1000);
        })
        .catch((error) => this.onServiceError(error));
    },

    async onFinally() {
      this.$store.state.loadingStatus = false;
    },

    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(
      date,
      splitter = '/',
      format = [{ day: '2-digit' }, { month: '2-digit' }, { year: 'numeric' }]
    ) {
      return formatDate(date, splitter, 'tr', format);
    },

    /**
     * This will trigger a function on service error.
     * @param {Function} special
     * @param {*} error
     */
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },

    /**
     * Reset all models in View.
     */
    resetAllModels() {
      this.resetForm();
    },
  },
};
</script>
<style></style>
