import axios from '../javascript/custom-axios'
const RESOURCE_NAME = 'productMarket'

export default {
  
  addProductToMarketplace(body){
    return axios.post(`${RESOURCE_NAME}/add`,body)
 
  },
  updateProductToMarketplace(body){
    return axios.put(`${RESOURCE_NAME}/update`,body)
 
  },
  checkProductMarketplaces(productId,marketplaceId) {
    return axios.get(`${RESOURCE_NAME}/get`, {params:{ProductId:productId,MarketplaceId:marketplaceId}});
  },
  getByProductId(productId) {
    return axios.get(`${RESOURCE_NAME}/get`, {params:{ProductId:productId}});
  }
}