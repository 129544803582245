var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('NavMenu',{attrs:{"fromLogin":true}}),_c('v-layout',{attrs:{"wrap":"","align-center":"","justify-center":"","fill-height":""}},[_c('v-container',{staticStyle:{"padding-top":"10vh"}},[_c('v-layout',{attrs:{"wrap":"","align-center":"","justify-center":"","fill-height":""}},[_c('v-card',{staticStyle:{"padding":"2vh","border-radius":"20px"},style:({
            maxWidth: _vm.$vuetify.breakpoint.xl
              ? '75%'
              : _vm.$vuetify.breakpoint.lg
              ? '80%'
              : _vm.$vuetify.breakpoint.md
              ? '85%'
              : _vm.$vuetify.breakpoint.sm
              ? '80%'
              : _vm.$vuetify.breakpoint.xs
              ? '95%'
              : '100%',
          })},[_c('v-card-title',[_c('h1',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.title))])]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.content)}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }