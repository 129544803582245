// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'payment';

export default {
  /**
   * Complete payment for order list.
   * @param {array} orderList
   * @returns {Promise<any>}
   */
  completePayment(orderList) {
    // TODO: Servisler tamamlandığın URL'in geldiği servis eklenecek.
    return new Promise((resolve, reject) => {
      resolve({
        data: {
          URL: 'http://185.149.103.52:1202/',
        },
      });
    });
    return axios.post(`${RESOURCE_NAME}/completePayment`, orderList);
  },
};
