import axios from "../javascript/custom-axios";
let RESOURCE_NAME = "invoice";

export default {
  /**
   * Get By Id.
   * @param {string} id
   * @returns {Promise<any>}
   */
  getById(id) {
    return axios.get(`${RESOURCE_NAME}/getById?Id=${id}`);
  },

  /**
   * Get invoice list.
   * @param {*} body
   * @returns {Promise<any>}
   */
  getAll(body) {
    return axios.get(`${RESOURCE_NAME}/getAll`, {
      params: body,
    });
  },

  /**
   * Create an invoice.
   * @param {*} body
   * @returns {Promise<any>}
   */
  create(body) {
    return axios.post(`${RESOURCE_NAME}/create`, body);
  },

  /**
   * Update an invoice.
   * @param {*} body
   * @returns {Promise<any>}
   */
  update(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },

  /**
   * Delete an invoice.
   * @param {string} id
   * @returns {Promise<any>}
   */
  delete(id) {
    return axios.delete(`${RESOURCE_NAME}/delete?Id=${id}`);
  },
};
