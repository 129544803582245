import axios from 'axios';

export default {
  updateUserToken(state, token) {
    state.Token = token.access;
    state.RefreshToken = token.refresh;
    axios.defaults.headers.common['Authorization'] = token.access;
  },
  updateUserId(state, id) {
    state.Id = id;
  },
  updateUserType(state, type) {
    state.Type = type;
  },
  updateChangePassword(state, value) {
    state.IsChangePassword = value;
  },
  updatePortalUserId(state, id) {
    state.PortalUserId = id;
  },
  updateIsPrevAdmin(state, value) {
    state.isPrevAdmin = value;
  },
  updateIsPackagePopupVisible(state, value) {
    state.IsPackagePopupVisible = value;
  },
  updateUserInfo(state, data) {
    state.Avatar = data.Avatar;
    state.Email = data.Email;
    state.FirstName = data.Name;
    state.FullName = data.Name + ' ' + data.Surname;
    state.Id = data.UserId;
    state.IsBlocked = data.IsBlocked;
    state.LastName = data.Surname;
    state.Type = data.Type;
    state.Username = 'Nothing';
    state.CompanyId = data.CompanyId;
    state.GroupPermessionIds = data.GroupPermessionIds;
    state.CompanyName = data.CompanyName;
    state.IsChangePassword = data.IsChangePassword;
    state.IsPackagePopupVisible = data.IsPackagePopupVisible;
    state.userBlacklist = data.Modules;
    state.PhotoUrl = data.PhotoUrl;
  },
  authLogout(state) {
    state.Token = '';
    state.RefreshToken = '';
    state.Avatar = '';
    state.Email = '';
    state.FirstName = '';
    state.FullName = '';
    state.Id = '';
    state.IsBlocked = '';
    state.LastName = '';
    state.Type = '';
    state.Username = '';
    state.PortalUserId = null;
    state.companies = [];
    state.isPrevAdmin = false;
    state.userBlacklist = [];
    state.PhotoUrl = '';
    axios.defaults.headers.common['Authorization'] = '';
    state.router.push({ name: 'createOffer' });
  },

  /**
   * Update companies' data in store.
   * @param {*} state
   * @param {*} data
   */
  updateCompanies(state, data) {
    state.companies = data.Result;
  },

  /**
   * Update unseen counts.
   * @param {*} state
   * @param {*} data
   */
  updateUnseenCounts(state, data) {
    state.unseenCounts = data;
  },

  setMenuItems(state, data) {
    state.menuItems = data;
  },
};
