var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticStyle:{"border-radius":"10px"}},[_c('v-card-title',{staticClass:"popupHeadline"},[_c('v-row',[_c('v-col',{staticClass:"py-2"},[_vm._v(_vm._s(_vm.$t('title.stockManagement')))])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"item-key":"Id","options":_vm.options,"hide-default-footer":true,"footer-props":{
          disablePagination: true,
          disableItemsPerPage: true,
        },"no-data-text":_vm.$t('description.noList')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.PhotoUrl",fn:function({ item }){return [_c('v-avatar',{attrs:{"size":"90"}},[_c('v-img',{attrs:{"lazy-src":item.ProductPhotos != null
                  ? item.ProductPhotos[0].FilePath
                  : 'https://img.freepik.com/premium-vector/furniture-logo-icon-vector-flat-design_586739-2914.jpg',"max-height":"200","max-width":"200","src":item.ProductPhotos != null
                  ? item.ProductPhotos[0].FilePath
                  : 'https://img.freepik.com/premium-vector/furniture-logo-icon-vector-flat-design_586739-2914.jpg'}})],1)]}},{key:"item.Status",fn:function({ item }){return [(
              item.CriticalStockCount > item.StockCount &&
              item.StockCount != 0
            )?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"#F57C00","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t('labels.criticalStock'))+" ")]):_vm._e(),(item.CriticalStockCount <= item.StockCount)?_c('v-chip',{staticClass:"ma-2 text--center",attrs:{"small":"","color":"#a7fdba","text-color":"black"}},[_vm._v(" "+_vm._s(_vm.$t('labels.hasStock'))+" ")]):_vm._e(),(item.StockCount == 0)?_c('v-chip',{staticClass:"ma-2 text--center",attrs:{"small":"","color":"#B71C1C","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t('labels.hasNotStock'))+" ")]):_vm._e()]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 green-icon",attrs:{"medium":""},on:{"click":function($event){return _vm.goDetail(item.Id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-edit ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('buttons.addStock')))])])]}}])}),_c('div',{staticClass:"text-xs-center pt-2 md4 lg4"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"ml-md-auto",attrs:{"md":"3"}},[_c('v-pagination',{attrs:{"value":_vm.pagination.page,"length":_vm.pages,"total-visible":10,"circle":""},on:{"input":_vm.paginationChangeHandler}})],1),_c('v-col',{staticClass:"ml-md-auto",attrs:{"md":"2"}},[_c('v-select',{attrs:{"items":_vm.perPageValues,"label":_vm.$t('labels.selectItemsPerPage'),"item-value":"value","item-text":"name","outlined":"","dense":"","rounded":""},on:{"change":function($event){return _vm.changeItemsPerPage()}},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }