<template>
  <div>
    <v-dialog
      persistent
      v-model="categoryDeatilStatus"
      max-width="500"
      ref="categoryModal"
    >
      <v-card class="popup-card" v-if="!isDelete" elevation="1">
        <v-card-title class="">
          <div
            class="popupHeadline"
            style="width: 100%; color: #fb3d6b !important"
          >
            {{ this.modalHeader }}
          </div>
        </v-card-title>
        <v-card-subtitle>
          <div
            class="popupHeadline"
            style="width: 100%; color: #393a3b !important"
          >
            {{ this.modelSubTitle }}
          </div>
        </v-card-subtitle>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm(false))">
            <v-list-item-subtitle
              class="px-10"
              style="color: #fb3d6b !important"
            >
              {{ $t('description.deletedCategory') }}
            </v-list-item-subtitle>
            <v-list-item-title class="px-10" style="color: #727375">
              {{ changedCategory.CategoryName }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="pt-3 px-10"
              style="color: #69c6d1 !important"
            >
              {{ $t('title.newCategory') }}
            </v-list-item-subtitle>
            <v-row
              align="center"
              justify="start"
              class="px-10"
              style="margin: auto"
            >
              <!-- Brand creator select start -->
              <v-col cols="15" style="padding: 0%">
                <v-select
                  class="rounded-lg"
                  v-model="changedCategoryModal"
                  :value="changedCategoryModal"
                  :items="selectableCategories"
                  item-text="CategoryName"
                  item-value="Id"
                  :label="$t('labels.category')"
                  outlined
                  rounded
                  dense
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-card-text class="pt-3 px-10">
              <ValidationProvider
                :name="$t('labels.brandName')"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="brandModel.Brand"
                  :value="brandModel.Brand"
                  :label="$t('labels.brandName')"
                  :error-messages="errors[0]"
                  @keypress.enter.prevent
                  @keydown.space.prevent
                  v-on:keypress="isLetter($event)"
                ></v-text-field>
              </ValidationProvider>
              <v-card-text class="py-1"> </v-card-text>
            </v-card-text>
            <v-card-actions
              class="d-flex justify-center rounded-lg pt-3 px-10 card-action"
            >
              <v-btn
                class="btn-save rounded-lg"
                type="submit"
                :loading="loadings.send"
              >
                {{ $t('buttons.save') }}
              </v-btn>

              <v-btn
                class="btn-cancel rounded-lg"
                @click="
                  categoryDeatilStatus = false;
                  clearModal();
                "
              >
                {{ $t('buttons.cancel') }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
      <v-card v-if="isDelete">
        <v-card-title class="">
          <div class="popupHeadline" style="width: 100%">
            {{ this.modalHeader }}
          </div>
        </v-card-title>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm(true))">
            <v-card-text class="pt-10 px-10">
              <ValidationProvider
                :name="$t('labels.brandName')"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="inspectedModel.CategoryName"
                  :value="inspectedModel.CategoryName"
                  :label="$t('labels.category')"
                  :error-messages="errors[0]"
                  @keypress.enter.prevent
                  @keydown.space.prevent
                  v-on:keypress="isLetter($event)"
                ></v-text-field>
              </ValidationProvider>
            </v-card-text>
            <v-card-actions
              class="d-flex justify-center rounded-lg"
              style="background-color: white; position: sticky; bottom: 5px"
            >
              <v-spacer></v-spacer>
              <v-btn
                class="white--text"
                width="50%"
                color="#5ed578"
                type="submit"
                :loading="loadings.send"
              >
                {{ $t('buttons.save') }}
              </v-btn>
              <v-btn
                class="white--text"
                width="50%"
                color="#bdabb2"
                @click="
                  categoryDeatilStatus = false;
                  clearModal();
                "
              >
                {{ $t('buttons.close') }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <!-- Content status modal -->
    <delete-modal
      :yesFunc="selectedContentFunc"
      :description="this.modalDescription"
      :title="this.modalTitle"
      ref="contentModalRef"
    />
    <!-- Page content -->
    <div v-if="!isCreate">
      <v-card style="border-radius: 10px">
        <v-card-title style="color: #0177d4">
          <v-row>
            <v-col class="py-2">{{ $t('title.categories') }}</v-col>
            <v-spacer />
            <div style="gap: 1rem">
              <span
                class="align-self-center mr-2"
                style="font-size: 12px; font-weight: normal; color: ##598db5"
                >{{ $t('labels.addNew') }}</span
              >
              <v-icon
                @click="
                  isCreate = true;
                  getCompanyMarketplaces();
                  modalHeader = $t('title.createBrand');
                "
                class="icon-button mr-4 align-self-center"
              >
                mdi-plus
              </v-icon>
            </div>
          </v-row>
        </v-card-title>

        <v-divider />

        <v-card-text class="pt-4">
          <v-row align="center" justify="start">
            <v-col cols="3">
              <v-text-field
                v-model="search"
                :label="$t('labels.search')"
                @click:append="searchTable"
                v-on:keyup.enter="searchTable"
                @click:clear="searchTable('clear')"
                clear-icon="mdi-close-circle"
                clearable
                append-icon="mdi-magnify"
                single-line
                hide-details
                outlined
                dense
                rounded-lg
                class="mb-5"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="tableData"
            :loading="loadings.table"
            :options.sync="options"
            :hide-default-footer="true"
            :footer-props="{
              disablePagination: true,
              disableItemsPerPage: true,
            }"
            :no-data-text="$t('description.noList')"
            ref="surveyList"
          >
            <!-- eslint-disable -->
            <template v-slot:item.Category="{ item }">
              {{ item.CategoryName }}
            </template>
            <!-- eslint-disable -->

            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    class="mr-2 green-icon"
                    @click="getCategoryDetails(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-file-edit
                  </v-icon>
                </template>
                <span>{{ $t('labels.tooltipEdit') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    class="mr-2 red-icon"
                    @click="openDeleteModal(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>{{ $t('labels.tooltipDelete') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 md4 lg4">
            <v-row align="center">
              <v-col md="3" class="ml-md-auto">
                <v-pagination
                  @input="paginationChangeHandler"
                  :value="pagination.page"
                  :length="pages"
                  :total-visible="10"
                  circle
                ></v-pagination>
              </v-col>
              <v-col md="2" class="ml-md-auto">
                <v-select
                  v-model="itemsPerPage"
                  :items="perPageValues"
                  :label="$t('labels.selectItemsPerPage')"
                  item-value="value"
                  item-text="name"
                  @change="changeItemsPerPage()"
                  outlined
                  dense
                  rounded
                >
                </v-select>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="isCreate">
      <v-card style="border-radius: 10px">
        <v-card-title style="color: #0177d4">
          <v-row>
            <v-col class="py-2">
              <v-icon
                large
                style="color: #0177d4 !important"
                id="menu-activator"
                @click="goBack2()"
              >
                mdi-arrow-left-bold
              </v-icon>
              {{ $t('title.categories') }}</v-col
            >
          </v-row>
        </v-card-title>

        <v-divider />
        <template>
          <form
            style="max-width: 50%"
            class="px-10"
            @submit.prevent="sendCategoryForm(isUpdate)"
          >
            <v-text-field
              v-model="addedCategoryName"
              label="Kategori Adı"
              required
            ></v-text-field>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t('labels.marketplace.title') }}
                  </th>
                  <th class="text-left">{{ $t('labels.category') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in this.companyMarkets" :key="index">
                  <td>{{ item.MarketplaceName }}</td>
                  <td>
                    <v-select
                      v-model="item.selectedItem"
                      :items="item.Categories"
                      item-text="CategoryName"
                      item-value="CategoryId"
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-btn
              style="margin-bottom: 20px; margin-top: 10px"
              class="btn-save rounded-lg"
              type="submit"
              :loading="loadings.send"
            >
              {{
                this.isUpdate == true ? $t('buttons.save') : $t('buttons.save')
              }}
            </v-btn>
          </form>
        </template>
      </v-card>
    </div>
  </div>
</template>

<script>
import CategoriesService from '../services/CategoriesService';
import DeleteModal from '../components/General/DeleteModal';
import { mapActions } from 'vuex';
export default {
  data: () => ({
    // List options
    options: {
      itemsPerPage: 15,
    },
    addedCategoryName: '',
    marketplaceIds: [],
    top: 15,
    isCreate: false,
    isUpdate: false,
    updatedCategoryId: '',
    skip: 0,
    tableData: [],
    selectableCategories: [],
    changedCategoryModal: '',
    headers: [],
    companyMarkets: [],
    //Loading states
    loadings: {
      table: false,
      select: false,
      send: false,
    },
    inspectedModel: {},
    selectedContentModel: {},
    changedCategory: '',
    isDelete: false,
    editItemId: null, //Inspected item ID
    modalHeader: '', //Global header for opened modal
    modelSubTitle: '',
    modalDescription: '',
    modalTitle: '',
    categoryDeatilStatus: false,
    search: '',
    pagination: {},
    itemsPerPage: 15,
    perPageValues: [
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],
  }),

  watch: {},
  mounted() {
    this.setup();
    this.getTableList();
    this.goback();
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
  },
  components: {
    DeleteModal,
  },

  methods: {
    goBack2() {
      this.isCreate = false;
      this.isUpdate = false;
      this.addedCategoryName = '';
    },
    goback() {
      window.onpopstate = (event) => {
        if (this.isCreate || this.isUpdate) {
          this.isCreate = false;
          this.isUpdate = false;
          this.$router.push({
            path: '/home/Categories',
          });
        }
      };
    },
    getCompanyMarketplaces(item) {
      this.$store.state.loadingStatus = true;
      CategoriesService.getMarkets()
        .then((res) => {
          this.companyMarkets = res.data.Result;
          if (item == null) {
            this.companyMarkets.forEach((obj) => (obj.selectedItem = null));
          } else {
            for (let i = 0; i < this.companyMarkets.length; i++) {
              this.companyMarkets[i].selectedItem =
                item.MarketplaceCategories.find((x) =>
                  this.companyMarkets[i].Categories.find(
                    (y) => y.CategoryId == x.CategoryId
                  )
                );
            }
          }
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          // this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },
    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getTableList();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
      this.getTableList();
    },

    //Page setup
    setup() {
      this.modalHeader = this.$t('title.moduleModalCreate');
      this.headers = [
        {
          text: this.$t('title.category'),
          value: 'Category',
          sortable: false,
        },
        {
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
        },
        (this.pagination = {
          totalItems: 200,
          rowsPerPage: this.itemsPerPage,
          page: 1,
        }),
      ];

      this.pagination = {
        totalItems: 100,
        rowsPerPage: 15,
        page: 1,
      };

      this.changedCategory = '';
      this.selectableCategories = [];
    },

    sendCategoryForm(bool) {
      this.$store.state.loadingStatus = true;

      if (bool == true) {
        let body = {
          Id: this.updatedCategoryId,
          categoryName: this.addedCategoryName,
          MarketplaceCategoryIds: this.companyMarkets.map((obj) =>
            obj.selectedItem.CategoryId == null
              ? obj.selectedItem
              : obj.selectedItem.CategoryId
          ),
        };
        CategoriesService.updateName(body)
          .then((res) => {
            this.getTableList();
            this.isCreate = false;
          })
          .catch((err) => {
            window.handleServiceError(res, err);
            this.$store.state.loadingStatus = false;
          })
          .then(() => {
            // this.loadings.table = false;
            this.$store.state.loadingStatus = false;
          });
      } else {
        let body = {
          categoryName: this.addedCategoryName,
          MarketplaceCategoryIds: this.companyMarkets.map((obj) =>
            obj.selectedItem == null
              ? null
              : obj.selectedItem.CategoryId == null
              ? obj.selectedItem
              : obj.selectedItem.CategoryId
          ),
        };

        if (body.MarketplaceCategoryIds.findIndex((x) => x === null) === -1) {
          CategoriesService.addWithMarketplace(body)
            .then((res) => {
              this.getTableList();
              this.isCreate = false;
            })
            .catch((err) => {
              window.handleServiceError(err);
              this.$store.state.loadingStatus = false;
            })
            .then(() => {
              // this.loadings.table = false;
              this.$store.state.loadingStatus = false;
            });
        } else {
          window.showWarning(this.$t('description.invalidCategoryMarketPlace'));

          this.$store.state.loadingStatus = false;
        }
      }
      this.addedCategoryName = '';
    },
    searchTable(type = 'search') {
      type == 'clear' ? (this.search = '') : null;
      this.getTableList(this.search);
    },
    getCategoryDetails(item) {
      this.modalHeader = this.$t('title.categoryDetail');
      this.editItemId = item.Id;
      //Use item data to fill other parts of detail modal
      this.inspectedModel = {
        CategoryName: item.CategoryName,
      };
      this.updatedCategoryId = item.Id;
      this.isCreate = true;
      this.isUpdate = true;
      this.getCompanyMarketplaces(item);
      this.addedCategoryName = item.CategoryName;
      this.marketplaceIds = this.marketplaceIds[item.CategoryId];
    },
    sendForm(bool) {
      if (bool) {
        this.updateCategory();
      } else {
        this.deleteCategory();
      }
    },
    deleteCategory() {
      this.$store.state.loadingStatus = true;

      let body = {
        oldId: this.changedCategory.Id,
        newId: this.changedCategoryModal,
      };
      CategoriesService.updateProducts(body)
        .then((res) => {
          this.categoryDeatilStatus = false;
          this.getTableList();
          this.clearModal();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          // this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },
    updateCategory() {
      this.$store.state.loadingStatus = true;
      this.loadings.table = true;

      this.categoryDeatilStatus = false;
      let body = {
        CategoryName: this.inspectedModel.CategoryName,
        Id: this.editItemId,
      };
      CategoriesService.updateName(body)
        .then((res) => {
          this.isDelete = false;
          this.getTableList();
          this.clearModal();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },
    //Fill's the content list
    getTableList(item) {
      this.tableData = [];
      this.$store.state.loadingStatus = true;

      CategoriesService.getCategories(
        item,
        this.pagination.page,
        this.pagination.rowsPerPage
      )
        .then((res) => {
          this.tableData = res.data.Result;
          this.pagination.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    //Clears all modal's states and item ID
    clearModal() {
      this.inspectedModel = {};
      //Reset all the flags, modals and references
      this.modalHeader = '';
      this.isCreate = false;
      this.$refs.observer.reset();
      this.createdModelGroupIds = {};
      this.editItemId = null;
    },

    //Handle content

    //Opens modal for deletion operation
    openDeleteModal(item) {
      this.selectedContentModel = item;
      this.$refs.contentModalRef.modalStatus = true;
    },
    //Delete operation
    selectedContentFunc() {
      this.$store.state.loadingStatus = true;
      this.$refs.contentModalRef.modalStatus = false;
      CategoriesService.deleteCategory(this.selectedContentModel.Id)
        .then(() => {
          this.getTableList();
        })
        .catch((err) => {
          if (err.response.status === 406) {
            this.selectableCategories = [];
            this.tableData.forEach((element) => {
              if (element.Id != this.selectedContentModel.Id) {
                this.selectableCategories.push(element);
              } else {
                this.changedCategory = element;
              }
            });
            this.categoryDeatilStatus = true;
            this.isDelete = false;
            this.modelSubTitle = this.$t('description.deleteCategory');
            this.modalHeader = this.$t('title.deleteCategory');
            this.$store.state.loadingStatus = false;
          } else {
            this.$store.state.loadingStatus = false;
            window.showError(err);
          }
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    filterContent() {
      this.$store.state.loadingStatus = true;

      this.tableData = [];

      this.pagination.rowsPerPage = 15;
      this.itemsPerPage = 15;
      this.pagination.page = 1;
      this.getTableList();
    },
    //Gets sidebar data dynamically
    ...mapActions(['getMenuData']),
  },
};
</script>
