<template>
  <div>
    <v-card-title class="popupHeadline">
      <v-row>
        <v-row class="pl-2 py-2"
          ><p class="clickable-header" @click="navigateBack()">
            {{ $t('title.products') }}
          </p>
          <p class="font-weight-regular" style="">{{ pageTitle }}</p></v-row
        >
      </v-row>
    </v-card-title>
    <!-- Page content -->
    <TabScreen :slots="slots" :changeIndexFunction="handleTabChange">
      <!-- Add Product Template -->
      <template v-slot:productInformation>
        <v-card
          style="padding: 10vh; border-radius: 0"
          max-width="100%"
          min-width="80%"
          elevation="2"
        >
          <v-row>
            <v-col md="12" xs="12">
              <div>
                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                  <form
                    autocomplete="off"
                    @submit.prevent="handleSubmit(addProduct)"
                  >
                    <v-row class="align-center">
                      <!-- Brand Information -->
                      <v-col cols="12" lg="2">
                        <div class="mb-lg-9 form-label text-field-label">
                          <label>{{ $t('labels.brand') }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <ValidationProvider
                          :name="$t('labels.brand')"
                          rules="required"
                          v-slot="{ errors }"
                          class="d-lg-flex"
                        >
                          <v-select
                            :items="companyBrands"
                            v-model="product.ProductBrandId"
                            :placeholder="$t('labels.brand')"
                            class="custom-border"
                            item-value="Value"
                            item-text="Name"
                            outlined
                            variant="underlined"
                            append-icon="keyboard_arrow_down"
                            :error-messages="errors[0]"
                          ></v-select>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="4"></v-col>
                      <!-- Category Information -->
                      <v-col cols="12" lg="2">
                        <div class="mb-lg-9 form-label text-field-label">
                          <label>{{ $t('labels.category') }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <ValidationProvider
                          :name="$t('labels.category')"
                          rules="required"
                          v-slot="{ errors }"
                          class="d-lg-flex"
                        >
                          <v-select
                            :items="companyCategories"
                            v-model="product.CategoryId"
                            :placeholder="$t('labels.category')"
                            class="custom-border"
                            item-value="Value"
                            item-text="Name"
                            outlined
                            variant="underlined"
                            append-icon="keyboard_arrow_down"
                            :error-messages="errors[0]"
                          ></v-select>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="4"></v-col>
                      <!-- Name Information -->
                      <v-col cols="10" lg="2">
                        <div
                          class="form-label mb-lg-9 align-self-center text-field-label"
                        >
                          <label>{{ $t('labels.productName') }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <ValidationProvider
                          :name="$t('labels.productName')"
                          rules="required"
                          v-slot="{ errors }"
                          class="d-lg-flex"
                        >
                          <v-text-field
                            :placeholder="$t('labels.productName')"
                            class="custom-border"
                            outlined
                            v-model="product.Name"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="4"></v-col>
                      <!-- Description Information -->
                      <v-col cols="10" lg="2">
                        <div
                          class="form-label mb-lg-9 align-self-center text-field-label"
                        >
                          <label>{{ $t('labels.productDescripton') }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <ValidationProvider
                          :name="$t('labels.productDescripton')"
                          v-slot="{ errors }"
                          class="d-lg-flex"
                        >
                          <v-text-field
                            :placeholder="$t('labels.productDescripton')"
                            class="custom-border"
                            outlined
                            v-model="product.Description"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="4"></v-col>
                      <!-- Barcode Information -->
                      <v-col cols="10" lg="2">
                        <div
                          class="form-label mb-lg-9 align-self-center text-field-label"
                        >
                          <label>{{ $t('labels.Barcode') }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <ValidationProvider
                          :name="$t('labels.Barcode')"
                          rules="required"
                          v-slot="{ errors }"
                          class="d-lg-flex"
                        >
                          <v-text-field
                            :placeholder="$t('labels.Barcode')"
                            class="custom-border"
                            outlined
                            v-model="product.Barcode"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="4"></v-col>
                      <!-- SKU No Information -->
                      <v-col cols="10" lg="2">
                        <div
                          class="form-label mb-lg-9 align-self-center text-field-label"
                        >
                          <label>{{ $t('labels.SKUNo') }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <ValidationProvider
                          :name="$t('labels.SKUNo')"
                          rules="required"
                          v-slot="{ errors }"
                          class="d-lg-flex"
                        >
                          <v-text-field
                            :placeholder="$t('labels.SKUNo')"
                            class="custom-border"
                            outlined
                            v-model="product.SKUNo"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="4"></v-col>
                      <!-- Dimensions Information -->
                      <v-col cols="12" lg="2">
                        <div
                          class="form-label mb-lg-9 ml-lg-3 ml-xl-14 align-self-center text-field-label"
                        >
                          <label>{{ $t('labels.productDimensions') }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="2">
                        <!-- Gender selection -->
                        <ValidationProvider
                          :name="$t('labels.Width')"
                          v-slot="{ errors }"
                          :rules="{
                            required: true,
                            numeric: true,
                          }"
                          class="d-lg-flex"
                        >
                          <v-text-field
                            :placeholder="$t('labels.Width')"
                            class="custom-border"
                            outlined
                            v-model="product.Width"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="2">
                        <!-- Gender selection -->
                        <ValidationProvider
                          :name="$t('labels.Length')"
                          v-slot="{ errors }"
                          :rules="{
                            required: true,
                            numeric: true,
                          }"
                          class="d-lg-flex"
                        >
                          <v-text-field
                            :placeholder="$t('labels.Length')"
                            class="custom-border"
                            outlined
                            v-model="product.Length"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="2">
                        <!-- Gender selection -->
                        <ValidationProvider
                          :name="$t('labels.Height')"
                          v-slot="{ errors }"
                          :rules="{
                            required: true,
                            numeric: true,
                          }"
                          class="d-lg-flex"
                        >
                          <v-text-field
                            :placeholder="$t('labels.Height')"
                            class="custom-border"
                            outlined
                            v-model="product.Height"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="2">
                        <!-- Gender selection -->
                        <ValidationProvider
                          :name="$t('labels.Weight')"
                          v-slot="{ errors }"
                          :rules="{
                            required: true,
                            numeric: true,
                          }"
                          class="d-lg-flex"
                        >
                          <v-text-field
                            :placeholder="$t('labels.Weight')"
                            class="custom-border"
                            outlined
                            v-model="product.Weight"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="2"></v-col>
                      <!-- Times Information -->
                      <v-col cols="12" lg="2">
                        <div
                          class="form-label mb-lg-9 ml-lg-3 ml-xl-14 align-self-center text-field-label"
                        >
                          <label>{{ $t('labels.productTimes') }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="3">
                        <ValidationProvider
                          :name="$t('labels.preparingTime')"
                          v-slot="{ errors }"
                          :rules="{
                            required: true,
                            numeric: true,
                          }"
                          class="d-lg-flex"
                        >
                          <v-text-field
                            :placeholder="$t('labels.preparingTime')"
                            class="custom-border"
                            outlined
                            v-model="product.PrepareTime"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="3">
                        <ValidationProvider
                          :name="$t('labels.warrantyTime')"
                          v-slot="{ errors }"
                          :rules="{
                            required: true,
                            numeric: true,
                          }"
                          class="d-lg-flex"
                        >
                          <v-text-field
                            :placeholder="$t('labels.warrantyTime')"
                            class="custom-border"
                            outlined
                            v-model="product.WarrantyTime"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="2"></v-col>
                      <v-col cols="12" lg="2"></v-col>
                      <!-- MaxOrderCount Information -->
                      <v-col cols="10" lg="2">
                        <div
                          class="form-label mb-lg-9 align-self-center text-field-label"
                        >
                          <label>{{ $t('labels.productMaxOrderCount') }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <ValidationProvider
                          :name="$t('labels.productMaxOrderCount')"
                          :rules="{
                            required: true,
                            numeric: true,
                          }"
                          v-slot="{ errors }"
                          class="d-lg-flex"
                        >
                          <v-text-field
                            :placeholder="$t('labels.productMaxOrderCount')"
                            class="custom-border"
                            outlined
                            v-model="product.MaximumPurchaseCount"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="4"></v-col>
                      <!-- Critical Stock Information -->
                      <v-col cols="10" lg="2">
                        <div
                          class="form-label mb-lg-9 align-self-center text-field-label"
                        >
                          <label>{{
                            $t('labels.productCriticalStockCount')
                          }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <ValidationProvider
                          :name="$t('labels.productCriticalStockCount')"
                          :rules="{
                            required: true,
                            numeric: true,
                          }"
                          v-slot="{ errors }"
                          class="d-lg-flex"
                        >
                          <v-text-field
                            :placeholder="
                              $t('labels.productCriticalStockCount')
                            "
                            class="custom-border"
                            outlined
                            v-model="product.CriticalStockCount"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="4"></v-col>

                      <!-- GTIIN Information -->
                      <v-col cols="10" lg="2">
                        <div
                          class="form-label mb-lg-9 align-self-center text-field-label"
                        >
                          <label>{{ $t('labels.productGTIN') }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <ValidationProvider
                          :name="$t('labels.productGTIN')"
                          rules="required"
                          v-slot="{ errors }"
                          class="d-lg-flex"
                        >
                          <v-text-field
                            :placeholder="$t('labels.productGTIN')"
                            class="custom-border"
                            outlined
                            v-model="product.GTIN"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="4"></v-col>

                      <v-col
                        cols="12"
                        lg="3"
                        xl="12"
                        class="align-self-center justify-center"
                      >
                        <v-btn
                          elevation="0"
                          class="button add darken-1"
                          dark
                          type="submit"
                          :loading="isLoading"
                        >
                          {{
                            isUpdate ? $t('buttons.update') : $t('buttons.add')
                          }}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <!-- Şifremi unuttum  burda -->
                  </form>
                </ValidationObserver>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </template>
      <!-- Add Product Photo Template -->
      <template v-slot:productPhoto>
        <v-row align="start" class="d-flex ml-2">
          <v-col cols="4" class="">
            <v-file-input
              :placeholder="$t('labels.photoUploadNotifications')"
              accept="image/png, image/jpeg, image/bmp"
              v-model="file"
              prepend-icon=""
              append-icon="mdi-camera"
              :label="$t('labels.photoUploadNotifications')"
              class="custom-border"
              outlined
              @change="onChangeImage()"
            ></v-file-input>
          </v-col>
          <v-col v-if="photoSelected" cols="2" class="mt-3">
            <div style="gap: 1rem">
              <span
                class="align-self-center mr-2"
                style="font-size: 12px; font-weight: normal; color: #598db5"
                >{{ $t('buttons.add') }}</span
              >
              <v-icon
                @click="uploadFile()"
                class="icon-button mr-4 align-self-center"
              >
                mdi-plus
              </v-icon>
            </div>
          </v-col>
        </v-row>
        <template>
          <v-row class="ml-2">
            <v-col
              v-for="item in product.ProductPhotos"
              :key="item.Id"
              class="d-flex child-flex"
              cols="2"
            >
              <v-hover v-slot="{ hover }">
                <v-img
                  :src="item.FilePath"
                  :lazy-src="item.FilePath"
                  aspect-ratio="1"
                  cover
                  class="grey lighten-2"
                >
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal text-h2 white--text"
                      style="height: 100%"
                    >
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="error"
                        @click="deletePhoto(item.Id)"
                      >
                        <v-icon dark> mdi-minus </v-icon>
                      </v-btn>
                    </div>
                  </v-expand-transition>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </template>
      <!-- Add Product Template -->
      <template v-slot:marketplaceInfo>
        <v-card
          style="padding: 10vh; border-radius: 0"
          max-width="100%"
          min-width="80%"
          elevation="2"
        >
          <v-row>
            <v-col md="12" xs="12">
              <div>
                <ValidationObserver
                  key="ttt"
                  ref="observer"
                  v-slot="{ handleSubmit }"
                >
                  <form
                    autocomplete="off"
                    @submit.prevent="handleSubmit(productToMarketplace)"
                  >
                    <v-row class="align-center">
                      <!-- Marketplace and Sale Status Information -->
                      <v-col cols="12" lg="2">
                        <div class="mb-lg-9 form-label text-field-label">
                          <label>{{ $t('labels.marketplaceLabel') }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <ValidationProvider
                          :name="$t('labels.marketplaceLabel')"
                          rules="required"
                          v-slot="{ errors }"
                          class="d-lg-flex"
                        >
                          <v-select
                            :items="companyMarketplaces"
                            v-model="productMarketplace.CompanyMarketplaceId"
                            :placeholder="$t('labels.marketplaceLabel')"
                            class="custom-border"
                            item-value="Value"
                            item-text="Name"
                            outlined
                            @change="getMarketInfo()"
                            variant="underlined"
                            append-icon="keyboard_arrow_down"
                            :error-messages="errors[0]"
                          ></v-select>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="2">
                        <div class="mb-lg-9 form-label text-field-label">
                          <label>{{ $t('labels.status') }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <ValidationProvider
                          :name="$t('labels.status')"
                          rules="required"
                          v-slot="{ errors }"
                          class="d-lg-flex"
                        >
                          <v-select
                            :items="productSaleStatus"
                            v-model="productMarketplace.SaleStatus"
                            :placeholder="$t('labels.status')"
                            class="custom-border"
                            item-value="Value"
                            item-text="Name"
                            outlined
                            variant="underlined"
                            append-icon="keyboard_arrow_down"
                            :error-messages="errors[0]"
                          ></v-select>
                        </ValidationProvider>
                      </v-col>
                      <!-- Price Information -->
                      <v-col cols="12" lg="2">
                        <div class="mb-lg-9 form-label text-field-label">
                          <label>{{ $t('labels.price') }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="3">
                        <ValidationProvider
                          :name="$t('labels.price')"
                          :rules="{
                            required: true,
                            numeric: true,
                          }"
                          v-slot="{ errors }"
                          class="d-lg-flex"
                        >
                          <v-text-field
                            :placeholder="$t('labels.price')"
                            class="custom-border"
                            outlined
                            v-model="productMarketplace.Price"
                            :error-messages="errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="2">
                        <ValidationProvider
                          :name="$t('labels.currency')"
                          rules="required"
                          v-slot="{ errors }"
                          class="d-lg-flex"
                        >
                          <v-autocomplete
                            v-model="productMarketplace.Currency"
                            :items="currencies"
                            item-value="Value"
                            item-text="Name"
                            :label="$t('labels.currency')"
                            :placeholder="$t('labels.currency')"
                            outlined
                            variant="underlined"
                            class="custom-border"
                            append-icon="keyboard_arrow_down"
                            :error-messages="errors[0]"
                          ></v-autocomplete>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="5"></v-col>

                      <!-- Tag Information -->
                      <v-col cols="12" lg="2">
                        <div class="mb-lg-9 form-label text-field-label">
                          <label>{{ $t('labels.tags') }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-combobox
                          v-model="tagName"
                          :items="tags"
                          :search-input.sync="search"
                          hide-selected
                          :hint="$t('labels.max15')"
                          :label="$t('labels.tags')"
                          multiple
                          :disabled="tagIds.length == 15"
                          persistent-hint
                          deletable-chips
                          small-chips
                          item-text="Name"
                          item-value="Id"
                          @input="selectTag(tagName)"
                          @keyup.enter="addTag(tagName)"
                          counter="15"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  "<strong>{{ search }}</strong
                                  >" için bir eşleşme bulunamadı. Yeni bir tane
                                  oluşturmak için <kbd>enter</kbd> tuşuna basın.
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-combobox>
                        <v-alert
                          v-if="tagIds.length == 15"
                          border="left"
                          close-text="Close Alert"
                          type="info"
                          color="#7443a0"
                          dismissible
                          outlined
                          prominent
                          class="font-weight-black mt-3"
                          style="font-size: 1.2em"
                        >
                          {{ $t('description.tagCountReached') }}
                        </v-alert>
                      </v-col>
                      <v-col cols="12" lg="4"></v-col>
                      <v-col cols="12" lg="2">
                        <div class="mb-lg-9 form-label text-field-label">
                          <label>{{ $t('labels.selectPhoto') }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="10">
                        <v-row class="ml-2">
                          <v-col
                            v-for="item in product.ProductPhotos"
                            :key="item.Id"
                            class="d-flex child-flex"
                            style="opacity: 0.5"
                            :class="{
                              selectedClass: selectedImages.includes(item.Id),
                            }"
                            cols="2"
                          >
                            <v-hover v-slot="{ hover }">
                              <v-img
                                :src="item.FilePath"
                                :lazy-src="item.FilePath"
                                aspect-ratio="1"
                                cover
                                class="grey lighten-2"
                              >
                                <v-expand-transition>
                                  <div
                                    v-if="hover"
                                    class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal text-h2 white--text"
                                    style="height: 100%"
                                  >
                                    <v-btn
                                      class="mx-2"
                                      fab
                                      dark
                                      small
                                      color="warning"
                                      @click="selectImage(item)"
                                    >
                                      <v-icon dark>
                                        {{
                                          selectedImages.includes(item.Id)
                                            ? 'mdi-minus'
                                            : 'mdi-plus'
                                        }}
                                      </v-icon>
                                    </v-btn>
                                  </div>
                                </v-expand-transition>
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-hover>
                          </v-col>
                        </v-row>
                      </v-col>

                      <!-- İçerik Bölümü -->
                      <v-col cols="12" lg="2">
                        <div class="mb-lg-9 form-label text-field-label">
                          <label>{{ $t('labels.productIntroduction') }}</label>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="10">
                        <ckeditor
                          :editor="editor"
                          :config="editorConfig"
                          v-model="editorData"
                        />
                      </v-col>
                      <!-- İçerik end -->

                      <v-col
                        cols="12"
                        lg="3"
                        xl="12"
                        class="align-self-center justify-center"
                      >
                        <v-btn
                          elevation="0"
                          class="button add darken-1"
                          dark
                          type="submit"
                          :loading="isLoading"
                        >
                          {{
                            checkIsUpdate == true
                              ? $t('buttons.save')
                              : $t('buttons.add')
                          }}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <!-- Şifremi unuttum  burda -->
                  </form>
                </ValidationObserver>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </template>
      <template v-slot:summary>
        <v-card
          style="padding: 10vh; border-radius: 0"
          max-width="100%"
          min-width="80%"
          elevation="2"
        >
          <v-data-table
            :headers="productMarketHeaders"
            :items="tableData"
            :loading="loadings.table"
            :options.sync="options"
            :hide-default-footer="true"
            :footer-props="{
              disablePagination: true,
              disableItemsPerPage: true,
            }"
            :no-data-text="$t('description.noList')"
            ref="wordList"
          >
            <!-- eslint-disable -->

            <template v-slot:item.Status="{ item }">
              <v-chip
                v-if="item.Status == 0"
                class="ma-2"
                small
                color="#c5f4db"
                text-color="#2b3838"
              >
                {{ $t('labels.onSale') }}
              </v-chip>

              <v-chip
                v-if="item.Status == 1"
                class="ma-2 text--center"
                small
                color="#e3e2e3"
                text-color="#2b3838"
              >
                {{ $t('labels.notOnSale') }}
              </v-chip>
            </template>
            <template v-slot:item.DraftStatus="{ item }">
              <v-chip
                v-if="item.DraftStatus == 0"
                class="ma-2"
                small
                color="#c5f4db"
                text-color="#2b3838"
              >
                {{ $t('labels.live') }}
              </v-chip>

              <v-chip
                v-if="item.DraftStatus == 1"
                class="ma-2 text--center"
                small
                color="#e3e2e3"
                text-color="#2b3838"
              >
                {{ $t('labels.notLive') }}
              </v-chip>
            </template>
            <!-- eslint-disable -->
          </v-data-table>
        </v-card>
      </template>
    </TabScreen>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      color="#E65100"
      rounded="pill"
    >
      {{ snackbarText }}

      <template v-if="hasButton" v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="goToStockPage()">
          {{ $t('buttons.addStock') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import CompanyMarketplaceService from '../../services/CompanyMarketplaceService';
import ProductService from '../../services/ProductService';
import ProductMarketplaceService from '../../services/ProductMarketplaceServices';
import MarketplacePhotoService from '../../services/MarketplacePhotoServices';
import CategoryService from '../../services/CategoriesService';
import BrandService from '../../services/BrandService';
import TabScreen from '../../components/TabScreen.vue';
import TagService from '../../services/TagServices';
import MyUploadAdapter from '../../adapters/MyUploadAdapter';
import MyUploadAdapterPlugin from '../../adapters/MyUploadAdapterPlugin';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import FileRepository from '@ckeditor/ckeditor5-upload/src/filerepository';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Link from '@ckeditor/ckeditor5-link/src/link';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';

export default {
  data: () => ({
    // General Models
    editor: ClassicEditor,
    editorConfig: {
      uploadAdapter: MyUploadAdapter,
      plugins: [
        FileRepository,
        Essentials,
        Bold,
        Italic,
        Link,
        Paragraph,
        List,
        Heading,
        Indent,
        IndentBlock,
        ImageUpload,
        Font,
        Image,
        ImageToolbar,
        ImageCaption,
        ImageStyle,
        ImageResize,
        LinkImage,
      ],
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'imageUpload',
          '|',
          'outdent',
          'indent',
          '|',
          'undo',
          'redo',
          '|',
          'fontSize',
          'fontColor',
          'imageUpload',
          'fontBackgroundColor',
          'imageStyle:block',

          'imageStyle:side',
          '|',
          'toggleImageCaption',
          'imageTextAlternative',
          '|',
          'linkImage',
        ],
      },
      extraPlugins: [MyUploadAdapterPlugin],
      fileRepository: {
        // Yükleme adaptörünüzü tanımlayın
        uploadAdapter: new MyUploadAdapter(),
      },
    },

    editorData: '',
    isUpdateProductMarketplace: false,
    updateStatus: 0,
    isLoading: false,
    isUpdate: false,
    isUploading: false,
    file: null,
    base64: '',
    pageTitle: 'Ürün Ekle',
    companyBrands: [],
    tableData: [],
    tagName: [],
    tags: [],
    search: null,
    productMarketplace: {},
    tagIds: [],
    snackbar: false,
    hasButton: false,
    snackbarText: '',
    timeout: 5000,
    companyMarketplaces: [],
    productSaleStatus: [
      {
        Name: 'Satışa Koy',
        Value: 0,
      },
      {
        Name: 'Satışa Koyma',
        Value: 1,
      },
    ],
    productPhotos: [],
    companyCategories: [],
    currencies: [
      {
        Name: 'USD',
        Value: 1,
      },
      {
        Name: 'EUR',
        Value: 2,
      },
      {
        Name: 'GBP',
        Value: 3,
      },
      {
        Name: 'AUD',
        Value: 4,
      },
      {
        Name: 'TRY',
        Value: 5,
      },
      {
        Name: 'AZN',
        Value: 6,
      },
      {
        Name: 'BGN',
        Value: 7,
      },
      {
        Name: 'CAD',
        Value: 8,
      },
      {
        Name: 'CHF',
        Value: 9,
      },
      {
        Name: 'CLP',
        Value: 10,
      },
      {
        Name: 'CZK',
        Value: 11,
      },
      {
        Name: 'DKK',
        Value: 12,
      },
      {
        Name: 'HUF',
        Value: 13,
      },
      {
        Name: 'IDR',
        Value: 14,
      },
      {
        Name: 'JPY',
        Value: 15,
      },
      {
        Name: 'KGS',
        Value: 16,
      },
      {
        Name: 'KRW',
        Value: 17,
      },
      {
        Name: 'MXN',
        Value: 18,
      },
      {
        Name: 'NOK',
        Value: 19,
      },
      {
        Name: 'NZD',
        Value: 20,
      },
      {
        Name: 'PLN',
        Value: 21,
      },
      {
        Name: 'SEK',
        Value: 22,
      },
      {
        Name: 'SGD',
        Value: 23,
      },
      {
        Name: 'THB',
        Value: 24,
      },
      {
        Name: 'XPF',
        Value: 25,
      },
      {
        Name: 'ZAR',
        Value: 26,
      },
    ],
    search: null,
    slots: [
      { name: 'productInformation', title: 'Ürün Bilgisi' },
      { name: 'productPhoto', title: 'Ürün Resimleri' },
      { name: 'marketplaceInfo', title: 'Pazaryeri Bilgileri' },
      { name: 'summary', title: 'Özet' },
    ],
    product: {},

    // List options
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    headers: [],
    productMarketHeaders: [],
    selectedImages: [],
    //Loading states
    loadings: {
      table: false,
      send: false,
    },
    pagination: {},
    itemsPerPage: 15,
    perPageValues: [
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],
  }),

  computed: {
    isUpdateProduct() {
      return this.isUpdate;
    },
    checkIsUpdate() {
      return this.isUpdateProductMarketplace;
    },
    photoSelected() {
      return this.isUploading;
    },
  },

  watch: {
    model(value) {},
    search(val) {
      // Items have already been loaded
      if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.$store.state.loadingStatus = false;
    },
  },

  watch: {},
  mounted() {
    this.clearModal();
    this.$route.query.id != null
      ? this.getProductById(this.$route.query.id)
      : null;
    this.setup();
    this.getCompanyCategories();
    this.getBrands();
  },
  components: {
    TabScreen,
  },
  methods: {
    MyCustomUploadAdapterPlugin(editor) {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    },
    navigateBack() {
      this.$router.push({ name: 'products' });
    },
    productToMarketplace() {
      this.productMarketplace.Tags = this.tagIds;
      this.productMarketplace.ProductId = this.product.Id;
      this.productMarketplace.DraftStatus = this.updateStatus;
      this.productMarketplace.Language = 'Turkish';
      this.productMarketplace.Content = this.editorData;
      if (this.selectedImages.length != 0) {
        this.$store.state.loadingStatus = true;
        if (this.checkIsUpdate) {
          ProductMarketplaceService.updateProductToMarketplace(
            this.productMarketplace
          )
            .then((response) => {
              if (response.data.Code == 400) {
                this.snackbar = true;
                this.snackbarText = this.$t('description.addStock');
                this.hasButton = true;
                this.clearModal();
              } else {
                let photoBody = {
                  PhotoId: this.selectedImages,
                  ProductMarketplaceId: this.productMarketplace.Id,
                };
                MarketplacePhotoService.updateProductPhotoToMarketplace(
                  photoBody
                )
                  .then((res) => {
                    window.showSuccess(
                      this.$t('messages.successfulUpdate', {
                        value: 'Ürünü pazaryerinde ',
                      })
                    );
                  })
                  .catch((error) => {
                    window.showError(error);
                  });
              }
            })
            .catch((error) => {
              window.showError(error);
            })
            .finally(() => {
              this.$store.state.loadingStatus = false;
            });
        } else {
          ProductMarketplaceService.addProductToMarketplace(
            this.productMarketplace
          )
            .then((response) => {
              if (response.data.Code == 400) {
                this.snackbar = true;
                this.snackbarText = this.$t('description.addStock');
                this.hasButton = true;
                this.clearModal();
              } else {
                this.productMarketplace.Id = response.data.Result.Id;
                let photoBody = {
                  ProductPhotoId: this.selectedImages,
                  ProductMarketplaceId: this.productMarketplace.Id,
                };
                MarketplacePhotoService.addProductPhotoToMarketplace(photoBody)
                  .then((res) => {
                    window.showSuccess(
                      this.$t('messages.successfulAdd', {
                        value: 'Ürünü pazaryerine ',
                      })
                    );
                  })
                  .catch((error) => {
                    window.showError(error);
                  });
              }
            })
            .catch((error) => {
              window.showError(error.Message);
            })
            .finally(() => {
              this.$store.state.loadingStatus = false;
            });
        }
      } else {
        this.snackbar = true;
        this.snackbarText = 'Lütfen fotoğraf seçiniz.';
        this.hasButton = false;
      }
    },
    handleTabChange(index) {
      if (index == 2) {
        this.getMarketplaces();
        this.getTags();
      }
      if (index == 3) {
        this.getTableList();
      }
    },
    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
    },
    getProductById(id) {
      this.isUpdate = true;
      this.$store.state.loadingStatus = true;
      ProductService.getProductById(id)
        .then((response) => {
          this.product = response.data.Result;
          this.pageTitle = ' / ' + this.product.Name;
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    deletePhoto(id) {
      this.$store.state.loadingStatus = true;
      ProductService.deleteProductPhoto(id)
        .then((response) => {
          window.showSuccess(
            this.$t('messages.successfulDelete', {
              value: 'Fotoğraf ',
            })
          );
          this.getProductById(this.product.Id);
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getTableList() {
      if (this.product.Id != null) {
        this.tableData = [];
        this.$store.state.loadingStatus = true;

        ProductService.getProductMarketplacesById(this.product.Id)
          .then((res) => {
            this.tableData = res.data.Result;
          })
          .catch((err) => {
            window.showError(err);
          })
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },
    selectImage(image) {
      const index = this.selectedImages.indexOf(image.Id);
      if (index > -1) {
        this.selectedImages.splice(index, 1);
      } else {
        this.selectedImages.push(image.Id);
      }
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
    },
    getCompanyCategories() {
      this.companyCategories = [];
      this.$store.state.loadingStatus = true;
      CategoryService.getCategories()
        .then((res) => {
          res.data.Result.forEach((element) => {
            this.companyCategories.push({
              Name: element.CategoryName,
              Value: element.Id,
            });
          });
        })
        .catch((err) => {
          this.$store.state.loadingStatus = false;
          window.showError(err);
        })
        .then(() => {});
    },
    selectTag(tag) {
      if (tag && this.tagIds.length <= 15 && tag.length <= 15) {
        this.tagIds = [];
        tag.forEach((x) => this.tagIds.push(x.Name));
      }
      this.tagIds = [...new Set(this.tagIds)];
      this.tagIds = this.tagIds.filter((x) => x != undefined);
    },

    getTags() {
      if (this.tags.length == 0) {
        this.$store.state.loadingStatus = true;
        TagService.getTags()
          .then((res) => {
            this.tags = res.data.Result;
          })
          .catch((err) => {
            window.showError(err);
          })
          .then(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },
    addTag(tag) {
      if (this.tagIds.length <= 15) {
        tag.forEach((x, index) => {
          if (x.Id == undefined) {
            let body = {
              Name: x,
            };

            if (index == tag.length - 1) {
              this.$store.state.loadingStatus = true;
              TagService.addTag(body)
                .then((res) => {
                  this.tags[index] = res.data;
                  this.tagIds.push(body.Name);
                })
                .catch((err) => {
                  window.showError(err);
                })
                .then(() => {
                  this.$store.state.loadingStatus = false;
                });
            }
          }
        });
        this.tagIds = this.tagIds.filter((x) => x != undefined);
        this.tagIds = [...new Set(this.tagIds)];
      }
    },
    getProductPhotos() {
      this.productPhotos = [];
      this.$store.state.loadingStatus = true;
      ProductService.getProductPhotos(this.product.Id)
        .then((response) => {
          this.productPhotos = response.data.Result;
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    addProductPhotos() {},
    getBrands() {
      this.companyBrands = [];
      this.$store.state.loadingStatus = true;
      BrandService.getBrands(100, 1)
        .then((res) => {
          res.data.Result.forEach((element) => {
            this.companyBrands.push({ Name: element.Brand, Value: element.Id });
          });
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getMarketInfo() {
      ProductMarketplaceService.checkProductMarketplaces(
        this.product.Id,
        this.productMarketplace.CompanyMarketplaceId
      )
        .then((res) => {
          if (res.data.Result.length == 1) {
            this.isUpdateProductMarketplace = true;
            this.tagIds = [];
            this.productMarketplace.Id = res.data.Result[0].Id;
            this.productMarketplace.CompanyMarketplaceId =
              res.data.Result[0].CompanyMarketplaceID;
            this.productMarketplace.SaleStatus = res.data.Result[0].SalesStatus;
            this.productMarketplace.Price = res.data.Result[0].Price;
            this.productMarketplace.Currency = res.data.Result[0].Currency;
            this.tagName = res.data.Result[0].Tags;

            this.selectedImages = [];
            res.data.Result[0].Photos.forEach((element) => {
              this.selectedImages.push(element.Id);
            });
            this.editorData = res.data.Result[0].Content;
            this.updateStatus = 1;
          } else {
            this.isUpdateProductMarketplace = false;
          }
        })
        .catch((err) => {
          this.clearModal();
          this.isUpdateProductMarketplace = false;
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    clearModal() {
      this.productMarketplace.SaleStatus = null;
      this.productMarketplace.Price = null;
      this.productMarketplace.Currency = null;
      this.selectedImages = [];
      this.tagName = null;
      this.productMarketplace.Tags = [];
      this.productMarketplace.Language = 'Turkish';
      this.editorData = '';
    },
    getMarketplaces() {
      if (this.companyMarketplaces.length == 0) {
        this.$store.state.loadingStatus = true;
        CompanyMarketplaceService.getMarkets(100, 1)
          .then((res) => {
            res.data.Result.forEach((element) => {
              this.companyMarketplaces.push({
                Name: element.MarketplaceName,
                Value: element.Id,
              });
            });
          })
          .catch((err) => {
            window.handleServiceError(err);
          })
          .then(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },
    //Page setup
    setup() {
      this.pagination = {
        totalItems: 15,
        rowsPerPage: 15,
        page: 1,
      };
      this.headers = [
        {
          text: this.$t('labels.logo'),
          value: 'PhotoUrl',
          sortable: false,
        },
        {
          text: this.$t('labels.companyName'),
          value: 'CompanyName',
          sortable: false,
        },
        {
          text: this.$t('labels.companyType'),
          value: 'CompanyType',
          sortable: false,
        },
      ];
      this.productMarketHeaders = [
        {
          text: 'Pazaryeri',
          value: 'MarketplaceName',
          sortable: false,
        },
        {
          text: 'Ürün Adı',
          value: 'ProductName',
          sortable: false,
        },
        {
          text: 'Kategori',
          value: 'Category',
          sortable: false,
        },
        {
          text: 'Stok',
          value: 'StockCount',
          sortable: false,
        },
        {
          text: 'Fiyat',
          value: 'Price',
          sortable: true,
        },
        {
          text: 'Güncel',
          value: 'DraftStatus',
          sortable: false,
        },
        {
          text: 'Durum',
          value: 'Status',
          sortable: false,
        },
      ];
    },
    onChangeImage() {
      if (this.file) {
        var reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
          this.base64 = reader.result;
        };
        this.isUploading = true;
      } else {
        this.isUploading = false;
      }
    },
    uploadFile() {
      this.$store.state.loadingStatus = true;
      if (this.file) {
        const media = this.file;

        if (media === undefined) {
        }
        let formData = new FormData();
        if (media.type == 'image/jpeg' || media.type == 'image/png') {
          formData.append('Photos', media);
          formData.append('ProductId', this.product.Id);
        }

        ProductService.addProductPhoto(formData)
          .then((uploadFileResponse) => {
            window.showSuccess(
              this.$t('messages.successfulAdd', {
                value: 'Fotoğraf ',
              })
            );
            this.file = null;
            this.isUploading = false;
            this.getProductById(this.product.Id);
          })
          .catch((error) => {});
      }
    },

    goToStockPage() {
      const id = this.product.Id;
      this.$router.push({
        name: `stockDetail`,
        query: { id },
      });
    },
    addProduct() {
      this.$store.state.loadingStatus = true;
      if (this.isUpdate) {
        ProductService.updateProduct(this.product)
          .then((response) => {
            window.showSuccess(
              this.$t('messages.successfulUpdate', {
                value: 'Ürünü ',
              })
            );
            this.getProductById(this.product.Id);
          })
          .catch((error) => {
            window.showError(error);
          })
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      } else {
        ProductService.addProduct(this.product)
          .then((response) => {
            this.product.Id = response.data.Result.Id;
            window.showSuccess(
              this.$t('messages.successfulAdd', {
                value: 'Ürün ',
              })
            );
          })
          .catch((error) => {
            window.showError(error);
          })
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },
  },
};
</script>
