import axios from '../javascript/custom-axios';

export default {
  async auth(body) {
    return axios.post(`fedex/auth`, body);
  },

  /**
   * Get Service Types.
   * @returns {Promise}
   */
  async getServiceTypes() {
    return axios.get(`fedex/get-service-types`);
  },

  /**
   * Get Pickup Types
   * @returns {Promise}
   */
  async getPickupTypes() {
    return axios.get(`fedex/get-pickup-types`);
  },

  /**
   * Create Shipment For FedEx.
   * @param {{ OrderId: string, ShipmentDate: string, ServiceType: string, PickupType: string, CountryOfManufacture: string }} body 
   * @returns 
   */
  async createShipment(body) {
    return axios.post(`fedex/create-shipment`, body);
  },
};
