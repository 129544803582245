// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
let RESOURCE_NAME = "PackageBaskets";

export default {
  /**
   * Creates a new package basket.
   * @param {*} body
   * @returns {Promise<any>}
   */
  add(body) {
    return axios.post(`${RESOURCE_NAME}`, body);
  },

  /**
   * Get package basket list.
   * @param {*} body
   * @returns {Promise<any>}
   */
  get(body) {
    return axios.get(`${RESOURCE_NAME}`, body);
  },

  /**
   * This service empties cart.
   */
  emptyCart() {
    return axios.delete(`${RESOURCE_NAME}/emptyPackageBasket`);
  },

  /**
   * Get package order list.
   * @param {*} body
   * @returns {Promise<any>}
   */
  getPackageOrders(body) {
    return axios.get(`PackageOrders`, {
      params: body,
    });
  },

  /**
   * Post package order.
   * @param {*} body
   * @returns {Promise<any>}
   */
  postPackageOrders(body) {
    return axios.post(`PackageOrders`, body);
  },

  /**
   * Complete package order's operation.
   * @param {integer} id
   * @returns {Promise<any>}
   */
  completePackageOrder(id) {
    return axios.get(`PackageOrders/complete-package-order/${id}`);
  },

  /**
   * cancel package order's operation.
   * @param {integer} id
   * @returns {Promise<any>}
   */
  cancelPackageOrder(id) {
    return axios.put(`PackageOrders/cancel-package-order`, {
      PackageOrderId: id,
    });
  },

  /**
   * Get a package order.
   * @param {integer} id
   * @returns {Promise<any>}
   */
  getPackageOrder(id) {
    return axios.get(`PackageOrders/${id}`);
  },

  /**
   * Delete a package basket.
   * @param {string} packageBasketItemId
   * @returns {Promise<any>}
   */
  delete(packageBasketItemId) {
    return axios.delete(
      `PackageBaskets?PackageBasketId=${packageBasketItemId}`
    );
  },
};
