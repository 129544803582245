// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'sampleExcels';

export default {
  /**
   * Creates a new sample excel.
   * @param {*} body
   * @returns {Promise<any>}
   */
  add(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },

  /**
   * Get sample excel list.
   * @param {*} body
   * @returns {Promise<any>}
   */
  get(body) {
    return axios.get(`${RESOURCE_NAME}/get`, body);
  },

  /**
   * Update a sample excel.
   * @param {*} body
   * @returns {Promise<any>}
   */
  update(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },

  /**
   * Delete a sample excel.
   * @param {string} sampleExcelId
   * @returns {Promise<any>}
   */
  delete(sampleExcelId) {
    return axios.delete(`${RESOURCE_NAME}/delete?Id=${sampleExcelId}`);
  },
};
