<template>
  <div elevation="1">
    <!-- Add Roles To Company Modal -->
    <v-dialog persistent v-model="addRoleModal" max-width="600">
      <v-card class="popup-card" elevation="1">
        <v-card-title
          class="popupHeadline d-flex justify-center"
          style="font-size: 16px"
        >
          {{ this.modalHeader }}
        </v-card-title>

        <v-divider class="mb-6" />
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendRoleForm)">
            <v-card-text class="py-3">
              <v-row class="align-center">
                <FormElement
                  v-if="!isUpdate"
                  v-model="role.Name"
                  :validationLabel="$t('labels.user.firstName')"
                  :formLabel="$t('labels.user.firstName')"
                  rules="required|min:3|onlyStringText:true"
                  :formType="{
                    name: 'text',
                  }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="12"
                  :placeholder="$t('labels.user.firstName')"
                />
                <FormElement
                  v-if="!isUpdate"
                  v-model="role.Surname"
                  :validationLabel="$t('labels.user.lastName')"
                  :formLabel="$t('labels.user.lastName')"
                  rules="required|min:2|onlyStringText:true"
                  :formType="{
                    name: 'text',
                  }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="12"
                  :placeholder="$t('labels.user.lastName')"
                />

                <FormElement
                  v-if="!isUpdate"
                  v-model="role.Email"
                  :validationLabel="$t('labels.email')"
                  :formLabel="$t('labels.email')"
                  rules="required|email"
                  :formType="{
                    name: 'text',
                  }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="12"
                  :placeholder="$t('labels.email')"
                />

                <FormElement
                  v-if="!isUpdate"
                  v-model="role.PhoneNumber"
                  :validationLabel="$t('labels.phone')"
                  :formLabel="$t('labels.phone')"
                  :rules="rules.phone"
                  :formType="{
                    name: 'text',
                    label: $t('labels.phone'),
                  }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="12"
                  :placeholder="$t('labels.userPhonePlaceHolder')"
                />

                <FormElement
                  v-model="role.Role"
                  :validationLabel="
                    $t('labels.type', { value: $t('labels.authority') })
                  "
                  :formLabel="
                    $t('labels.type', { value: $t('labels.authority') })
                  "
                  rules="required"
                  :formType="{
                    name: 'select',
                    items: {
                      data: roleList,
                      text: (item) => $t(`labels.roles.${item.Name}`),
                      value: 'Name',
                    },
                  }"
                  :labelShown="false"
                  :active="
                    $store.state.Type === 'Supplier' ||
                    $store.state.Type === 'PendingSupplier'
                  "
                  :hidden="
                    $store.state.Type === 'Customer' ||
                    $store.state.Type === 'PendingCustomer'
                  "
                  :topLabel="true"
                  :lg="12"
                  :placeholder="
                    $t('labels.type', { value: $t('labels.authority') })
                  "
                />
              </v-row>
            </v-card-text>

            <v-card-actions class="d-flex justify-center">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;
                  padding: 2vh 3vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                type="submit"
                :loading="loadings.send"
              >
                {{ $t('buttons.' + (isUpdate ? 'save' : 'add')) }}
              </v-btn>
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 2vh 3vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                @click="
                  addRoleModal = false;
                  clearModal();
                "
              >
                {{ $t('buttons.close') }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="bankModal"
      max-width="650"
      @input="resetBankModal"
      @close="resetBankModal"
    >
      <v-card class="popup-card" elevation="1">
        <v-card-title
          class="popupHeadline d-flex justify-center"
          style="font-size: 16px"
          >{{
            isEditBankModal
              ? $t('labels.editBankInfo')
              : $t('labels.addBankInfo')
          }}</v-card-title
        >
        <v-divider class="mb-4" />
        <ValidationObserver v-slot="{ handleSubmit }" ref="bankObserver">
          <v-card-text>
            <form
              @submit.prevent="
                handleSubmit(
                  isEditBankModal ? editBankInformation : addBankInformation
                )
              "
            >
              <v-row v-if="!isEditBankModal">
                <FormElement
                  v-model="newBank.BankName"
                  :validationLabel="$t('labels.bankName')"
                  :formLabel="$t('labels.bankName')"
                  :rules="'required|min:3|onlyStringText'"
                  :formType="{
                    name: 'text',
                  }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="6"
                  :placeholder="$t('labels.bankName')"
                />
                <FormElement
                  v-model="newBank.FullName"
                  :validationLabel="$t('labels.accountOwner')"
                  :formLabel="$t('labels.accountOwner')"
                  :rules="'required|min:3|onlyStringText'"
                  :formType="{
                    name: 'text',
                  }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="6"
                  :placeholder="$t('labels.accountOwner')"
                />
                <FormElement
                  v-model="newBank.IBAN"
                  :validationLabel="$t('labels.IBAN')"
                  :formLabel="$t('labels.IBAN')"
                  :rules="'required'"
                  :formType="{
                    name: 'text',
                  }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="6"
                  :placeholder="$t('labels.IBAN')"
                />
                <FormElement
                  :vertical="true"
                  v-model="newBank.CurrencyTypeId"
                  :validationLabel="$t('labels.currency')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: currencies,
                      text: 'Code',
                      value: 'Id',
                    },
                  }"
                  :placeholder="$t('labels.currency')"
                />
              </v-row>

              <v-row v-else>
                <FormElement
                  v-model="editBank.BankName"
                  :validationLabel="$t('labels.bankName')"
                  :formLabel="$t('labels.bankName')"
                  :rules="'required|min:3|onlyStringText'"
                  :formType="{
                    name: 'text',
                  }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="6"
                  :placeholder="$t('labels.bankName')"
                />
                <FormElement
                  v-model="editBank.FullName"
                  :validationLabel="$t('labels.accountOwner')"
                  :formLabel="$t('labels.accountOwner')"
                  :rules="'required|min:3|onlyStringText'"
                  :formType="{
                    name: 'text',
                  }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="6"
                  :placeholder="$t('labels.accountOwner')"
                />
                <FormElement
                  v-model="editBank.IBAN"
                  :validationLabel="$t('labels.IBAN')"
                  :formLabel="$t('labels.IBAN')"
                  :rules="'required'"
                  :formType="{
                    name: 'text',
                  }"
                  :labelShown="false"
                  :topLabel="true"
                  :lg="6"
                  :placeholder="$t('labels.IBAN')"
                />
                <FormElement
                  :vertical="true"
                  v-model="editBank.CurrencyTypeId"
                  :validationLabel="$t('labels.currency')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: currencies,
                      text: 'Code',
                      value: 'Id',
                    },
                  }"
                  :placeholder="$t('labels.currency')"
                />
              </v-row>

              <v-card-actions style="justify-content: center">
                <v-btn
                  class="add mt-6"
                  size="x-large"
                  style="
                    text-transform: uppercase !important;
                    border-radius: 30px !important;
                    padding: 2vh 3vh !important;
                    font-weight: bold;
                    font-size: 16px !important;
                  "
                  dark
                  type="submit"
                  >{{
                    isEditBankModal ? $t('buttons.save') : $t('buttons.add')
                  }}</v-btn
                >
                <v-btn
                  class="add mt-6"
                  size="x-large"
                  style="
                    text-transform: uppercase !important;
                    border-radius: 30px !important;
                    background-color: var(--red) !important;
                    padding: 2vh 3vh !important;
                    font-weight: bold;
                    font-size: 16px !important;
                  "
                  dark
                  @click="resetBankModal"
                >
                  {{ $t('buttons.close') }}
                </v-btn>
              </v-card-actions>
            </form>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <!-- Add Roles To Company Modal END -->
    <delete-modal :yesFunc="deleteRole" ref="deleteModalRef" />
    <!-- Page content -->
    <div v-if="!showTabScreen" class="contract-view" style="padding: 20px">
      <v-card style="border-radius: 10px">
        <v-card-title style="color: #0177d4">
          <v-row align="center">
            <v-col cols="8" class="py-2">
              <v-icon
                v-if="!showTabScreen"
                @click.stop="showTabScreen = true"
                style="color: #0177d4"
              >
                mdi-arrow-left
              </v-icon>
              {{
                selectedFile.FileName
                  ? selectedFile.FileName
                  : $t('labels.noFileTitle')
              }}
              - {{ companyFileTypes[selectedFile.CompanyFileType - 1].Name }}
            </v-col>
          </v-row>
        </v-card-title>
        <iframe
          :src="selectedFile.Url"
          width="100%"
          height="1000"
          frameborder="0"
        ></iframe>
      </v-card>
    </div>
    <TabScreen
      :slots="slots"
      v-if="showTabScreen && company"
      :changeIndexFunction="handleTabChange"
    >
      <!-- Contract Details -->
      <template v-slot:contractDetails>
        <v-card
          style="
            padding: 4vh;
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-top-width: 0px;
            border-color: lightgray;
          "
          max-width="100%"
          min-width="80%"
          key="CONTRACT_DETAILS_TAB"
        >
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.prevent="handleSubmit(updateCompany)"
              :style="isOfficer ? '' : 'pointer-events: none;'"
            >
              <v-row>
                <FormElement
                  key="CONTRACT_DETAILS_CUT_OFF_HOUR"
                  :vertical="true"
                  v-model="company.CutOffHour"
                  :validationLabel="$t('labels.cutOffHour')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.cutOffHour')"
                />
                <FormElement
                  key="CONTRACT_DETAILS_LOADING_DEADLINE"
                  :vertical="true"
                  v-model="company.LoadingDeadline"
                  :validationLabel="$t('labels.loadingDeadline')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.loadingDeadline')"
                />
                <FormElement
                  key="CONTRACT_DETAILS_LATEST_NOTIFY_TIME"
                  :vertical="true"
                  v-model="company.LatestNotifyTime"
                  :validationLabel="$t('labels.latestNotifyTime')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.latestNotifyTime')"
                />
                <FormElement
                  key="CONTRACT_DETAILS_TERM_DAY"
                  :vertical="true"
                  v-model="company.TermDay"
                  :validationLabel="$t('labels.termDay')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.termDay')"
                />
                <FormElement
                  key="CONTRACT_DETAILS_PENALTY_PER_DAY"
                  :vertical="true"
                  v-model="company.PenaltyPerDay"
                  :validationLabel="$t('labels.penaltyPerDay')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.penaltyPerDay')"
                />
                <FormElement
                  key="CONTRACT_DETAILS_PENALTY_CURRENCY"
                  :vertical="true"
                  v-model="company.PenaltyPerDayCurrencyId"
                  :validationLabel="$t('labels.currency')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: currencies,
                      text: 'Code',
                      value: 'Id',
                    },
                  }"
                  :placeholder="$t('labels.currency')"
                />
                <v-col
                  v-if="isOfficer"
                  cols="12"
                  class="d-flex justify-center align-center"
                >
                  <v-btn
                    class="add"
                    size="x-large"
                    style="
                      text-transform: uppercase !important;
                      border-radius: 30px !important;
                      padding: 3vh 4vh !important;
                      font-weight: bold;
                      font-size: 16px !important;
                    "
                    dark
                    type="submit"
                    :loading="isLoading"
                  >
                    {{ $t('buttons.save') }}
                  </v-btn>
                </v-col>
              </v-row>
            </form></ValidationObserver
          >
        </v-card>
      </template>
      <!-- Contract Details END -->
      <!-- Information Template -->
      <template v-slot:information>
        <v-card
          style="
            padding: 5vh;
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-top-width: 0px;
            border-color: lightgray;
          "
          max-width="100%"
          min-width="80%"
          key="INFORMATION_TAB"
        >
          <v-row>
            <v-col
              style="border-radius: 10px; border: 1px solid rgb(213, 213, 213)"
              cols="12"
              lg="12"
              class="mb-4"
              v-if="
                $store.state.Type === 'PendingCustomer' ||
                $store.state.Type === 'PendingSupplier'
              "
            >
              <h2 style="color: rgb(245, 106, 106)">
                {{ $t('messages.companyInPending') }}
              </h2>
              <p style="margin-bottom: 0 !important">
                {{ $t('messages.waitingForApproval') }}
              </p>
            </v-col>
            <v-col v-if="company.CompanyWorkType == 1" lg="6" cols="12">
              <div class="d-flex flex-lg-row flex-column align-center">
                <h3 class="mr-lg-4">{{ $t('labels.rating') }}</h3>
                <v-rating
                  empty-icon="mdi-star-outline"
                  full-icon="mdi-star"
                  half-icon="mdi-star-half-full"
                  dense
                  length="5"
                  half-increments
                  color="#FFCD06"
                  background-color="#FFCD06"
                  size="64"
                  readonly
                  large
                  :value="company.RatingAvg"
                  style="width: fit-content"
                  class="align-self-center"
                >
                </v-rating>
                <h3 style="font-size: 14px; color: #6b5507" class="ml-1">
                  {{ company.RatingAvg }}
                </h3>
              </div>
            </v-col>
            <v-col md="12" xs="12">
              <div>
                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                  <form
                    @submit.prevent="handleSubmit(updateCompany)"
                    :style="isOfficer ? '' : 'pointer-events: none;'"
                  >
                    <v-row>
                      <FormElement
                        v-for="(element, index) in formItems"
                        :key="
                          element.key.parent +
                          '_' +
                          element.key.child +
                          '_' +
                          index
                        "
                        :vertical="element.vertical"
                        v-model="$data[element.key.parent][element.key.child]"
                        :validationLabel="$t(element.validationLabel)"
                        :rules="element.rules"
                        :formLabel="$t(element.formLabel)"
                        :onChange="element.onChange"
                        :formType="element.formType"
                        :lg="element.lg"
                        :cols="element.cols"
                        :light="element.light"
                        :hidden="element.hidden"
                        :labelShown="element.labelShown"
                        :topLabel="element.topLabel"
                        :active="element.active"
                        :disabled="element.disabled"
                        :placeholder="$t(element.placeholder)"
                      />
                    </v-row>
                    <v-row class="align-center">
                      <v-col
                        v-if="isOfficer"
                        cols="12"
                        class="d-flex justify-center align-center"
                      >
                        <v-btn
                          class="add"
                          size="x-large"
                          style="
                            text-transform: uppercase !important;
                            border-radius: 30px !important;
                            padding: 3vh 4vh !important;
                            font-weight: bold;
                            font-size: 16px !important;
                          "
                          dark
                          type="submit"
                          :loading="isLoading"
                        >
                          {{ $t('buttons.save') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </form>
                </ValidationObserver>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </template>
      <!-- Information Template END -->
      <template v-slot:roles>
        <v-card
          key="ROLES_TAB"
          style="
            padding: 2vh;
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-top-width: 0px;
            border-color: lightgray;
          "
        >
          <v-card-title v-if="isOfficer" style="color: #595959">
            <v-row>
              <FormElement
                v-model="filters.fullName"
                :validationLabel="$t('labels.fullName')"
                :formLabel="$t('labels.fullName')"
                @input="getSearchDebounced"
                :formType="{
                  name: 'text',
                  appendIcon: 'mdi-magnify',
                  appendClick: () => {
                    getSearchDebounced();
                  },
                }"
                :labelShown="false"
                :topLabel="true"
                :cols="12"
                :sm="12"
                :md="3"
                :lg="3"
                :xl="3"
                :placeholder="$t('labels.fullName')"
              />

              <FormElement
                v-for="(element, index, key) in filterFormItems"
                :key="element.key.parent + '_' + element.key.child"
                :vertical="element.vertical"
                v-model="$data[element.key.parent][element.key.child]"
                :validationLabel="$t(element.validationLabel)"
                :rules="element.rules"
                @input="element.input"
                :formLabel="$t(element.formLabel)"
                :topLabel="element.topLabel"
                :labelShown="element.labelShown"
                :onChange="element.onChange"
                :md="element.md"
                :cols="element.cols"
                :sm="element.sm"
                :lg="element.lg"
                :xl="element.xl"
                :formType="element.formType"
                :placeholder="$t(element.placeholder)"
              />
              <v-spacer />
              <v-col class="align-self-center d-flex justify-end">
                <v-icon
                  v-if="!isPending"
                  @click="
                    addRoleModal = true;
                    modalHeader = $t('title.add', {
                      value: $t('labels.user.singular'),
                    });
                  "
                  small
                  rounded
                  style="font-size: 32px"
                >
                  mdi-plus-box-outline
                </v-icon>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="roles"
              :loading="loadings.table"
              :options.sync="options"
              :items-per-page="itemsPerPage"
              :hide-default-footer="true"
              :no-data-text="$t('description.noList')"
            >
              <!-- eslint-disable -->
              <template v-slot:item.FullName="{ item }">
                {{ item.Name + ' ' + item.Surname }}
              </template>
              <template v-slot:item.Roles[0]="{ item }">
                {{ $t('labels.roles.' + item.Roles[0]) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="text-start">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="changeActiveRole(item, true)"
                        middle
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        color="#29B7B7"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>{{ $t('labels.tooltipEdit') }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        middle
                        v-if="item.Id !== $store.state.Id"
                        class="mr-2"
                        @click="openDeleteModal(item)"
                        v-bind="attrs"
                        v-on="on"
                        color="#29B7B7"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>{{ $t('labels.tooltipDelete') }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template
                      v-if="item.Id === $store.state.Id && !isPending"
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-icon
                        middle
                        class="mr-2"
                        color="#29B7B7"
                        @click="$router.push({ name: 'profile' })"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-account
                      </v-icon>
                    </template>
                    <span>{{ $t('navigationDrawerMenu.profile') }}</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
            <div class="d-flex justify-center mt-8">
              <v-row align="center" justify="center">
                <v-col
                  v-if="
                    $vuetify.breakpoint.md ||
                    $vuetify.breakpoint.lg ||
                    $vuetify.breakpoint.xl
                  "
                  md="2"
                ></v-col>
                <v-col cols="9" sm="12" md="7" lg="7" xl="7">
                  <v-pagination
                    @input="paginationChangeHandler"
                    :value="pagination.page"
                    :length="pages"
                    :total-visible="15"
                    circle
                  ></v-pagination>
                </v-col>
                <v-col
                  cols="11"
                  sm="11"
                  md="3"
                  lg="3"
                  xl="3"
                  class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
                >
                  <v-select
                    v-model="itemsPerPage"
                    :items="perPageValues"
                    :label="$t('labels.selectItemsPerPage')"
                    item-value="value"
                    item-text="name"
                    @change="changeItemsPerPage()"
                    outlined
                    dense
                    rounded
                  >
                  </v-select>
                </v-col>
              </v-row>
              <!-- Pagination select box end -->
            </div>
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:attachedFiles>
        <v-card
          key="ATTACHED_FILES_TAB"
          style="
            padding: 2vh;
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-top-width: 0px;
            border-color: lightgray;
          "
        >
          <div>
            <!-- Modal for adding new file -->
            <v-dialog persistent v-model="isFileUploadOpen" max-width="500">
              <v-card class="popup-card" elevation="1">
                <v-card-title
                  class="popupHeadline d-flex justify-center"
                  style="font-size: 16px"
                  >{{ $t('labels.addNewFile') }}</v-card-title
                >

                <v-divider class="mb-4" />
                <ValidationObserver
                  v-slot="{ handleSubmit }"
                  ref="fileUploadObserver"
                >
                  <v-card-text>
                    <form @submit.prevent="handleSubmit(fileUploadOperation)">
                      <v-row>
                        <FormElement
                          v-model="fileName"
                          :validationLabel="$t('labels.fileName')"
                          :formLabel="$t('labels.fileName')"
                          :rules="'required'"
                          :formType="{
                            name: 'text',
                          }"
                          :topLabel="true"
                          :labelShown="false"
                          :lg="6"
                          :placeholder="$t('labels.fileName')"
                        />
                        <FormElement
                          v-model="selectedCompanyFileType"
                          :validationLabel="$t('labels.fileType')"
                          :formLabel="$t('labels.fileType')"
                          :rules="'required'"
                          :formType="{
                            name: 'select',
                            items: {
                              data: companyFileTypes,
                              value: 'Value',
                              text: 'Name',
                            },
                          }"
                          :topLabel="true"
                          :labelShown="false"
                          :lg="6"
                          :placeholder="$t('labels.fileType')"
                        />
                      </v-row>
                      <v-row>
                        <FormElement
                          v-model="selectedDocument"
                          :validationLabel="$t('labels.file')"
                          :formLabel="$t('labels.selectFile')"
                          :rules="'required'"
                          :formType="{
                            name: 'file',
                            showSize: true,
                            data: {
                              accept: 'application/pdf',
                            },
                          }"
                          :topLabel="true"
                          :labelShown="false"
                          :placeholder="$t('labels.selectFile')"
                        />
                      </v-row>

                      <v-card-actions style="justify-content: center">
                        <v-btn
                          style="
                            color: #fff;
                            text-transform: unset !important;
                            background-color: #0077d5;
                            padding: 2vh 2vh;
                            border-radius: 30px !important;
                            font-weight: bolder;
                            font-size: medium;
                          "
                          elevation="0"
                          class="darken-1"
                          type="submit"
                          >{{ $t('buttons.add') }}</v-btn
                        >
                        <v-btn
                          style="
                            color: #fff;
                            text-transform: unset !important;
                            background-color: var(--red);
                            padding: 2vh 2vh;
                            border-radius: 30px !important;
                            font-weight: bolder;
                            font-size: medium;
                          "
                          elevation="0"
                          class="darken-1"
                          type="submit"
                          @click="
                            isFileUploadOpen = false;
                            clearFileUploadDialog();
                          "
                          >{{ $t('buttons.cancel') }}</v-btn
                        >
                      </v-card-actions>
                    </form>
                  </v-card-text>
                </ValidationObserver>
              </v-card>
            </v-dialog>
            <!-- Ekli Dosyalar List -->
            <v-list>
              <v-list-item
                class="mb-3"
                style="
                  border-style: solid;
                  border-width: 0px;
                  border-bottom-width: 2px;
                  border-color: lightgrey;
                "
              >
                <!-- Left Side (Pdf icon, Date, Name) -->
                <v-list-item-content>
                  <v-data-table
                    :headers="getFileHeaders()"
                    :loading="$store.state.loadingStatus"
                    :items="companyFiles"
                    :no-data-text="$t('messages.noData')"
                    hide-default-footer
                  >
                    <!-- eslint-disable -->
                    <template v-slot:item.CreatedOnUtc="{ item }">
                      {{ convertToLocal(item.CreatedOnUtc) }}
                    </template>
                    <template v-slot:item.CompanyFileType="{ item }">
                      <v-chip
                        v-if="item.CompanyFileType == 1"
                        class="ma-2"
                        small
                        color="#79a832"
                        text-color="black"
                      >
                        {{ companyFileTypes[item.CompanyFileType - 1].Name }}
                      </v-chip>
                      <v-chip
                        v-if="item.CompanyFileType == 2"
                        class="ma-2"
                        small
                        color="#32a860"
                        text-color="black"
                      >
                        {{ companyFileTypes[item.CompanyFileType - 1].Name }}
                      </v-chip>
                      <v-chip
                        v-if="item.CompanyFileType == 3"
                        class="ma-2"
                        small
                        color="#a85332"
                        text-color="black"
                      >
                        {{ companyFileTypes[item.CompanyFileType - 1].Name }}
                      </v-chip>
                      <v-chip
                        v-if="item.CompanyFileType == 4"
                        class="ma-2"
                        small
                        color="#a83275"
                        text-color="black"
                      >
                        {{ $t('labels.labelsOrderStatuses.approved') }}
                      </v-chip>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <div class="d-flex text-center justify-center">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              color="#29B7B7"
                              class="mr-1"
                              style="font-size: 20px"
                              v-bind="attrs"
                              v-on="on"
                              @click="navigateContract(item)"
                            >
                              mdi-eye
                            </v-icon>
                          </template>
                          <span>{{ $t('labels.tooltipInspectDocument') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              color="#29B7B7"
                              class="mr-1"
                              style="font-size: 20px"
                              v-bind="attrs"
                              v-on="on"
                              @click="downloadDocument(item)"
                            >
                              mdi-download
                            </v-icon>
                          </template>
                          <span>{{ $t('buttons.downloadFile') }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              color="#29B7B7"
                              class="mr-1"
                              style="font-size: 20px"
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteDocument(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                          <span>{{ $t('buttons.deleteFile') }}</span>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-data-table>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <div class="d-flex justify-center">
                    <v-btn
                      variant="tonal"
                      color="red"
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        padding: 2vh 4vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: large;
                      "
                      @click="isFileUploadOpen = true"
                      class="darken-1"
                      type="submit"
                    >
                      {{ $t('labels.addNewFile') }}
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-card>
      </template>
      <!-- Notifications Template -->
      <template v-slot:notifications>
        <v-card
          key="NOTIFICATIONS_TAB"
          style="
            padding: 2vh;
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-top-width: 0px;
            border-color: lightgray;
          "
          max-width="100%"
          min-width="80%"
        >
          <v-row>
            <v-col cols="12">
              <h2 class="popupHeadline text-lg-left text-center">
                {{ $t('labels.languagePreference') }}
              </h2>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4">
              <input
                v-model="notification.languagePreference"
                type="radio"
                id="zero"
                value="0"
                style="
                  width: 20px;
                  height: 20px;
                  accent-color: var(--mainColor);
                  margin-right: 10px;
                "
              />
              <label
                style="
                  display: inline-block;
                  padding: 5px 10px;
                  border-radius: 15px;
                  background-color: var(--mainColor);
                  color: white;
                  cursor: pointer;
                "
                class="custom-label-form-element mr-10"
                for="one"
                >Türkçe</label
              >
              <input
                v-model="notification.languagePreference"
                type="radio"
                id="one"
                value="1"
                style="
                  width: 20px;
                  height: 20px;
                  accent-color: var(--mainColor);
                  margin-right: 10px;
                "
              />
              <label
                style="
                  display: inline-block;
                  padding: 5px 10px;
                  border-radius: 15px;

                  background-color: var(--mainColor);
                  color: white;
                  cursor: pointer;
                "
                class="custom-label-form-element"
                for="one"
                >English</label
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="8" xl="5">
              <v-alert outlined text class="mt-4 mb-n4" type="info">{{
                $t('messages.multipleEmailInformation')
              }}</v-alert>
            </v-col>
            <v-col md="12" xs="12">
              <div>
                <ValidationObserver
                  ref="notificationsObserver"
                  v-slot="{ handleSubmit }"
                >
                  <form @submit.prevent="handleSubmit(updateNotifications)">
                    <v-row
                      style="border-bottom: 1px solid rgb(212, 212, 212)"
                      :key="serviceType"
                      v-for="(serviceType, index) in notificationsFormTitles"
                      class="align-center my-2"
                    >
                      <v-col lg="12" cols="12">
                        <h3 class="popupHeadline text-lg-left text-center">
                          {{ $t('labels.' + serviceType.toLocaleLowerCase()) }}
                        </h3>
                      </v-col>
                      <v-col
                        v-for="(department, departmentIndex) in Object.keys(
                          notificationsFormItem
                        )"
                        :key="'DEPARTMENT_' + department"
                        lg="6"
                      >
                        <v-col cols="12" lg="12">
                          <h5 style="color: var(--pink)">
                            {{ $t('labels.' + department) }}
                          </h5>
                        </v-col>

                        <FormElement
                          :disabled="!isOfficer"
                          :key="'SMS_' + serviceType + '_' + departmentIndex"
                          :vertical="true"
                          v-model="
                            company[
                              `${serviceType}${notificationsFormItem[department][1]}`
                            ]
                          "
                          :validationLabel="
                            $t('labels.' + serviceType.toLocaleLowerCase()) +
                            ' ' +
                            $t('labels.' + department) +
                            ' ' +
                            $t('labels.phone')
                          "
                          :rules="rules.phone"
                          :topLabel="true"
                          :labelShown="false"
                          :lg="12"
                          :formType="{
                            name: 'text',
                          }"
                          :placeholder="$t('labels.phone')"
                        />
                        <FormElement
                          :disabled="!isOfficer"
                          :key="'Email_' + serviceType + '_' + departmentIndex"
                          :vertical="true"
                          v-model="
                            company[
                              `${serviceType}${notificationsFormItem[department][0]}`
                            ]
                          "
                          :validationLabel="
                            $t('labels.' + serviceType.toLocaleLowerCase()) +
                            '  ' +
                            $t('labels.' + department) +
                            ' ' +
                            $t('labels.email')
                          "
                          rules="multipleEmail"
                          :topLabel="true"
                          :labelShown="false"
                          :lg="12"
                          :formType="{
                            name: 'text',
                          }"
                          :placeholder="$t('labels.email')"
                        />
                      </v-col>
                    </v-row>
                    <v-row
                      style="border-bottom: 1px solid rgb(212, 212, 212)"
                      class="align-center my-2"
                    >
                      <v-col lg="12" cols="12">
                        <h3 class="popupHeadline text-lg-left text-center">
                          {{ $t('labels.pickupOrganisation') }}
                        </h3>
                      </v-col>
                      <v-col lg="6">
                        <FormElement
                          :disabled="!isOfficer"
                          :key="'Email_pickupOrganisation'"
                          :vertical="true"
                          v-model="company.PickupOperationMail"
                          :validationLabel="$t('labels.email')"
                          rules="multipleEmail"
                          :topLabel="true"
                          :labelShown="false"
                          :lg="12"
                          :formType="{
                            name: 'text',
                          }"
                          :placeholder="$t('labels.email')"
                        />
                      </v-col>
                      <v-col lg="12" cols="12">
                        <h3 class="popupHeadline text-lg-left text-center">
                          {{ $t('labels.warehouseOrganisation') }}
                        </h3>
                      </v-col>
                      <v-col lg="6">
                        <FormElement
                          :disabled="!isOfficer"
                          :key="'Email_warehouseOrganisation'"
                          :vertical="true"
                          v-model="company.WarehouseOperationMail"
                          :validationLabel="$t('labels.email')"
                          rules="multipleEmail"
                          :topLabel="true"
                          :labelShown="false"
                          :lg="12"
                          :formType="{
                            name: 'text',
                          }"
                          :placeholder="$t('labels.email')"
                        />
                      </v-col>
                      <v-col lg="12" cols="12">
                        <h3 class="popupHeadline text-lg-left text-center">
                          {{ $t('labels.accountantOrganisation') }}
                        </h3>
                      </v-col>
                      <v-col lg="6">
                        <FormElement
                          :disabled="!isOfficer"
                          :key="'Email_accountantOrganisation'"
                          :vertical="true"
                          v-model="company.AccountantOperationMail"
                          :validationLabel="$t('labels.email')"
                          rules="multipleEmail"
                          :topLabel="true"
                          :labelShown="false"
                          :lg="12"
                          :formType="{
                            name: 'text',
                          }"
                          :placeholder="$t('labels.email')"
                        />
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="isOfficer"
                      class="align-self-center justify-center"
                    >
                      <v-col cols="3" lg="2" xl="3">
                        <v-btn
                          class="add"
                          size="x-large"
                          style="
                            text-transform: uppercase !important;
                            border-radius: 30px !important;
                            padding: 3vh 4vh !important;
                            font-weight: bold;
                            font-size: 16px !important;
                          "
                          dark
                          type="submit"
                          :loading="isLoading"
                        >
                          {{ $t('buttons.save') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </form>
                </ValidationObserver>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </template>
      <template v-slot:bankInformation>
        <v-card
          style="
            padding-top: 2vh;
            padding-left: 2vh;
            padding-right: 2vh;
            padding-bottom: 3vh;
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-top-width: 0px;
            border-color: lightgray;
          "
          max-width="100%"
          min-width="80%"
          key="BANKINFORMATION_TAB"
        >
          <v-btn
            @click="openAddBankModal"
            class="rounded-circle d-flex ml-auto"
            style="
              min-width: 1px;
              min-height: 1px;
              width: 36px;
              height: 36px;
              background-color: #0177d4;
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small color="white" style="font-size: 24px" v-on="on">
                  mdi-plus
                </v-icon>
              </template>
              <span>{{ $t('labels.addBankInfo') }}</span>
            </v-tooltip>
          </v-btn>
          <v-data-table
            :headers="bankHeaders"
            :items="bankInformation"
            :loading="loadings.table"
            :options.sync="options"
            :items-per-page="itemsPerPage"
            :hide-default-footer="true"
            :no-data-text="$t('description.noList')"
          >
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="#29B7B7"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="openEditBankModal(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $t('labels.tooltipEdit') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="red"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteBankInformation(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>{{ $t('labels.tooltipDelete') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </template>
      <template v-slot:surchargeInformation>
        <v-card
          style="
            padding-top: 5vh;
            padding-left: 2vh;
            padding-right: 2vh;
            padding-bottom: 3vh;
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-top-width: 0px;
            border-color: lightgray;
          "
          max-width="100%"
          min-width="80%"
          key="SURCHARGEINFORMATION_TAB"
        >
          <v-row>
            <v-col
              style="border-radius: 10px; border: 1px solid rgb(213, 213, 213)"
              cols="12"
              lg="12"
              class="mb-4"
              v-if="
                $store.state.Type === 'PendingCustomer' ||
                $store.state.Type === 'PendingSupplier'
              "
            >
              <h2 style="color: rgb(245, 106, 106)">
                {{ $t('messages.companyInPending') }}
              </h2>
              <p style="margin-bottom: 0 !important">
                {{ $t('messages.waitingForApproval') }}
              </p>
            </v-col>
            <v-col cols="12" md="12" xs="12">
              <div>
                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                  <form>
                    <v-expansion-panels :value="0" class="py-5">
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          style="padding: 0"
                          class="pl-6 pt-4 pr-4 pb-2"
                        >
                          <p
                            style="color: #235c9e; font-weight: bold"
                            class="text-h6 pt-2"
                          >
                            {{ $t('labels.dangerSubstanceLabel') }}
                          </p>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row class="mt-3">
                            <template v-for="(price, index) in hazmatPrices">
                              <FormElement
                                v-model="price.Price"
                                :vertical="true"
                                :validationLabel="
                                  $t(
                                    `hazmatCodes.${price.LoadingType}${price.ShipmentType}`
                                  )
                                "
                                :topLabel="true"
                                :labelShown="false"
                                :lg="4"
                                :formType="{ name: 'text' }"
                                :placeholder="
                                  $t(
                                    `hazmatCodes.${price.LoadingType}${price.ShipmentType}`
                                  )
                                "
                              />

                              <FormElement
                                :vertical="true"
                                v-model="price.PriceCurrencyId"
                                :validationLabel="$t('labels.currency')"
                                rules="required"
                                :topLabel="true"
                                :labelShown="false"
                                :lg="2"
                                :formType="{
                                  name: 'multipleSelect',
                                  items: {
                                    data: currencies,
                                    text: 'Code',
                                    value: 'Id',
                                  },
                                }"
                                :placeholder="$t('labels.currency')"
                              />
                            </template>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </form>
                </ValidationObserver>
              </div>
            </v-col>
            <v-row class="align-center">
              <v-col
                v-if="isOfficer"
                cols="12"
                class="d-flex justify-center align-center"
              >
                <v-btn
                  class="add"
                  size="x-large"
                  style="
                    text-transform: uppercase !important;
                    border-radius: 30px !important;
                    padding: 3vh 4vh !important;
                    font-weight: bold;
                    font-size: 16px !important;
                  "
                  dark
                  @click="updateHazmatPrices"
                >
                  {{ $t('buttons.save') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-row>
        </v-card>
      </template>
    </TabScreen>
  </div>
</template>

<script>
import CompanyServices from '../../services/CompanyServices';
import AddressService from '../../services/AddressService';
import DeleteModal from '../../components/General/DeleteModal';
import TabScreen from '../../components/TabScreen.vue';
import UserService from '../../services/UserService';
import uploadFile from '../../utils/uploadFile';
import FormElement from '../../components/FormElement.vue';
import FileService from '../../services/FileService';
import OrderService from '../../services/OrderService';
import CompanyHazmatService from '../../services/CompanyHazmatService';
import RoleServices from '../../services/RoleServices';
import {
  clearText,
  getCountries,
  getCurrencyTypes,
} from '../../utils/constants';
import rules from '../../utils/validationRules';

export default {
  data: () => ({
    // General Models
    isLoading: false,
    isUpdate: false,
    base64: '',
    showPassword: false,
    fileParent: {
      file: null,
    },
    role: {
      Name: '',
      Surname: '',
      Username: '',
      Email: '',
      Password: '',
      Gender: '',
      PhoneNumber: '',
      CompanyId: '',
      Authority: '',
      Role: '',
      ContactPermission: false,
    },
    roleList: [],
    descriptionLimit: 60,
    model: null,
    search: null,
    roles: [],
    slots: [],
    deletedId: -1,
    // Company Data
    company: null,
    debounceTimeout: null,
    hazmatPrices: [],
    generalProps: {
      file: null,
    },
    currencies: [],
    cities: [
      {
        id: 'string',
        value: 'İzmir',
      },
    ],
    countries: [],

    cityParent: {
      cityChild: null,
    },

    addRoleModal: false,

    // List options
    options: {
      itemsPerPage: 15,
    },
    phoneCodeItems: [],
    top: 15,
    skip: 0,
    headers: [],
    //Loading states
    loadings: {
      table: false,
      send: false,
    },
    companyTypes: [],
    accountTypes: [],
    bankHeaders: [],
    bankInformation: [],
    showTabScreen: true,
    modalHeader: '',
    pagination: {
      page: 1,
      rowsPerPage: 15,
    },
    itemsPerPage: 15,
    bankModal: false,
    isEditBankModal: false,
    newBank: {
      BankName: '',
      IBAN: '',
      AccountOwner: '',
    },
    editBank: {
      Id: '',
      BankName: '',
      IBAN: '',
      AccountOwner: '',
    },
    perPageValues: [
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],
    selectedDocument: null,
    isFileUploadOpen: false,
    fileName: '',
    companyFiles: [],
    selectedCompanyFileType: null,
    companyFileTypes: [],
    selectedFile: null,
    notificationsFormTitles: ['Seaway', 'Airline', 'Roadway', 'Railway'],
    notificationsFormItem: {
      pricing: ['PriceMail', 'PriceSms'],
      operation: ['OperationMail', 'OperationSms'],
    },
    notification: {
      languagePreference: 0,
    },
    contractDetails: {},
    temporaryReceiptAddress: '',
    receiptStatus: false,

    upload: {
      logo: false,
      mailLogo: false,
    },

    filters: {
      fullName: '',
      authority: '',
    },

    weeklyAveragesForm: {
      updated: false,
    },
  }),

  computed: {
    filterFormItems() {
      const callback = this.getRoles;

      return [
        {
          key: {
            parent: 'filters',
            child: 'authority',
          },
          cols: 12,
          sm: 12,
          md: 3,
          lg: 3,
          xl: 3,
          validationLabel: 'labels.authority',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            appendIcon: 'mdi-magnify',
            appendClick: () => {
              getSearchDebounced();
            },
            items: {
              data: this.roleList,
              disableAutoSelection: true,
              value: 'Name',
              text: 'Name',
            },
          },
          input: this.getSearchDebounced,
          placeholder: 'labels.authority',
        },
      ];
    },
    rules() {
      return rules;
    },
    formItems() {
      return [
        {
          key: {
            parent: 'company',
            child: 'CompanyName',
          },
          rules: 'required',
          validationLabel: 'labels.companyName',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          placeholder: 'labels.companyName',
        },
        {
          key: {
            parent: 'company',
            child: 'CompanyWorkType',
          },
          rules: 'required',
          validationLabel: 'labels.accountType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.companyTypes,
              value: 'Value',
              text: 'Name',
            },
          },
          lg: 6,
          disabled: true,
          placeholder: 'labels.accountType',
        },
        {
          key: {
            parent: 'company',
            child: 'AccountType',
          },
          rules: 'required',
          validationLabel: 'labels.accountType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.accountTypes,
              value: 'Value',
              text: 'Name',
            },
          },
          lg: 6,
          placeholder: 'labels.companyType',
        },
        {
          key: {
            parent: 'company',
            child: 'CountryId',
          },
          rules: 'required',
          validationLabel: 'labels.country',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.countries,
              value: 'Id',
              text: 'Name',
            },
          },
          onChange: async () => {
            this.onChangeCountry();
          },
          lg: 6,
          placeholder: 'labels.country',
        },

        {
          key: {
            parent: 'cityParent',
            child: 'cityChild',
          },
          rules: 'required',
          validationLabel: 'labels.city',
          topLabel: true,
          active: this.cities.length > 0,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.cities,
              value: 'Id',
              text: 'Name',
            },
          },
          lg: 6,
          placeholder: 'labels.city',
        },
        {
          key: {
            parent: 'company',
            child: 'District',
          },
          rules: 'required',
          validationLabel: 'labels.district',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          placeholder: 'labels.district',
        },

        {
          key: {
            parent: 'company',
            child: 'TaxNo',
          },
          rules: 'required|numeric',
          validationLabel: 'labels.company.taxNo',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          placeholder: 'labels.company.taxNo',
        },
        {
          key: {
            parent: 'company',
            child: 'TaxOffice',
          },
          rules: 'required',
          validationLabel: 'labels.company.taxOffice',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          placeholder: 'labels.company.taxOffice',
        },
        {
          key: {
            parent: 'company',
            child: 'Address1',
          },
          rules: 'required',
          validationLabel: 'labels.companyAddress',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },
          lg: 6,
          placeholder: 'labels.companyAddress',
        },
        {
          key: {
            parent: 'company',
            child: 'ReceiptAddress',
          },
          rules: this.company.receiptStatus ? '' : 'required',
          hidden: this.company.receiptStatus,
          validationLabel: 'labels.receiptAddress',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },
          lg: 6,
          placeholder: 'labels.receiptAddress',
        },
        {
          key: {
            parent: 'company',
            child: 'GsmNo',
          },
          rules: rules.phone,
          validationLabel: 'labels.phone',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          placeholder: 'labels.phone',
        },
        {
          key: {
            parent: 'company',
            child: 'Email',
          },
          rules: 'required|multipleEmail',
          validationLabel: 'labels.email',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          placeholder: 'labels.email',
        },

        // Weekly Averages Form Items
        {
          key: {
            parent: 'weeklyAveragesForm',
            child: 'AverageWeeklyUploadCount',
          },
          validationLabel: 'labels.averageWeeklyLoadCount',
          formType: {
            name: 'text',
          },
          rules: 'numeric',
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.averageWeeklyLoadCount',
          onChange: () => {
            this.weeklyAveragesForm.updated = true;
          },
        },
        {
          key: {
            parent: 'weeklyAveragesForm',
            child: 'AverageWeeklyUploadWeight',
          },
          validationLabel: 'labels.averageWeeklyLoadWeight',
          formType: {
            name: 'text',
          },
          rules: 'numeric',
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.averageWeeklyLoadWeight',
          onChange: () => {
            this.weeklyAveragesForm.updated = true;
          },
        },
        // Weekly Averages Form Items END

        {
          key: {
            parent: 'company',
            child: 'receiptStatus',
          },
          validationLabel: 'labels.receiptAddressInfo',
          topLabel: true,
          light: false,
          labelShown: false,
          formType: {
            name: 'checkbox',
          },
          onChange: () => {
            this.clickedReceiptStatus();
          },
          lg: 12,
          placeholder: 'labels.receiptAddressInfo',
        },
        {
          key: {
            parent: 'company',
            child: 'IsRate',
          },
          validationLabel: 'labels.isRate',
          topLabel: true,
          light: false,
          labelShown: false,
          formType: {
            name: 'checkbox',
          },

          lg: 12,
          placeholder: 'labels.isRate',
        },
        {
          key: {
            parent: 'fileParent',
            child: 'file',
          },
          validationLabel: 'labels.logo',
          formLabel: 'labels.logo',
          labelShown: false,
          onChange: () => (this.upload.logo = true),
          formType: {
            name: 'file',
            type: 'image',
            data: {
              lazy: this.company.PhotoUrl,
              source: this.company.PhotoUrl,
              accept: 'image/png, image/jpeg, image/bmp',
            },
          },
          lg: 6,
          placeholder: 'labels.logo',
        },
        {
          key: {
            parent: 'fileParent',
            child: 'mailLogo',
          },
          validationLabel: 'labels.mailLogo',
          formLabel: 'labels.mailLogo',
          labelShown: false,
          onChange: () => (this.upload.mailLogo = true),
          formType: {
            name: 'file',
            type: 'image',
            data: {
              lazy: this.company.MailPhotoUrl,
              source: this.company.MailPhotoUrl,
              accept: 'image/png, image/jpeg, image/bmp',
            },
          },
          lg: 6,
          active: this.isSupplier,
          placeholder: 'labels.mailLogo',
        },
      ];
    },
    getNoImage() {
      return require('../../assets/no-image.png');
    },
    isSupplier() {
      return (
        this.$store.state.Type === 'Supplier' ||
        this.$store.state.Type === 'PendingSupplier'
      );
    },
    isOfficer() {
      return this.company.OfficerId === this.$store.state.Id;
    },
    isPending() {
      return (
        this.$store.state.Type === 'PendingCustomer' ||
        this.$store.state.Type === 'PendingSupplier'
      );
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    fields() {
      if (!this.model) return [];
      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || 'n/a',
        };
      });
    },
    items() {
      return this.roles.map((entry) => {
        const FullName = entry.Name + ' ' + entry.Surname;
        const UserId = entry.Id || 1;
        return Object.assign({}, entry, { UserId, FullName });
      });
    },
  },

  watch: {
    'notification.languagePreference'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateLanguage();
      }
    },
    search(val) {
      // Items have already been loaded
      if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      this.getRoles();
    },
  },

  async mounted() {
    this.perPageValues.push({
      name: this.$t('labels.All'),
      value: 9999,
    });
    this.roleList = [];

    this.companyFileTypes = [
      {
        Name: this.$t('labels.companyFileTypeTaxBoard'),
        Value: 1,
      },
      {
        Name: this.$t('labels.companyFileTypeCircularOfSignature'),
        Value: 2,
      },
      {
        Name: this.$t('labels.companyFileTypeCertificateOfRegistry'),
        Value: 3,
      },
      {
        Name: this.$t('labels.companyFileTypeArticlesOfIncorporation'),
        Value: 4,
      },
    ];

    this.companyTypes = [
      {
        Name: this.$t('labels.supplier'),
        Value: 1,
      },
      {
        Name: this.$t('labels.customer'),
        Value: 2,
      },
    ];
    this.accountTypes = [
      {
        Name: this.$t('labels.personal'),
        Value: 0,
      },
      {
        Name: this.$t('labels.corporate'),
        Value: 1,
      },
    ];

    this.bankHeaders = [
      {
        text: this.$t('labels.bankName'),
        value: 'BankName',
        sortable: false,
      },
      {
        text: this.$t('labels.accountOwner'),
        value: 'FullName',
        sortable: false,
      },
      {
        text: this.$t('labels.IBAN'),
        value: 'IBAN',
        sortable: false,
      },
      {
        text: this.$t('labels.tableActions'),
        value: 'actions',
        sortable: false,
      },
    ];
    this.slots = [
      { name: 'information', title: this.$t('navigationDrawerMenu.company') },
      { name: 'roles', title: this.$t('company.roles') },
      { name: 'attachedFiles', title: this.$t('titles.attachments') },
    ];

    if (this.isSupplier) {
      this.slots.splice(1, 0, {
        name: 'contractDetails',
        title: this.$t('titles.contractDetails'),
      });

      this.slots.push({
        name: 'notifications',
        title: this.$t('labels.notifications'),
      });

      this.slots.push({
        name: 'bankInformation',
        title: this.$t('labels.bankInformation'),
      });

      this.slots.push({
        name: 'surchargeInformation',
        title: this.$t('labels.surchargeInformation'),
      });
    }

    await this.setup();
  },

  components: {
    DeleteModal,
    TabScreen,
    FormElement,
  },
  methods: {
    getSearchDebounced() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.rowsPerPage = 15;
        this.itemsPerPage = 15;
        this.page = 1;
        this.getRoles();
      }, 300);
    },
    getRoleList() {
      this.$store.state.loadingStatus = true;
      let body = {
        Page: 1,
        PageSize: 9999,
        Type: 0,
      };
      RoleServices.get(body)
        .then((response) => {
          this.roleList = response.data.Result.Datas;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getHazmatPrices() {
      this.$store.state.loadingStatus = true;
      CompanyHazmatService.getHazmatCodes()
        .then((response) => {
          this.hazmatPrices = response.data.Result;
          console.log('HAZMAT PRICES >> ', this.hazmatPrices);
          this.$store.state.loadingStatus = false;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    clickedReceiptStatus(checked) {
      if (checked) {
        this.temporaryReceiptAddress = this.company.ReceiptAddress;
        this.company.ReceiptAddress = this.company.Address1;
      } else {
        this.company.ReceiptAddress = this.temporaryReceiptAddress;
      }
    },
    getFileHeaders() {
      return [
        {
          text: this.$t('labels.fileName'),
          value: 'FileName',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.fileType'),
          value: 'CompanyFileType',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.fileUploadDate'),
          value: 'CreatedOnUtc',
          sortable: false,
          groupable: false,
          align: 'center',
        },

        {
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
          groupable: false,
          align: 'center',
        },
      ];
    },
    updateLanguage() {
      this.$store.state.loadingStatus = true;

      let body = {
        OperationSmsLanguage: parseInt(this.notification.languagePreference),
      };

      CompanyServices.updateLanguage(body)
        .then((response) => {
          window.showSuccess(
            this.$t('messages.updateCompanyNotificationLanguageSuccessful')
          );
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    updateNotifications() {
      this.$store.state.loadingStatus = true;
      const body = {
        CompanyId: this.company.Id,
        SeawayPriceMail: this.company.SeawayPriceMail,
        SeawayPriceSms: this.company.SeawayPriceSms,
        SeawayOperationMail: this.company.SeawayOperationMail,
        SeawayOperationSms: this.company.SeawayOperationSms,
        RailwayPriceMail: this.company.RailwayPriceMail,
        RailwayPriceSms: this.company.RailwayPriceSms,
        RailwayOperationMail: this.company.RailwayOperationMail,
        RailwayOperationSms: this.company.RailwayOperationSms,
        RoadwayPriceMail: this.company.RoadwayPriceMail,
        RoadwayPriceSms: this.company.RoadwayPriceSms,
        RoadwayOperationMail: this.company.RoadwayOperationMail,
        RoadwayOperationSms: this.company.RoadwayOperationSms,
        AirlinePriceMail: this.company.AirlinePriceMail,
        AirlinePriceSms: this.company.AirlinePriceSms,
        AirlineOperationMail: this.company.AirlineOperationMail,
        AirlineOperationSms: this.company.AirlineOperationSms,
        PickupOperationMail: this.company.PickupOperationMail,
        AccountantOperationMail: this.company.AccountantOperationMail,
        WarehouseOperationMail: this.company.WarehouseOperationMail,
      };

      CompanyServices.updateDepartment(body)
        .then((response) => {
          window.showSuccess(
            this.$t('messages.updateCompanyNotificationsSuccessful')
          );
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getRoles();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
      this.getRoles();
    },

    //Page setup
    async setup() {
      this.$store.state.loadingStatus = true;
      this.getAllCurrencies();
      this.countries = await getCountries();
      // Get Companies
      await CompanyServices.getById()
        .then(async (response) => {
          this.company = response.data;

          this.receiptStatus =
            this.company.Address1 === this.company.ReceiptAddress;
          this.temporaryReceiptAddress = this.company.ReceiptAddress;

          this.weeklyAveragesForm.AverageWeeklyUploadCount =
            this.company.AverageWeeklyUploadCount;
          this.weeklyAveragesForm.AverageWeeklyUploadWeight =
            this.company.AverageWeeklyUploadWeight;

          this.getRoles();
        })
        .catch((error) => {
          window.showError(error);
        });

      if (this.company.CountryId) {
        await AddressService.getCities(this.company.CountryId)
          .then((response) => {
            this.cities = response.data.Result;
            this.cities = response.data.Result;
            this.cityParent.cityChild = this.company.CityId;
          })
          .catch((error) => {
            window.showError(error);
          });
      }

      this.pagination = {
        totalItems: 15,
        rowsPerPage: 15,
        page: 1,
      };
      this.modalHeader = this.$t('title.add', {
        value: this.$t('labels.user.singular'),
      });

      this.headers = [
        {
          text: this.$t('company.title.users'),
          value: 'FullName',
          requestValue: (item) => {
            return item.Name + ' ' + item.Surname;
          },
          sortable: false,
        },
        {
          text: this.$t('labels.authority'),
          value: 'Roles[0]',
          sortable: false,
        },
        {
          text: this.$t('labels.email'),
          value: 'Email',
          sortable: false,
        },
        {
          text: this.$t('labels.phone'),
          value: 'PhoneNumber',
          sortable: false,
        },
      ];

      if (this.isOfficer) {
        this.headers.push({
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
        });
      }

      this.phoneCodeItems = [
        {
          Name: '+90',
          Id: 90,
        },
        {
          Name: '+91',
          Id: 91,
        },
        {
          Name: '+40',
          Id: 92,
        },
        {
          Name: '+20',
          Id: 93,
        },
      ];
    },

    async getCountries() {
      await AddressService.getCounties()
        .then((response) => {
          this.countries = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Get role list.
     */
    async getAllCurrencies() {
      this.currencies = await getCurrencyTypes();
    },

    //Clears all modal's states and item ID
    clearModal() {
      setTimeout(() => {
        this.modalHeader = '';
        this.resetForm();
        this.isUpdate = false;
        this.role = {
          Name: '',
          Surname: '',
          Username: '',
          Email: '',
          Password: '',
          Gender: '',
          PhoneNumber: '',
          CompanyId: '',
          Authority: '',
          Roles: [],
        };
      }, 500);
    },

    /**
     * Change active role of the page.
     * @param {*} item
     * @param {boolean} isUpdate
     */
    changeActiveRole(item, isUpdate = false) {
      this.modalHeader = this.$t('title.update', {
        value: this.$t('labels.user.singular'),
      });
      this.addRoleModal = true;
      this.role = item;
      this.role.Role = item.Roles[0];
      this.isUpdate = isUpdate;
    },

    /**
     * Get role list.
     */
    getRoles() {
      this.loadings.table = true;
      this.$store.state.loadingStatus = true;
      UserService.getCompanyUsers({
        pageSize: this.pagination.rowsPerPage,
        page: this.pagination.page,
        CompanyId: this.model,
        Role: this.filters.authority,
        Name: this.filters.fullName,
      })
        .then((res) => {
          this.roles = res.data.Result.Users;
          this.pagination.totalItems = res.data.TotalItems - 1;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Add or update a role.
     */
    sendRoleForm() {
      if (this.isUpdate) {
        this.updateRole();
      } else {
        this.createRole();
      }
    },

    onChangeCountry() {
      this.$store.state.loadingStatus = true;
      AddressService.getCities(this.company.CountryId)
        .then((response) => {
          this.cities = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Update Any Company Role
     */
    updateRole() {
      this.loadings.send = true;
      this.$store.state.loadingStatus = true;

      UserService.updateUserRole({
        UserId: this.role.Id,
        Roles: [this.role.Role],
      })
        .then((res) => {
          window.showSuccess(this.$t('messages.successfulUpdateUserRole'));
          this.addRoleModal = false;
          this.clearModal();
          this.getRoles();
        })
        .catch(window.showError)
        .finally(() => {
          this.loadings.send = false;
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Create a role for company.
     */
    createRole() {
      this.loadings.send = true;
      this.$store.state.loadingStatus = true;

      this.role.CompanyId = this.company.Id;
      this.role.PhoneNumber = clearText(this.role.PhoneNumber);
      this.role.IsChangePassword = false;
      if (
        this.$store.state.Type === 'Customer' ||
        this.$store.state.Type === 'PendingCustomer'
      ) {
        this.role.Role = 'Customer';
      }

      UserService.createUser(this.role)
        .then((res) => {
          window.showSuccess(
            this.$t('messages.successfulAdd', {
              value: this.$t('labels.user.singular'),
            })
          );
          this.addRoleModal = false;
          this.clearModal();
          this.getRoles();
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.loadings.send = false;
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Delete a role.
     */
    deleteRole() {
      this.loadings.table = true;
      this.$store.state.loadingStatus = true;

      CompanyServices.deleteCompanyUser(this.deletedId)
        .then((response) => {
          window.showSuccess(this.$t('messages.deleteCompanyUser'));
        })
        .catch((error) => {
          window.handleServiceError(error);
        })
        .finally(() => {
          this.roles = [];
          this.getRoles();
          this.loadings.table = false;
          this.$store.state.loadingStatus = false;
          this.$refs.deleteModalRef.modalStatus = false;
        });
    },
    navigateContract(file) {
      this.selectedFile = file;
      this.showTabScreen = false;
    },
    navigateCarriageContract() {
      this.navigateContract(this.carriageContract);
    },
    /**
     * Shows delete modal.
     * @param {*} item
     */
    openDeleteModal(item) {
      this.deletedId = item.Id;
      this.$refs.deleteModalRef.modalStatus = true;
    },

    /**
     * Resetting forms' validation.
     */
    async resetForm() {
      if (this.$refs.observer) {
        this.$refs.observer.reset();
      } else if (this.$refs.fileUploadObserver) {
        this.$refs.fileUploadObserver.reset();
      }
    },

    uploadReset() {
      this.upload = {
        logo: false,
        mailLogo: false,
      };
    },

    /**
     * Update company informations.
     */
    async updateCompany(uploadFinished = false) {
      this.isLoading = true;
      console.log(uploadFinished);
      let isUploading = false,
        stack = [];
      if (!uploadFinished) {
        if (this.upload.logo) {
          isUploading = true;
          stack.push(
            uploadFile(this.fileParent.file, (data) => {
              this.company.PhotoUrl = data;
            })
          );
        }

        if (this.upload.mailLogo) {
          isUploading = true;
          stack.push(
            uploadFile(this.fileParent.mailLogo, (data) => {
              this.company.MailPhotoUrl = data;
            })
          );
        }
      }

      if (isUploading && !uploadFinished) {
        await Promise.all(stack).then(() => {
          this.updateCompany(true);

          this.uploadReset();
        });
      } else {
        this.company.Country =
          this.company.CountryId != null
            ? this.company.CountryId
            : this.company.Country;
        this.company.City =
          this.company.CityId != null ? this.company.CityId : this.company.City;
        this.company.receiptStatus
          ? (this.company.ReceiptAddress = this.company.Address1)
          : null;

        this.company.GsmNo = clearText(this.company.GsmNo);

        if (this.weeklyAveragesForm.updated) {
          CompanyServices.updateWeeklyAverages({
            AverageWeeklyUploadCount:
              this.weeklyAveragesForm.AverageWeeklyUploadCount,
            AverageWeeklyUploadWeight:
              this.weeklyAveragesForm.AverageWeeklyUploadWeight,
          })
            .then((response) => {
              this.$t('messages.succesfulUpdateWeeklyAverages');
              this.weeklyAveragesForm.updated = false;
            })
            .catch(window.showError);
        }

        CompanyServices.update(this.company)
          .then((response) => {
            this.company = response.data.Result;
            window.showSuccess(
              this.$t('messages.successfulUpdate', {
                value: this.$t('labels.companyUpdateString'),
              })
            );
            //location.reload(true);
          })
          .catch((error) => {
            window.showError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    clearFileUploadDialog() {
      setTimeout(() => {
        this.resetForm();
        this.selectedDocument = null;
        this.selectedCompanyFileType = null;
        this.fileName = '';
      }, 500);
    },
    fileUploadOperation() {
      this.uploadFile();
    },
    /**
     * Upload a file.
     */
    uploadFile() {
      if (this.selectedDocument) {
        const document = this.selectedDocument;
        if (document === undefined) {
        }
        let formData = new FormData();
        if (document.type == 'application/pdf') {
          formData.append('Files', document);
          formData.append('Type', 'Document');
        }
        this.$store.state.loadingStatus = true;
        FileService.uploadFile(formData)
          .then((uploadFileResponse) => {
            const body = {
              FileId: uploadFileResponse.data.Result[0].Id,
              FileName: this.fileName,
              CompanyFileType: this.selectedCompanyFileType,
            };
            CompanyServices.addCompanyFile(body)
              .then((res) => {
                window.showSuccess(this.$t('messages.fileAddSuccess'));
                this.getFiles();
              })
              .catch((error) => {
                window.showError(error);
              })
              .then(() => {
                this.clearFileUploadDialog();
                this.$store.state.loadingStatus = false;
                this.isFileUploadOpen = false;
              });
          })
          .catch((error) => {
            this.$store.state.loadingStatus = false;
            this.isFileUploadOpen = false;
          });
      }
    },
    downloadDocument(file) {
      const body = {
        s3Uri: file.s3Uri,
      };

      OrderService.downloadFile(body, file.FileName);
    },
    deleteDocument(file) {
      this.$store.state.loadingStatus = true;

      CompanyServices.deleteCompanyFile(file.Id)
        .then((response) => {
          window.showSuccess(this.$t('messages.fileDeleteSuccess'));
          this.getFiles();
        })
        .catch((error) => {
          window.showError(error);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getFiles() {
      this.$store.state.loadingStatus = true;
      CompanyServices.getCompanyFiles()
        .then((response) => {
          this.companyFiles = response.data.Result;
        })
        .catch((error) => {
          window.showError(error);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    handleTabChange(index) {
      console.log('INDEX >> ', index);
      if (
        this.$store.state.Type === 'Supplier' ||
        (this.$store.state.Type === 'PendingSupplier' && index == 2)
      ) {
        this.getRoleList();
      } else if (
        (this.$store.state.Type === 'Customer' ||
          this.$store.state.Type === 'PendingCustomer') &&
        index == 2
      ) {
        this.getFiles();
      } else if (
        (this.$store.state.Type === 'Supplier' ||
          this.$store.state.Type === 'PendingSupplier') &&
        index == 3
      ) {
        this.getFiles();
      } else if (
        (this.$store.state.Type === 'Supplier' ||
          this.$store.state.Type === 'PendingSupplier') &&
        index == 5
      ) {
        this.getBankInformation();
      } else if (
        (this.$store.state.Type === 'Supplier' ||
          this.$store.state.Type === 'PendingSupplier') &&
        index == 6
      ) {
        this.getHazmatPrices();
      }
    },
    updateHazmatPrices() {
      this.$store.state.loadingStatus = true;
      CompanyHazmatService.updateHazmatCodes({
        Updates: this.hazmatPrices,
      })
        .then((response) => {
          window.showSuccess(
            this.$t('messages.updateHazmatPricesSuccessfully')
          );
          this.getHazmatPrices();
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    updatePrice(price) {
      console.log(price);
    },
    getBankInformation() {
      this.$store.state.loadingStatus = true;
      CompanyServices.getAllBankInformation()
        .then((response) => {
          this.bankInformation = response.data.Result;
          this.$store.state.loadingStatus = false;
        })
        .catch((error) => {
          window.showError(error);
          this.$store.state.loadingStatus = false;
        });
    },
    addBankInformation() {
      this.$store.state.loadingStatus = true;
      let body = {
        BankName: this.newBank.BankName,
        FullName: this.newBank.FullName,
        IBAN: this.newBank.IBAN,
        CurrencyTypeId: this.newBank.CurrencyTypeId,
      };

      CompanyServices.addBankInformation(body)
        .then((response) => {
          window.showSuccess(this.$t('description.bankInformationAddSuccess'));
          this.getBankInformation();
          this.resetBankModal();
        })
        .catch((error) => {
          window.showError(error);
          this.resetBankModal();
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
          this.resetBankModal();
        });
    },
    editBankInformation() {
      this.$store.state.loadingStatus = true;
      let body = {
        Id: this.editBank.Id,
        BankName: this.editBank.BankName,
        FullName: this.editBank.FullName,
        IBAN: this.editBank.IBAN,
        CurrencyTypeId: this.editBank.CurrencyTypeId,
      };

      CompanyServices.updateBankInformation(body)
        .then((response) => {
          window.showSuccess(this.$t('description.bankInformationEditSuccess'));
          this.getBankInformation();
          this.resetBankModal();
        })
        .catch((error) => {
          window.showError(error);
          this.resetBankModal();
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
          this.resetBankModal();
        });
    },
    resetBankModal() {
      this.isEditBankModal = false;
      this.bankModal = false;
      this.newBank = {
        BankName: '',
        AccountOwner: '',
        IBAN: '',
        CurrencyTypeId: '',
      };
      this.editBank = {
        Id: '',
        BankName: '',
        AccountOwner: '',
        IBAN: '',
        CurrencyTypeId: '',
      };
      this.$refs.bankObserver.reset();
    },
    openAddBankModal() {
      this.isEditBankModal = false;
      this.newBank = {
        BankName: '',
        FullName: '',
        IBAN: '',
        CurrencyTypeId: '',
      };
      this.bankModal = true;
    },
    openEditBankModal(item) {
      this.isEditBankModal = true;

      this.editBank = {
        Id: item.Id,
        BankName: item.BankName,
        FullName: item.FullName,
        IBAN: item.IBAN,
        CurrencyTypeId: item.CurrencyType.Id,
      };
      this.bankModal = true;
    },

    deleteBankInformation(item) {
      this.$store.state.loadingStatus = true;
      CompanyServices.deleteBankInformation(item.Id)
        .then((response) => {
          window.showSuccess(
            this.$t('description.bankInformationDeleteSuccess')
          );
          this.getBankInformation();
        })
        .catch((error) => {
          window.showError(error);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
  },
};
</script>
