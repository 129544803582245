// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "/company/category";

export default {
  getCategories( categoryName="",page=1,pageSize=15) {
    return axios.get(`${RESOURCE_NAME}/getByCompanyId`, {
      params: { categoryName,page,pageSize },
    });
  },
  deleteCategory(Id) {
    return axios.delete(`${RESOURCE_NAME}/delete`, {
      params: { Id },
    });
  },
  updateName(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },
  updateProducts(body) {
    return axios.put(`${RESOURCE_NAME}/updateProducts`, body);
  },
  getMarkets() {
    return axios.get(`${RESOURCE_NAME}/getCompanyMarketCategories`);
  },
  addWithMarketplace(body) {
    return axios.post(`${RESOURCE_NAME}/addWithMarketplace`,body);
  },
};
