<template>
  <div>
    <!-- Prohibited Word modal -->
    <v-dialog
      persistent
      v-model="brandDeatilStatus"
      max-width="500"
      ref="brandModal"
    >
      <v-card class="popup-card" v-if="!isDetails" elevation="1">
        <v-card-title>
          <div class="popupHeadline" style="width: 100%">
            {{ this.modalHeader }}
          </div>
        </v-card-title>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-card-text class="pt-10 px-10">
              <ValidationProvider
                :name="$t('labels.brandName')"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="brandModel.Brand"
                  :value="brandModel.Brand"
                  :label="$t('labels.brandName')"
                  :error-messages="errors[0]"
                  @keypress.enter.prevent
                  @keydown.space.prevent
                  v-on:keypress="isLetter($event)"
                ></v-text-field>
              </ValidationProvider>
              <v-card-text class="py-3"> </v-card-text>
            </v-card-text>
            <v-card-actions
              class="d-flex justify-center rounded-lg card-action"
            >
              <v-spacer></v-spacer>
              <v-btn
                class="btn-save rounded-lg"
                type="submit"
                :loading="loadings.send"
              >
                {{ $t("buttons.save") }}
              </v-btn>

              <v-btn
                class="btn-cancel rounded-lg"
                @click="
                  brandDeatilStatus = false;
                  clearModal();
                "
              >
                {{ $t("buttons.cancel") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
      <v-card v-if="isDetails" class="popup-card">
        <v-card-title class="">
          <div class="popupHeadline" style="width: 100%">
            {{ this.modalHeader }}
          </div>
        </v-card-title>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendForm)">
            <v-card-text class="pt-10 px-10">
              <ValidationProvider
                :name="$t('labels.brandName')"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="inspectedModel.Brand"
                  :value="inspectedModel.Brand"
                  :label="$t('labels.brandName')"
                  :error-messages="errors[0]"
                  @keypress.enter.prevent
                  @keydown.space.prevent
                  v-on:keypress="isLetter($event)"
                ></v-text-field>
              </ValidationProvider>
            </v-card-text>
            <v-card-actions
              class="d-flex justify-center rounded-lg card-action"
            >
              <v-spacer></v-spacer>
              <v-btn
                class="btn-save rounded-lg"
                type="submit"
                :loading="loadings.send"
              >
                {{ $t("buttons.save") }}
              </v-btn>
              <v-btn
                class="btn-cancel rounded-lg"
                @click="
                  brandDeatilStatus = false;
                  clearModal();
                "
              >
                {{ $t("buttons.cancel") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <!-- Prohibited Word deletion modal -->
    <delete-modal :yesFunc="deleteItem" ref="deleteModalRef" />
    <!-- Page content -->
    <v-card class="popup-card">
      <v-card-title style="color: #0177d4">
        <v-row>
          <v-col class="py-2">{{ $t("title.brands") }}</v-col>
          <v-spacer />
          <div style="gap: 1rem">
            <span
              class="align-self-center mr-2"
              style="font-size:12px; font-weight:normal;color:##598DB5;"
            >
              {{ $t("labels.addNew") }}</span
            >
            <v-icon
              @click="
                brandDeatilStatus = true;
                isDetails = false;
                modalHeader = $t('title.createBrand');
              "
              class="icon-button mr-4 align-self-center"
            >
              mdi-plus
            </v-icon>
          </div>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-row align="center" justify="start">
          <!-- Brand creator select start -->
          <v-col cols="2">
            <v-select
              v-model="selectedCreatedBy"
              :value="selectedCreatedBy"
              :items="brandCrators.concat({ Name: 'Tümü', Value: '' })"
              item-text="Name"
              item-value="Value"
              :label="$t('labels.Creator')"
              outlined
              rounded
              dense
              @change="filterContent()"
              required
            ></v-select>
          </v-col>
          <!-- Brand creator select end -->
          <!-- Brand status select start -->
          <v-col cols="2">
            <v-select
              v-model="selectedBrandStatus"
              :value="selectedBrandStatus"
              :items="brandStatus.concat({ Name: 'Tümü', Value: '' })"
              item-text="Name"
              item-value="Value"
              outlined
              dense
              rounded
              :label="$t('labels.selectStatus')"
              @change="filterContent()"
              required
            ></v-select>
          </v-col>
          <!-- Brand status select end -->

          <!-- Pagination select box start -->

          <!-- Pagination select box end -->
        </v-row>

        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{
            disablePagination: true,
            disableItemsPerPage: true,
          }"
          :no-data-text="$t('description.noList')"
          ref="wordList"
        >
          <!-- eslint-disable -->
          <template v-slot:item.Brand="{ item }">
            <div style="font-weight: bold !important;">{{ item.Brand }}</div>
          </template>
          <template v-slot:item.CreatedBy="{ item }">
            {{ item.CreatedBy }}
          </template>

          <template v-slot:item.CreatedOnUtc="{ item }">
            {{ convertToLocal(item.CreatedOnUtc) }}
          </template>

          <template v-slot:item.Status="{ item }">
            <v-chip
              v-if="item.Status == true"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
            {{ $t("labels.tooltipActive") }}
            </v-chip>

            <v-chip
              v-if="item.Status == false"
              class="ma-2 text--center"
              small
              color="#e3e2e3"
              text-color="#2b3838"
            >
            {{ $t("labels.tooltipPassive") }}
            </v-chip>
          </template>
          <!-- eslint-disable -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  class="mr-2 green-icon"
                  @click="getBrandDetails(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-file-edit
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipEdit") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  class="mr-2 red-icon"
                  @click="openDeleteModal(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t("labels.tooltipDelete") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <v-row align="center">
            <v-col md="3" class="ml-md-auto">
              <v-pagination
                @input="paginationChangeHandler"
                :value="pagination.page"
                :length="pages"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="ml-md-auto">
              <v-select
                v-model="itemsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                @change="changeItemsPerPage()"
                outlined
                dense
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import BrandService from "../services/BrandService";
import DeleteModal from "../components/General/DeleteModal";
import { mapActions } from "vuex";
import { VEmojiPicker } from "v-emoji-picker";
export default {
  data: () => ({
    // List options
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    tableData: [],
    brandStatus: [],
    brandCrators: [],
    selectedBrandStatus: "",
    selectedCreatedBy: "",
    headers: [],
    //Loading states
    loadings: {
      table: false,
      select: false,
      send: false,
    },

    brandDeatilStatus: false,
    // To add new model
    brandModel: {
      Brand: "",
      CompanyId: "",
    },
    // To inspect a model
    inspectedModel: {
      Word: "",
      AddedUser: "",
      CreatedOnUtc: "",
      UpdatedAt: "",
    },

    deleteItemModel: {},
    isDetails: false,
    editItemId: null, //Inspected item ID
    modalHeader: "", //Global header for opened modal

    //Division select modals
    selectedDivision: null,
    divisionItems: [],

    pagination: {},
    itemsPerPage: 15,
    perPageValues: [
      {
        name: "10",
        value: 10,
      },
      {
        name: "15",
        value: 15,
      },
      {
        name: "30",
        value: 30,
      },
      {
        name: "50",
        value: 50,
      },
      {
        name: "100",
        value: 100,
      },
    ],
  }),

  watch: {},
  mounted() {
    this.setup();
    this.getTableList();
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
  },
  components: {
    DeleteModal,
    VEmojiPicker,
  },

  methods: {
    
    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getTableList();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
      this.getTableList();
    },

    //Page setup
    setup() {
      this.modalHeader = this.$t("title.brands");
      this.headers = [
        {
          text: this.$t("title.brandName"),
          value: "Brand",
          sortable: false,
        },
        {
          text: this.$t("title.Creator"),
          value: "CreatedBy",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderCreateDate"),
          value: "CreatedOnUtc",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderStatus"),
          value: "Status",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("title.tableHeaderActions"),
          value: "actions",
          sortable: false,
        },
        (this.pagination = {
          totalItems: 200,
          rowsPerPage: this.itemsPerPage,
          page: 1,
        }),
      ];
      this.pagination = {
        totalItems: 100,
        rowsPerPage: 15,
        page: 1,
      };
      this.brandStatus = [
        {
          Name: "Aktif",
          Value: true,
        },
        {
          Name: "Pasif",
          Value: false,
        },
      ];
      this.brandCrators = [];
      // this.fillGroups();
      //this.getDivisions();
      this.selectedDivision = -1;
    },

    getFullName(item) {
      if (!!item.User) {
        return item.User.FirstName + " " + item.User.LastName;
      } else {
        return "-";
      }
    },

    //Fill's the word list
    getTableList() {
      this.tableData = [];
      this.$store.state.loadingStatus = true;

      BrandService.getBrands(
        this.pagination.rowsPerPage,
        this.pagination.page,
        this.selectedBrandStatus,
        this.selectedCreatedBy
      )
        .then((res) => {
          this.tableData = res.data.Result;
          this.pagination.totalItems = res.data.TotalItems;
          res.data.Result.forEach((element) => {
            this.brandCrators.indexOf(element.CreatedBy) === -1
              ? this.brandCrators.push(element.CreatedBy)
              : this.brandCrators;
          });
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    filterContent() {
      this.$store.state.loadingStatus = true;
      this.tableData = [];
      this.pagination.rowsPerPage = 15;
      this.itemsPerPage = 15;
      this.pagination.page = 1;
      this.getTableList();
    },
    //Clears all modal's states and item ID
    clearModal() {
      this.brandModel = {
        Brand: "",
        CompanyId: "",
      };
      this.inspectedModel = {
        Word: "",
        AddedUser: "",
        CreatedOnUtc: "",
        UpdatedAt: "",
      };
      //Reset all the flags, modals and references
      this.modalHeader = "";
      this.$refs.observer.reset();
      this.createdModelGroupIds = {};
      this.editItemId = null;

      this.selectedDivision = -1;
    },

    //Submits the form whether it is an update operation or create operation
    sendForm() {
      if (this.editItemId) {
        this.updateWord();
      } else {
        this.createBrand();
      }
    },
    //Create operation
    createBrand() {
      this.$store.state.loadingStatus = true;
      this.brandDeatilStatus = false;
      BrandService.addBrand(this.brandModel)
        .then((res) => {
          this.getTableList();
          this.clearModal();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          // this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },
    //Inspect an item in list
    getBrandDetails(item) {
      this.modalHeader = this.$t("title.brandDetail");
      this.editItemId = item.Id;
      //Use item data to fill other parts of detail modal
      this.inspectedModel = {
        Brand: item.Brand,
        AddedUser: item.CreatedBy,
        CreatedOnUtc: item.CreatedOnUtc,
        Status: item.Status,
      };

      this.isDetails = true;
      this.brandDeatilStatus = true;
    },

    //Update operation
    updateWord() {
      this.$store.state.loadingStatus = true;
      this.brandDeatilStatus = false;
      let body = {
        Brand: this.inspectedModel.Brand,
        BrandId: this.editItemId,
      };
      BrandService.updateBrand(body)
        .then((res) => {
          this.getTableList();
          this.clearModal();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          // this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },
    //Opens modal for deletion operation
    openDeleteModal(item) {
      this.deleteItemModel = item;
      this.$refs.deleteModalRef.modalStatus = true;
    },
    //Delete operation
    deleteItem() {
      // this.loadings.table = true;
      this.$refs.deleteModalRef.modalStatus = false;
      this.$store.state.loadingStatus = true;
      BrandService.deleteBrand(this.deleteItemModel.Id)
        .then(() => {
          this.getTableList();
        })
        .catch((err) => {
          window.handleServiceError(res, err);
        })
        .then(() => {
          // this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },

    //Gets sidebar data dynamically
    ...mapActions(["getMenuData"]),
  },
};
</script>
