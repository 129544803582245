// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "/brand";

export default {
  getBrands(pageSize,page,status,createdBy) {
    return axios.get(`${RESOURCE_NAME}/getList`, {
      params: { page:page,pageSize:pageSize,Status:status,CreatedBy:createdBy }
    });
  },
  addBrand(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },
  updateBrand(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },
  deleteBrand(id) {
    return axios.delete(`${RESOURCE_NAME}/delete?BrandId=${id}`);
  },
};
