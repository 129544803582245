// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'companies';
let RESOURCE_NAME2 = 'company-file';

export default {
  /**
   * Creates a new company.
   * @param {*} body
   * @returns {Promise<any>}
   */
  add(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },

  /**
   * Update informations of company..
   * @param {*} body
   * @returns {Promise<any>}
   */
  update(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },

  /**
   * Delete a company.
   * @param {*} body
   * @returns {Promise<any>}
   */
  delete(body) {
    return axios.delete(`${RESOURCE_NAME}/delete`, {
      params: body,
    });
  },

  /**
   * It returns company list by filter.
   * @param {*} body { SectorId, Country, City, District, PostCode, CompanyType }
   * @returns {Promise<any>}
   */
  getList(body) {
    return axios.get(`${RESOURCE_NAME}/getList`, {
      params: body,
    });
  },
  /**
   * It returns company file list.
   * @param {*} page
   * @param {*} pageSize
   * @returns {Promise<any>}
   */
  getCompanyFiles(page = 1, pageSize = 9999, companyId) {
    return axios.get(`${RESOURCE_NAME2}/get`, {
      params: { page: page, pageSize: pageSize, CompanyId: companyId },
    });
  },
  /**
   * Add a new file to company files.
   * @param {*} body { FileName, FileId, CompanyFileType}
   * @returns {Promise<any>}
   */
  addCompanyFile(body) {
    return axios.post(`${RESOURCE_NAME2}/add`, body);
  },
  /**
   * Delete a company file by id
   * @param {*} id { File id}
   * @returns {Promise<any>}
   */
  deleteCompanyFile(id) {
    return axios.delete(`${RESOURCE_NAME2}/delete`, {
      params: { Id: id },
    });
  },

  /**
   * Get a specific company.
   * @param {*} { Id }
   * @returns {Promise<any>}
   */
  getById(body) {
    return axios.get(`${RESOURCE_NAME}/getById`, body);
  },

  /**
   * Get Company Type list.
   * @returns {Promise<any>}
   */
  getCompanyTypes() {
    return axios.get(`companyType/getList`);
  },

  getSubCompanies(Page = 1, PageSize = 10000) {
    return axios.get(`subCompany/getList`, {
      params: { Page: Page, PageSize: PageSize },
    });
  },

  addSubCompany(body) {
    return axios.post(`subCompany/add`, body);
  },

  updateCompany(body) {
    return axios.put(`subCompany/update`, body);
  },

  deleteCompany(id) {
    return axios.delete(`subCompany/delete`, { params: { id: id } });
  },

  deleteCompanyUser(id) {
    return axios.delete(`${RESOURCE_NAME}/delete-user`, {
      params: { UserId: id },
    });
  },

  /**
   * Update Department Notification Settings
   * @param {*} body
   * @returns {Promise}
   */
  updateDepartment(body) {
    return axios.put(`${RESOURCE_NAME}/update-department-info`, body);
  },

  getAllBankInformation() {
    return axios.get(`${RESOURCE_NAME}/get-all-bank-info`);
  },
  addBankInformation(body) {
    return axios.post(`${RESOURCE_NAME}/add-bank-information`, body);
  },
  updateBankInformation(body) {
    return axios.put(`${RESOURCE_NAME}/update-bank-information`, body);
  },
  deleteBankInformation(id) {
    return axios.delete(`${RESOURCE_NAME}/delete-bank-information`, {
      params: { Id: id },
    });
  },

  updateLanguage(body) {
    return axios.put(`${RESOURCE_NAME}/update-department-language`, body);
  },
  connectPortalMail(body) {
    return axios.post(`${RESOURCE_NAME}/connect-portal-mail`, body);
  },
  connectPortal(body) {
    return axios.put(`${RESOURCE_NAME}/connect-portal`, body);
  },

  /**
   * Update a portal user.
   * @param {*} body
   * @returns
   */
  updatePortalUser(body) {
    return axios.put(`${RESOURCE_NAME}/update-portal`, body);
  },

  /**
   * Update Weekly Averages.
   * @param {{ AverageWeeklyUploadCount: number, AverageWeeklyUploadWeight: number }} body
   * @returns {Promise}
   */
  updateWeeklyAverages(body) {
    return axios.put(`${RESOURCE_NAME}/update-weekly-averages`, body);
  },

  /**
   * Get Weekly Averages.
   * @param {*} params
   * @returns {Promise}
   */
  getWeeklyAverages(params) {
    return axios.get(`${RESOURCE_NAME}/get-weekly-averages`, { params });
  },
  getAgencies() {
    return axios.get(`${RESOURCE_NAME}/get-agencies`);
  },

  /**
   * Get source companies.
   * @returns {Promise}
   */
  getSourceCompanies() {
    return axios.get(`${RESOURCE_NAME}/get-source-companies`);
  },
  updatePackagePopup() {
    return axios.put(`${RESOURCE_NAME}/updatePackagePopupVisibility`, {
      IsPackagePopupVisible: false,
    });
  },
};
