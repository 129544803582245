<template>
  <fragment>
    <v-dialog v-model="sureModal" persistent max-width="400">
      <v-card class="popup-card" elevation="1">
        <!-- Title Section -->
        <v-card-title
          class="popupHeadline d-flex justify-center"
          style="font-size: 24px"
        >
          {{ $t('labels.Alert') }}
        </v-card-title>

        <v-divider />

        <v-card-text class="text-center pt-4 pb-0">
          <p style="font-size: 16px">
            {{ $t('labels.closeWithoutSave') }}
          </p>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            variant="tonal"
            class="rounded-lg"
            style="
              color: white;
              text-transform: none;
              font-size: large;
              font-weight: bold;
              padding: 1em;
            "
            color="var(--red)"
            @click="
              openModal = false;
              sureModal = false;
            "
          >
            {{ $t('buttons.yes') }}
          </v-btn>
          <v-btn
            variant="tonal"
            class="rounded-lg"
            style="
              color: white;
              text-transform: none;
              font-size: large;
              font-weight: bold;
              padding: 1em;
            "
            color="#0077d5"
            @click="sureModal = false"
          >
            {{ $t('buttons.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editMode.enabled" max-width="1000px">
      <v-card class="popup-card modal" elevation="1">
        <v-card-title class="d-flex justify-center mb-4">
          <v-icon>mdi-calculator</v-icon>

          {{ $t('labels.tooltipEdit') }}
        </v-card-title>
        <v-card-text>
          <ValidationObserver
            :disabled="true"
            v-if="editMode.enabled"
            ref="editVolumeObserver"
            v-slot="{ handleSubmit }"
          >
            <form
              autocomplete="off"
              @submit.prevent="handleSubmit(editVolumeItem)"
            >
              <v-row>
                <FormElement
                  v-for="(element, index) in createFormItems(
                    'editVolumeElement'
                  )"
                  :key="
                    element.key.parent + '_' + element.key.child + '_' + index
                  "
                  :vertical="element.vertical"
                  v-model="$data[element.key.parent][element.key.child]"
                  :validationLabel="$t(element.validationLabel)"
                  :rules="element.rules"
                  :formLabel="$t(element.formLabel)"
                  :onChange="element.onChange"
                  :formType="element.formType"
                  :lg="element.lg"
                  :cols="element.cols"
                  :disabled="element.disabled"
                  :light="true"
                  :hidden="element.hidden"
                  :labelShown="element.labelShown"
                  :topLabel="element.topLabel"
                  :active="element.active"
                  :placeholder="$t(element.placeholder)"
                  :persistentPlaceholder="element.persistentPlaceholder"
                />
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="2"
                  xl="2"
                  class="form-column d-flex justify-center pl-2"
                >
                  <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: var(--primary);
                      height: 60%;
                      border-radius: 15px !important;
                      font-weight: bold;
                      font-size: small;
                      width: 100%;
                    "
                    :style="{
                      height:
                        $vuetify.breakpoint.xs ||
                        $vuetify.breakpoint.sm ||
                        $vuetify.breakpoint.md
                          ? '50px'
                          : '60%',
                      padding:
                        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                          ? '1em'
                          : '0',
                    }"
                    elevation="0"
                    type="submit"
                    class="volumeButton"
                  >
                    <v-icon left dark> mdi-pencil </v-icon>
                    {{ $t('buttons.save') }}
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="!isCard" :value="openModal" persistent max-width="1000px">
      <v-card class="popup-card modal" elevation="1">
        <v-card-title class="popupHeadline d-flex justify-center">
          <v-icon>mdi-calculator</v-icon>

          {{
            $t('buttons.' + (readOnly ? 'viewPackageDetails' : 'addPackage'))
          }}
        </v-card-title>

        <v-divider />
        <div class="px-4">
          <v-container :style="readOnly ? 'margin-top: 0' : ''">
            <ValidationObserver
              :disabled="true"
              v-if="!readOnly"
              ref="addVolumeObserver"
              v-slot="{ handleSubmit }"
            >
              <form
                autocomplete="off"
                @submit.prevent="handleSubmit(addVolume)"
              >
                <v-row>
                  <FormElement
                    v-for="(element, index) in createFormItems('volumeItem')"
                    :key="
                      element.key.parent + '_' + element.key.child + '_' + index
                    "
                    :vertical="element.vertical"
                    v-model="$data[element.key.parent][element.key.child]"
                    :validationLabel="$t(element.validationLabel)"
                    :rules="element.rules"
                    :formLabel="$t(element.formLabel)"
                    :onChange="element.onChange"
                    :formType="element.formType"
                    :cols="element.cols"
                    :lg="element.lg"
                    :disabled="element.disabled"
                    :light="true"
                    :hidden="element.hidden"
                    :labelShown="element.labelShown"
                    :topLabel="element.topLabel"
                    :active="element.active"
                    :placeholder="$t(element.placeholder)"
                    :persistentPlaceholder="element.persistentPlaceholder"
                  />
                  <FormElement
                    v-for="(element, index) in form_details"
                    :key="
                      element.key.parent + '_' + element.key.child + '_' + index
                    "
                    :vertical="element.vertical"
                    v-model="$data[element.key.parent][element.key.child]"
                    :validationLabel="$t(element.validationLabel)"
                    :rules="element.rules"
                    :formLabel="$t(element.formLabel)"
                    :onChange="element.onChange"
                    :formType="element.formType"
                    :lg="element.lg"
                    :cols="element.cols"
                    :light="true"
                    :hidden="element.hidden"
                    :labelShown="element.labelShown"
                    :topLabel="element.topLabel"
                    :active="element.active"
                    :placeholder="$t(element.placeholder)"
                  />
                  <v-col
                    cols="12"
                    sm="12"
                    md="2"
                    lg="2"
                    xl="2"
                    class="form-column d-flex justify-center pl-sm-0 mb-4 ml-auto"
                  >
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: var(--primary);
                        border-radius: 15px !important;
                        font-weight: bold;
                        font-size: small;
                        width: 100%;
                        height: 55px;
                      "
                      elevation="0"
                      type="submit"
                      class="volumeButton"
                    >
                      <v-icon left dark> {{ 'mdi-plus' }} </v-icon>
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </form>
            </ValidationObserver>
            <div :class="!readOnly ? 'my-4' : ''">
              <v-data-table
                light
                style="padding: 20px"
                :headers="volumeHeaders"
                :items-per-page="volumes.length"
                hide-default-footer
                :items="volumes"
                :no-data-text="$t('messages.noData')"
                :item-style="() => '.table-item'"
              >
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.dimensions="{ item }">
                  {{
                    item.width && item.length && item.height
                      ? `${item.width}/${item.length}/${item.height}`
                      : '-'
                  }}
                </template>
                <template v-slot:body.append="{ item }">
                  <tr>
                    <td style="text-align: left !important; font-weight: bold">
                      {{ $t('titles.total') }}
                    </td>
                  </tr>
                  <tr style="background-color: white; color: black">
                    <td>
                      <span style="font-weight: bold">
                        {{ calculate(volumes, 'containerCount', false) }}</span
                      >
                    </td>
                    <td>
                      <span>
                        <span style="font-weight: bold">
                          {{ calculate(volumes, 'width') }}
                        </span>
                        cm /
                      </span>
                      <span>
                        <span style="font-weight: bold">
                          {{ calculate(volumes, 'length') }}
                        </span>
                        cm /
                      </span>
                      <span>
                        <span style="font-weight: bold">
                          {{ calculate(volumes, 'height') }}
                        </span>
                        cm
                      </span>
                    </td>

                    <td></td>
                    <td>
                      <span style="font-weight: bold">
                        {{ calculate(volumes, 'totalWeight') }}
                      </span>
                      kg
                    </td>
                    <td>
                      <span style="font-weight: bold">
                        {{ calculate(volumes, 'volume') }}
                      </span>
                      m<sup>3</sup>
                    </td>
                    <td></td>
                  </tr>
                </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex align-center">
                    <v-tooltip top v-if="!readOnly">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          medium
                          class="mr-2"
                          color="var(--darkBlue)"
                          @click="editItem(item.index, item.id)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>{{ $t('labels.tooltipEdit') }}</span>
                    </v-tooltip>
                    <v-tooltip top v-if="!readOnly">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          medium
                          class="mr-2"
                          color="var(--lightRed)"
                          @click="deleteItem(item.index, item.id)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-trash-can
                        </v-icon>
                      </template>
                      <span>{{ $t('labels.tooltipDelete') }}</span>
                    </v-tooltip>
                  </div>
                </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.containerCount="props">
                  <v-edit-dialog :return-value.sync="props.item.containerCount">
                    {{ props.item.containerCount }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.containerCount"
                        :rules="['required', 'numeric']"
                        label="Edit"
                        single-line
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
              </v-data-table>
            </div>
          </v-container>
        </div>
        <v-card-actions class="justify-center">
          <v-btn
            v-if="!readOnly"
            style="
              text-transform: uppercase !important;
              border-radius: 12px !important;
              padding: 2vh 3vh !important;
              font-weight: bold;
              font-size: 16px !important;
            "
            dark
            type="submit"
            class="add mb-2"
            @click="save()"
          >
            {{ $t('buttons.save') }}
          </v-btn>
          <v-btn
            style="
              text-transform: uppercase !important;
              border-radius: 12px !important;
              padding: 2vh 3vh !important;
              font-weight: bold;
              font-size: 16px !important;
              background-color: var(--red) !important;
            "
            dark
            type="submit"
            class="add mb-2"
            @click="sureModal = true"
          >
            {{ $t('buttons.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card
      v-else
      :class="isCard ? '' : 'popup-card modal'"
      :elevation="isCard ? 0 : 1"
    >
      <v-card-title v-if="!isCard" class="popupHeadline d-flex justify-center">
        <v-icon>mdi-calculator</v-icon>

        {{ $t('buttons.' + (readOnly ? 'viewPackageDetails' : 'addPackage')) }}
      </v-card-title>

      <v-divider />
      <div>
        <v-row
          class="mt-0 mt-sm-10 mt-lg-10 mt-xl-0"
          align="center"
          :style="'padding: 0;' + (readOnly ? 'margin-top: 0' : '')"
        >
          <v-col sm="12" lg="9" md="12" xl="12">
            <ValidationObserver
              :disabled="true"
              v-if="!readOnly"
              ref="addVolumeObserver"
              v-slot="{ handleSubmit }"
            >
              <form
                autocomplete="off"
                @submit.prevent="handleSubmit(addVolume)"
              >
                <v-row justify="start">
                  <v-col lg="12" cols="12" xl="12">
                    <v-row>
                      <FormElement
                        v-for="(element, index) in createFormItems(
                          'volumeItem'
                        )"
                        :key="
                          element.key.parent +
                          '_' +
                          element.key.child +
                          '_' +
                          index
                        "
                        :vertical="element.vertical"
                        v-model="$data[element.key.parent][element.key.child]"
                        :validationLabel="$t(element.validationLabel)"
                        :rules="element.rules"
                        :formLabel="$t(element.formLabel)"
                        :onChange="element.onChange"
                        :formType="element.formType"
                        :lg="element.lg"
                        :cols="element.cols"
                        :disabled="element.disabled"
                        :light="true"
                        :hidden="element.hidden"
                        :labelShown="element.labelShown"
                        :topLabel="element.topLabel"
                        :active="element.active"
                        :placeholder="$t(element.placeholder)"
                        :persistentPlaceholder="element.persistentPlaceholder"
                      />
                      <v-col
                        cols="12"
                        sm="2"
                        lg="2"
                        class="form-column d-flex justify-center pl-2"
                      >
                        <v-btn
                          style="
                            color: #fff;
                            text-transform: unset !important;
                            background-color: var(--primary);
                            height: 55px;
                            border-radius: 15px !important;
                            font-weight: bold;
                            font-size: small;
                            width: 100%;
                          "
                          elevation="0"
                          type="submit"
                          class="volumeButton"
                        >
                          <v-icon left dark> {{ 'mdi-plus' }} </v-icon>
                          {{ $t('buttons.add') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </form>
            </ValidationObserver>
          </v-col>
          <v-col lg="12" md="12" xl="12">
            <div :class="!readOnly ? 'my-4' : ''">
              <v-data-table
                light
                style="padding: 20px"
                :headers="volumeHeaders"
                hide-default-footer
                :items-per-page="volumes.length"
                :items="volumes"
                :no-data-text="$t('messages.noData')"
                :item-style="() => '.table-item'"
              >
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.dimensions="{ item }">
                  {{
                    item.width && item.length && item.height
                      ? `${item.width}/${item.length}/${item.height}`
                      : '-'
                  }}
                </template>
                <template v-slot:body.append="{}">
                  <tr>
                    <td style="text-align: left !important; font-weight: bold">
                      {{ $t('titles.total') }}
                    </td>
                  </tr>
                  <tr style="background-color: white; color: black">
                    <td>
                      <span style="font-weight: bold">
                        {{ calculate(volumes, 'containerCount', false) }}</span
                      >
                    </td>
                    <td>
                      <span>
                        <span style="font-weight: bold">
                          {{ calculate(volumes, 'width') }}
                        </span>
                        /
                      </span>
                      <span>
                        <span style="font-weight: bold">
                          {{ calculate(volumes, 'length') }}
                        </span>
                        /
                      </span>
                      <span>
                        <span style="font-weight: bold">
                          {{ calculate(volumes, 'height') }}
                        </span>
                        cm
                      </span>
                    </td>
                    <td>
                      <span style="font-weight: bold">
                        {{ calculate(volumes, 'totalWeight') }}
                      </span>
                      kg
                    </td>
                    <td></td>
                    <td>
                      <span style="font-weight: bold">
                        {{ calculate(volumes, 'volume') }}
                      </span>
                      m<sup>3</sup>
                    </td>
                    <td></td>
                  </tr>
                </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex align-center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          medium
                          class="mr-2"
                          color="var(--darkBlue)"
                          @click="editItem(item.index, item.id)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>{{ $t('labels.tooltipEdit') }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          medium
                          class="mr-2"
                          color="var(--lightRed)"
                          @click="deleteItem(item.index, item.id)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-trash-can
                        </v-icon>
                      </template>
                      <span>{{ $t('labels.tooltipDelete') }}</span>
                    </v-tooltip>
                  </div>
                </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.containerCount="props">
                  <v-edit-dialog :return-value.sync="props.item.containerCount">
                    {{ props.item.containerCount }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.containerCount"
                        :rules="['required', 'numeric']"
                        label="Edit"
                        single-line
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-card-actions v-if="!isCard" class="justify-center">
        <v-btn
          v-if="!readOnly"
          style="
            text-transform: uppercase !important;
            border-radius: 12px !important;
            padding: 2vh 3vh !important;
            font-weight: bold;
            font-size: 16px !important;
          "
          dark
          type="submit"
          class="add mb-2"
          @click="save()"
        >
          {{ $t('buttons.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </fragment>
</template>
<script>
import FormElement from '../../components/FormElement.vue';
import { LADEMETER_CONSTANT } from '../../utils/constants';
import ShipmentBoxServices from '../../services/ShipmentBoxService';
import { productType } from '../../utils/validationRules';

export default {
  constructor: () => {},
  name: 'AddVolume',
  props: {
    isCard: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    onSave: {
      type: Function,
      default: () => {},
    },
    volumesByUser: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    calculateParameter: {
      type: Number,
      default: 0,
    },
    calculateLademeter: {
      type: Boolean,
      default: false,
    },
    calculateChargeableWeight: {
      type: Function,
      default: null,
    },
    closeAfterSave: {
      type: Boolean,
      default: true,
    },
    isWarehouse: {
      type: Boolean,
      default: false,
    },
    isFirstSearch: {
      type: Boolean,
      default: false,
    },
    oneTimeSave: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    'volumeItem.width': function (newWidth, oldWidth) {
      this.updateVolume(this.volumeItem);
    },
    'volumeItem.length': function (newLength, oldLength) {
      this.updateVolume(this.volumeItem);
    },
    'volumeItem.height': function (newHeight, oldHeight) {
      this.updateVolume(this.volumeItem);
    },

    'editVolumeElement.width': function (newWidth, oldWidth) {
      this.updateVolume(this.editVolumeElement);
    },
    'editVolumeElement.length': function (newLength, oldLength) {
      this.updateVolume(this.editVolumeElement);
    },
    'editVolumeElement.height': function (newHeight, oldHeight) {
      this.updateVolume(this.editVolumeElement);
    },
  },
  components: { FormElement },
  mounted() {
    if (this.volumesByUser.length > 0) {
      this.volumes = [...this.volumes, ...this.volumesByUser];
    }

    if (!this.readOnly) {
      this.volumeHeaders.push({
        text: '',
        value: 'actions',
        sortable: false,
        groupable: false,
      });
    }

    this.getPackageTypes();

    if (this.oneTimeSave) {
      this.save();
    }
  },
  data() {
    return {
      dialog: false,
      sureModal: false,
      volumeItem: {
        volume: 0,
        volumeWeight: 0,
      },
      editVolumeElement: {
        volume: 0,
        volumeWeight: 0,
      },
      volumeHeaders: [
        {
          text: this.$t('labels.numOfContainers'),
          value: 'containerCount',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('labels.widthLengthHeight'),
          value: 'dimensions',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('labels.kilo'),
          value: 'kilo',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('titles.totalKilo') + ' (kg)',
          value: 'totalWeight',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('titles.totalVolume') + ' (m3)',
          value: 'volume',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('labels.productType'),
          value: 'packageType',
          sortable: false,
          groupable: false,
        },
      ],
      volumes: [],
      totalContainerCount: 0,
      totalVolume: 0,
      totalKilo: 0,
      totalVolumeWeight: 0,
      chargeableWeight: 0,
      totalLademeter: 0,
      canStack: true,

      // States
      pageLoading: false,
      editMode: {
        enabled: false,
        item: {},
        itemIndex: -1,
      },

      packageTypes: [],
    };
  },
  methods: {
    getPackageTypes() {
      this.$store.state.loadingStatus = true;
      const packages = JSON.parse(localStorage.getItem('packages'));
      if (packages?.length > 0) {
        this.packageTypes = packages;
        this.$store.state.loadingStatus = false;
      } else {
        ShipmentBoxServices.getPackageTypes()
          .then((response) => {
            this.packageTypes = response.data.Result;
            localStorage.setItem('packages', JSON.stringify(this.packageTypes));
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },
    /**
     * Save the volumes.
     */
    updateVolume(item) {
      const newWidth = item.width || 0;
      const newLength = item.length || 0;
      const newHeight = item.height || 0;

      let newVolume = (newWidth * newLength * newHeight) / 1000000;
      newVolume = parseFloat(newVolume).toFixed(2);
      this.$set(item, 'volume', newVolume);

      const volumeWithPrecision = newVolume;
      this.$set(item, 'volume', volumeWithPrecision);
    },
    save() {
      let totalVolumeWeight = 0,
        willNotBeStacked = false;

      this.totalContainerCount = 0;
      this.totalVolume = 0.0;
      this.totalKilo = 0.0;

      this.volumes.map((item) => {
        if (this.calculateLademeter) {
          const lade =
            (item.width * item.length * item.containerCount) / 2.4 / 10000;
          this.totalLademeter += lade;

          if (item.height >= 140 && !willNotBeStacked) {
            willNotBeStacked = true;
          }
        }

        if (item.height > 135) {
          this.canStack = false;
        }

        this.totalContainerCount += parseInt(item.containerCount);
        this.totalVolume += parseFloat(item.volume);
        this.totalKilo += parseFloat(item.totalWeight);

        const volumeWeight = this.calculateChargeableWeight
          ? this.calculateChargeableWeight(
              item.width,
              item.length,
              item.height,
              item.containerCount
            )
          : item.volume * this.calculateParameter;
        const totalWeight = item.kilo * item.containerCount;

        totalVolumeWeight += parseFloat(
          (volumeWeight || 0) > totalWeight ? volumeWeight : totalWeight
        );
      });

      this.totalLademeter = parseFloat(this.totalLademeter.toFixed(3));

      const body = {
        canStack: this.canStack,
        totalContainerCount: this.totalContainerCount,
        totalKilo: this.totalKilo.toFixed(3),
        totalVolume: this.totalVolume.toFixed(3),
        totalVolumeWeight: totalVolumeWeight,
        willNotBeStacked,
        chargeableWeight: totalVolumeWeight,
      };

      if (this.calculateLademeter) {
        body.totalLademeter = parseFloat(
          this.totalLademeter * LADEMETER_CONSTANT
        ).toFixed(3);

        if (body.chargeableWeight < body.totalLademeter) {
          body.chargeableWeight = body.totalLademeter;
        }
      }

      if (typeof body.chargeableWeight !== 'string')
        body.chargeableWeight = body.chargeableWeight.toFixed(2);

      this.onSave(this.volumes, body);
      if (this.closeAfterSave) {
        this.openModal = false;
      }
    },
    /**
     * Deletes an item from volumes.
     */
    async deleteItem(index, id = null) {
      this.volumes = this.volumes.filter((item) => item.index !== index);
      this.calculate();

      if (this.isCard) {
        this.$store.state.loadingStatus = true;
        setTimeout(() => {
          this.save();
          this.$store.state.loadingStatus = false;
        }, 1000);
      }
    },

    /**
     * Enables Edit Mode
     */
    async editItem(index, id = null) {
      const found = this.volumes.findIndex((item) => item.index === index);
      if (found > -1) {
        this.editMode = {
          enabled: true,
          itemIndex: found,
        };
        this.editVolumeElement = Object.assign({}, this.volumes[found]);
      }
    },

    /**
     * Enables Edit Mode
     */
    async editVolumeItem() {
      this.addVolumeToList(this.editVolumeElement);
      this.volumes[this.editMode.itemIndex] = this.editVolumeElement;
      this.volumes = [...this.volumes];
      this.editMode.enabled = false;
      this.editMode.itemIndex = -1;
      this.volumeItem = {};
      this.editVolumeElement = {};
      this.$refs.addVolumeObserver.reset();
    },

    /**
     * Calculate prices in the list.
     * @param {array} items
     */
    calculate(items, key, isDecimal = true) {
      let total = 0;
      if (items) {
        items.map((item) => (total += parseFloat(item[key])));
      }

      switch (key) {
        case 'containerCount':
          this.totalContainerCount = total;
          break;
        case 'totalWeight':
          this.totalKilo = total;
          break;
        case 'volume':
          this.totalVolume = total;
          break;
      }

      if (!total) return 0.0;

      return isDecimal ? parseFloat(total).toFixed(2) : parseInt(total);
    },

    calculationOfVolume(item) {
      item.kilo = parseFloat(item.kilo).toFixed(2);
      item.totalWeight = parseFloat(item.kilo * item.containerCount).toFixed(2);
      item.volume = parseFloat(
        item.volume ? item.volume * item.containerCount : 0
      ).toFixed(2);

      return item;
    },

    /**
     * Add a new volume.
     */
    addVolumeToList(item = null) {
      if (!item) {
        this.volumeItem.index = this.volumes.length;
        this.volumeItem = this.calculationOfVolume(this.volumeItem);
        this.volumes.unshift(this.volumeItem);

        this.$refs.addVolumeObserver.reset();
        this.volumeItem = {};
        window.showSuccess(
          this.$t('messages.successfulAdd', {
            value: this.$t('labels.volume').replaceAll('(m3)', ''),
          })
        );
      } else {
        item = this.calculationOfVolume(item);
      }

      if (this.isCard) {
        this.$store.state.loadingStatus = true;
        setTimeout(() => {
          this.save();
          this.$store.state.loadingStatus = false;
        }, 1000);
      }
    },
    async addVolume() {
      this.addVolumeToList();
    },

    createFormItems(parent) {
      return [
        {
          key: {
            parent,
            child: 'containerCount',
          },
          rules: 'required|numeric|min_value:1',
          validationLabel: 'labels.numOfContainers',
          topLabel: true,
          labelShown: false,
          formType: {
            persistentPlaceholder: true,
            name: 'text',
          },

          cols: 6,
          sm: this.isCard ? 4 : 2,
          md: 4,
          lg: 2,
          xl: 2,
          placeholder: 'labels.numOfContainers',
        },
        {
          key: {
            parent,
            child: 'packageType',
          },
          rules: this.isFirstSearch ? '' : 'required',
          validationLabel: 'labels.productPackageType',
          topLabel: true,
          labelShown: false,
          formType: {
            persistentPlaceholder: true,
            name: 'multipleSelect',
            items: {
              data: this.packageTypes,
              value: 'Name',
              text: 'Name',
            },
          },
          cols: 6,
          sm: this.isCard ? 4 : 2,
          md: 4,
          lg: 2,
          xl: 2,
          placeholder: 'labels.productPackageType',
        },
        {
          key: {
            parent,
            child: 'width',
          },
          rules: this.isFirstSearch ? '' : 'required|numeric|min_value:1|max:3',
          validationLabel: 'labels.width',
          topLabel: true,
          labelShown: false,
          formType: {
            persistentPlaceholder: true,
            name: 'text',
          },

          cols: 6,
          sm: this.isCard ? 4 : 2,
          md: 4,
          lg: 2,
          xl: 2,
          placeholder: 'labels.width',
        },
        {
          key: {
            parent,
            child: 'length',
          },
          rules: this.isFirstSearch ? '' : 'required|numeric|min_value:1|max:3',
          validationLabel: 'labels.length',
          topLabel: true,
          labelShown: false,
          formType: {
            persistentPlaceholder: true,
            name: 'text',
          },

          cols: 6,
          sm: this.isCard ? 4 : 2,
          md: 4,
          lg: 2,
          xl: 2,
          placeholder: 'labels.length',
        },
        {
          key: {
            parent,
            child: 'height',
          },
          rules: this.isFirstSearch ? '' : 'required|numeric|min_value:1|max:3',
          validationLabel: 'labels.height',
          topLabel: true,
          labelShown: false,
          formType: {
            persistentPlaceholder: true,
            name: 'text',
          },

          cols: 6,
          sm: this.isCard ? 4 : 2,
          md: 4,
          lg: 2,
          xl: 2,
          placeholder: 'labels.height',
        },

        {
          key: {
            parent,
            child: 'volume',
          },
          rules: this.isFirstSearch ? '' : 'required|double',
          validationLabel: 'labels.volume',
          topLabel: true,
          labelShown: false,
          disabled: true,
          formType: {
            persistentPlaceholder: true,
            name: 'text',
          },

          cols: 6,
          sm: this.isCard ? 4 : 2,
          md: 4,
          lg: 2,
          xl: 2,
          placeholder: 'labels.volume',
        },
        {
          key: {
            parent,
            child: 'countInCap',
          },
          rules: this.isFirstSearch ? '' : 'required|numeric|min_value:1',
          validationLabel: 'labels.countInCap',
          topLabel: true,
          labelShown: false,
          formType: {
            persistentPlaceholder: true,
            name: 'text',
          },

          cols: 6,
          sm: this.isCard ? 4 : 2,
          md: 4,
          lg: 2,
          xl: 2,
          placeholder: 'labels.countInCap',
        },
        {
          key: {
            parent,
            child: 'productType',
          },
          rules: this.isFirstSearch ? '' : productType,
          validationLabel: 'labels.productType',
          topLabel: true,
          labelShown: false,
          formType: {
            persistentPlaceholder: true,
            name: 'text',
          },
          cols: 6,
          sm: 4,
          md: 4,
          lg: 4,
          xl: 4,
          placeholder: 'labels.productType',
        },
        {
          key: {
            parent,
            child: 'gTypeNumber',
          },
          validationLabel: 'labels.gTypeNumber',
          topLabel: true,
          labelShown: false,
          formType: {
            persistentPlaceholder: true,
            name: 'text',
          },

          cols: 6,
          sm: this.isCard ? 4 : 2,
          md: 4,
          lg: 2,
          xl: 2,
          placeholder: 'labels.gTypeNumber',
        },
        {
          key: {
            parent,
            child: 'kilo',
          },
          rules: 'required|double|min_value:0',
          validationLabel: 'labels.kilo',
          topLabel: true,
          labelShown: false,
          formType: {
            persistentPlaceholder: true,
            name: 'text',
          },

          cols: 6,
          sm: this.isCard ? 4 : 2,
          md: 4,
          lg: 2,
          xl: 2,
          placeholder: 'labels.kilo',
        },
      ];
    },
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formItems1() {
      return;
    },
    form_details() {
      return [];
    },
  },
};
</script>
<style>
.table-item {
  border-bottom: 1px solid #333 !important;
}
.modal .v-data-table {
  background-color: var(--lightGray) !important;
}
</style>
