<template>
  <v-main>
    <NavMenu :fromLogin="true" />
    <div class="background-image" id="offerPage">
      <div>
        <div
          fluid
          class="mt-0 pt-16 flex justify-center items-center align-center"
          style="flex-grow: 1; height: 100%"
        >
          <v-row
            class="flex justify-center"
            style="height: 100%; margin: 0; padding-top: 5%"
          >
            <v-col
              :cols="getColumns()"
              class="pl-sm-4 pl-md-8 pl-lg-12 pl-xl-16 pt-sm-8 pt-md-12 pt-lg-10 pt-xl-16"
            >
              <div>
                <p
                  style="line-height: 1.2"
                  class="white--text font-weight-regular text-h4 text-sm-h3 text-md-h3 text-lg-h3 text-xl-h2 text-xxl-h2 mb-0 text-center text-sm-center text-md-left"
                >
                  {{ $t('messages.createOfferFormInfoTextTitle') }}
                  <span class="font-weight-black font-italic">AlGönder </span>
                </p>
              </div>
              <hr
                class="mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0"
                style="
                  width: 20%;
                  border: 3px solid red;
                  margin-top: 4%;
                  margin-bottom: 4%;
                "
              />

              <p
                class="white--text font-weight-regular text-h7 text-sm-h6 text-md-h6 text-lg-h8 text-xl-h5 text-xxl-h2 mb-0 text-center text-sm-center text-md-left"
                style="line-height: 1.5"
              >
                {{ $t('messages.createOfferFormInfoTextDescription') }}
              </p>
              <br />
              <p
                class="white--text font-weight-regular text-h7 text-sm-h6 text-md-h6 text-lg-h8 text-xl-h5 text-xxl-h2 mb-0 text-center text-sm-center text-md-left"
                style="line-height: 1.5"
              >
                {{ $t('messages.integrationText') }}
              </p>
              <br />
              <p
                class="white--text font-weight-regular text-h8 text-sm-h7 text-md-h7 text-lg-h9 text-xl-h6 text-xxl-h3 mb-0 text-center text-sm-center text-md-left"
                style="line-height: 1.25"
              >
                {{ $t('messages.etsyText') }}
              </p>
            </v-col>

            <v-col
              :cols="getFormColumns()"
              class="pt-sm-8 pt-md-12 pt-lg-16 pt-xl-16"
            >
              <CreateOfferForm
            /></v-col>
          </v-row>
          <!-- <v-row class="flex justify-center">
            <v-col
              :cols="getColumns()"
              class="pl-sm-4 pl-md-8 pl-lg-12 pl-xl-16"
            >
            
            </v-col>
            <v-col cols="6"></v-col>
          </v-row> -->
        </div>
      </div>
    </div>
  </v-main>
</template>
<script>
import NavMenu from '../components/NavMenu.vue';
import FormElement from '../components/FormElement.vue';
import Seaway from '../views/OfferScreens/Seaway.vue';
import Airline from '../views/OfferScreens/Airline.vue';
import Roadway from '../views/OfferScreens/Roadway.vue';
import Railway from '../views/OfferScreens/Railway.vue';
import CreateOfferForm from './OfferScreens/CreateOfferForm.vue';

export default {
  data() {
    return {
      // List
      transferTypes: [
        {
          Id: 1,
          Name: 'Kargo Taşıma',
        },
        {
          Id: 2,
          Name: 'Express Taşıma',
        },
      ],
      // Data

      // States
      transferType: 1,
      shipmentType: 1,
      pageLoading: false,
      creatingOffer: false,
    };
  },
  components: {
    NavMenu,
    FormElement,
    Seaway,
    Airline,
    Roadway,
    Railway,
    CreateOfferForm,
  },
  mounted() {
    this.setup();
  },
  computed: {
    formItems() {
      return this.shipmentTypes.find((item) => item.Id === this.shipmentType)
        .formItems;
    },
    shipmentTypes() {
      return [
        {
          Id: 1,
          Name: 'Deniz Yolu',
          Shipments: [1],
          Icon: 'mdi-ferry',
        },
        {
          Id: 2,
          Name: 'Hava Yolu',
          Shipments: [1, 2],
          Icon: 'mdi-airplane',
        },
        {
          Id: 3,
          Name: 'Kara Yolu',
          Shipments: [1],
          Icon: 'mdi-truck',
        },
        {
          Id: 4,
          Name: 'Demir Yolu',
          Shipments: [1],
          Icon: 'mdi-train',
        },
      ];
    },
  },
  watch: {},
  methods: {
    getColumns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      } else if (this.$vuetify.breakpoint.lg) {
        return 5;
      } else {
        return 12;
      }
    },
    getFormColumns() {
      if (this.$vuetify.breakpoint.xl) {
        return 8;
      }
      if (this.$vuetify.breakpoint.lg) {
        return 7;
      } else {
        return 12;
      }
    },
    setup() {},
    /**
     * Resetting forms' validation.
     */
    async resetForm() {
      this.item = {};
      this.$refs.observer.reset();
      this.setup();
    },

    /**
     * This will trigger a function on service error.
     * @param {Function} special
     * @param {*} error
     */
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },

    /**
     * Reset all models in View.
     */
    resetAllModels() {
      this.resetForm();
    },
  },
};
</script>
<style>
#offerPage {
  background: url('../images/1.jpg') no-repeat;
  animation: zoomIn 0.75s ease-out;
}

@keyframes zoomIn {
  from {
    transform: scale(1.075);
    opacity: 0.75;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
