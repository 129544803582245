// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'spot-shipping';

export default {
  /**
   * Creates a spot shipping.
   * @param {string} fastShippingSearchId
   * @param {string} targetPrice
   * @param {string} currencyTypeId
   * @param {string} note
   * @returns {Promise<any>}
   */
  addSpotShipping(fastShippingSearchId, targetPrice, currencyTypeId, note) {
    return axios.post(`${RESOURCE_NAME}/add`, {
      FastShippingSearchId: fastShippingSearchId,
      TargetPrice: targetPrice,
      CurrencyTypeId: currencyTypeId,
      Note: note,
    });
  },

  /**
   * Get spot shipping list from service.
   * @param {number} page
   * @param {number} pageSize
   * @returns {Promise<any>}
   */
  getSpotShippingList(page = 1, pageSize = 10) {
    return axios.get(`${RESOURCE_NAME}/get`, {
      params: {
        page,
        pageSize,
      },
    });
  },

  /**
   * Update a spot shipping.
   * @param {string} id
   * @param {number} targetPrice
   * @param {string} currencyTypeId
   * @param {string} note
   * @returns {Promise<any>}
   */
  updateSpotShipping(id, targetPrice, currencyTypeId, note) {
    return axios.put(`${RESOURCE_NAME}/update`, {
      Id: id,
      TargetPrice: targetPrice ? targetPrice : null,
      CurrencyTypeId: currencyTypeId,
      Note: note,
    });
  },

  /**
   * Get a spot shipping by id.
   * @param {string} id
   * @returns {Promise<any>}
   */
  getSpotShippingById(id) {
    return axios.get(`${RESOURCE_NAME}/getById`, {
      params: {
        Id: id,
      },
    });
  },

  /**
   * Add a new package.
   * @param {{ FastShippingSearchId: string, CapPieces: number, Weight: Number, Height: number, Length: number, Width: number, GTipNo: string,CarriageType: string }} body
   * @returns {Promise<any>}
   */
  addPackage(body) {
    return axios.post(`fast-shipping-search-box/add`, body);
  },

  /**
   * Delete a package by id.
   * @param {number} id
   * @returns {Promise<any>}
   */
  deletePackage(id) {
    return axios.delete(`fast-shipping-search-box/delete`, {
      params: {
        FastShippingSearchBoxId: id,
      },
    });
  },

  /**
   * Update the boxes.
   * @param {{ FastShippingSearchId: string, boxViewModels: Array }} body
   * @returns {Promise<any>}
   */
  updateBoxes(body) {
    return axios.put(`fast-shipping-search/updateBoxes`, body);
  },
};
