<template>
  <v-card style="border-radius: 10px">
    <!-- INVALID FIELDS MODAL -->
    <v-dialog v-model="invalidFieldsModal" max-width="600">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 22px">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('labels.Warning') }}
          </div>
        </v-card-title>
        <v-card-text>
          <h4 style="color: red" class="mb-4">
            {{ $t('messages.requiredFieldsWarning') }}
          </h4>
          <div v-for="item in invalidFields">{{ item }}</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            style="
              color: #fff;
              text-transform: unset !important;
              background-color: var(--red);
              padding: 1vh 1vh;
              border-radius: 30px !important;
              font-weight: bolder;
              font-size: medium;
            "
            elevation="0"
            class="darken-1"
            @click="invalidFieldsModal = false"
            >{{ $t('buttons.close') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- INVALID FIELDS MODAL END -->

    <ConfirmationModal
      :title="$t('labels.warning')"
      :message="confirmation.message"
      :confirm="confirmation.onConfirm"
      :buttonSettings="confirmation.buttonSettings"
      v-model="confirmation.visible"
    />
    <v-dialog v-model="draftDialog" max-width="600">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 22px">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('buttons.saveAsDraft') }}
          </div>
        </v-card-title>
        <v-divider class="mb-4" />
        <ValidationObserver ref="observer">
          <v-card-text class="">
            <div class="text-style">
              {{ $t('description.saveOrderAsDraft') }}
            </div>
            <v-card-actions class="d-flex justify-center mt-10">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;
                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                color="primary"
                @click="sendDraft()"
                >{{ $t('buttons.save') }}</v-btn
              >
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                @click="draftDialog = false"
                >{{ $t('buttons.cancel') }}</v-btn
              >
            </v-card-actions>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-dialog v-model="orderFileWarningDialog" max-width="600">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 22px">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('titles.orderFileTypeWarning') }}
          </div>
        </v-card-title>
        <v-divider class="mb-4" />
        <ValidationObserver ref="observer">
          <v-card-text class="">
            <div class="text-style">
              {{ $t('description.orderFileTypeWarningDesc') }}
            </div>
            <ul class="mt-3">
              <li v-for="item in mandatoryFileTypes" :key="item.Id">
                {{ item.Name }}
              </li>
            </ul>
            <v-card-actions class="d-flex justify-center mt-10">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                @click="
                  orderFileWarningDialog = false;
                  mandatoryFileTypes = [];
                "
                >{{ $t('buttons.ok') }}</v-btn
              >
            </v-card-actions>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-dialog v-model="navToFiles" max-width="600">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 22px">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('labels.saveSuccess') }}
          </div>
        </v-card-title>
        <v-divider class="mb-4" />
        <ValidationObserver ref="observer">
          <v-card-text class="">
            <div class="text-style text-center">
              {{ $t('labels.fileNavDescription') }}
            </div>
            <v-card-actions class="d-flex justify-center mt-10">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                @click="navigateToFiles"
                >{{ $t('buttons.ok') }}</v-btn
              >
            </v-card-actions>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="short-content"
      v-model="priceDetailsModal"
      v-if="shipment.PriceDetails"
      max-width="600"
    >
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('titles.priceDetails') }}
          </div>
        </v-card-title>
        <v-divider class="mb-4" />

        <v-card-text>
          <v-list>
            <template v-for="(element, index) in shipment.PriceDetails">
              <v-list-item :key="`LIST_ITEM_${index}`">
                <div class="d-flex justify-space-between" style="width: 100%">
                  <h3 class="list-item-title">
                    {{ priceTypes[element.PriceType] }}
                  </h3>
                  <h2 class="price">
                    {{
                      `${$getFormattedFloatValue(element.Value)} ${
                        shipment.PriceCurrency
                      }`
                    }}
                  </h2>
                </div>
              </v-list-item>
              <v-divider></v-divider>
            </template>

            <v-list-item
              class="mt-4"
              :key="`LIST_ITEM_${shipment.PriceDetails.length}`"
            >
              <div class="d-flex justify-space-between" style="width: 100%">
                <h3 style="font-size: 30px" class="list-item-title">
                  {{ $t('titles.total') }}
                </h3>
                <h2 class="price last">
                  {{
                    `${parseFloat(shipment.Price).toFixed(2)} ${
                      shipment.PriceCurrency
                    }`
                  }}
                </h2>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            style="
              color: #fff;
              text-transform: unset !important;
              background-color: var(--red);
              padding: 1vh 1vh;
              border-radius: 30px !important;
              font-weight: bolder;
              font-size: medium;
            "
            elevation="0"
            class="darken-1"
            @click="priceDetailsModal = false"
            >{{ $t('buttons.cancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title style="color: #0177d4">
      <v-row class="px-2">
        <v-col cols="8" lg="8" class="d-flex justify-start">
          <v-icon
            v-if="!showTabScreen"
            @click.stop="showTabScreen = true"
            style="color: #0177d4; font-size: 36px"
            class="mr-2"
          >
            mdi-arrow-left
          </v-icon>
          <div v-if="$vuetify.breakpoint.xs">
            <h3
              :style="{
                fontSize: $vuetify.breakpoint.xs
                  ? '20px'
                  : $vuetify.breakpoint.lg || $vuetify.breakpoint.xl
                  ? '24px'
                  : '22px',
              }"
            >
              {{ $t('title.orderDetails') }}
            </h3>
          </div>
          <h3
            v-else
            :style="{
              fontSize: $vuetify.breakpoint.xs
                ? '20px'
                : $vuetify.breakpoint.lg || $vuetify.breakpoint.xl
                ? '24px'
                : '22px',
            }"
          >
            {{ $t('title.orderDetails') }}
          </h3>
        </v-col>
        <v-col cols="4" lg="4" class="d-flex justify-end">
          <v-btn
            v-if="userRole == 'Customer' || userRole == 'PendingCustomer'"
            @click="research"
            class="rounded-circle mr-4"
            style="
              min-width: 1px;
              min-height: 1px;
              width: 36px;
              height: 36px;
              background-color: #0177d4;
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small color="white" style="font-size: 24px" v-on="on">
                  mdi-magnify
                </v-icon>
              </template>
              <span>{{ $t('labels.research') }}</span>
            </v-tooltip>
          </v-btn>

          <BlockUser
            :onBlocked="onBlocked"
            v-if="isSupplier"
            :customer="{
              customerId: order.Shipment?.CustomerCompanyId,
              fullName: order.CustomerCompanyName,
            }"
          />

          <RefreshButton></RefreshButton>
        </v-col>
      </v-row>
    </v-card-title>

    <div v-if="!showTabScreen" class="contract-view" style="padding: 20px">
      <iframe
        :src="selectedFile.Url"
        width="100%"
        height="1000"
        frameborder="0"
      ></iframe>
    </div>

    <TabScreen
      v-if="showTabScreen"
      :slots="slots"
      :changeIndexFunction="handleTabChange"
      :activeTab="activeTab"
      :style="{
        paddingLeft: $vuetify.breakpoint.xs ? '5px' : '20px',
        paddingRight: $vuetify.breakpoint.xs ? '5px' : '20px',
      }"
    >
      <template v-slot:subsupplier>
        <v-card
          style="
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 2px;
            border-left-width: 2px;
            border-right-width: 2px;
            border-top-width: 0px;
            border-color: lightgray;
          "
          max-width="100%"
          min-width="100%"
        >
          <div class="px-4 py-4" v-if="subsupplierShipmentData">
            <h4 style="color: #0177d4">{{ $t('titles.integration.title') }}</h4>
            <v-row class="mt-4">
              <v-col class="font-weight-bold" lg="3" cols="3">
                {{ $t('titles.integration.trackingNumber') }}
              </v-col>
              <v-col lg="9" cols="9">
                {{ subsupplierShipmentData.MasterTrackingNumber }}
              </v-col>

              <v-col class="font-weight-bold" lg="3" cols="3">
                {{ $t('titles.integration.serviceName') }}
              </v-col>
              <v-col lg="9" cols="9">
                {{ subsupplierShipmentData.ServiceName }}
              </v-col>

              <v-col class="font-weight-bold" lg="3" cols="3">
                {{ $t('titles.integration.netRateAmount') }}
              </v-col>
              <v-col lg="9" cols="9">
                {{ subsupplierShipmentData.NetRateAmount }}
              </v-col>

              <v-col class="font-weight-bold" lg="3" cols="3">
                {{ $t('titles.integration.logisticsCompany') }}
              </v-col>
              <v-col lg="9" cols="9">
                {{
                  subsupplierShipmentData.LogisticType === 2 ? 'UPS' : 'FedEx'
                }}
              </v-col>

              <v-col class="font-weight-bold" lg="3" cols="3">
                {{ $t('titles.integration.label') }}
              </v-col>
              <v-col lg="9" cols="9">
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: #0077d5;
                    border-radius: 30px !important;
                    font-weight: bolder;
                  "
                  elevation="0"
                  class="darken-1"
                  :href="subsupplierShipmentData.LabelUrl"
                  download
                  target="_blank"
                >
                  {{ $t('buttons.download') }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div class="px-8 py-6" v-else>
            <ValidationObserver
              v-slot="{ handleSubmit }"
              ref="subsupplierFormReference"
            >
              <form @submit.prevent="handleSubmit(createShipment)">
                <v-row class="my-2">
                  <FormElement
                    v-model="selectedLogisticsCompany"
                    :validationLabel="$t('titles.subsupplier')"
                    :formLabel="$t('titles.subsupplier')"
                    :rules="'required'"
                    :disabled="true"
                    :formType="{
                      name: 'multipleSelect',
                      items: {
                        data: logisticsCompanies,
                        value: 'LogisticName',
                        text: 'LogisticName',
                      },
                    }"
                    :lg="6"
                    :labelShown="false"
                    :topLabel="true"
                    :placeholder="$t('titles.subsupplier')"
                  />

                  <FormElement
                    v-for="(element, index) in subsupplier_form_items"
                    :key="
                      element.key.parent + '_' + element.key.child + '_' + index
                    "
                    v-model="$data[element.key.parent][element.key.child]"
                    :validationLabel="$t(element.validationLabel)"
                    :rules="element.rules"
                    :formLabel="$t(element.formLabel)"
                    :onChange="element.onChange"
                    :formType="element.formType"
                    :lg="element.lg"
                    :cols="element.cols"
                    :disabled="element.disabled"
                    :hidden="element.hidden"
                    :hideDetails="true"
                    :labelShown="element.labelShown"
                    :active="element.active"
                    :topLabel="element.topLabel"
                    :placeholder="$t(element.placeholder)"
                    :ref="element.key.parent + '_' + element.key.child"
                  />
                  <v-col align="center" lg="12">
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: #0077d5;
                        padding: 2vh 4vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                      "
                      elevation="0"
                      class="darken-1 mt-4 mx-auto"
                      type="submit"
                      :loading="$store.state.loadingStatus"
                      :disabled="$store.state.loadingStatus"
                    >
                      {{ $t('buttons.createShipment') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </form>
            </ValidationObserver>
          </div>
        </v-card>
      </template>

      <template v-slot:orderInformation>
        <v-card
          style="
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 2px;
            border-left-width: 2px;
            border-right-width: 2px;
            border-top-width: 0px;
            border-color: lightgray;
          "
          max-width="100%"
          min-width="100%"
        >
          <div class="px-4 py-2">
            <v-dialog
              v-model="addSubCompany"
              @input="resetSubCompanyDialog"
              @close="resetSubCompanyDialog"
              max-width="600"
            >
              <v-card class="popup-card" elevation="1">
                <v-card-title
                  class="popupHeadline d-flex justify-center"
                  style="font-size: 16px"
                >
                  <h3>{{ $t('labels.addSubCompany') }}</h3>
                </v-card-title>
                <v-divider class="mb-4" />
                <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                  <v-card-text class="">
                    <form @submit.prevent="handleSubmit(saveSubCompany)">
                      <v-row>
                        <FormElement
                          v-for="(element, index) in addSubModalFormElement"
                          :key="
                            element.key.parent +
                            '_' +
                            element.key.child +
                            '_' +
                            index
                          "
                          v-model="$data[element.key.parent][element.key.child]"
                          :validationLabel="$t(element.validationLabel)"
                          :rules="element.rules"
                          :formLabel="$t(element.formLabel)"
                          :onChange="element.onChange"
                          :formType="element.formType"
                          :lg="element.lg"
                          :cols="element.cols"
                          :hidden="element.hidden"
                          :labelShown="element.labelShown"
                          :topLabel="element.topLabel"
                          :active="element.active"
                          :placeholder="$t(element.placeholder)"
                          @openURL="handleOpenURL"
                          :ref="element.key.parent + '_' + element.key.child"
                        />
                      </v-row>
                      <v-row class="mb-4">
                        <v-col
                          cols="12"
                          class="d-flex flex-row px-2"
                          style="padding: 0"
                        >
                          <v-checkbox
                            style="padding: 0; margin: 0"
                            hide-details
                            v-model="isHome"
                          ></v-checkbox>
                          <label> {{ $t('labels.isHome') }} </label>
                        </v-col>
                      </v-row>
                      <v-card-actions class="text-center justify-center">
                        <v-btn
                          style="
                            color: #fff;
                            text-transform: unset !important;
                            background-color: #0077d5;
                            padding: 1vh 1vh;
                            border-radius: 30px !important;
                            font-weight: bolder;
                            font-size: medium;
                          "
                          elevation="0"
                          class="darken-1"
                          type="submit"
                        >
                          {{
                            $t(
                              'buttons.' +
                                (editAddressModeModal ? 'update' : 'save')
                            )
                          }}
                        </v-btn>
                        <v-btn
                          style="
                            color: #fff;
                            text-transform: unset !important;
                            background-color: var(--red);
                            padding: 1vh 1vh;
                            border-radius: 30px !important;
                            font-weight: bolder;
                            font-size: medium;
                          "
                          elevation="0"
                          class="darken-2"
                          @click="resetSubCompanyDialog"
                        >
                          {{ $t('buttons.close') }}
                        </v-btn>
                      </v-card-actions>
                    </form>
                  </v-card-text>
                </ValidationObserver>
              </v-card>
            </v-dialog>

            <v-dialog v-model="contractModal" max-width="600">
              <v-card class="popup-card" elevation="1">
                <v-card-title
                  class="popupHeadline d-flex justify-center"
                  style="font-size: 16px"
                >
                  <h3>{{ $t('labels.contract') }}</h3>
                </v-card-title>
                <v-divider class="mb-4" />
                <ValidationObserver>
                  <v-card-text>
                    <div v-html="contractContent" />

                    <v-card-actions
                      v-if="!order.SignStatus"
                      class="text-center justify-center"
                    >
                      <v-btn
                        style="
                          color: #fff;
                          background-color: #0077d5;
                          padding: 1vh 1vh;
                          border-radius: 30px !important;
                          font-weight: bolder;
                          font-size: medium;
                        "
                        elevation="0"
                        class="darken-1"
                        @click="acceptContract"
                      >
                        {{ $t('labels.tooltipAccept') }}
                      </v-btn>
                      <v-btn
                        style="
                          color: #fff;
                          background-color: var(--red);
                          padding: 1vh 1vh;
                          border-radius: 30px !important;
                          font-weight: bolder;
                          font-size: medium;
                        "
                        elevation="0"
                        class="darken-2"
                        @click="contractModal = false"
                      >
                        {{ $t('buttons.close') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                </ValidationObserver>
              </v-card>
            </v-dialog>

            <v-dialog
              v-model="cancelOrderModal"
              max-width="600"
              @input="resetCancelModal"
            >
              <v-card class="popup-card" elevation="1">
                <v-card-title
                  class="popupHeadline d-flex justify-center"
                  style="font-size: 16px"
                >
                  <h3>{{ $t('buttons.cancelOrder') }}</h3>
                </v-card-title>
                <v-divider class="mb-4" />

                <ValidationObserver
                  v-slot="{ handleSubmit }"
                  ref="cancelModalObserver"
                >
                  <v-card-text>
                    <form @submit.prevent="handleSubmit(cancelOrder)">
                      <v-row>
                        <FormElement
                          :vertical="true"
                          v-model="selectedReason"
                          :validationLabel="$t('labels.cancelReason')"
                          rules="required"
                          :topLabel="true"
                          :labelShown="false"
                          :lg="12"
                          :formType="{
                            name: 'multipleSelect',
                            items: {
                              data: cancelReasons,
                              value: 'value',
                              text: 'name',
                            },
                          }"
                          :placeholder="$t('labels.cancelReason')"
                        />
                        <FormElement
                          v-model="cancelDescription"
                          :lg="12"
                          rules="required"
                          :validationLabel="$t('labels.cancelDescription')"
                          :labelShown="false"
                          :placeholder="$t('labels.cancelDescription')"
                          :formType="{ name: 'textarea' }"
                        />
                      </v-row>
                      <v-card-actions class="text-center justify-center">
                        <v-btn
                          style="
                            color: #fff;
                            text-transform: unset !important;
                            background-color: #0077d5;
                            padding: 1vh 1vh;
                            border-radius: 30px !important;
                            font-weight: bolder;
                            font-size: medium;
                          "
                          elevation="0"
                          type="submit"
                          class="darken-1"
                        >
                          {{ $t('buttons.approve') }}
                        </v-btn>
                        <v-btn
                          style="
                            color: #fff;
                            text-transform: unset !important;
                            background-color: var(--red);
                            padding: 1vh 1vh;
                            border-radius: 30px !important;
                            font-weight: bolder;
                            font-size: medium;
                          "
                          elevation="0"
                          class="darken-2"
                          @click="resetCancelModal"
                        >
                          {{ $t('buttons.close') }}
                        </v-btn>
                      </v-card-actions>
                    </form>
                  </v-card-text>
                </ValidationObserver>
              </v-card>
            </v-dialog>

            <AddVolume
              :onSave="onSaveVolumes"
              :calculateParameter="calculateParameter"
              :calculateChargeableWeight="
                shipment.TransportType === 2 ? calculateChargeableWeight : null
              "
              v-model="addVolumeModal"
              key="ORDER_DETAIL_PACKAGE_DETAILS"
              :volumesByUser="convertedVolumes"
              :readOnly="order.OrderStatus > 0 && !isSupplier"
              v-if="!$store.state.loadingStatus"
            />
            <v-row v-if="order.OrderStatus === 9" class="mb-1 mt-2">
              <v-col cols="12" class="px-6">
                <v-row class="elevated-card px-2">
                  <v-col cols="12" class="pb-2" style="padding: 0">
                    <v-subheader class="text-h6">{{
                      $t('labels.cancelInformation')
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <FormElement
                        :vertical="true"
                        v-model="order.CancelReason"
                        :validationLabel="$t('labels.cancelReason')"
                        :disabled="true"
                        :topLabel="true"
                        :labelShown="false"
                        :lg="12"
                        :formType="{
                          name: 'multipleSelect',
                          items: {
                            data: cancelReasons,
                            value: 'value',
                            text: 'name',
                            appendIcon: 'null',
                          },
                        }"
                        :placeholder="$t('labels.cancelReason')"
                      />
                      <FormElement
                        v-model="order.CancelDescription"
                        :lg="12"
                        :disabled="true"
                        :validationLabel="$t('labels.cancelDescription')"
                        :labelShown="false"
                        :placeholder="$t('labels.cancelDescription')"
                        :formType="{ name: 'textarea' }"
                      />
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <ValidationObserver ref="updateOrderObserver">
              <form>
                <v-expansion-panels
                  v-if="
                    order?.CustomerCompanyName &&
                    order?.CustomerCompanyGsmNo &&
                    order?.CustomerCompanyEmail
                  "
                  :value="0"
                  class="mt-4"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      style="padding: 0"
                      class="pl-6 pt-4 pr-4 pb-2"
                    >
                      <p
                        style="color: #235c9e; font-weight: bold"
                        class="text-h6 pt-2"
                      >
                        {{ $t('labels.customerInformation') }}
                      </p>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="mb-2 mt-1">
                        <FormElement
                          v-for="(element, index) in customerForm"
                          :key="
                            element.key.parent +
                            '_' +
                            element.key.child +
                            '_' +
                            index
                          "
                          v-model="$data[element.key.parent][element.key.child]"
                          :validationLabel="$t(element.validationLabel)"
                          :rules="element.rules"
                          :formLabel="$t(element.formLabel)"
                          :onChange="element.onChange"
                          :formType="element.formType"
                          :cols="element.cols"
                          :sm="element.sm"
                          :md="element.md"
                          :lg="element.lg"
                          :xl="element.xl"
                          :disabled="element.disabled"
                          :hidden="element.hidden"
                          :labelShown="element.labelShown"
                          :active="element.active"
                          :topLabel="element.topLabel"
                          :placeholder="$t(element.placeholder)"
                          :ref="element.key.parent + '_' + element.key.child"
                        />
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels
                  v-if="order.OrderStatus == 0 || shipment?.PurposeOfShipment"
                  :value="0"
                  class="mt-4"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      style="padding: 0"
                      class="pl-6 pt-4 pr-4 pb-2"
                    >
                      <p
                        style="color: #235c9e; font-weight: bold"
                        class="text-h6 pt-2"
                      >
                        {{ $t('labels.purposeOfShippingLabel') }}
                      </p>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="mb-2 mt-1">
                        <FormElement
                          v-for="(element, index) in purposeOfShippingForm"
                          :key="
                            element.key.parent +
                            '_' +
                            element.key.child +
                            '_' +
                            index
                          "
                          v-model="$data[element.key.parent][element.key.child]"
                          :validationLabel="$t(element.validationLabel)"
                          :rules="element.rules"
                          :formLabel="$t(element.formLabel)"
                          :onChange="element.onChange"
                          :formType="element.formType"
                          :cols="element.cols"
                          :sm="element.sm"
                          :md="element.md"
                          :lg="element.lg"
                          :xl="element.xl"
                          :disabled="element.disabled"
                          :hidden="element.hidden"
                          :hideDetails="true"
                          :labelShown="element.labelShown"
                          :active="element.active"
                          :topLabel="element.topLabel"
                          :placeholder="$t(element.placeholder)"
                          :ref="element.key.parent + '_' + element.key.child"
                        />
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels :value="0" class="mt-4">
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      style="padding: 0"
                      class="pl-6 pt-4 pr-4 pb-2"
                    >
                      <div
                        class="d-flex flex-column flex-sm-row align-sm-center"
                      >
                        <p
                          style="color: #235c9e; font-weight: bold"
                          class="text-h6 pt-2 mr-4"
                        >
                          {{ $t('labels.generalOrderInformation') }}
                        </p>
                        <v-chip class="mb-2" color="blue" dark
                          ><span class="font-weight-bold">{{
                            orderType
                          }}</span></v-chip
                        >
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="mb-2 mt-1">
                        <FormElement
                          v-for="(element, index) in orderDetailTop"
                          :key="
                            element.key.parent +
                            '_' +
                            element.key.child +
                            '_' +
                            index
                          "
                          v-model="$data[element.key.parent][element.key.child]"
                          :validationLabel="$t(element.validationLabel)"
                          :rules="element.rules"
                          :formLabel="$t(element.formLabel)"
                          :onChange="element.onChange"
                          :formType="element.formType"
                          :cols="element.cols"
                          :sm="element.sm"
                          :md="element.md"
                          :lg="element.lg"
                          :xl="element.xl"
                          :disabled="element.disabled"
                          :hidden="element.hidden"
                          :labelShown="element.labelShown"
                          :active="element.active"
                          :topLabel="element.topLabel"
                          :placeholder="$t(element.placeholder)"
                          :willBeEliminated="false"
                          :ref="element.key.parent + '_' + element.key.child"
                        />
                        <FormElement
                          :vertical="true"
                          v-model="shipment.HazmatCodeDescription"
                          :validationLabel="$t('labels.hazmatDescription')"
                          :disabled="true"
                          :topLabel="true"
                          :labelShown="false"
                          :cols="12"
                          :sm="12"
                          :md="shipment.LoadingCountry ? 8 : 12"
                          :lg="6"
                          :xl="6"
                          :formType="{
                            name: 'text',
                          }"
                          :placeholder="$t('labels.hazmatDescription')"
                          :ref="'HAZMAT_CODE_DESCRIPTION'"
                        />
                        <FormElement
                          v-for="(element, index) in volumeRow"
                          :key="
                            element.key.parent +
                            '_' +
                            element.key.child +
                            '_' +
                            index
                          "
                          v-model="$data[element.key.parent][element.key.child]"
                          :validationLabel="$t(element.validationLabel)"
                          :rules="element.rules"
                          :formLabel="$t(element.formLabel)"
                          :onChange="element.onChange"
                          :formType="element.formType"
                          :lg="element.lg"
                          :sm="element.sm"
                          :cols="element.cols"
                          :disabled="element.disabled"
                          :hidden="element.hidden"
                          :labelShown="element.labelShown"
                          :topLabel="element.topLabel"
                          :active="element.active"
                          :placeholder="$t(element.placeholder)"
                          :willBeEliminated="false"
                          :ref="element.key.parent + '_' + element.key.child"
                        />

                        <v-col
                          class="ml-auto"
                          cols="12"
                          sm="12"
                          md="6"
                          lg="3"
                          xl="2"
                          style="
                            padding: 0px;
                            padding-left: 5px;
                            padding-right: 5px;
                          "
                          v-if="
                            (shipment.LoadingType === 4 ||
                            shipment.LoadingType === 1 ||
                            shipment.LoadingType === 6) && order.Shipment.ExpressPackageType !== 2
                          "
                        >
                          <v-btn
                            style="
                              color: #fff;
                              text-transform: unset !important;
                              background-color: var(--primary);
                              height: 50px;
                              width: 100%;
                              border-radius: 12px !important;
                              font-weight: bold;
                              font-size: small;
                            "
                            elevation="0"
                            class="volumeButton"
                            @click="openAddVolumeModal()"
                          >
                            {{
                              $t('buttons.viewPackageDetails') +
                              '(' +
                              order.Shipment.ShipmentBoxes.length +
                              ')'
                            }}
                          </v-btn>
                          <span
                            v-if="order.Shipment.IsShipmentBoxesUpdated"
                            style="
                              width: 100%;
                              margin-top: 5px;
                              color: red;
                              font-size: 12px;
                              font-weight: normal;
                              display: flex;
                              justify-content: center;
                            "
                          >
                            {{ $t('description.packageDetailsUpdated') }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-row class="mt-4">
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="6"
                    xl="6"
                    class="d-flex px-6 pr-sm-4 mb-4 mb-sm-0"
                  >
                    <v-row class="elevated-card px-2">
                      <v-col cols="12" class="pb-2" style="padding: 0">
                        <v-subheader class="text-h6">{{
                          $t('labels.carrierCompanyInfo')
                        }}</v-subheader>
                      </v-col>
                      <FormElement
                        v-model="loadingCompany"
                        :cols="8"
                        :sm="8"
                        :md="10"
                        :lg="10"
                        :xl="10"
                        v-if="order.OrderStatus === 0"
                        key="LOADING_COMPANY"
                        :rules="{
                          required:
                            (userRole == 'Customer' ||
                              userRole == 'PendingCustomer') &&
                            order.OrderStatus == 0,
                        }"
                        :validationLabel="$t('labels.loaderCompany')"
                        :labelShown="false"
                        :formType="{
                          name: 'multipleSelect',
                          items: {
                            data: subCompanies,
                            value: null,
                            text: 'displayName',
                          },
                        }"
                        :onChange="updateLoadingSubCompany"
                        :disabled="
                          order.OrderStatus !== 0 ||
                          userRole == 'Supplier' ||
                          userRole == 'PendingSupplier'
                        "
                        :placeholder="$t('labels.loaderCompany')"
                        :ref="'LOADING_COMPANY'"
                      />

                      <FormElement
                        v-model="shipment.LoadingSubCompany"
                        :cols="12"
                        :sm="12"
                        :md="6"
                        :lg="6"
                        :xl="6"
                        :rules="{
                          required:
                            (userRole == 'Customer' ||
                              userRole == 'PendingCustomer') &&
                            order.OrderStatus == 0,
                        }"
                        v-if="order.OrderStatus > 0"
                        :validationLabel="$t('labels.loaderCompany')"
                        :topLabel="true"
                        :labelShown="false"
                        :formType="{
                          name: 'text',
                        }"
                        :onChange="updateLoadingSubCompany"
                        :disabled="
                          order.OrderStatus !== 0 ||
                          userRole == 'Supplier' ||
                          userRole == 'PendingSupplier'
                        "
                        :placeholder="$t('labels.loaderCompany')"
                        :ref="'LOADING_SUB_COMPANY'"
                      />

                      <v-col
                        :cols="2"
                        :sm="2"
                        :md="1"
                        :lg="1"
                        :xl="1"
                        style="padding: 0"
                        v-if="
                          order.OrderStatus == 0 &&
                          ($store.state.Type === 'Customer' ||
                            $store.state.Type === 'PendingCustomer')
                        "
                      >
                        <v-btn
                          :disabled="!loadingCompany"
                          class="rounded-lg"
                          @click="editAddressMode()"
                          style="
                            width: 95%;
                            height: 65%;
                            min-width: 0px;
                            min-height: 0px;
                            background-color: #235c9e;
                            color: #ffffff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <v-icon> mdi-pencil </v-icon>
                        </v-btn>
                      </v-col>

                      <v-col
                        :cols="2"
                        :sm="2"
                        :md="1"
                        :lg="1"
                        :xl="1"
                        style="padding: 0"
                        v-if="
                          order.OrderStatus == 0 &&
                          ($store.state.Type === 'Customer' ||
                            $store.state.Type === 'PendingCustomer')
                        "
                      >
                        <v-btn
                          class="rounded-lg"
                          @click="
                            editAddressModeModal = false;
                            addSubCompany = true;
                            isSubCompanyLoading = true;
                          "
                          style="
                            width: 95%;
                            height: 65%;
                            min-width: 0px;
                            min-height: 0px;
                            background-color: #235c9e;
                            color: #ffffff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <v-icon> mdi-plus </v-icon>
                        </v-btn>
                      </v-col>

                      <FormElement
                        v-model="destinationCompany"
                        :cols="8"
                        :sm="8"
                        :md="10"
                        :lg="10"
                        :xl="10"
                        :rules="{
                          required:
                            (userRole == 'Customer' ||
                              userRole == 'PendingCustomer') &&
                            order.OrderStatus == 0,
                        }"
                        v-if="order.OrderStatus === 0"
                        key="BUYER_COMPANY"
                        :validationLabel="$t('labels.buyerCompany')"
                        :labelShown="false"
                        :formType="{
                          name: 'multipleSelect',
                          items: {
                            data: subCompanies,
                            value: null,
                            text: 'displayName',
                          },
                        }"
                        :onChange="updateDestinationSubCompany"
                        :disabled="
                          order.OrderStatus !== 0 ||
                          userRole == 'Supplier' ||
                          userRole == 'PendingSupplier'
                        "
                        :placeholder="$t('labels.buyerCompany')"
                        :ref="'BUYER_COMPANY'"
                      />

                      <FormElement
                        v-if="order.OrderStatus > 0"
                        v-model="shipment.DestinationSubCompany"
                        :cols="12"
                        :sm="12"
                        :md="6"
                        :lg="6"
                        :xl="6"
                        :rules="{
                          required:
                            userRole == 'Customer' && order.OrderStatus == 0,
                        }"
                        :validationLabel="$t('labels.buyerCompany')"
                        :labelShown="false"
                        :topLabel="true"
                        :formType="{
                          name: 'text',
                        }"
                        :onChange="updateLoadingSubCompany"
                        :disabled="
                          order.OrderStatus !== 0 ||
                          userRole == 'Supplier' ||
                          userRole == 'PendingSupplier'
                        "
                        :placeholder="$t('labels.buyerCompany')"
                        :ref="'BUYER_SUB_COMPANY'"
                      />
                      <v-col
                        :cols="2"
                        :sm="2"
                        :md="1"
                        :lg="1"
                        :xl="1"
                        style="padding: 0"
                        v-if="
                          order.OrderStatus == 0 &&
                          ($store.state.Type === 'Customer' ||
                            $store.state.Type === 'PendingCustomer')
                        "
                      >
                        <v-btn
                          :disabled="!destinationCompany"
                          class="rounded-lg"
                          @click="editAddressMode(false)"
                          style="
                            width: 95%;
                            height: 65%;
                            min-width: 0px;
                            min-height: 0px;
                            background-color: #235c9e;
                            color: #ffffff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <v-icon> mdi-pencil </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col
                        :cols="2"
                        :sm="2"
                        :md="1"
                        :lg="1"
                        :xl="1"
                        style="padding: 0"
                        v-if="
                          order.OrderStatus == 0 &&
                          ($store.state.Type === 'Customer' ||
                            $store.state.Type === 'PendingCustomer')
                        "
                      >
                        <v-btn
                          class="rounded-lg"
                          @click="
                            editAddressModeModal = false;
                            addSubCompany = true;
                            isSubCompanyLoading = false;
                          "
                          style="
                            width: 95%;
                            height: 65%;
                            min-width: 0px;
                            min-height: 0px;
                            background-color: #235c9e;
                            color: #ffffff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <v-icon> mdi-plus </v-icon>
                        </v-btn>
                      </v-col>

                      <FormElement
                        v-for="(element, index) in companyAddressFormElement"
                        :key="
                          element.key.parent +
                          '_' +
                          element.key.child +
                          '_' +
                          index
                        "
                        v-model="$data[element.key.parent][element.key.child]"
                        :validationLabel="$t(element.validationLabel)"
                        :rules="element.rules"
                        :formLabel="$t(element.formLabel)"
                        :onChange="element.onChange"
                        :formType="element.formType"
                        :lg="element.lg"
                        :cols="element.cols"
                        :disabled="element.disabled"
                        :hidden="element.hidden"
                        :labelShown="element.labelShown"
                        :topLabel="element.topLabel"
                        :active="element.active"
                        :placeholder="$t(element.placeholder)"
                        :ref="element.key.parent + '_' + element.key.child"
                      />
                    </v-row>
                  </v-col>

                  <v-spacer></v-spacer>

                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="6"
                    xl="6"
                    class="d-flex px-6"
                  >
                    <v-row class="elevated-card px-2">
                      <v-col cols="12" class="pb-2" style="padding: 0">
                        <v-subheader class="text-h6">{{
                          $t('labels.orderDates')
                        }}</v-subheader>
                        <p
                          v-if="
                            order.PaymentStatus == 0 && order.OrderStatus > 1
                          "
                          class="pl-2"
                          style="
                            margin: 5px;
                            flex: 1;
                            color: var(--red);
                            font-size: 14px;
                            font-weight: 600;
                            font-style: italic;
                          "
                        >
                          {{ $t('description.makeSurePayment') }}
                        </p>
                      </v-col>

                      <FormElement
                        v-for="(element, index) in dateRow"
                        :key="
                          element.key.parent +
                          '_' +
                          element.key.child +
                          '_' +
                          index
                        "
                        v-model="$data[element.key.parent][element.key.child]"
                        :validationLabel="$t(element.validationLabel)"
                        :rules="element.rules"
                        :formLabel="$t(element.formLabel)"
                        :onChange="element.onChange"
                        :formType="element.formType"
                        :lg="element.lg"
                        :cols="element.cols"
                        :disabled="element.disabled"
                        :hidden="element.hidden"
                        :labelShown="element.labelShown"
                        :topLabel="element.topLabel"
                        :active="element.active"
                        :placeholder="$t(element.placeholder)"
                        :ref="element.key.parent + '_' + element.key.child"
                      />
                    </v-row>
                  </v-col>
                </v-row>

                <v-row v-if="order.Shipment?.TransportType === 2" class="mt-6">
                  <v-col cols="12" class="px-6">
                    <v-row class="elevated-card px-2">
                      <v-col cols="12" class="pb-2" style="padding: 0">
                        <v-subheader class="text-h6">{{
                          $t('labels.expressInformation')
                        }}</v-subheader>
                      </v-col>
                      <v-col cols="12">
                        <v-row>
                          <FormElement
                            v-model="order.Shipment.TaxPayer"
                            :cols="12"
                            :sm="6"
                            :md="3"
                            :lg="3"
                            :xl="3"
                            :disabled="
                              userRole == 'Supplier' ||
                              userRole == 'PendingSupplier' ||
                              order.OrderStatus != 0
                            "
                            :rules="{
                              required:
                                (userRole == 'Customer' ||
                                  userRole == 'PendingCustomer') &&
                                order.OrderStatus == 0,
                            }"
                            :validationLabel="$t('labels.taxPayer')"
                            :topLabel="true"
                            :labelShown="false"
                            :formType="{
                              name: 'select',
                              items: {
                                data: taxPayers,
                                text: 'name',
                                value: 'value',
                              },
                            }"
                            :placeholder="$t('labels.taxPayer')"
                            :ref="'TAX_PAYER'"
                          />

                          <!-- :rules="order.Shipment.TaxPayer == 1 ? 'required|min:3|onlyStringText' : false" -->
                          <FormElement
                            v-model="order.Shipment.IOSS"
                            :cols="12"
                            :sm="6"
                            :md="3"
                            :lg="3"
                            :xl="3"
                            :validationLabel="$t('labels.IOSS')"
                            :formLabel="$t('labels.IOSS')"
                            :disabled="
                              userRole == 'Supplier' ||
                              userRole == 'PendingSupplier' ||
                              order.OrderStatus != 0
                            "
                            :formType="{
                              name: 'text',
                            }"
                            :labelShown="false"
                            :topLabel="true"
                            :placeholder="$t('labels.IOSS')"
                            :ref="'IOSS'"
                          />
                          <!-- <v-checkbox
                              :disabled="
                                userRole == 'Supplier' ||
                                userRole == 'PendingSupplier' ||
                                order.OrderStatus != 0
                              "
                              hide-details
                              v-model="order.Shipment.IOSS"
                            ></v-checkbox>

                            <label class="mt-5"> {{
                              $t('labels.IOSS')
                            }} </label> -->
                        </v-row>
                        <v-row>
                          <FormElement
                            v-if="false"
                            v-for="(element, index) in ETGB_formItems"
                            :key="
                              element.key.parent +
                              '_' +
                              element.key.child +
                              '_' +
                              index
                            "
                            v-model="$data[element.key.parent][element.key.child]"
                            :validationLabel="$t(element.validationLabel)"
                            :rules="sendSupplier ? 'required' : ''"
                            :formLabel="$t(element.formLabel)"
                            :onChange="element.onChange"
                            :formType="element.formType"
                            :lg="element.lg"
                            :sm="element.sm"
                            :cols="element.cols"
                            :disabled="element.disabled"
                            :hidden="element.hidden"
                            :labelShown="element.labelShown"
                            :topLabel="element.topLabel"
                            :active="element.active"
                            :placeholder="$t(element.placeholder)"
                            :willBeEliminated="false"
                          />
                          <FormElement
                            v-model="order.Shipment.CustomerNote"
                            :lg="12"
                            :disabled="
                              userRole == 'Supplier' ||
                              userRole == 'PendingSupplier' ||
                              order.OrderStatus != 0
                            "
                            :validationLabel="$t('labels.customerNote')"
                            :labelShown="false"
                            :placeholder="$t('labels.customerNote')"
                            :formType="{ name: 'textarea' }"
                            :ref="'CUSTOMER_NOTE'"
                          />
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="py-2 mt-6">
                  <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    :lg="isSupplier ? 4 : 6"
                    xl="4"
                    class="px-6 pr-lg-4 pl-lg-6"
                  >
                    <v-row class="elevated-card px-2">
                      <v-col cols="12" style="padding: 0">
                        <v-subheader class="text-h6 mb-2">{{
                          $t('labels.deliveryInfo')
                        }}</v-subheader>
                      </v-col>

                      <FormElement
                        v-for="(element, index) in carryRow"
                        :key="
                          element.key.parent +
                          '_' +
                          element.key.child +
                          '_' +
                          index
                        "
                        v-model="$data[element.key.parent][element.key.child]"
                        :validationLabel="$t(element.validationLabel)"
                        :rules="element.rules"
                        :formLabel="$t(element.formLabel)"
                        :onChange="element.onChange"
                        :formType="element.formType"
                        :lg="element.lg"
                        :cols="element.cols"
                        :willBeEliminated="false"
                        :disabled="element.disabled"
                        :hidden="element.hidden"
                        :labelShown="element.labelShown"
                        :topLabel="element.topLabel"
                        :active="element.active"
                        :placeholder="$t(element.placeholder)"
                        :ref="element.key.parent + '_' + element.key.child"
                      />
                      <FormElement
                        v-if="shipment.TransportType === 2"
                        v-for="(element, index) in subsupplier_form"
                        :key="
                          element.key.parent +
                          '_' +
                          element.key.child +
                          '_' +
                          index
                        "
                        v-model="$data[element.key.parent][element.key.child]"
                        :validationLabel="$t(element.validationLabel)"
                        :rules="element.rules"
                        :formLabel="$t(element.formLabel)"
                        :onChange="element.onChange"
                        :formType="element.formType"
                        :lg="element.lg"
                        :cols="element.cols"
                        :willBeEliminated="false"
                        :disabled="element.disabled"
                        :hidden="element.hidden"
                        :labelShown="element.labelShown"
                        :topLabel="element.topLabel"
                        :active="element.active"
                        :placeholder="$t(element.placeholder)"
                        :ref="element.key.parent + '_' + element.key.child"
                      />
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    :lg="isSupplier ? 4 : 6"
                    xl="4"
                    class="px-6 px-md-4 mt-4 mt-md-0"
                  >
                    <v-row class="elevated-card px-2">
                      <v-col cols="12" style="padding: 0">
                        <v-subheader class="text-h6 mb-2">{{
                          $t('labels.payOptions')
                        }}</v-subheader>
                      </v-col>

                      <FormElement
                        v-for="(element, index) in priceRow"
                        :key="
                          element.key.parent +
                          '_' +
                          element.key.child +
                          '_' +
                          index
                        "
                        v-model="$data[element.key.parent][element.key.child]"
                        :validationLabel="$t(element.validationLabel)"
                        :rules="element.rules"
                        :formLabel="$t(element.formLabel)"
                        :onChange="element.onChange"
                        :formType="element.formType"
                        :lg="element.lg"
                        :cols="element.cols"
                        :disabled="element.disabled"
                        :hidden="element.hidden"
                        :labelShown="element.labelShown"
                        :topLabel="element.topLabel"
                        :active="element.active"
                        :placeholder="$t(element.placeholder)"
                        :ref="element.key.parent + '_' + element.key.child"
                      />
                    </v-row>
                  </v-col>
                  <v-col
                    v-if="isSupplier"
                    cols="12"
                    sm="12"
                    md="4"
                    lg="4"
                    xl="4"
                    class="px-6 mt-4 mt-md-0"
                  >
                    <v-row class="elevated-card px-2">
                      <v-col cols="12" style="padding: 0">
                        <v-subheader class="text-h6 mb-2">{{
                          $t('titles.financialInformation')
                        }}</v-subheader>
                      </v-col>

                      <FormElement
                        v-for="(element, index) in expense_form_items"
                        :key="
                          element.key.parent +
                          '_' +
                          element.key.child +
                          '_' +
                          index
                        "
                        v-model="$data[element.key.parent][element.key.child]"
                        :validationLabel="$t(element.validationLabel)"
                        :rules="element.rules"
                        :formLabel="$t(element.formLabel)"
                        :onChange="element.onChange"
                        :formType="element.formType"
                        :lg="element.lg"
                        :xl="element.xl"
                        :cols="element.cols"
                        :disabled="element.disabled"
                        :hidden="element.hidden"
                        :labelShown="element.labelShown"
                        :topLabel="element.topLabel"
                        :active="element.active"
                        :placeholder="$t(element.placeholder)"
                        :ref="element.key.parent + '_' + element.key.child"
                      />
                    </v-row>
                  </v-col>
                </v-row>

                <v-row
                  v-if="order?.Shipment?.TransportType === 2"
                  class="mt-2 mt-md-0 px-3 mb-4"
                >
                  <v-expansion-panels :value="0" class="mt-4">
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        style="padding: 0"
                        class="pl-6 pt-4 pr-4 pb-2"
                      >
                        <p
                          style="color: #235c9e; font-weight: bold"
                          class="text-h6 pt-2"
                        >
                          {{ $t('labels.otherServices') }}
                        </p>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row class="mb-2 mt-1">
                          <FormElement
                            v-for="(element, index) in otherServices"
                            :classes="element.classes"
                            :key="
                              element.key.parent +
                              '_' +
                              element.key.child +
                              '_' +
                              index
                            "
                            v-model="
                              $data[element.key.parent][element.key.child]
                            "
                            :validationLabel="$t(element.validationLabel)"
                            :rules="element.rules"
                            :formLabel="$t(element.formLabel)"
                            :onChange="element.onChange"
                            :formType="element.formType"
                            :lg="element.lg"
                            :cols="element.cols"
                            :disabled="element.disabled"
                            :hidden="element.hidden"
                            :hideDetails="true"
                            :labelShown="element.labelShown"
                            :active="element.active"
                            :topLabel="element.topLabel"
                            :placeholder="$t(element.placeholder)"
                            :ref="element.key.parent + '_' + element.key.child"
                          />
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>

                <v-row v-if="order.OrderStatus != 9" class="px-6 mt-4 mt-lg-0">
                  <v-col
                    cols="12"
                    class="d-flex flex-row pb-2"
                    style="padding: 0; max-width: max-content; flex-grow: 1"
                  >
                    <v-checkbox
                      :disabled="order.SignStatus || isSupplier || !isAgreement"
                      hide-details
                      v-model="order.SignStatus"
                    ></v-checkbox>

                    <label class="mt-5 link">
                      <a @click="openContract">
                        {{ $t('labels.readAccept') }}
                      </a>
                    </label>
                  </v-col>
                  <v-col
                    v-if="order.OrderStatus == 0"
                    cols="12"
                    class="d-flex flex-row pb-2"
                    style="padding: 0"
                  >
                    <v-checkbox
                      :disabled="lockButton"
                      hide-details
                      v-model="sendSupplier"
                    ></v-checkbox>

                    <label class="mt-5 link">
                      <a>
                        {{ $t('messages.sendSupplier') }}
                      </a>
                    </label>
                  </v-col>
                </v-row>

                <v-row
                  v-if="order.OrderStatus != 9"
                  class="d-flex flex-column text-center justify-center items-center mb-2"
                >
                  <span
                    v-if="lockButton"
                    style="
                      margin: 5px;
                      flex: 1;
                      color: red;
                      font-size: 16px;
                      font-weight: normal;
                    "
                  >
                    {{ disabledText }}
                  </span>
                  <div class="d-sm-flex justify-center items-center">
                    <v-btn
                      :disabled="lockButton"
                      @click="saveShipmentInfo()"
                      class="rounded-xl mb-2 ml-2"
                      style="
                        color: white !important;
                        background-color: #1482d8;
                        text-transform: none;
                        padding: 30px;
                        font-size: large;
                        font-weight: bold;
                        width: 250px;
                      "
                    >
                      {{
                        sendSupplier && order.OrderStatus === 0
                          ? $t('buttons.submit')
                          : (userRole == 'Customer' ||
                              userRole == 'PendingCustomer') &&
                            order.OrderStatus === 3
                          ? $t('buttons.isAlreadySended')
                          : $t('buttons.saveLower')
                      }}
                    </v-btn>
                    <v-btn
                      v-if="false"
                      :disabled="lockButton"
                      class="darken-1 rounded-xl ml-2"
                      @click="saveShipmentInfo()"
                      style="
                        color: #fff;
                        background-color: #0077d5;
                        text-transform: none;
                        padding: 30px;
                        font-size: large;
                        font-weight: bold;
                        width: 250px;
                      "
                      color="primary"
                    >
                      {{
                        order.OrderStatus == 0
                          ? $t('labels.sendToSupplier')
                          : $t('buttons.saveLower')
                      }}
                    </v-btn>
                    <v-btn
                      v-if="order.OrderStatus !== 7 && order.OrderStatus !== 9"
                      variant="tonal"
                      class="rounded-xl ml-2"
                      style="
                        color: white !important;
                        background-color: var(--red);
                        text-transform: none;
                        padding: 30px;
                        font-size: large;
                        font-weight: bold;
                        width: 250px;
                      "
                      @click="cancelOrderModal = true"
                    >
                      {{ $t('buttons.cancelOrder') }}
                    </v-btn>
                  </div>
                </v-row>
              </form>
            </ValidationObserver>
          </div>
        </v-card>
      </template>

      <!-- eslint-disable -->
      <template v-slot:loadDetail>
        <v-card
          style="
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 2px;
            border-left-width: 2px;
            border-right-width: 2px;
            border-top-width: 0px;
            border-color: lightgray;
          "
          max-width="100%"
          min-width="100%"
        >
          <div>
            <v-data-table
              style="padding: 20px"
              :headers="volumeHeaders"
              hide-default-footer
              :items="shipmentBoxes"
              :items-per-page="shipmentBoxes.length"
              :no-data-text="$t('messages.noData')"
              :item-style="() => '.table-item'"
            >
              <template v-slot:body.append="{ tableItems }">
                <tr>
                  <td style="font-weight: bold; text-align: center">
                    {{ $t('titles.total') }}
                  </td>
                </tr>
                <tr
                  style="
                    background-color: #03a6ad;
                    color: white;
                    font-weight: bold;
                    text-align: center;
                  "
                >
                  <td>
                    {{ shipment.SumCapPieces }}
                  </td>
                  <td>{{ shipment.SumWeight }} kg</td>
                  <td>{{ shipment.SumVolume }} m³</td>
                </tr>
              </template>
              <template v-slot:item.totalVolume="{ item }">
                {{
                  (item.Length * item.Width * item.Height * item.CapPieces) /
                  1000000
                }}
              </template>
            </v-data-table>
          </div>
        </v-card>
      </template>
      <template v-slot:attachedFiles>
        <v-card
          style="
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 2px;
            border-left-width: 2px;
            border-right-width: 2px;
            border-top-width: 0px;
            border-color: lightgray;
          "
          max-width="100%"
          min-width="100%"
        >
          <div>
            <!-- Modal for note inspection of a file -->
            <v-dialog v-model="showFileNoteFlag" max-width="850">
              <v-card style="padding: 10px">
                <v-card-title>{{ $t('labels.fileNote') }}</v-card-title>

                <v-card-text>
                  {{
                    selectedFileNote?.Description
                      ? selectedFileNote.Description
                      : $t('labels.noNote')
                  }}
                </v-card-text>
                <v-card-actions style="justify-content: center">
                  <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: var(--red);
                      padding: 1vh 1vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                      font-size: small;
                    "
                    elevation="0"
                    class="darken-1"
                    type="submit"
                    @click="showFileNoteFlag = false"
                    >{{ $t('buttons.close') }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Modal for adding new file -->
            <v-dialog persistent v-model="isFileUploadOpen" max-width="850">
              <v-card style="padding: 10px">
                <v-card-title>{{ $t('labels.addNewFile') }}</v-card-title>
                <ValidationObserver
                  v-slot="{ handleSubmit }"
                  ref="fileUploadObserver"
                >
                  <v-card-text>
                    <form @submit.prevent="handleSubmit(uploadFile)">
                      <v-row>
                        <FormElement
                          v-model="selectedFileType"
                          :validationLabel="$t('labels.fileType')"
                          :formLabel="$t('labels.fileType')"
                          :rules="'required'"
                          :onChange="selectedFileTypeChanged"
                          :topLabel="true"
                          :labelShown="false"
                          :formType="{
                            name: 'multipleSelect',
                            items: {
                              data: fileTypes,
                              value: 'Id',
                              text: 'Name',
                            },
                          }"
                          :placeholder="$t('labels.fileType')"
                          :ref="'FILE_TYPE'"
                        />
                        <FormElement
                          v-model="fileName"
                          :validationLabel="$t('labels.fileName')"
                          :formLabel="$t('labels.fileName')"
                          :rules="'required'"
                          :topLabel="true"
                          :labelShown="false"
                          :formType="{
                            name: 'text',
                          }"
                          :placeholder="$t('labels.fileName')"
                          :ref="'FILE_NAME'"
                        />
                        <FormElement
                          v-model="selectedDocument"
                          :validationLabel="$t('labels.file')"
                          :formLabel="$t('labels.selectFile')"
                          :rules="'required'"
                          :topLabel="true"
                          :labelShown="false"
                          :formType="{
                            name: 'file',
                            showSize: true,
                            data: {
                              accept: 'application/pdf',
                            },
                          }"
                          :placeholder="$t('labels.selectFile')"
                          :ref="'FILE'"
                        />
                        <FormElement
                          v-model="fileNote"
                          :validationLabel="$t('labels.fileNote')"
                          :formLabel="$t('labels.fileNote')"
                          :topLabel="true"
                          :labelShown="false"
                          :formType="{
                            name: 'textarea',
                          }"
                          :placeholder="$t('labels.fileNote')"
                          :ref="'FILE_NOTE'"
                        />
                      </v-row>

                      <v-card-actions style="justify-content: center">
                        <v-btn
                          style="
                            color: #fff;
                            text-transform: unset !important;
                            background-color: #0077d5;
                            padding: 1vh 1vh;
                            border-radius: 30px !important;
                            font-weight: bolder;
                            font-size: medium;
                          "
                          elevation="0"
                          class="darken-1"
                          type="submit"
                          >{{ $t('buttons.add') }}</v-btn
                        >
                        <v-btn
                          style="
                            color: #fff;
                            text-transform: unset !important;
                            background-color: var(--red);
                            padding: 1vh 1vh;
                            border-radius: 30px !important;
                            font-weight: bolder;
                            font-size: medium;
                          "
                          elevation="0"
                          class="darken-1"
                          type="submit"
                          @click="
                            isFileUploadOpen = false;
                            clearFileUploadDialog();
                          "
                          >{{ $t('buttons.cancel') }}</v-btn
                        >
                      </v-card-actions>
                    </form>
                  </v-card-text>
                </ValidationObserver>
              </v-card>
            </v-dialog>
            <v-dialog content-class="short-content" persistent v-model="isAddETGBOpen" max-width="850">
              <v-card style="padding: 10px">
                <v-card-title>{{ $t('labels.etgbForm') }}</v-card-title>
                <ValidationObserver
                  v-slot="{ handleSubmit }"
                  ref="fileUploadObserver"
                >
                  <v-card-text>
                    <form @submit.prevent="handleSubmit(createETGB)">
                      <v-row>
                        <FormElement
                          v-for="(element, index) in ETGB_formItems"
                          :key="
                            element.key.parent + '_' + element.key.child + '_' + index
                          "
                          v-model="$data[element.key.parent][element.key.child]"
                          :validationLabel="$t(element.validationLabel)"
                          :rules="element.rules"
                          :formLabel="$t(element.formLabel)"
                          :onChange="element.onChange"
                          :formType="element.formType"
                          :lg="element.lg"
                          :cols="element.cols"
                          :disabled="element.disabled"
                          :hidden="element.hidden"
                          :labelShown="element.labelShown"
                          :active="element.active"
                          :topLabel="element.topLabel"
                          :placeholder="$t(element.placeholder)"
                        />
                      </v-row>

                      <v-card-actions style="justify-content: center">
                        <v-btn
                          style="
                            color: #fff;
                            text-transform: unset !important;
                            background-color: #0077d5;
                            padding: 1vh 1vh;
                            border-radius: 30px !important;
                            font-weight: bolder;
                            font-size: medium;
                          "
                          elevation="0"
                          class="darken-1"
                          type="submit"
                          >{{ $t('buttons.add') }}</v-btn
                        >
                        <v-btn
                          style="
                            color: #fff;
                            text-transform: unset !important;
                            background-color: var(--red);
                            padding: 1vh 1vh;
                            border-radius: 30px !important;
                            font-weight: bolder;
                            font-size: medium;
                          "
                          elevation="0"
                          class="darken-1"
                          type="button"
                          @click="
                            isAddETGBOpen = false;
                            clearETGBDialog();
                          "
                          >{{ $t('buttons.cancel') }}</v-btn
                        >
                      </v-card-actions>
                    </form>
                  </v-card-text>
                </ValidationObserver>
              </v-card>
            </v-dialog>
            <!-- Ekli Dosyalar List -->
            <v-list>
              <v-list-item
                class="mb-3"
                style="
                  border-style: solid;
                  border-width: 0px;
                  border-bottom-width: 2px;
                  border-color: lightgrey;
                "
              >
                <!-- Left Side (Pdf icon, Date, Name) -->
                <v-list-item-content>
                  <v-data-table
                    :headers="fileHeaders"
                    :loading="$store.state.loadingStatus"
                    :items="orderFiles"
                    :no-data-text="$t('messages.noData')"
                    hide-default-footer
                  >
                    <!-- eslint-disable -->
                    <template v-slot:item.CreatedOnUtc="{ item }">
                      {{ convertToLocal(item.CreatedOnUtc) }}
                    </template>
                    <template v-slot:item.UploadedSubSuplier="{ item }">
                      <v-chip
                        v-if="item.UploadedSubSuplier"
                        style="font-weight: 600"
                        class="ma-2"
                        small
                        color="#c5f4db"
                        text-color="#2b3838"
                      >
                        {{ $t('labels.orderFileSubSupplierUploaded') }}
                      </v-chip>

                      <v-chip
                        v-if="!item.UploadedSubSuplier"
                        class="ma-2"
                        small
                        color="#E72929"
                        text-color="white"
                        style="font-weight: 600"
                      >
                        {{ $t('labels.orderFileSubSupplierNotUploaded') }}
                      </v-chip>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <div class="text-start">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              color="#29B7B7"
                              class="mr-1"
                              style="font-size: 20px"
                              v-bind="attrs"
                              v-on="on"
                              @click="navigateContract(item)"
                            >
                              mdi-eye
                            </v-icon>
                          </template>
                          <span>{{ $t('labels.tooltipInspectDocument') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              color="#29B7B7"
                              class="mr-1"
                              style="font-size: 20px"
                              v-bind="attrs"
                              v-on="on"
                              @click="downloadDocument(item)"
                            >
                              mdi-download
                            </v-icon>
                          </template>
                          <span>{{ $t('buttons.downloadFile') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="item.Description"
                              small
                              color="#29B7B7"
                              class="mr-1"
                              style="font-size: 20px"
                              v-bind="attrs"
                              v-on="on"
                              @click="
                                selectedFileNote = item;
                                showFileNoteFlag = true;
                              "
                            >
                              mdi-receipt-text-outline
                            </v-icon>
                          </template>
                          <span>{{
                            $t('labels.tooltipInspectDocumentNote')
                          }}</span>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-data-table>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <div class="d-flex justify-center">
                    <v-btn
                      v-if="
                        ($store.state.Type === 'Customer' ||
                          $store.state.Type === 'PendingCustomer') &&
                        order.OrderStatus > 0
                      "
                      variant="tonal"
                      color="red"
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        padding: 2vh 4vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: large;
                      "
                      @click="isAddETGBOpen = true"
                      elevation="0"
                      class="darken-1 mr-4"
                      type="submit"
                    >
                      {{ $t('labels.createETGBform') }}
                    </v-btn>
                    <v-btn
                      variant="tonal"
                      color="red"
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        padding: 2vh 4vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: large;
                      "
                      @click="isFileUploadOpen = true"
                      elevation="0"
                      class="darken-1"
                      type="submit"
                    >
                      {{ $t('labels.addNewFile') }}
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-card>
      </template>
      <template v-slot:orderOperations>
        <v-card
          style="
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 2px;
            border-left-width: 2px;
            border-right-width: 2px;
            border-top-width: 0px;
            border-color: lightgray;
          "
          max-width="100%"
          min-width="100%"
        >
          <div>
            <!-- Order Operation Checkbox List -->
            <v-list>
              <v-list-item v-for="item in orderChecklist" :key="item.Id">
                <v-checkbox
                  v-model="item.IsChecked"
                  :label="item.Title"
                  @click="orderCheckboxToggled(item)"
                ></v-checkbox>
              </v-list-item>
            </v-list>
          </div>
        </v-card>
      </template>
      <template v-slot:orderStatus>
        <v-card
          style="
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 2px;
            border-left-width: 2px;
            border-right-width: 2px;
            border-top-width: 0px;
            border-color: lightgray;
          "
          max-width="100%"
          min-width="100%"
        >
          <div style="padding-left: 1px; padding-right: 1px; padding-top: 1px">
            <v-dialog
              v-model="isEquipmentOpen"
              max-width="750"
              @input="resetEquipmentFields"
            >
              <v-card style="padding: 10px">
                <v-card-title>{{
                  editEquipmentFlag
                    ? $t('labels.editEquipment')
                    : $t('labels.addNewEquipment')
                }}</v-card-title>

                <div class="d-flex">
                  <div class="column" style="flex-grow: 1; margin-right: 5px">
                    <ValidationProvider
                      :name="$t('labels.equipmentNo')"
                      v-slot="{ errors }"
                      class="d-lg-flex"
                      style="flex-grow: 1"
                    >
                      <v-text-field
                        v-model="equipmentNo"
                        class="custom-border"
                        outlined
                        :label="$t('labels.equipmentNo')"
                        :error-messages="errors[0]"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      :name="$t('labels.sealNo')"
                      rules="numeric"
                      v-slot="{ errors }"
                      class="d-lg-flex"
                      style="flex-grow: 1"
                    >
                      <v-text-field
                        v-model="sealNo"
                        class="custom-border"
                        outlined
                        :label="$t('labels.sealNo')"
                        :error-messages="errors[0]"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      :name="$t('labels.goodsType')"
                      v-slot="{ errors }"
                      class="d-lg-flex"
                      style="flex-grow: 1"
                    >
                      <v-text-field
                        v-model="goodsType"
                        class="custom-border"
                        outlined
                        :label="$t('labels.goodsType')"
                        :error-messages="errors[0]"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                  <div class="column" style="flex-grow: 1; margin-left: 5px">
                    <ValidationProvider
                      :name="$t('labels.containerCount')"
                      rules="numeric"
                      v-slot="{ errors }"
                      class="d-lg-flex"
                      style="flex-grow: 1"
                    >
                      <v-text-field
                        v-model="containerCount"
                        class="custom-border"
                        outlined
                        :label="$t('labels.containerCount')"
                        :error-messages="errors[0]"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      :name="$t('labels.loadedKg')"
                      rules="numeric"
                      v-slot="{ errors }"
                      class="d-lg-flex"
                      style="flex-grow: 1"
                    >
                      <v-text-field
                        v-model="loadedKg"
                        class="custom-border"
                        outlined
                        :label="$t('labels.loadedKg')"
                        :error-messages="errors[0]"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      :name="$t('labels.loadedVolume')"
                      rules="numeric"
                      v-slot="{ errors }"
                      class="d-lg-flex"
                      style="flex-grow: 1"
                    >
                      <v-text-field
                        v-model="loadedVolume"
                        class="custom-border"
                        outlined
                        :label="$t('labels.loadedVolume')"
                        :error-messages="errors[0]"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                </div>

                <v-card-actions style="justify-content: center">
                  <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: #0077d5;
                      padding: 1vh 1vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                      font-size: medium;
                    "
                    elevation="0"
                    class="darken-1"
                    type="submit"
                    @click="handleEquipmentOperation"
                    >{{
                      editEquipmentFlag ? $t('buttons.save') : $t('buttons.add')
                    }}</v-btn
                  >
                  <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: var(--red);
                      padding: 1vh 1vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                      font-size: medium;
                    "
                    elevation="0"
                    class="darken-1"
                    type="submit"
                    @click="closeNewEquipmentModal"
                    >{{ $t('buttons.cancel') }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <ValidationObserver ref="locationObserver">
              <v-dialog
                v-model="isLocationOpen"
                max-width="1500"
                style="width: 1500px"
              >
                <v-card style="padding: 10px">
                  <v-card-title>{{ $t('labels.addLocation') }}</v-card-title>
                  <div style="display: flex; flex-wrap: wrap">
                    <div style="flex: 1; padding-right: 10px">
                      <ValidationProvider
                        :name="$t('labels.orderDate')"
                        rules="required"
                        v-slot="{ errors }"
                        class="d-lg-flex"
                        style="flex-grow: 1"
                      >
                        <v-dialog
                          ref="locationDateDialog"
                          v-model="locationDateModalDialog"
                          :return-value.sync="locationDate"
                          persistent
                          width="290px"
                          :error-messages="errors[0]"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="convertToLocal(locationDate)"
                              @click:clear="locationDate = ''"
                              v-bind="attrs"
                              v-on="on"
                              clearable
                              :label="$t('labels.orderDate')"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="locationDate"
                            :locale="$i18n.locale"
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              style="
                                color: #fff;
                                text-transform: unset !important;
                                background-color: var(--red);
                                padding: 1vh 1vh;
                                border-radius: 30px !important;
                                font-weight: bolder;
                                font-size: small;
                              "
                              elevation="0"
                              class="darken-1"
                              type="submit"
                              @click="locationDateModalDialog = false"
                            >
                              {{ $t('buttons.close') }}
                            </v-btn>
                            <v-btn
                              style="
                                color: #fff;
                                text-transform: unset !important;
                                background-color: #0077d5;
                                padding: 1vh 1vh;
                                border-radius: 30px !important;
                                font-weight: bolder;
                                font-size: medium;
                              "
                              elevation="0"
                              class="darken-1"
                              type="submit"
                              @click="
                                $refs.locationDateDialog.save(locationDate)
                              "
                            >
                              {{ $t('buttons.select') }}
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </ValidationProvider>

                      <v-dialog
                        ref="locationTimeDialog"
                        v-model="locationTimeModalDialog"
                        :return-value.sync="locationTime"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider
                            :name="$t('labels.orderTime')"
                            rules="required"
                            v-slot="{ errors }"
                            class="d-lg-flex"
                            style="flex-grow: 1"
                          >
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              clearable
                              :label="$t('labels.orderTime')"
                              :error-messages="errors[0]"
                              v-model="locationTime"
                            ></v-text-field>
                          </ValidationProvider>
                        </template>
                        <v-time-picker
                          v-model="locationTime"
                          :locale="$i18n.locale"
                          scrollable
                          format="24hr"
                        >
                          <v-spacer></v-spacer>

                          <v-btn
                            style="
                              color: #fff;
                              text-transform: unset !important;
                              background-color: var(--red);
                              padding: 1vh 1vh;
                              border-radius: 30px !important;
                              font-weight: bolder;
                              font-size: small;
                            "
                            elevation="0"
                            class="darken-1"
                            type="submit"
                            @click="locationTimeModalDialog = false"
                          >
                            {{ $t('buttons.close') }}
                          </v-btn>
                          <v-btn
                            style="
                              color: #fff;
                              text-transform: unset !important;
                              background-color: #0077d5;
                              padding: 1vh 1vh;
                              border-radius: 30px !important;
                              font-weight: bolder;
                              font-size: medium;
                            "
                            elevation="0"
                            class="darken-1"
                            type="submit"
                            @click="$refs.locationTimeDialog.save(locationTime)"
                          >
                            {{ $t('buttons.select') }}
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>

                      <ValidationProvider
                        rules="required"
                        :name="$t('labels.location')"
                        v-slot="{ errors }"
                        class="d-lg-flex"
                        style="flex-grow: 1"
                      >
                        <v-text-field
                          v-model="location"
                          class="custom-border mt-4"
                          outlined
                          :label="$t('labels.location')"
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>
                      <ValidationProvider
                        rules="required"
                        :name="$t('labels.latitude')"
                        v-slot="{ errors }"
                        class="d-lg-flex"
                        style="flex-grow: 1"
                      >
                        <v-text-field
                          disabled
                          v-model="latitude"
                          class="custom-border"
                          outlined
                          :label="$t('labels.latitude')"
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        rules="required"
                        :name="$t('labels.longitude')"
                        v-slot="{ errors }"
                        class="d-lg-flex"
                        style="flex-grow: 1"
                      >
                        <v-text-field
                          disabled
                          v-model="longitude"
                          class="custom-border"
                          outlined
                          :label="$t('labels.longitude')"
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>
                    </div>
                    <div style="flex: 1">
                      <div style="width: 100%; height: 400px">
                        <google-map
                          :center="{ lat: 39.925533, lng: 32.866287 }"
                          :zoom="2.5"
                          @click="handleMapClick"
                          style="width: 100%; height: 100%"
                          ref="map"
                        >
                        </google-map>
                      </div>
                    </div>
                  </div>

                  <v-card-actions
                    style="
                      justify-content: center;
                      display: flex;
                      flex-direction: column;
                    "
                  >
                    <p
                      class="text-center my-2"
                      style="
                        color: var(--darkRed);
                        font-style: italic;
                        font-weight: 600;
                      "
                    >
                      {{ $t('labels.selectOnMap') }}
                    </p>
                    <div>
                      <v-btn
                        style="
                          color: #fff;
                          text-transform: unset !important;
                          background-color: #0077d5;
                          padding: 1vh 1vh;
                          border-radius: 30px !important;
                          font-weight: bolder;
                          font-size: medium;
                        "
                        elevation="0"
                        class="darken-1"
                        type="submit"
                        @click="addLocation(selectedEquipment)"
                        >{{ $t('labels.addLocation') }}</v-btn
                      >
                      <v-btn
                        style="
                          color: #fff;
                          text-transform: unset !important;
                          background-color: var(--red);
                          padding: 1vh 1vh;
                          border-radius: 30px !important;
                          font-weight: bolder;
                          font-size: medium;
                        "
                        elevation="0"
                        class="darken-1 mr-4"
                        type="submit"
                        @click="closeLocationModal"
                        >{{ $t('buttons.cancel') }}</v-btn
                      >
                    </div>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </ValidationObserver>

            <v-dialog
              @close="resetAddStatus"
              @input="resetAddStatus"
              v-model="isAddLogOpen"
              max-width="500"
            >
              <v-card style="padding: 10px">
                <v-card-title>
                  {{ $t('labels.addStatus') }}
                </v-card-title>

                <ValidationProvider
                  :name="$t('labels.description')"
                  v-slot="{ errors }"
                  class="d-lg-flex"
                  style="flex-grow: 1"
                >
                  <v-text-field
                    v-model="orderLogDescription"
                    class="custom-border"
                    outlined
                    :label="$t('labels.description')"
                    :error-messages="errors[0]"
                  ></v-text-field>
                </ValidationProvider>

                <v-card-actions style="justify-content: center">
                  <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: #0077d5;
                      padding: 1vh 1vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                      font-size: medium;
                    "
                    elevation="0"
                    class="darken-1"
                    type="submit"
                    @click="addOrderLog"
                  >
                    {{ $t('labels.addStatus') }}</v-btn
                  >
                  <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: var(--red);
                      padding: 1vh 1vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                      font-size: medium;
                    "
                    elevation="0"
                    class="darken-1"
                    type="submit"
                    @click="resetAddStatus"
                    >{{ $t('buttons.cancel') }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <div style="position: relative">
              <google-map
                :center="{ lat: 39.925533, lng: 32.866287 }"
                :zoom="2.5"
                style="width: 100%; height: 500px; margin-bottom: 10px"
                ref="map"
              >
                <google-map-cluster :gridSize="60" @click="clusterClickHandler">
                  <google-map-marker
                    v-for="(item, index) in mapMarkers"
                    :key="index"
                    :position="{
                      lat: item.position
                        ? item.position.lat
                        : parseFloat(item.LastLatitude.replace(',', '.')),
                      lng: item.position
                        ? item.position.lng
                        : parseFloat(item.LastLongitude.replace(',', '.')),
                    }"
                    :clickable="true"
                    :draggable="false"
                    :icon="getMarkerIcon(item)"
                    @click="openInfoWindow(item)"
                  />
                </google-map-cluster>
              </google-map>
              <div
                v-if="markerModalShow"
                style="
                  position: absolute;
                  left: 10px;
                  bottom: 10px;
                  background-color: white;
                  width: 90%;
                  border-radius: 8px;
                  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                  z-index: 1000;
                "
              >
                <v-icon
                  small
                  style="
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    cursor: pointer;
                    font-size: 24px;
                    color: #999;
                  "
                  @click="markerModalShow = false"
                >
                  mdi-close
                </v-icon>
                <v-row class="d-flex align-center">
                  <v-col cols="2" class="d-flex justify-center align-center">
                    <v-img
                      :src="getMarkerIcon(selectedEquipment).url"
                      style="
                        max-width: 125px;
                        max-height: 125px;
                        object-fit: contain;
                      "
                      class="ml-5"
                    ></v-img>
                  </v-col>
                  <v-col cols="3">
                    <p style="font-size: 13px">
                      {{ $t('labels.dateAsNoun') }}:
                      {{ getDateTime(selectedEquipment.LastLocationDate) }}
                    </p>
                    <p style="font-size: 13px">
                      {{ $t('labels.equipmentNo') }}:
                      {{ selectedEquipment.EquipmentNo }}
                    </p>
                    <p style="font-size: 13px">
                      {{ $t('labels.goodsType') }}:
                      {{ selectedEquipment.GoodsType }}
                    </p>
                  </v-col>
                  <v-col cols="3">
                    <p style="font-size: 13px">
                      {{ $t('labels.lastLocation') }}:
                      {{ selectedEquipment.LastLocation }}
                    </p>
                    <p style="font-size: 13px">
                      {{ $t('labels.containerCount') }}:
                      {{ selectedEquipment.ContainerCount }}
                    </p>
                    <p style="font-size: 13px">
                      {{ $t('labels.equipmentType') }}:
                      {{ selectedEquipment.EquipmentType }}
                    </p>
                  </v-col>
                  <v-col cols="3">
                    <p style="font-size: 13px">
                      {{ $t('labels.loadedKg') }}:
                      {{ selectedEquipment.LoadedKg }}
                    </p>
                    <p style="font-size: 13px">
                      {{ $t('labels.loadedVolume') }}:
                      {{ selectedEquipment.LoadedVolume }}
                    </p>
                    <p style="font-size: 13px">
                      {{ $t('labels.sealNo') }}:
                      {{ selectedEquipment.SealNo }}
                    </p>
                  </v-col>
                </v-row>
                <div></div>
              </div>
            </div>

            <!-- Taşıyıcı Bilgileri Header
            
               &&
                    (shipment.LoadingType == 2 ||
                      shipment.LoadingType == 3 ||
                      shipment.LoadingType == 5)
            -->
            <template v-if="showCarrierInfo">
              <v-row class="my-2 px-4">
                <v-col cols="8" sm="9">
                  <h3 style="color: #55a4e2">
                    {{ $t('labels.carrierInfo') }}
                  </h3>
                </v-col>

                <v-col v-if="isSupplier" cols="4" sm="3" class="text-right">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        style="font-size: 24px"
                        @click="addLocationExcel"
                      >
                        mdi-file-excel-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('labels.addActionFromExcel') }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        style="font-size: 24px"
                        @click="downloadSample"
                      >
                        mdi-file-check-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('buttons.dowloadTempFile') }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="shipment.TransportType === 2"
                        small
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        style="font-size: 24px"
                        @click="
                          isEquipmentOpen = true;
                          editEquipmentFlag = false;
                        "
                      >
                        mdi-plus-circle-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('labels.addNewEquipment') }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <v-data-table
                :headers="getEquipmentHeaders()"
                :items="carrierInfo"
                :options="{
                  itemsPerPage: itemsPerPage,
                }"
                item-key="Id"
                hide-default-footer
                :no-data-text="$t('messages.noData')"
              >
                <template v-slot:item.actions="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        color="#29B7B7"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        style="font-size: 20px"
                        @click="showEquipmentDetail(item)"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                    <span>{{ $t('labels.equipmentDetails') }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="isSupplier"
                        small
                        color="#29B7B7"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        style="font-size: 20px"
                        @click="editEquipment(item)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>{{ $t('labels.editEquipment') }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:footer>
                  <div class="d-flex justify-center mt-8 pb-lg-0 pb-8">
                    <v-row justify="center">
                      <v-col md="3" class="ml-md-auto">
                        <v-pagination
                          class="mr-2"
                          @input="onPageChange"
                          :value="page"
                          :length="pageLength"
                          :total-visible="15"
                          circle
                        ></v-pagination>
                      </v-col>
                      <v-col md="2" class="ml-md-auto">
                        <v-select
                          v-model="itemsPerPage"
                          :items="perPageValues"
                          :label="$t('labels.selectItemsPerPage')"
                          item-value="value"
                          item-text="name"
                          outlined
                          dense
                          @change="changeItemsPerPage"
                          rounded
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </div>
                </template>
              </v-data-table>

              <v-row class="my-2 px-4">
                <v-col cols="11">
                  <h3 style="color: #55a4e2">
                    {{ $t('labels.orderStatusLogs') }}
                  </h3>
                </v-col>
              </v-row>
              <template>
                <v-data-table
                  :headers="getOrderStatusLogsHeaders()"
                  hide-default-footer
                  :sort-desc="true"
                  sort-by="CreatedOnUtc"
                  :items="orderStatusData"
                  :no-data-text="$t('messages.noData')"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ convertToLocal(item.CreatedOnUtc) }}</td>
                      <td>{{ convertToLocalTime(item.CreatedOnUtc + 'Z') }}</td>
                      <td>{{ item.OrderStatusLog }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </template>

              <v-row class="my-2 px-4">
                <v-col cols="11">
                  <h3 style="color: #55a4e2">
                    {{ $t('labels.orderActionStatusLog') }}
                  </h3>
                </v-col>

                <v-col
                  v-if="isSupplier && shipment.ContainerType"
                  cols="1"
                  class="text-right"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        style="font-size: 24px"
                        @click="isAddLogOpen = true"
                      >
                        mdi-plus-box-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('labels.addStatus') }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <template>
                <v-data-table
                  :headers="getOrderLogsHeaders()"
                  hide-default-footer
                  :items="orderLogs"
                  :no-data-text="$t('messages.noData')"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.date }}</td>
                      <td>{{ item.hour }}</td>
                      <td>
                        {{
                          item.location && item.location.length > 0
                            ? item.location
                            : '-'
                        }}
                      </td>
                      <td>
                        {{
                          item.code && item.code.length > 0 ? item.code : '-'
                        }}
                      </td>
                      <td>{{ item.status }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </template>
            </template>
            <template v-if="!showCarrierInfo">
              <div>
                <v-row class="my-2 align-center pr-5">
                  <v-col cols="1">
                    <v-btn @click="closeHistory()" icon>
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="10">
                    <h3 style="color: #55a4e2">
                      {{ $t('labels.locationHistory') }}
                    </h3>
                  </v-col>

                  <v-col cols="1" v-if="isSupplier">
                    <div class="d-flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            v-bind="attrs"
                            v-on="on"
                            style="font-size: 30px; margin-right: 5px"
                            @click="isLocationOpen = true"
                          >
                            mdi-plus-box-outline
                          </v-icon>
                        </template>
                        <span>{{ $t('labels.addAction') }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>

                <v-data-table
                  :headers="getLocationHistoryHeaders()"
                  hide-default-footer
                  :loading="$store.state.loadingStatus"
                  :items="selectedEquipment.EquipmentLocations"
                  :no-data-text="$t('messages.noData')"
                >
                  <template v-slot:item.LocationDate="{ item }">
                    {{ convertToLocal(item.LocationDate) }}
                  </template>
                </v-data-table>
              </div>
            </template>
          </div>
        </v-card>
      </template>
      <template v-slot:orderMessages>
        <v-card
          style="
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 2px;
            border-left-width: 2px;
            border-right-width: 2px;
            border-top-width: 0px;
            border-color: lightgray;
          "
          max-width="100%"
          min-width="100%"
        >
          <div>
            <MessageDetail
              :messages="messages"
              :id="chatId"
              :disableProp="false"
              :isHeaderHidden="true"
            />
          </div>
        </v-card>
      </template>
    </TabScreen>
  </v-card>
</template>

<script>
import OrderService from '../services/OrderService';
import ShipmentService from '../services/ShipmentService';
import TabScreen from '../components/TabScreen.vue';
import CompanyServices from '../services/CompanyServices';
import EquipmentService from '../services/EquipmentService';
import FileService from '../services/FileService';
import EquipmentTypeService from '../services/EquipmentTypeService';
import ContractService from '../services/ContractService';
import FormElement from '../components/FormElement.vue';
import RefreshButton from '../components/RefreshButton.vue';
import AddVolume from '../views/OfferScreens/AddVolume.vue';
import MessageDetail from './MessageDetail.vue';
import MessageService from '../services/MessageServices';
import AddressService from '../services/AddressService';
import {
  convertBoxesToVolumes,
  getCurrencyTypes,
  convertVolumeToBoxes,
  getCountries,
  orderTypes,
} from '../utils/constants';

import BlockUser from '../components/BlockUser.vue';

import IntegrationServices from '../services/IntegrationService';
import FedExServices from '../services/FedexService';
import ShipmentBoxServices from '../services/ShipmentBoxService';

import ConfirmationModal from '../components/General/ConfirmationModal.vue';
import PortalService from '../services/PortalService';

const USA_ID = 'ccf580f9-25e0-4aac-9216-6741eeece89e';
const CANADA_ID = '8e1060f5-9433-4b64-9e8f-3da864f7cac6';

const stateRequiredCountries = [USA_ID, CANADA_ID];

import rules, { authorizedUser, company } from '../utils/validationRules';

export default {
  data: () => ({
    form: {},
    isIconVisible: false,
    addVolumeModal: false,
    showTabScreen: true,
    orderId: null,
    shipmentId: null,
    shipment: {},
    order: {},
    userRole: null,
    orderDate: null,
    loadingFile: null,
    newLoadingFile: null,
    isPending: false,
    intervalId: null,
    isHome: false,
    orderDateModalDialog: false,
    estimateDateModalDialog: false,
    desiredDateModalDialog: false,
    loadingDateModalDialog: false,
    leaveDateModalDialog: false,
    arrivalDateModalDialog: false,
    deliveryDateModalDialog: false,
    locationDateModalDialog: false,
    locationTimeModalDialog: false,
    orderLogs: [],

    subCompanies: [],
    loadingCompany: null,
    destinationCompany: null,
    messages: [],
    currencies: [],
    selectedReason: null,
    cancelDescription: null,
    cancelReasons: [],

    carriageContract: {},
    shipmentBoxes: [],
    carrierInfo: [],
    selectedMarker: '',
    markerModalShow: false,
    infoWindowPosition: { lat: 0, lng: 0 },
    addSubCompany: false,
    isSubCompanyLoading: true,
    totalCap: 0,
    totalWeight: 0,
    totalVolume: 0,
    CustomerCompanyId: null,
    orderFiles: [],
    equipmentTypes: [],
    isEquipmentOpen: false,
    isLocationOpen: false,
    isAddLogOpen: false,
    selectedEquipmentType: null,
    customerNote: null,
    phonePrefix: null,
    isUpdateOrder: false,
    expandedItems: [],
    isEquipmentCreated: false,
    selectedEquipment: {},
    equipmentNo: null,
    sealNo: null,
    goodsType: null,
    containerCount: null,
    loadedKg: null,
    loadedVolume: null,
    latitude: null,
    contractContent: null,
    longitude: null,
    location: null,
    locationDate: new Date().toISOString().substr(0, 10),
    locationTime: null,
    paymentDate: null,

    orderLogDescription: null,

    lockButton: false,
    disabledText: '',

    selectedFile: null,
    loadingPortParent: {
      loadingPort: null,
    },
    unloadingPortParent: {
      unloadingPort: null,
    },
    contractModal: false,
    cancelOrderModal: false,
    locationImagePath: null,
    showCarrierInfo: true,

    slots: [],

    paymentTypes: [],
    orderDeliveryTypes: [],
    buyerTypes: [],

    packageTypes: [],
    taxPayers: [],
    expressServiceTypes: [],
    minDate: null,
    mapMarkers: [],
    isAddETGBOpen: false,
    detailLocations: [],
    fileHeaders: [],
    fileTypes: [],
    selectedFileType: null,

    itemsPerPage: 10,
    page: 1,
    totalItems: 0,

    perPageValues: [
      {
        name: '5',
        value: 5,
      },
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],

    shipmentNames: {},
    loadTypes: [
      {
        name: 'labels.partial',
        value: 1,
      },
      {
        name: 'labels.complete',
        value: 2,
      },
      {
        name: 'labels.FCL',
        value: 3,
      },
      {
        name: 'labels.LCL',
        value: 4,
      },
      {
        name: 'labels.FTL',
        value: 5,
      },
      {
        name: 'labels.LTL',
        value: 6,
      },
    ],

    shipmentTypes: [
      {
        name: 'labels.airway',
        value: 1,
      },
      {
        name: 'labels.roadway',
        value: 2,
      },
      {
        name: 'labels.seaway',
        value: 3,
      },
      {
        name: 'labels.express',
        value: 4,
      },
      {
        name: 'labels.railway',
        value: 5,
      },
    ],

    serviceTypes: [
      {
        name: 'labels.doorToDoor',
        value: 1,
      },
      {
        name: 'labels.portToPort',
        value: 2,
      },
      {
        name: 'labels.airportToAirport',
        value: 3,
      },
      {
        name: 'labels.addressToAddress',
        value: 4,
      },
    ],

    shipmentStatus: [
      { name: 'labels.wait', value: 1 },
      { name: 'labels.onTheWay', value: 2 },
      { name: 'labels.delivered', value: 3 },
    ],

    isFileUploadOpen: false,
    showFileNoteFlag: false,
    isSupplier: true,
    selectedDocument: null,
    selectedFileNote: null,
    fileNote: '',
    fileName: '',

    orderStatuses: [],
    fileStatuses: [],
    orderStatus: {
      name: '',
    },
    fileStatus: {
      name: null,
    },
    volumes: [],
    chatId: null,
    countries: [],
    activeTab: 0,
    cities: [],
    estimateDateLimit: null,

    sendSupplier: false,
    editEquipmentFlag: false,

    // Subsupplier Data
    logisticsCompanies: [],
    selectedLogisticsCompany: {},
    subsupplierForm: {
      submitFunction: () => {},
    },
    fedExServiceTypes: [],
    fedExPickupTypes: [],
    subsupplierShipmentData: null,

    integrationPackages: [],
    // Subsupplier Data END

    transportType: 1,
    draftDialog: false,
    orderFileWarningDialog: false,
    navToFiles: false,

    confirmation: {
      message: '',
      onConfirm: () => {},
      visible: false,
      buttonSettings: null,
    },
    calculateParameter: 166.67,
    orderChecklist: [],

    temporaryShipment: {},
    purposeOfShippingItems: [],

    mandatoryFileTypes: [],
    requestDraft: false,
    editAddressModeModal: false,
    orderStatusData: [],
    priceDetailsModal: false,
    priceTypes: [],

    invalidFields: [],
    invalidFieldsModal: false,

    formETGB: {
      ETGBInvoiceNo: null,
      ETGBInvoiceDate: null,
      ETGBDocuments: null,
      ETGBBankInfo: null,
    }
  }),
  async mounted() {
    this.purposeOfShippingItems = this.$root.purposeOfShippingList;
    this.isSupplier = this.$store.state.Type === 'Supplier';

    this.fedExPickupTypes = [
      {
        key: 'CONTACT_FEDEX_TO_SCHEDULE',
        value: this.$t('labels.pickupTypes.contactWithFedEx'),
      },
      {
        key: 'DROPOFF_AT_FEDEX_LOCATION',
        value: this.$t('labels.pickupTypes.leavingPackageToFedEx'),
      },
      {
        key: 'USE_SCHEDULED_PICKUP',
        value: this.$t('labels.pickupTypes.scheduledPickup'),
      },
    ];

    this.slots = [
      { name: 'orderInformation', title: this.$t('buttons.orderDetail') },
      { name: 'loadDetail', title: this.$t('titles.loadDetail') },
      { name: 'attachedFiles', title: this.$t('titles.attachments') },
      { name: 'orderOperations', title: this.$t('titles.orderOperations') },
      { name: 'orderStatus', title: this.$t('labels.orderStatus') },
      { name: 'orderMessages', title: this.$t('titles.orderMessages') },
    ];
    this.priceTypes = [
      this.$t('labels.basePrice'),
      this.$t('labels.outOfArea'),
      this.$t('labels.circumference'),
      this.$t('labels.ddp'),
      this.$t('labels.handling'),
      this.$t('labels.personalDelivery'),
      this.$t('labels.oversize'),
      this.$t('labels.insurance'),
      this.$t('labels.signedDelivery'),
    ];
    if (this.currencies.length === 0) {
      this.currencies = await getCurrencyTypes();
    }

    this.cancelReasons = [
      {
        name: this.$t('labels.Other'),
        value: 0,
      },
      {
        name: this.$t('labels.ConfirmationNotRecived'),
        value: 1,
      },
      {
        name: this.$t('labels.DocumentsMissing'),
        value: 2,
      },
      {
        name: this.$t('labels.InformationChanged'),
        value: 3,
      },
      {
        name: this.$t('labels.PaymentNotReceived'),
        value: 4,
      },
      {
        name: this.$t('labels.DeliveryProblems'),
        value: 5,
      },
    ];

    this.orderId = this.$route.query.orderId;

    this.setup();
    this.getOrderDetails(this.orderId);
    this.getOrderChecklist(this.orderId);
    this.getOrderMessages();
    this.getCountries();

    this.fileHeaders = [
      {
        text: this.$t('labels.fileName'),
        value: 'FileName',
        sortable: false,
        groupable: false,
        align: 'center',
      },

      {
        text: this.$t('labels.fileUploadDate'),
        value: 'CreatedOnUtc',
        sortable: false,
        groupable: false,
        align: 'center',
      },

      {
        text: this.$t('title.tableHeaderActions'),
        value: 'actions',
        sortable: false,
        groupable: false,
      },
    ];
    if (this.isSupplier) {
      let element = {
        text: this.$t('labels.fileSubSupplierUploadStatus'),
        value: 'UploadedSubSuplier',
        sortable: false,
        groupable: false,
        align: 'center',
      };
      this.fileHeaders.splice(2, 0, element);
    }

    this.getLogisticsCompanies();

    if (this.isSupplier) {
      this.checkIntegrationShipment();
    }
  },
  components: {
    TabScreen,
    FormElement,
    RefreshButton,
    AddVolume,
    MessageDetail,
    BlockUser,
    ConfirmationModal,
  },
  watch: {
    selectedLogisticsCompany(newValue) {
      this.subsupplierForm = {};

      if (newValue === 'FedEx') {
        this.subsupplierForm = {
          OrderId: this.order.Id,
          ShipmentDate: this.shipment.DepartureDate,
          ServiceType: '',
          PickupType: '',
          CountryOfManufacture: '',
          submitFunction: FedExServices.createShipment,
        };
      } else {
        this.subsupplierForm = {
          OrderId: this.order.Id,
          submitFunction: IntegrationServices.createShipmentForUPS,
        };
      }
    },
    'shipment.EstimatedLoadingDate'(newVal, oldVal) {
      if (
        new Date(newVal) > this.estimateDateLimit &&
        (this.userRole != 'Customer' || this.userRole != 'PendingCustomer') &&
        this.order.OrderStatus == 3 &&
        oldVal
      ) {
        window.showWarning(this.$t('labels.estimateGreaterAuto'));
      }
      this.shipment.EstimatedLoadingDate = newVal;
    },
    newLoadingFile(newVal) {
      if (newVal) {
        this.uploadLoadingFile();
      }
    },
    isPending(newVal) {
      if (newVal && !this.order.SignStatus) {
        this.intervalId = setInterval(() => {
          this.checkSign();
        }, 10000);
      } else {
        clearInterval(this.intervalId);
      }
    },
    addVolumeModal(newValue) {
      if (newValue) {
        this.sendDraft(true);
      }
    },
  },
  computed: {
    isStateCodeRequired() {
      if (!this.form.countryCode) return false;

      return (
        stateRequiredCountries.findIndex(
          (element) => element === this.form.countryCode
        ) > -1
      );
    },
    orderType() {
      return orderTypes[this.order.OrderType];
    },
    proformaOptions() {
      return [
        {
          Value: 0,
          Name: this.$t('labels.customerProforma'),
        },
        {
          Value: 1,
          Name: this.$t('labels.supplierProforma'),
        },
        {
          Value: 2,
          Name: this.$t('labels.withoutProforma'),
        },
      ];
    },
    insuranceOptions() {
      return [
        {
          Value: 0,
          Name: this.$t('labels.customerInsurance'),
        },
        {
          Value: 1,
          Name: this.$t('labels.supplierInsurance'),
        },
        {
          Value: 2,
          Name: this.$t('labels.serviceProviderInsurance'),
        },
      ];
    },

    ETGB_formItems() {
      return [
        {
          key: {
            parent: 'formETGB',
            child: 'ETGBInvoiceNo',
          },
          validationLabel: 'labels.invoiceNo',
          topLabel: true,
          labelShown: false,
          rules: 'required',
          formType: {
            name: 'text',
            
          },
          lg: 3,
          placeholder: 'labels.invoiceNo',
        },
        {
          key: {
            parent: 'formETGB',
            child: 'ETGBInvoiceDate',
          },
          validationLabel: 'labels.invoiceDate',
          topLabel: true,
          labelShown: false,
          rules: 'required',
          formType: {
            name: 'date',
            dateMode: 'date',
          },
          lg: 3,
          placeholder: 'labels.invoiceDate',
        },
        {
          key: {
            parent: 'formETGB',
            child: 'ETGBDocuments',
          },
          validationLabel: 'labels.etgbDocuments',
          topLabel: true,
          labelShown: false,
          rules: 'required',
          formType: {
            name: 'text'
          },
          lg: 3,
          placeholder: 'labels.etgbDocuments',
        },
        {
          key: {
            parent: 'formETGB',
            child: 'ETGBBankInfo',
          },
          validationLabel: 'labels.etgbBankInfo',
          topLabel: true,
          labelShown: false,
          rules: 'required',
          formType: {
            name: 'text'
          },
          lg: 3,
          placeholder: 'labels.etgbBankInfo',
        },
      ];
    },

    // Subsupplier Packaging Form
    subsupplier_form() {
      return [
        {
          key: {
            parent: 'shipment',
            child: 'SubSupplierPackagingType',
          },
          active: !!this.shipment.SubSupplier,
          validationLabel: 'titles.integrationPackaging',
          topLabel: true,
          labelShown: false,
          rules: 'required',
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.integrationPackages,
              value: 'key',
              text: 'value',
            },
          },
          lg: 12,
          placeholder: 'titles.integrationPackaging',
        },
      ];
    },

    // Subsupplier Form Items
    subsupplier_form_items() {
      if (this.selectedLogisticsCompany === 'UPS') return [];

      if (this.selectedLogisticsCompany === 'FedEx')
        return [
          {
            key: {
              parent: 'subsupplierForm',
              child: 'PickupType',
            },
            validationLabel: 'labels.pickupType',
            topLabel: true,
            labelShown: false,
            rules: 'required',
            formType: {
              name: 'multipleSelect',
              items: {
                data: this.fedExPickupTypes,
                value: 'key',
                text: 'value',
              },
            },
            lg: 6,
            placeholder: 'labels.pickupType',
          },
        ];
    },

    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    convertedVolumes() {
      if (this.order?.Shipment?.ShipmentBoxes?.length > 0)
        return convertBoxesToVolumes(
          this.order.Shipment.ShipmentBoxes,
          this.order.Shipment.ShipmentType
        );
      return [];
    },

    orderDetailTop() {
      return [
        {
          key: {
            parent: 'order',
            child: 'orderDate',
          },
          disabled: true,
          validationLabel: 'labels.orderDate',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
          placeholder: 'labels.orderDate',
        },
        {
          key: {
            parent: 'shipmentNames',
            child: 'shipmentTypeName',
          },
          disabled: true,
          validationLabel: 'labels.shipmentType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
          placeholder: 'labels.shipmentType',
        },
        {
          key: {
            parent: 'shipmentNames',
            child: 'loadType',
          },
          disabled: true,
          validationLabel: 'labels.loadType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
          placeholder: 'labels.loadType',
        },
        {
          key: {
            parent: 'shipmentNames',
            child: 'serviceType',
          },
          disabled: true,
          validationLabel: 'labels.serviceType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
          placeholder: 'labels.serviceType',
        },
        {
          key: {
            parent: 'shipment',
            child: 'Price',
          },
          disabled: true,
          validationLabel: 'labels.price',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            appendIcon: 'mdi-eye',
            appendClick: () => {
              this.priceDetailsModal = !this.priceDetailsModal;
            },
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
          placeholder: 'labels.price',
        },
        {
          key: {
            parent: 'shipment',
            child: 'CarrierCompany',
          },
          disabled: true,
          validationLabel: 'labels.supplier',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 2,
          xl: 2,
          placeholder: 'labels.supplier',
        },
        {
          key: {
            parent: 'shipment',
            child: 'LoadingCountry',
          },
          disabled: true,
          validationLabel: 'labels.sourceCountry',
          topLabel: true,
          active: this.shipment.LoadingCountry ? true : false,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 3,
          xl: 3,
          placeholder: 'labels.sourceCountry',
        },
        {
          key: {
            parent: 'shipment',
            child: 'LoadingCity',
          },
          disabled: true,
          validationLabel: 'labels.sourceCity',
          active: this.shipment.LoadingCity ? true : false,
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 3,
          xl: 3,
          placeholder: 'labels.sourceCity',
        },
        {
          key: {
            parent: 'shipment',
            child: 'LoadingPostalCode',
          },
          disabled: true,
          active: this.shipment.LoadingPostalCode ? true : false,
          validationLabel: 'labels.sourcePostalCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 2,
          lg: 3,
          xl: 3,
          placeholder: 'labels.sourcePostalCode',
        },
        {
          key: {
            parent: 'loadingPortParent',
            child: 'loadingPort',
          },
          disabled: true,
          validationLabel: 'labels.loadingPort',
          topLabel: true,
          labelShown: false,
          active: this.loadingPortParent.loadingPort ? true : false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 2,
          lg: 3,
          xl: 3,
          placeholder:
            this.shipment.TransportType == 5
              ? 'labels.loadingStation'
              : 'labels.loadingPort',
        },
        {
          key: {
            parent: 'shipment',
            child: 'ReferenceCode',
          },
          disabled: true,
          validationLabel: 'labels.algönderRefNo',
          topLabel: true,
          labelShown: false,
          active: this.loadingPortParent.loadingPort ? false : true,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: !this.shipment.LoadingCountry ? 4 : 2,
          lg: 3,
          xl: 3,
          placeholder: 'labels.algönderRefNo',
        },
        {
          key: {
            parent: 'shipment',
            child: 'DestinationCountry',
          },
          disabled: true,
          active: this.shipment.DestinationCountry ? true : false,
          validationLabel: 'labels.destinationCountry',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 3,
          xl: 3,
          placeholder: 'labels.destinationCountry',
        },
        {
          key: {
            parent: 'shipment',
            child: 'DestinationCity',
          },
          disabled: true,
          active: this.shipment.DestinationCity ? true : false,
          validationLabel: 'labels.destinationCity',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 3,
          xl: 3,
          placeholder: 'labels.destinationCity',
        },
        {
          key: {
            parent: 'shipment',
            child: 'DestinationPostalCode',
          },
          disabled: true,
          active: this.shipment.DestinationPostalCode ? true : false,
          validationLabel: 'labels.destinationPostalCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 2,
          lg: 3,
          xl: 3,
          placeholder: 'labels.destinationPostalCode',
        },
        {
          key: {
            parent: 'unloadingPortParent',
            child: 'unloadingPort',
          },
          disabled: true,
          validationLabel: 'labels.unloadingPort',
          topLabel: true,
          labelShown: false,
          active: this.unloadingPortParent.unloadingPort ? true : false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 2,
          lg: 3,
          xl: 3,
          placeholder:
            this.shipment.TransportType == 5
              ? 'labels.unloadingStation'
              : 'labels.unloadingPort',
        },
        {
          key: {
            parent: 'shipment',
            child: 'ReferenceCode',
          },
          disabled: true,
          validationLabel: 'labels.algönderRefNo',
          topLabel: true,
          active: this.loadingPortParent.loadingPort ? true : false,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 2,
          lg: 3,
          xl: 3,
          placeholder: 'labels.algönderRefNo',
        },
        {
          key: {
            parent: 'shipment',
            child: 'SubSupplierTrackingNo',
          },
          disabled: true,
          validationLabel: 'labels.subSupplierTrackingNo',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: !this.shipment.LoadingCountry ? 4 : 2,
          lg: 3,
          xl: 3,
          placeholder: 'labels.subSupplierTrackingNo',
        },
        {
          key: {
            parent: 'shipment',
            child: this.shipment.ContainerType
              ? 'ContainerType'
              : this.shipment.TruckType
              ? 'TruckType'
              : 'CarriageType',
          },
          disabled: true,
          validationLabel: this.shipment.ContainerType
            ? 'labels.carType'
            : 'labels.vehicleType',
          topLabel: true,
          labelShown: false,
          active:
            this.shipment.LoadingType === 2 ||
            this.shipment.LoadingType === 3 ||
            this.shipment.LoadingType === 5
              ? true
              : false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 3,
          xl: 3,
          placeholder:
            this.shipment.ShipmentType === 5
              ? 'labels.carType'
              : this.shipment.ShipmentType === 2
              ? 'labels.vehicleType'
              : this.shipment.ShipmentType === 3
              ? 'labels.containerType'
              : 'labels.containerType',
        },
        {
          key: {
            parent: 'shipment',
            child: this.shipment.CarriageCount
              ? 'CarriageCount'
              : this.shipment.TruckCount
              ? 'TruckCount'
              : 'ContainerCount',
          },
          disabled: true,
          validationLabel: 'labels.carCount',
          topLabel: true,
          labelShown: false,
          active:
            this.shipment.LoadingType === 2 ||
            this.shipment.LoadingType === 3 ||
            this.shipment.LoadingType === 5
              ? true
              : false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 3,
          xl: 3,
          placeholder:
            this.shipment.ShipmentType === 5
              ? 'labels.carCount'
              : this.shipment.ShipmentType === 2
              ? 'labels.vehicleCount'
              : this.shipment.ShipmentType === 3
              ? 'labels.containerCount'
              : 'labels.containerCount',
        },
        {
          key: {
            parent: 'shipment',
            child: 'GoodsType',
          },
          rules:
            'maxTrim:35|minWords:2' +
            (this.order.Shipment?.TransportType === 2 ? '|required' : ''),
          validationLabel: 'labels.packageDescription',
          disabled: this.order.OrderStatus !== 0 || this.isSupplier,
          active: this.$vuetify.breakpoint.md,
          hidden: !this.$vuetify.breakpoint.md,
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 3,
          xl: 3,
          placeholder: 'labels.packageDescription',
        },
      ];
    },
    companyAddressFormElement() {
      return [
        {
          key: {
            parent: 'shipment',
            child: 'LoadingAddress1',
          },
          rules: {
            required:
              (this.userRole == 'Customer' ||
                this.userRole == 'PendingCustomer') &&
              this.order.OrderStatus == 0,
          },
          disabled: true,
          validationLabel: 'labels.loaderAddress',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },
          cols: 12,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
          placeholder: 'labels.loaderAddress',
        },
        {
          key: {
            parent: 'shipment',
            child: 'DestinationAddress1',
          },
          rules: {
            required:
              (this.userRole == 'Customer' ||
                this.userRole == 'PendingCustomer') &&
              this.order.OrderStatus == 0,
          },
          disabled: true,
          validationLabel: 'labels.buyerAddress',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },
          cols: 12,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
          placeholder: 'labels.buyerAddress',
        },
      ];
    },
    volumeRow() {
      return [
        {
          key: {
            parent: 'shipment',
            child: 'SumCapPieces',
          },
          validationLabel: 'labels.numOfContainers',
          disabled: true,
          topLabel: true,
          labelShown: false,
          active:
            this.shipment.LoadingType === 4 ||
            this.shipment.LoadingType === 1 ||
            this.shipment.LoadingType === 6
              ? true
              : false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 2,
          lg: 2,
          xl: 2,
          placeholder: 'labels.numOfContainers',
        },
        {
          key: {
            parent: 'shipment',
            child: 'SumWeight',
          },
          validationLabel: 'labels.kg',
          disabled: true,
          topLabel: true,
          labelShown: false,
          active:
            this.shipment.LoadingType === 4 ||
            this.shipment.LoadingType === 1 ||
            this.shipment.LoadingType === 6
              ? true
              : false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 2,
          lg: 2,
          xl: 2,
          placeholder: 'labels.kg',
        },
        {
          key: {
            parent: 'shipment',
            child: 'SumVolume',
          },
          validationLabel: 'labels.m3',
          disabled: true,
          topLabel: true,
          labelShown: false,
          active:
            this.shipment.LoadingType === 4 ||
            this.shipment.LoadingType === 1 ||
            this.shipment.LoadingType === 6
              ? true
              : false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 2,
          lg: 2,
          xl: 2,
          placeholder: 'labels.m3',
        },
        {
          key: {
            parent: 'shipment',
            child: 'ChargeableWeight',
          },
          disabled: true,
          validationLabel: 'labels.chargeableWeight',
          topLabel: true,
          labelShown: false,
          active:
            this.shipment.LoadingType === 4 ||
            this.shipment.LoadingType === 1 ||
            this.shipment.LoadingType === 6
              ? true
              : false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 3,
          lg: 3,
          xl: 3,
          placeholder: 'labels.chargeableWeight',
        },
        {
          key: {
            parent: 'shipment',
            child: 'ExpressPackageType',
          },
          rules: {
            required:
              (this.userRole == 'Customer' ||
                this.userRole == 'PendingCustomer') &&
              this.order.OrderStatus == 0,
          },
          disabled: true,
          validationLabel: 'labels.packageType',
          topLabel: true,
          labelShown: false,
          active:
            (this.shipment.LoadingType === 4 ||
            this.shipment.LoadingType === 1 ||
            this.shipment.LoadingType === 6
              ? true
              : false) && this.shipment?.TransportType === 2,
          formType: {
            name: 'select',
            items: {
              data: this.$expressPackageTypes,
              text: 'name',
              value: 'value',
            },
          },
          cols: 12,
          sm: 6,
          md: 3,
          lg: 3,
          xl: 3,
          placeholder: 'labels.packageType',
        },
        {
          active: this.shipment.ExpressPackageType !== 2,
          key: {
            parent: 'shipment',
            child: 'ExpressServiceType',
          },
          rules: {
            required:
              (this.userRole == 'Customer' ||
                this.userRole == 'PendingCustomer') &&
              this.order.OrderStatus == 0,
          },
          disabled: true,
          validationLabel: 'titles.expressServiceType',
          topLabel: true,
          labelShown: false,
          active:
            (this.shipment.LoadingType === 4 ||
            this.shipment.LoadingType === 1 ||
            this.shipment.LoadingType === 6
              ? true
              : false) && this.shipment?.TransportType === 2,
          formType: {
            name: 'select',
            items: {
              data: this.$expressServiceTypes,
              text: 'name',
              value: 'value',
            },
          },
          cols: 12,
          sm: 6,
          md: 3,
          lg: 3,
          xl: 3,
          placeholder: 'titles.expressServiceType',
          willBeEliminated:"false"
        },
        {
          key: {
            parent: 'shipment',
            child: 'GoodsType',
          },
          rules:
            'maxTrim:35|minWords:2' +
            (this.order.Shipment?.TransportType === 2 ? '|required' : ''),
          disabled: this.order.OrderStatus !== 0 || this.isSupplier,
          validationLabel: 'labels.packageDescription',
          active: !this.$vuetify.breakpoint.md,
          hidden: this.$vuetify.breakpoint.md,
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            hint: this.$t('labels.willReturn'),
            persistentHint: true,
          },
          cols: 12,
          sm: 6,
          md: 3,
          lg: 3,
          xl: 3,
          placeholder: 'labels.packageDescription',
        },
        {
          key: {
            parent: 'shipment',
            child: 'ProformaOption',
          },
          validationLabel: 'labels.proformaOption',
          topLabel: true,
          disabled:
            this.order.OrderStatus != 0 ||
            this.order.OrderStatus == 9 ||
            this.order.PaymentStatus == 0,
          active: this.order.Shipment?.TransportType === 2,
          hidden: this.order.Shipment?.TransportType !== 2,
          rules: 'required',
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.proformaOptions,
              value: 'Value',
              text: 'Name',
            },
          },
          cols: 12,
          sm: 6,
          md: 3,
          lg: 3,
          xl: 3,
          placeholder: 'labels.proformaOption',
        },

        {
          key: {
            parent: 'shipment',
            child: 'GoodsPrice',
          },
          validationLabel: 'labels.goodsPrice',
          topLabel: true,
          rules: 'required|double',
          active: this.order.Shipment?.TransportType === 2,
          hidden: this.order.Shipment?.TransportType !== 2,
          disabled:
            this.userRole === 'Supplier' ||
            this.userRole == 'PendingSupplier' ||
            this.order.OrderStatus != 0 ||
            this.order.OrderStatus == 9 ||
            this.order.PaymentStatus == 0,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 3,
          lg: 3,
          xl: 3,
          placeholder: 'labels.goodsPrice',
        },
        {
          key: {
            parent: 'shipment',
            child: 'GoodsPriceCurrency',
          },
          rules: 'required',
          active: this.order.Shipment?.TransportType === 2,
          hidden: this.order.Shipment?.TransportType !== 2,
          disabled:
            this.userRole === 'Supplier' ||
            this.userRole == 'PendingSupplier' ||
            this.order.OrderStatus != 0 ||
            this.order.OrderStatus == 9 ||
            this.order.PaymentStatus == 0,
          validationLabel: 'labels.currency',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.currencies,
              value: 'Id',
              text: 'Code',
            },
          },
          cols: 12,
          sm: 6,
          md: 3,
          lg: 3,
          xl: 3,
          placeholder: 'labels.currency',
        },
        {
          key: {
            parent: 'shipment',
            child: 'CountryOfManufacture',
          },
          validationLabel: 'labels.origin',
          topLabel: true,
          rules: 'required',
          active: this.order.Shipment?.TransportType === 2,
          hidden: this.order.Shipment?.TransportType !== 2,
          labelShown: false,
          disabled:
            this.userRole === 'Supplier' ||
            this.userRole == 'PendingSupplier' ||
            this.order.OrderStatus != 0 ||
            this.order.OrderStatus == 9 ||
            this.order.PaymentStatus == 0,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.countries,
              value: 'ISOCode2',
              text: 'Name',
            },
          },
          cols: 12,
          sm: 6,
          md: 3,
          lg: 3,
          xl: 3,
          placeholder: 'labels.origin',
        },
      ];
    },

    dateRow() {
      return [
        {
          key: {
            parent: 'shipment',
            child: 'WantedLoadingDate',
          },
          disabled:
            this.userRole == 'Supplier' || this.userRole == 'PendingSupplier',
          validationLabel: 'labels.desiredDate',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'date',
            minimum: new Date(),
            attributes: [
              {
                key: 'today',
                highlight: {
                  color: 'white',
                  style: {
                    backgroundColor: 'orange',
                  },
                },
                dates: new Date(),
              },
              {
                key: 'selectedDate',
                highlight: true,
                dates: this.shipment.WantedLoadingDate
                  ? this.shipment.WantedLoadingDate + 'Z'
                  : '',
              },
            ],
          },
          lg: this.shipment.EstimatedLoadingDate ? 6 : 12,
          placeholder: 'labels.desiredDate',
        },
        {
          key: {
            parent: 'shipment',
            child: 'EstimatedLoadingDate',
          },
          rules: {
            required:
              this.userRole == 'Supplier' || this.userRole == 'PendingSupplier',
          },
          disabled:
            this.userRole == 'Customer' || this.userRole == 'PendingCustomer',
          validationLabel: 'labels.estimationDate',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'date',
          },
          lg: 6,
          placeholder: 'labels.estimationDate',
          active: !!this.shipment.EstimatedLoadingDate,
        },
        {
          key: {
            parent: 'shipment',
            child: 'LoadingDate',
          },
          disabled:
            this.userRole == 'Customer' || this.userRole == 'PendingCustomer',
          validationLabel: 'labels.loadingDate',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'date',
          },
          lg: 6,
          placeholder: 'labels.loadingDate',
        },
        {
          key: {
            parent: 'shipment',
            child: 'DepartureDate',
          },
          disabled:
            this.userRole == 'Customer' ||
            this.userRole == 'PendingCustomer' ||
            this.shipment.LoadingDate == null,
          validationLabel: 'labels.departureDate',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'date',
            minimum: this.shipment.LoadingDate,
          },
          lg: 6,
          placeholder: 'labels.departureDate',
        },
        {
          key: {
            parent: 'shipment',
            child: 'ArrivalDate',
          },
          disabled:
            this.userRole == 'Customer' ||
            this.userRole == 'PendingCustomer' ||
            this.shipment.DepartureDate == null,
          validationLabel: 'labels.arrivalDate',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'date',
            minimum: this.shipment.DepartureDate,
          },
          lg: 6,
          placeholder: 'labels.arrivalDate',
        },
        {
          key: {
            parent: 'shipment',
            child: 'DeliveryDate',
          },
          disabled:
            this.userRole == 'Customer' ||
            this.userRole == 'PendingCustomer' ||
            this.shipment.ArrivalDate == null,
          validationLabel: 'labels.deliveryDate',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'date',
            minimum: this.shipment.ArrivalDate,
          },
          lg: 6,
          placeholder: 'labels.deliveryDate',
        },
      ];
    },
    carryRow() {
      return [
        {
          key: {
            parent: 'shipment',
            child: 'OrderDeliveryType',
          },
          validationLabel: 'labels.orderSender',
          formLabel: 'labels.orderSender',
          formType: {
            name: 'select',
            items: {
              data: this.orderDeliveryTypes,
              value: 'value',
              text: 'name',
            },
          },
          disabled:
            this.userRole == 'Supplier' || this.userRole == 'PendingSupplier',
          topLabel: true,
          labelShown: false,
          lg: 12,
          placeholder: 'labels.orderSender',
        },
        {
          key: {
            parent: 'shipment',
            child: 'CargoCompanyName',
          },
          validationLabel: 'labels.cargoCompanyName',
          formLabel: 'labels.cargoCompanyName',
          active: this.order.Shipment?.OrderDeliveryType == 0,
          formType: {
            name: 'text',
          },
          disabled:
            this.userRole == 'Supplier' || this.userRole == 'PendingSupplier',
          topLabel: true,
          labelShown: false,
          lg: 6,
          placeholder: 'labels.cargoCompanyName',
        },
        {
          key: {
            parent: 'shipment',
            child: 'CargoTrackingNumber',
          },
          validationLabel: 'labels.cargoTrackingNumber',
          formLabel: 'labels.cargoTrackingNumber',
          active: this.order.Shipment?.OrderDeliveryType == 0,
          formType: {
            name: 'text',
          },
          disabled:
            this.userRole == 'Supplier' || this.userRole == 'PendingSupplier',
          topLabel: true,
          labelShown: false,
          lg: 6,
          placeholder: 'labels.cargoTrackingNumber',
        },
        {
          key: {
            parent: 'shipment',
            child: 'TransportCompanyName',
          },
          validationLabel: 'labels.transportCompanyName',
          formLabel: 'labels.transportCompanyName',
          active: this.order.Shipment?.OrderDeliveryType == 1,
          formType: {
            name: 'text',
          },
          disabled:
            this.userRole == 'Supplier' || this.userRole == 'PendingSupplier',
          topLabel: true,
          labelShown: false,
          lg: 6,
          placeholder: 'labels.transportCompanyName',
        },
        {
          key: {
            parent: 'shipment',
            child: 'TransportPlate',
          },
          disabled:
            this.userRole == 'Supplier' || this.userRole == 'PendingSupplier',
          validationLabel: 'labels.transportPlate',
          formLabel: 'labels.transportPlate',
          active: this.order.Shipment?.OrderDeliveryType == 1,
          formType: {
            name: 'text',
          },
          topLabel: true,
          labelShown: false,
          lg: 6,
          placeholder: 'labels.transportPlate',
        },
        {
          key: {
            parent: 'shipment',
            child: 'TransportDriverName',
          },
          disabled:
            this.userRole == 'Supplier' || this.userRole == 'PendingSupplier',
          validationLabel: 'labels.transportDriverName',
          formLabel: 'labels.transportDriverName',
          active: this.order.Shipment?.OrderDeliveryType == 1,
          formType: {
            name: 'text',
          },
          topLabel: true,
          labelShown: false,
          lg: 6,
          placeholder: 'labels.transportDriverName',
        },
      ];
    },
    priceRow() {
      return [
        {
          key: {
            parent: 'shipment',
            child: 'PaymetTime',
          },
          validationLabel: 'labels.payingType',
          formLabel: 'labels.payingType',
          disabled: !this.isSupplier || !!this.temporaryShipment.PaymetTime,
          rules: {
            required: this.order.OrderStatus !== 0,
          },
          formType: {
            name: 'select',
            items: {
              data: this.buyerTypes,
              value: 'value',
              text: 'name',
            },
          },
          active: this.order.Shipment?.TransportType === 2,
          topLabel: true,
          labelShown: false,
          lg: 6,
          placeholder: 'labels.payingType',
        },
        {
          key: {
            parent: 'shipment',
            child: 'PaymentType',
          },
          validationLabel: 'labels.paymentType',
          formLabel: 'labels.paymentType',
          disabled: !this.isSupplier,
          formType: {
            name: 'select',
            items: {
              data: this.paymentTypes,
              value: 'value',
              text: 'name',
            },
          },
          topLabel: true,
          labelShown: false,
          lg: 6,
          placeholder: 'labels.paymentType',
        },
      ];
    },

    expense_form_items() {
      return [
        {
          key: {
            parent: 'shipment',
            child: 'Price',
          },
          validationLabel: 'labels.price',
          formLabel: 'labels.price',
          disabled: true,
          formType: {
            name: 'text',
          },
          topLabel: true,
          labelShown: false,
          cols: 12,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
          placeholder: 'labels.price',
        },
        {
          key: {
            parent: 'order',
            child: 'IsInvoiceIssued',
          },
          validationLabel: 'labels.invoiceStatus',
          formLabel: 'labels.invoiceStatus',
          disabled: true,
          formType: {
            name: 'text',
          },
          topLabel: true,
          labelShown: false,
          cols: 12,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
          placeholder: 'labels.invoiceStatus',
        },
        {
          key: {
            parent: 'shipment',
            child: 'Expense',
          },
          validationLabel: 'labels.expenseType',
          formLabel: 'labels.expenseType',
          rules: 'numeric|min_value:1',
          formType: {
            name: 'text',
          },
          active: this.order.Shipment?.TransportType === 2,
          topLabel: true,
          labelShown: false,
          cols: 12,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
          placeholder: 'labels.expenseType',
        },
        {
          key: {
            parent: 'shipment',
            child: 'ExpenseCurrencyId',
          },
          validationLabel: 'labels.currency',
          rules: {
            required: !!this.shipment.Expense,
          },
          formLabel: 'labels.currency',
          formType: {
            name: 'select',
            items: {
              data: this.currencies,
              value: 'Id',
              text: 'Code',
            },
          },
          topLabel: true,
          labelShown: false,
          cols: 12,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
          placeholder: 'labels.currency',
        },
        {
          key: {
            parent: 'shipment',
            child: 'AdditionalExpense',
          },
          validationLabel: 'labels.additionalExpense',
          formLabel: 'labels.additionalExpense',
          rules: 'double|min_value:1',
          formType: {
            name: 'text',
          },
          topLabel: true,
          labelShown: false,
          cols: 12,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
          placeholder: 'labels.additionalExpense',
        },
        {
          key: {
            parent: 'shipment',
            child: 'AdditionalExpenseCurrencyId',
          },
          rules: {
            required: !!this.shipment.AdditionalExpense,
          },
          validationLabel: 'labels.additionalExpenseCurrency',
          formLabel: 'labels.additionalExpenseCurrency',
          formType: {
            name: 'select',
            items: {
              data: this.currencies,
              value: 'Id',
              text: 'Code',
            },
          },
          topLabel: true,
          labelShown: false,
          cols: 12,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
          placeholder: 'labels.additionalExpenseCurrency',
        },
        {
          key: {
            parent: 'shipment',
            child: 'totalProfit',
          },
          validationLabel: 'labels.profit',
          formLabel: 'labels.profit',
          disabled: true,
          formType: {
            name: 'text',
          },
          topLabel: true,
          labelShown: false,
          cols: 12,
          placeholder: 'labels.profit',
        },
      ];
    },

    customerForm() {
      return [
        {
          key: {
            parent: 'orderStatus',
            child: 'name',
          },
          disabled: true,
          validationLabel: 'labels.orderStatus',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 6,
          sm: 4,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 4,
          placeholder: 'labels.orderStatus',
        },
        {
          key: {
            parent: 'fileStatus',
            child: 'name',
          },
          disabled: true,
          validationLabel: 'labels.fileStatus',
          topLabel: true,
          active:
            this.userRole === 'Supplier' || this.userRole === 'PendingSupplier',
          hidden:
            this.userRole === 'Customer' || this.userRole === 'PendingCustomer',

          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 6,
          sm: 4,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 4,
          placeholder: 'labels.fileStatus',
        },
        {
          key: {
            parent: 'order',
            child: 'CustomerCompanyName',
          },
          disabled: true,
          validationLabel: 'labels.customerCompanyName',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 6,
          sm: 4,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 4,
          placeholder: 'labels.customerCompanyName',
        },
        {
          key: {
            parent: 'order',
            child: 'CustomerCompanyGsmNo',
          },
          disabled: true,
          validationLabel: 'labels.customerPhone',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 6,
          sm: 4,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 4,
          placeholder: 'labels.customerPhone',
        },
        {
          key: {
            parent: 'order',
            child: 'CustomerCompanyEmail',
          },
          disabled: true,
          validationLabel: 'labels.customerEmail',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 4,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 4,

          placeholder: 'labels.customerEmail',
        },
      ];
    },

    purposeOfShippingForm() {
      return [
        {
          key: {
            parent: 'shipment',
            child: 'PurposeOfShipment',
          },
          validationLabel: 'labels.purposeOfShippingLabel',
          topLabel: true,
          disabled:
            this.order.OrderStatus != 0 ||
            this.order.OrderStatus == 9 ||
            this.order.PaymentStatus == 0,
          active: this.order.Shipment?.TransportType === 2,
          hidden: this.order.Shipment?.TransportType !== 2,
          rules: 'required',
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.purposeOfShippingItems,
              value: 'Value',
              text: 'Name',
            },
          },
          cols: 12,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6,
          placeholder: 'labels.purposeOfShippingLabel',
        },
      ];
    },

    otherServices() {
      return [
        {
          key: {
            parent: 'shipment',
            child: 'InsuranceType',
          },
          validationLabel: 'labels.insurance',
          topLabel: true,
          disabled:
            this.order.OrderStatus != 0 ||
            this.order.OrderStatus == 9 ||
            this.order.PaymentStatus == 0,
          active: this.order.Shipment?.TransportType === 2,
          hidden: this.order.Shipment?.TransportType !== 2,
          rules: 'required',
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.insuranceOptions,
              value: 'Value',
              text: 'Name',
            },
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 4,
          xl: 4,
          placeholder: 'labels.insurance',
          classes: 'mr-sm-16',
        },
        {
          key: {
            parent: 'shipment',
            child: 'SignedDelivery',
          },
          validationLabel: 'labels.wantSignedDelivery',
          topLabel: true,
          disabled:
            this.order.OrderStatus != 0 ||
            this.order.OrderStatus == 9 ||
            this.order.PaymentStatus == 0,
          active: this.order.Shipment?.TransportType === 2,
          hidden: this.order.Shipment?.TransportType !== 2,
          labelShown: false,
          formType: {
            name: 'checkbox',
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 4,
          xl: 4,
          placeholder: 'labels.wantSignedDelivery',
          classes: 'ml-lg-16 mt-4 mt-sm-2',
        },
      ];
    },

    addSubModalFormElement() {
      return [
        {
          key: {
            parent: 'form',
            child: 'companyName',
          },
          rules: company,
          validationLabel: 'labels.companyName',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.companyName',
        },
        {
          key: {
            parent: 'form',
            child: 'companyAddress',
          },
          rules: 'required',
          validationLabel: 'labels.address1',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.address1',
        },
        {
          key: {
            parent: 'form',
            child: 'countryCode',
          },
          rules: 'required',
          validationLabel: 'labels.country',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.countries,
              value: 'Id',
              text: 'Name',
            },
          },
          onChange: () => {
            this.onChangeCountry();
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.country',
        },
        {
          key: {
            parent: 'form',
            child: 'city',
          },
          rules: 'required',
          validationLabel: 'labels.city',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.cities,
              value: 'Id',
              text: 'Name',
            },
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.city',
        },
        {
          key: {
            parent: 'form',
            child: 'AuthorizedPerson',
          },
          rules: authorizedUser,
          validationLabel: 'labels.authorizedPerson',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.authorizedPerson',
        },
        {
          key: {
            parent: 'form',
            child: 'EoriCode',
          },
          validationLabel: 'labels.EORICode',
          rules: 'numeric',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.EORICode',
        },

        {
          key: {
            parent: 'form',
            child: 'postalCode',
          },
          rules: 'required|min:4|max:9',
          validationLabel: 'labels.postalCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.postalCode',
        },
        {
          key: {
            parent: 'form',
            child: 'stateCode',
          },
          rules: this.isStateCodeRequired ? 'required|max:2' : '',
          validationLabel: 'labels.stateCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            autocomplete: 'new-password',
            isMagnify: true,
            magnifyURL:
              'https://www.ups.com/worldshiphelp/WSA/ENU/AppHelp/mergedProjects/CORE/Codes/State_Province_Codes.htm',
            magnifyLabel: 'buttons.showStateCodes',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.stateCode',
        },
        {
          key: {
            parent: 'form',
            child: 'contactPhone',
          },
          rules: rules.phone,
          validationLabel: 'labels.phone',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prefix: this.phonePrefix,
            autocomplete: 'new-password',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.phone',
        },
        {
          key: {
            parent: 'form',
            child: 'email',
          },
          validationLabel: 'labels.email',
          rules: 'required|email|max:50',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.email',
        },
      ];
    },
    isAgreement() {
      if (!this.orderFiles || this.orderFiles.length === 0) {
        return false;
      }
      for (let file of this.orderFiles) {
        if (file.OrderFileType === 4) {
          return true;
        }
      }
      return false;
    },
  },

  methods: {
    handleOpenURL() {
      window.open(
        'https://www.ups.com/worldshiphelp/WSA/ENU/AppHelp/mergedProjects/CORE/Codes/State_Province_Codes.htm',
        '_blank'
      );
    },
    createETGB() {
      let body = {
        OrderId: this.orderId,
        ShipmentId: this.shipmentId,
        InvoiceNo: this.formETGB.ETGBInvoiceNo,
        InvoiceDate: this.formETGB.ETGBInvoiceDate,
        Documents: this.formETGB.ETGBDocuments,
        BankInfo: this.formETGB.ETGBBankInfo,
      };

      this.$store.state.loadingStatus = true;
      OrderService.createETGB(body)
        .then((response) => {
          this.$store.state.loadingStatus = false;
          window.showSuccess(this.$t('messages.createdETGBSuccessfully'));
          this.getOrderDetails(this.orderId);
          this.clearETGBDialog();
        })
        .catch((error) => {
          this.$store.state.loadingStatus = false;
          window.showError(error);
        });
    },
    clearETGBDialog() {
      this.ETGBInvoiceNo = '';
      this.ETGBInvoiceDate = '';
      this.ETGBDocuments = '';
      this.ETGBBankInfo = '';

      this.isAddETGBOpen = false;
    },
    getLogs() {
      this.$store.state.loadingStatus = true;
      OrderService.getOrderStatusLogs(this.orderId)
        .then((response) => {
          let mockData = response.data.Result;
          mockData.forEach((element) => {
            let object = {
              CreatedOnUtc: element.CreatedOnUtc,
              OrderStatusLog: this.orderStatuses[element.OrderStatus],
            };
            console.log('Object :', object);
            this.orderStatusData.push(object);
          });
          if (this.isSupplier) {
            OrderService.getOrderStatusLogsSupplier(this.orderId)
              .then((response) => {
                let mockData = response.data.Result;
                mockData.forEach((element) => {
                  let object = {
                    CreatedOnUtc: element.CreatedOnUtc,
                    OrderStatusLog:
                      this.fileStatuses[element.SupplierOrderStatus],
                  };

                  console.log('Object :', object);
                  this.orderStatusData.push(object);
                });
              })
              .catch()
              .finally(() => {
                this.$store.state.loadingStatus = false;
              });
          }
        })
        .catch()
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    editAddressMode(isLoading = true) {
      this.$store.state.loadingStatus = true;
      const selectedObject =
        this.$data[isLoading ? 'loadingCompany' : 'destinationCompany'];

      AddressService.getCities(selectedObject.CountryId)
        .then((response) => {
          this.cities = response.data.Result;
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loadingStatus = false;

          this.form = {
            companyName: selectedObject.Name,
            companyAddress: selectedObject.Address,
            countryCode: selectedObject.CountryId,
            city: selectedObject.CityId,
            AuthorizedPerson: selectedObject.AuthorizedPerson,
            EoriCode: selectedObject.EoriCode,
            postalCode: selectedObject.PostalCode,
            stateCode: selectedObject.StateCode,
            contactPhone: selectedObject.ContactPhone,
            email: selectedObject.Mail,
            Id: selectedObject.Id,
          };

          this.addSubCompany = true;
          this.isSubCompanyLoading = isLoading;
          this.editAddressModeModal = true;
        });
    },

    calculateChargeableWeight(width, length, height, containerCount) {
      return (width * length * height * containerCount) / 5000;
    },
    // Subsupplier Methods
    getPackagingTypes() {
      this.$store.state.loadingStatus = true;
      PortalService.getIntegrationPackages(this.shipment.SubSupplier)
        .then((response) => {
          this.integrationPackages = response.data;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    checkIntegrationShipment() {
      IntegrationServices.getIntegrationShipment(this.orderId)
        .then((response) => {
          this.subsupplierShipmentData = response.data.Result;
        })
        .catch(window.showError);
    },
    createShipment() {
      this.$store.state.loadingStatus = true;
      const submitFunction = this.subsupplierForm.submitFunction;
      delete this.subsupplierForm.submitFunction;

      submitFunction(this.subsupplierForm)
        .then((response) => {
          window.showSuccess(this.$t('messages.createdShipmentSuccessfully'));
          this.checkIntegrationShipment();
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getLogisticsCompanies() {
      this.$store.state.loadingStatus = true;
      IntegrationServices.getCompanyLogistics()
        .then((response) => {
          this.logisticsCompanies = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getServiceTypes() {
      this.$store.state.loadingStatus = true;
      FedExServices.getServiceTypes()
        .then((response) => {
          this.fedExServiceTypes = response.data;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getPickupTypes() {
      this.$store.state.loadingStatus = true;
      FedExServices.getPickupTypes()
        .then((response) => {
          this.fedExPickupTypes = response.data;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    // Subsupplier Methods END

    openAddVolumeModal() {
      if (this.order.Shipment.IsShipmentBoxesUpdated && !this.isSupplier) {
        ShipmentService.deleteWarning(this.order.Shipment.Id)
          .then((response) => {
            this.addVolumeModal = true;
          })
          .catch(window.showError);
      } else {
        this.addVolumeModal = true;
      }
    },
    onPageChange(newPage) {
      this.$store.state.loadingStatus = true;
      this.page = newPage;
      this.getEquipments();
    },
    changeItemsPerPage() {
      this.page = 1;
      this.getEquipments();
    },
    nameSetter() {
      const shipmentType = this.shipmentTypes.find(
        (x) => x.value == this.shipment.ShipmentType
      );

      const serviceType = this.serviceTypes.find(
        (x) => x.value == this.shipment.ServiceType
      );

      const loadType = this.loadTypes.find(
        (x) => x.value == this.shipment.LoadingType
      );
      const shipmentStatus = this.shipmentStatus.find(
        (x) => x.value == this.shipment.Status
      );
      this.shipmentNames.shipmentTypeName = this.$t(`${shipmentType.name}`);
      this.shipmentNames.serviceType = this.$t(`${serviceType.name}`);
      this.shipmentNames.loadType = this.$t(`${loadType.name}`);
      this.shipmentNames.shipmentStatusName = this.$t(`${shipmentStatus.name}`);
    },

    async getCountries() {
      this.countries = await getCountries();
    },

    onChangeCountry() {
      AddressService.getCounties(this.form.countryCode)
        .then((response) => {
          this.phonePrefix = response.data.Result[0].PhoneCode;
        })
        .catch((error) => {});

      AddressService.getCities(this.form.countryCode)
        .then((response) => {
          this.cities = response.data.Result;
        })
        .catch((error) => {});
    },

    showIcon() {
      this.isIconVisible = true;
    },
    hideIcon() {
      this.isIconVisible = false;
    },
    checkSign() {
      OrderService.getSignStatus(this.orderId)
        .then((response) => {
          if (response.data.Result === true) {
            this.isPending = true;
            this.order.SignStatus = true;
          }
        })
        .catch((error) => {
          window.showError(error);
        });
    },
    openImagePreview() {
      window.open(this.loadingFile.Url, '_blank');
    },
    handleMapClick(event) {
      const latitude = event.latLng.lat();
      const longitude = event.latLng.lng();

      this.latitude = latitude;
      this.longitude = longitude;
    },

    getOrderMessages() {
      this.$store.state.loadingStatus = true;
      MessageService.getById(this.orderId)
        .then(async (response) => {
          const data = response.data.Result;
          this.chatId = data.Id;
        })
        .catch((error) => {
          console.log('Error getting order messages >> ', error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    getOrderChecklist(orderId) {
      this.$store.state.loadingStatus = true;
      this.orderChecklist = [];
      OrderService.getOrderChecklist(orderId)
        .then(async (response) => {
          this.orderChecklist = response.data.Result;
        })
        .catch((error) => {
          console.log('Error getting order checklist >> ', error);
          window.showErrorMessage(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    orderCheckboxToggled(item) {
      let body = {
        Id: item.Id,
        IsChecked: item.IsChecked,
      };
      console.log('TOGGLE >> ', body);
      this.$store.state.loadingStatus = true;
      OrderService.checkboxToggle(body)
        .then(async (response) => {
          this.getOrderChecklist(this.orderId);
        })
        .catch((error) => {
          console.log('Error toggling order checklist >> ', error);
          window.showErrorMessage(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    async onSaveVolumes(volumes, parameters) {
      const {
        totalContainerCount,
        totalVolume,
        totalKilo,
        totalInvoiceWeight,
        chargeableWeight,
      } = parameters;

      console.log(parameters);

      const updateVolumes = () => {
        this.shipment.SumCapPieces = totalContainerCount;
        this.shipment.SumWeight = totalKilo;
        this.shipment.SumVolume = totalVolume;
        this.shipment.ChargeableWeight = chargeableWeight;

        this.shipment.ShipmentBoxes = convertVolumeToBoxes(volumes);

        this.shipment = Object.assign({}, this.shipment);
      };

      this.confirmation.message = this.$t('messages.addVolumeWarning');
      this.confirmation.onConfirm = () => {
        const converted = convertVolumeToBoxes(volumes);

        this.$store.state.loadingStatus = true;
        ShipmentBoxServices.update({
          Boxes: converted,
          ChargeableWeight: chargeableWeight,
          TotalCapPieces: totalContainerCount,
          SumWeight: totalKilo,
          SumVolume: totalVolume,
          ShipmentId: this.shipment.Id,
        })
          .then((response) => {
            updateVolumes();
            this.getOrderDetails(this.orderId);
            window.showSuccess(this.$t('messages.updateBoxesSuccessfully'));
          })
          .catch(window.showError)
          .finally(() => (this.$store.state.loadingStatus = false));
      };

      if (
        (parseInt(this.shipment.SumCapPieces) !== totalContainerCount ||
          parseFloat(this.shipment.SumWeight).toFixed(2) !== totalKilo ||
          parseFloat(this.shipment.SumVolume).toFixed(2) !== totalVolume) &&
        this.shipment.SumCapPieces &&
        this.shipment.SumWeight &&
        this.shipment.SumVolume &&
        this.shipment.TransportType !== 2
      ) {
        this.confirmation.visible = true;
      } else {
        this.confirmation.onConfirm();
      }
    },

    resetEquipmentFields() {
      (this.selectedEquipmentType = null),
        (this.equipmentNo = null),
        (this.sealNo = null),
        (this.goodsType = null),
        (this.containerCount = null);
      this.loadedKg = null;
      this.loadedVolume = null;
    },

    getOrderStatusLogsHeaders() {
      return [
        {
          text: this.$t('labels.dateAsNoun'),
          value: 'CreatedOnUtc',
          sortable: true,
          width: '300px',
        },
        {
          text: this.$t('labels.hourAsNoun'),
          value: 'Hour',
          sortable: false,
          groupable: false,
          width: '300px',
        },
        {
          text: this.$t('labels.status'),
          value: 'OrderStatusLog',
          sortable: true,
        },
      ];
    },

    getOrderLogsHeaders() {
      return [
        {
          text: this.$t('labels.dateAsNoun'),
          value: 'date',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('labels.hourAsNoun'),
          value: 'hour',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('labels.locationAsNoun'),
          value: 'location',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('labels.codeAsNoun'),
          value: 'code',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('labels.actions'),
          value: 'status',
          sortable: false,
          groupable: false,
        },
      ];
    },
    getLocationHistoryHeaders() {
      return [
        {
          text: this.$t('labels.dateAsNoun'),
          value: 'LocationDate',
          sortable: false,
          groupable: false,
          align: 'center',
          format: (value) => convertToLocal(value),
        },
        {
          text: this.$t('labels.location'),
          value: 'Location',
          sortable: false,
          groupable: false,
          align: 'center',
          format: (value) => convertToLocal(value),
        },
      ];
    },
    getEquipmentHeaders() {
      return [
        {
          text: this.$t('labels.equipmentType'),
          value: 'EquipmentType',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.equipmentNo'),
          value: 'EquipmentNo',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.lastLocation'),
          value: 'LastLocation',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.goodsType'),
          value: 'GoodsType',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.numOfContainers'),
          value: 'ContainerCount',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.loadedKg'),
          value: 'LoadedKg',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.loadedVolume'),
          value: 'LoadedVolume',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
          groupable: false,
          align: 'center',
        },
      ];
    },

    closeLocationModal() {
      this.$refs.locationObserver.reset();
      this.locationDate = new Date().toISOString().substr(0, 10);
      this.locationTime = null;
      this.location = '';
      this.latitude = '';
      this.longitude = '';
      this.isLocationOpen = false;
    },
    clearFileUploadDialog() {
      this.$refs.fileUploadObserver.reset();
      this.selectedDocument = null;
      this.selectedFileType = null;
      this.fileNote = '';
      this.fileName = '';
    },
    resetSubCompanyDialog() {
      this.$refs.observer.reset();
      this.form = {};
      this.isHome = false;
      this.addSubCompany = false;
    },
    research() {
      this.$router.push({
        name: 'offerResultsById',
        params: { id: this.shipment.FastShippingSearchId },
      });
    },
    getLoadingPorts() {
      this.loadingPortParent.loadingPort =
        this.shipment.AirloadingPort ||
        this.shipment.RailwayloadingPort ||
        this.shipment.SeaLoadingPort;
      this.unloadingPortParent.unloadingPort =
        this.shipment.AirUnloadingPort ||
        this.shipment.RailwayUnloadingPort ||
        this.shipment.SeaUnloadingPort;
    },

    navigateContract(file) {
      if (true) {
        this.selectedFile = file;
        this.showTabScreen = false;
      }
    },
    navigateToFiles() {
      this.navToFiles = false;
      this.activeTab = 2;
    },
    openContract() {
      if (this.order.ContractId) this.getContractDetail(this.order.ContractId);
    },

    acceptContract() {
      const body = {
        ContractId: this.order.ContractId,
        OrderId: this.orderId,
      };
      this.$store.state.loadingStatus = true;
      return ContractService.signContract(body)
        .then((res) => {
          this.getOrderDetails(this.orderId);
          this.$store.state.loadingStatus = false;
          window.showSuccess(this.$t('messages.contractSignSuccess'));
        })
        .catch((err) => {
          window.showError(err, this.$t('messages.contractSignError'));
          this.$store.state.loadingStatus = false;
        });
    },

    cancelOrder() {
      this.$store.state.loadingStatus = true;
      const body = {
        OrderId: this.orderId,
        CancelReason: this.selectedReason,
        CancelDescription: this.cancelDescription,
      };

      OrderService.cancelOrder(body)
        .then((res) => {
          this.resetCancelModal();
          this.getOrderDetails(this.orderId);
          window.showSuccess(this.$t('description.orderCanceled'));
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    resetCancelModal() {
      this.cancelOrderModal = false;
      this.selectedReason = null;
      this.cancelDescription = '';
      this.$refs.cancelModalObserver.reset();
    },

    statusControl() {
      this.userRole = this.$store.getters.getUserType;
      if (this.order.OrderStatus == 9) {
        this.lockButton = true;
      }

      if (this.userRole == 'Supplier' || this.userRole == 'PendingSupplier') {
        if (this.order.OrderStatus == 0) {
          this.lockButton = true;
          this.disabledText = this.$t('messages.needsApprovementByCustomer');
        } else if (this.order.OrderStatus == 2) {
          this.lockButton = true;
          this.disabledText = this.$t('messages.needsToSignedByCustomer');
        } else if (this.order.OrderStatus == 7) {
          this.lockButton = true;
        } else {
          this.lockButton = false;
        }
      } else {
        if (this.order.OrderStatus == 7 || this.order.PaymentStatus == 0) {
          this.lockButton = true;
        } else {
          this.lockButton = false;
        }
      }

      this.sendSupplier = this.lockButton;
    },

    calculatePrice(items, key) {
      let total = 0;
      if (items) {
        items.map((item) => (total += parseFloat(item[key])));
      }
      return total;
    },
    updateLoadingSubCompany() {
      this.statusControl();

      let formattedAddress = '';
      this.shipment.LoadingSubCompanyId = this.loadingCompany.Id;
      formattedAddress = this.loadingCompany.Address;
      formattedAddress +=
        ' ' + this.loadingCompany.City + '/' + this.loadingCompany.CountryCode;
      if (this.loadingCompany.StateCode) {
        formattedAddress += ' ' + this.loadingCompany.StateCode;
      }

      formattedAddress += ' ' + this.loadingCompany.PostalCode;
      this.shipment.LoadingAddress1 = formattedAddress.replaceAll('null', '');
    },
    updateDestinationSubCompany() {
      this.statusControl();
      let formattedAddress = '';
      this.shipment.DestinationSubCompanyId = this.destinationCompany.Id;
      formattedAddress =
        this.destinationCompany.Address +
        ' ' +
        this.destinationCompany.SecondAddress;
      formattedAddress +=
        ' ' +
        this.destinationCompany.City +
        '/' +
        this.destinationCompany.CountryCode;
      if (this.destinationCompany.StateCode) {
        formattedAddress += ' ' + this.destinationCompany.StateCode;
      }

      formattedAddress += ' ' + this.destinationCompany.PostalCode;
      this.shipment.DestinationAddress1 = formattedAddress.replaceAll(
        'null',
        ''
      );
    },
    addOrderLog() {
      const body = {
        OrderId: this.orderId,
        Description: this.orderLogDescription,
      };

      this.$store.state.loadingStatus = true;
      OrderService.addOrderLog(body)
        .then((res) => {
          this.isAddLogOpen = false;
          this.orderLogDescription = null;
          this.getOrderDetails(this.orderId);
          this.$store.state.loadingStatus = false;
        })
        .catch((err) => {
          window.handleServiceError(err);
        });
    },
    resetAddStatus() {
      this.isAddLogOpen = false;
      this.orderLogDescription = '';
    },
    downloadSample() {
      const body = {
        s3Uri:
          'algonder/Documents/30.11.2023 14:55:38Örnek Lokasyon Exceli.xlsx',
      };
      OrderService.downloadFile(body, 'Örnek Lokasyon Exceli', 'xlsx');
    },
    showEquipmentDetail(equipment) {
      this.mapMarkers = [];
      this.selectedEquipment = equipment;
      this.detailLocations = this.selectedEquipment.EquipmentLocations;
      this.detailLocations.map((location) => {
        console.log('Location >> ', location);
        if (!location.Latitude || !location.Longitude) {
          console.log('trigger >> ', location);
          let addressObj = {
            city: location.City,
          };
          this.$geocoder.send(addressObj, (response) => {
            console.log('Geocode Response >> ', response);
            if (response && response.results[0]) {
              this.mapMarkers.push({
                position: {
                  lat: response.results[0].geometry.location.lat,
                  lng: response.results[0].geometry.location.lng,
                },
                ...location,
              });
            } else {
              console.error(
                'Geocode was not successful for the following reason: ' +
                  response.status
              );
            }
          });
        } else {
          this.mapMarkers.push({
            position: {
              lat: parseFloat(location.Latitude.replace(',', '.')),
              lng: parseFloat(location.Longitude.replace(',', '.')),
            },
            ...location,
          });
        }
      });
      this.showCarrierInfo = false;
    },
    closeHistory() {
      this.mapMarkers = [];
      this.carrierInfo.forEach((item) => {
        if (item.LastLatitude && item.LastLongitude) {
          this.mapMarkers.push({
            position: {
              lat: parseFloat(item.LastLatitude.replace(',', '.')),
              lng: parseFloat(item.LastLongitude.replace(',', '.')),
            },
            ...item,
          });
        } else if (item.LastLocation) {
          this.geocodeLocation(item.LastLocation, item);
        }
      });
      console.log('Map markers after close method >> ', this.mapMarkers);
      this.showCarrierInfo = true;
      this.markerModalShow = false;
    },
    async fetchEquipmentTypes() {
      this.$store.state.loadingStatus = true;

      EquipmentTypeService.getEquipmentTypes()
        .then((res) => {
          const equipmentTypes = res.data.Result.map((equipmentType) => ({
            id: equipmentType.Id,
            name: equipmentType.Code,
          }));

          this.equipmentTypes = equipmentTypes;
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    editEquipment(item) {
      this.editEquipmentFlag = true;
      this.isEquipmentOpen = true;
      this.selectedEquipment = item;
      this.equipmentNo = item.EquipmentNo;
      this.sealNo = item.SealNo;
      this.goodsType = item.GoodsType;
      this.containerCount = item.ContainerCount;
      this.loadedKg = item.LoadedKg;
      this.loadedVolume = item.LoadedVolume;
    },
    closeNewEquipmentModal() {
      this.isEquipmentOpen = false;
      this.resetEquipmentFields();
    },
    handleEquipmentOperation() {
      this.$store.state.loadingStatus = true;

      /*
      Updates the selected equipment
      */
      if (this.editEquipmentFlag) {
        const updateEquipment = {
          Id: this.selectedEquipment.Id,
          EquipmentNo: this.equipmentNo,
          SealNo: this.sealNo,
          GoodsType: this.goodsType,
          EquipmentType: this.selectedEquipment.EquipmentType,
          ContainerCount: parseInt(this.containerCount),
          LoadedKg: parseInt(this.loadedKg),
          LoadedVolume: parseInt(this.loadedVolume),
        };
        EquipmentService.updateEquipment(updateEquipment)
          .then((res) => {
            this.getEquipments();
            this.getOrderDetails(this.orderId);
          })
          .catch((err) => {
            window.handleServiceError(err);
          })
          .finally(() => {
            this.isEquipmentOpen = false;
            this.$store.state.loadingStatus = false;
            this.resetEquipmentFields();
          });
      } else {
        /*
      Creates a new equipment
      */
        const addEquipment = {
          OrderId: this.order.Id,
          EquipmentNo: this.equipmentNo,
          SealNo: this.sealNo,
          GoodsType: this.goodsType,
          EquipmentType: '',
          ContainerCount: parseInt(this.containerCount),
          LoadedKg: parseInt(this.loadedKg),
          LoadedVolume: parseInt(this.loadedVolume),
        };
        EquipmentService.createEquipment(addEquipment)
          .then((res) => {
            this.getEquipments();
            this.getOrderDetails(this.orderId);
          })
          .catch((err) => {
            window.handleServiceError(err);
          })
          .finally(() => {
            this.isEquipmentOpen = false;
            this.$store.state.loadingStatus = false;
            this.resetEquipmentFields();
          });
      }
    },
    addLocation(equipment) {
      this.$refs.locationObserver.validate().then((valid) => {
        if (!valid) {
          return;
        }
        const body = {
          EquipmentId: equipment.Id,
          Latitude: this.latitude.toString(),
          Longitude: this.longitude.toString(),
          LocationDate: new Date(this.locationDate + ' ' + this.locationTime),

          Location: this.location,
        };
        this.$store.state.loadingStatus = true;
        this.markerModalShow = false;

        EquipmentService.addEquipmentLocation(body)
          .then((res) => {})
          .catch((err) => {
            window.showError(err);
          })
          .finally(() => {
            this.getEquipments();
            this.closeLocationModal();
            this.showCarrierInfo = true;
            this.isLocationOpen = false;
            this.$store.state.loadingStatus = false;
          });
      });
    },
    downloadDocument(file) {
      const body = {
        s3Uri: file.s3Uri,
      };

      OrderService.downloadFile(body, file.FileName);
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    uploadFile() {
      if (this.selectedDocument) {
        const document = this.selectedDocument;
        if (document === undefined) {
        }
        let formData = new FormData();
        if (document.type == 'application/pdf') {
          formData.append('Files', document);
          formData.append('Type', 'Document');
        }
        this.$store.state.loadingStatus = true;
        FileService.uploadFile(formData)
          .then((uploadFileResponse) => {
            const body = {
              OrderId: this.orderId,
              FileId: uploadFileResponse.data.Result[0].Id,
              Description: this.fileNote,
              FileName: this.fileName,
              OrderFileType: this.selectedFileType,
            };
            OrderService.addOrderFile(body)
              .then((res) => {
                this.getOrderDetails(this.orderId);
                this.clearFileUploadDialog();
              })
              .catch((error) => {})
              .then(() => {
                this.$store.state.loadingStatus = false;
                this.isFileUploadOpen = false;
              });
          })
          .catch((error) => {
            this.$store.state.loadingStatus = false;
            this.isFileUploadOpen = false;
          });
      }
    },

    uploadLoadingFile() {
      if (this.newLoadingFile) {
        const document = this.newLoadingFile;
        if (document === undefined) {
        }
        let formData = new FormData();
        if (document.type == 'application/pdf') {
          formData.append('Files', document);
          formData.append('Type', 'Document');
        }
        this.$store.state.loadingStatus = true;
        FileService.uploadFile(formData)
          .then((uploadFileResponse) => {
            this.loadingFile = uploadFileResponse.data.Result[0];
            const body = {
              OrderId: this.orderId,
              FileId: uploadFileResponse.data.Result[0].Id,
              OrderFileType: 5,
              Description: 'Yükleme Talimatı',
              FileName: 'Yükleme Talimatı',
            };
            OrderService.addOrderFile(body).then(() => {
              this.$store.state.loadingStatus = false;
            });
          })
          .catch((error) => {
            this.$store.state.loadingStatus = false;
          });
      }
    },

    handleFileSelect(event) {
      const selectedFile = event.target.files[0];

      if (selectedFile) {
        const formData = new FormData();
        formData.append('Files', selectedFile);
        formData.append('Type', 'Document');

        FileService.uploadFile(formData)
          .then((res) => {
            const body = {
              OrderId: this.orderId,
              FileId: res.data.Result[0].Id,
              Description: 'Description',
              OrderFileType: 1,
            };
            OrderService.addOrderFile(body).then((res) => {
              this.getOrderDetails(this.orderId);
            });
          })
          .catch((err) => {
            window.handleServiceError(err);
          });
      } else {
      }
    },

    addLocationExcel() {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = '.xlsx';

      fileInput.addEventListener('change', (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
          const formData = new FormData();
          formData.append('File', selectedFile);
          formData.append('OrderId', this.orderId);

          this.$store.state.loadingStatus = true;
          EquipmentService.addEquipmentLocationWithExcel(formData).then(
            (res) => {
              this.showCarrierInfo = true;
              this.getOrderDetails(this.orderId);
              this.$store.state.loadingStatus = false;
            }
          );
        } else {
        }
      });
      fileInput.click();
    },

    checkAllBoxes() {
      let invalidBoxes = [],
        array = this.convertedVolumes;

      array.forEach((element, index) => {
        let item = {
          index: index + 1,
          fieldList: '',
        };
        const entries = Object.entries(element);
        const excludes = ['gTypeNumber'];
        entries.forEach((entry) => {
          if (
            !entry[1] &&
            entry[0] !== 'index' &&
            !excludes.includes(entry[0])
          ) {
            console.log(entry);
            item.fieldList += `${this.$t(`labels.${entry[0]}`)}, `;
          }
        });

        if (item.fieldList.length > 0) {
          invalidBoxes.push(
            this.$t('messages.requiredFields', {
              index: item.index,
              fields: item.fieldList,
            })
          );
        }
      });

      return invalidBoxes;
    },

    async saveShipmentInfo() {
      const volumes = this.convertedVolumes;

      if (
        this.sendSupplier &&
        this.shipment.SumWeight &&
        this.shipment.SumWeight > 0.5 &&
        this.shipment.ExpressPackageType === 2
      ) {
        this.confirmation = {
          message: this.$t('messages.envelopeWeightWarning'),
          onConfirm: null,
          visible: true,
          buttonSettings: {
            no: {
              text: this.$t('buttons.ok'),
            },
          },
        };
        return;
      }

      const productType = volumes[0].productType;
      if (!this.shipment.GoodsType && productType) {
        this.confirmation = {
          message: this.$t('messages.noGoodsTypeWarning'),
          onConfirm: () => {
            this.shipment.GoodsType = productType;
            this.saveShipmentInfo();
          },
          visible: true,
        };
        return;
      }


      if(this.shipment.ExpressPackageType !== 2){
        const checked = this.checkAllBoxes();
        this.invalidFields = checked;

        if (checked.length > 0) {
          this.invalidFieldsModal = true;
        }
      }

      this.mandatoryFileTypes = [];
      let isDraft =
        this.userRole == 'Supplier' ||
        this.userRole == 'PendingSupplier' ||
        this.order.OrderStatus > 0
          ? false
          : !this.sendSupplier;

      if (!this.order.SignStatus && !isDraft) {
        window.showWarning(this.$t('messages.contractNotSigned'));
        return;
      }

      const body = {
        Id: this.shipment.Id,
        TransportType: this.shipment.TransportType,
        ShipmentType: this.shipment.ShipmentType,
        LoadingType: this.shipment.LoadingType,
        ServiceType: this.shipment.ServiceType,
        CustomerCompanyId: this.shipment.CustomerCompanyId,
        CarrierCompanyId: this.shipment.CarrierCompanyId,
        SeaUnloadingPortId: this.shipment.SeaUnloadingPortId,
        SeaLoadingPortId: this.shipment.SeaLoadingPortId,
        AirUnloadingPortId: this.shipment.AirUnloadingPortId,
        AirloadingPortId: this.shipment.AirloadingPortId,
        RailwayloadingPort: this.shipment.RailwayloadingPort,
        RailwayUnloadingPort: this.shipment.RailwayUnloadingPort,
        DestinationCityId: this.shipment.DestinationCityId,
        DestinationSubCompanyId: this.shipment.DestinationSubCompanyId,
        LoadingSubCompanyId: this.shipment.LoadingSubCompanyId,
        DestinationPostalCode: this.shipment.DestinationPostalCode,
        LoadingCityId: this.shipment.LoadingCityId,
        LoadingPostalCode: this.shipment.LoadingPostalCode,
        CarriageType: this.shipment.CarriageType,
        CarriageCount: this.shipment.CarriageCount,
        TruckType: this.shipment.TruckType,
        TruckCount: this.shipment.TruckCount,
        ContainerType: this.shipment.ContainerType,
        ContainerCount: this.shipment.ContainerCount,
        SumCapPieces: parseInt(this.shipment.SumCapPieces),
        SumWeight: this.shipment.SumWeight,
        SumVolume: this.shipment.SumVolume,
        IsDangeroousSubstance: this.shipment.IsDangeroousSubstance,
        DegreeOfDanger: this.shipment.DegreeOfDanger,
        GoodsType: this.shipment.GoodsType,
        IsStockUp: this.shipment.IsStockUp,
        ReferenceNo: this.shipment.ReferenceNo,
        ReferenceCode: this.shipment.ReferenceCode,
        LoadingAddress1: this.shipment.LoadingAddress1,
        LoadingAddress2: this.shipment.LoadingAddress2,
        DestinationAddress1: this.shipment.DestinationAddress1,
        DestinationAddress2: this.shipment.DestinationAddress2,
        PaymentType: this.shipment.PaymentType,
        WantedLoadingDate: this.shipment.WantedLoadingDate
          ? new Date(this.shipment.WantedLoadingDate)
          : null,
        EstimatedLoadingDate: this.shipment.EstimatedLoadingDate
          ? new Date(this.shipment.EstimatedLoadingDate)
          : null,
        LoadingDate: this.shipment.LoadingDate
          ? new Date(this.shipment.LoadingDate)
          : null,
        DepartureDate: this.shipment.DepartureDate
          ? new Date(this.shipment.DepartureDate)
          : null,
        ArrivalDate: this.shipment.ArrivalDate
          ? new Date(this.shipment.ArrivalDate)
          : null,
        DeliveryDate: this.shipment.DeliveryDate
          ? new Date(this.shipment.DeliveryDate)
          : null,
        UpdateOrder: isDraft ? false : true,
        CustomerNote: this.shipment.CustomerNote,
        TaxPayer: this.shipment.TaxPayer,
        PaymetTime: this.shipment.PaymetTime,
        ExpressServiceType: this.shipment.ExpressServiceType,
        ExpressPackageType: this.shipment.ExpressPackageType,
        IOSS: this.shipment.IOSS,
        GoodsPrice: this.shipment.GoodsPrice,
        GoodsPriceCurrency: this.shipment.GoodsPriceCurrency,
        OrderDeliveryType: this.shipment.OrderDeliveryType,
        CargoCompanyName: this.shipment.CargoCompanyName,
        CargoTrackingNumber: this.shipment.CargoTrackingNumber,
        TransportCompanyName: this.shipment.TransportCompanyName,
        TransportPlate: this.shipment.TransportPlate,
        TransportDriverName: this.shipment.TransportDriverName,
        CountryOfManufacture: this.shipment.CountryOfManufacture,
        SubSupplier: this.shipment.SubSupplier,
        SubSupplierPackagingType: this.shipment.SubSupplierPackagingType,
        Expense: this.shipment.Expense,
        ExpenseCurrencyId: this.shipment.ExpenseCurrencyId,
        ProformaOption: this.shipment.ProformaOption,
        PurposeOfShipment: this.shipment.PurposeOfShipment,
        InsuranceType: this.shipment.InsuranceType,
        SignedDelivery: this.shipment.SignedDelivery,

        AdditionalExpense: this.shipment.AdditionalExpense,
        AdditionalExpenseCurrencyId: this.shipment.AdditionalExpenseCurrencyId,
      };

      // this.fileTypes = [
      //   {
      //     Name: this.$t('labels.loadingInstruction'),
      //     Id: 5,
      //   },
      //   {
      //     Name: this.$t('labels.proformaFileType'),
      //     Id: 7,
      //   },
      //   {
      //     Name: this.$t('labels.eArchiveInvoice'),
      //     Id: 9,
      //   },
      //   {
      //     Name: this.$t('labels.etgbForm'),
      //     Id: 11,
      //   },
      //   {
      //     Name: this.$t('labels.eInvoice'),
      //     Id: 12,
      //   },
      //   {
      //     Name: this.$t('labels.otherFileType'),
      //     Id: 3,
      //   },
      // ];
      if (!isDraft) {
        this.$refs.updateOrderObserver.validate().then((valid) => {
          if (valid) {
            console.log('SUB SUPPLIER >> ', this.shipment.SubSupplier);

            //FedEx File Controls
            if (this.shipment.SubSupplier === 1) {
              switch (body.ExpressServiceType) {
                case 0:
                case 1:
                  console.log('CASE YAKALANDI >> ', body.ExpressServiceType);
                  if (
                    !this.orderFiles.some((obj) => obj.OrderFileType == 9) ||
                    (this.shipment.ProformaOption === 0 &&
                      !this.orderFiles.some((obj) => obj.OrderFileType == 7))
                  ) {
                    this.mandatoryFileTypes = this.fileTypes.filter(
                      (obj) => obj.Id == 9 || obj.Id == 7
                    );
                    console.log(
                      'Mandatory file types >> ',
                      this.mandatoryFileTypes
                    );
                    this.orderFileWarningDialog = true;
                    return;
                  }
                  break;
                case 2:
                case 3:
                case 4:
                  console.log('CASE YAKALANDI >> ', body.ExpressServiceType);

                  if (
                    this.shipment.ProformaOption === 0 &&
                    !this.orderFiles.some((obj) => obj.OrderFileType === 7)
                  ) {
                    this.mandatoryFileTypes = this.fileTypes.filter(
                      (obj) => obj.Id == 7
                    );
                    console.log(
                      'Mandatory file types >> ',
                      this.mandatoryFileTypes
                    );
                    this.orderFileWarningDialog = true;
                    return;
                  }
                  break;
              }
            }

            //UPS File Controls
            if (this.shipment.SubSupplier == 2) {
              switch (body.ExpressServiceType) {
                case 0:
                case 1:
                  if (
                    !this.orderFiles.some((obj) => obj.OrderFileType === 9) ||
                    (this.shipment.ProformaOption === 0 &&
                      !this.orderFiles.some(
                        (obj) => obj.OrderFileType === 7
                      )) ||
                    !this.orderFiles.some((obj) => obj.OrderFileType === 11)
                  ) {
                    this.mandatoryFileTypes = this.fileTypes.filter(
                      (obj) => obj.Id == 9 || obj.Id == 7 || obj.Id == 11
                    );
                    console.log(
                      'Mandatory file types >> ',
                      this.mandatoryFileTypes
                    );
                    this.orderFileWarningDialog = true;
                    return;
                  }
                  break;
                case 2:
                case 3:
                case 4:
                  if (
                    this.shipment.ProformaOption === 0 &&
                    !this.orderFiles.some((obj) => obj.OrderFileType === 7)
                  ) {
                    this.mandatoryFileTypes = this.fileTypes.filter(
                      (obj) => obj.Id == 7
                    );
                    console.log(
                      'Mandatory file types >> ',
                      this.mandatoryFileTypes
                    );
                    this.orderFileWarningDialog = true;
                    return;
                  }
                  break;
              }
            }
            this.$store.state.loadingStatus = true;
            ShipmentService.updateShipment(body)
              .then(async (res) => {
                this.getOrderDetails(this.orderId);
                window.showSuccess(
                  this.$t('messages.successfulSave', {
                    value: this.$t('buttons.orderDetail'),
                  })
                );
                if (this.order.OrderStatus == 0 && !isDraft) {
                  this.navToFiles = true;
                }
              })
              .catch((err) => {
                window.handleServiceError(err);
              })
              .finally(() => {
                this.$store.state.loadingStatus = false;
              });
          } else {
            this.scrollToFirstInvalidField();
            window.showWarning(this.$t('labels.fillIn'));
          }
        });
      } else {
        this.draftDialog = true;
      }
    },
    scrollToFirstInvalidField() {
      console.log(this.$refs);

      const invalidField = Object.keys(this.$refs).find((ref) => {
        const formElement = this.$refs[ref]?.[0]?.$refs?.[ref];

        if (
          formElement &&
          formElement.errors &&
          formElement.errors.length > 0
        ) {
          return true;
        }
      });

      if (invalidField) {
        const el = this.$refs[invalidField]?.[0]?.$refs?.[invalidField].$el;
        if (el) {
          const rect = el.getBoundingClientRect();
          const offset = 100; // Adjust this value as needed to control the offset

          window.scrollTo({
            top: rect.top + window.scrollY - offset,
            behavior: 'smooth',
          });
        }
      }
    },
    sendDraft(requestDraft = false) {
      const body = {
        Id: this.shipment.Id,
        TransportType: this.shipment.TransportType,
        ShipmentType: this.shipment.ShipmentType,
        LoadingType: this.shipment.LoadingType,
        ServiceType: this.shipment.ServiceType,
        CustomerCompanyId: this.shipment.CustomerCompanyId,
        CarrierCompanyId: this.shipment.CarrierCompanyId,
        SeaUnloadingPortId: this.shipment.SeaUnloadingPortId,
        SeaLoadingPortId: this.shipment.SeaLoadingPortId,
        AirUnloadingPortId: this.shipment.AirUnloadingPortId,
        AirloadingPortId: this.shipment.AirloadingPortId,
        RailwayloadingPort: this.shipment.RailwayloadingPort,
        RailwayUnloadingPort: this.shipment.RailwayUnloadingPort,
        DestinationCityId: this.shipment.DestinationCityId,
        DestinationSubCompanyId: this.shipment.DestinationSubCompanyId,
        LoadingSubCompanyId: this.shipment.LoadingSubCompanyId,
        DestinationPostalCode: this.shipment.DestinationPostalCode,
        LoadingCityId: this.shipment.LoadingCityId,
        LoadingPostalCode: this.shipment.LoadingPostalCode,
        CarriageType: this.shipment.CarriageType,
        CarriageCount: this.shipment.CarriageCount,
        TruckType: this.shipment.TruckType,
        TruckCount: this.shipment.TruckCount,
        ContainerType: this.shipment.ContainerType,
        ContainerCount: this.shipment.ContainerCount,
        SumCapPieces: parseInt(this.shipment.SumCapPieces),
        SumWeight: this.shipment.SumWeight,
        SumVolume: this.shipment.SumVolume,
        IsDangeroousSubstance: this.shipment.IsDangeroousSubstance,
        DegreeOfDanger: this.shipment.DegreeOfDanger,
        GoodsType: this.shipment.GoodsType,
        IsStockUp: this.shipment.IsStockUp,
        ReferenceNo: this.shipment.ReferenceNo,
        ReferenceCode: this.shipment.ReferenceCode,
        LoadingAddress1: this.shipment.LoadingAddress1,
        LoadingAddress2: this.shipment.LoadingAddress2,
        DestinationAddress1: this.shipment.DestinationAddress1,
        DestinationAddress2: this.shipment.DestinationAddress2,
        PaymentType: this.shipment.PaymentType,
        WantedLoadingDate: this.shipment.WantedLoadingDate
          ? new Date(this.shipment.WantedLoadingDate)
          : null,
        EstimatedLoadingDate: this.shipment.EstimatedLoadingDate
          ? new Date(this.shipment.EstimatedLoadingDate)
          : null,
        LoadingDate: this.shipment.LoadingDate
          ? new Date(this.shipment.LoadingDate)
          : null,
        DepartureDate: this.shipment.DepartureDate
          ? new Date(this.shipment.DepartureDate)
          : null,
        ArrivalDate: this.shipment.ArrivalDate
          ? new Date(this.shipment.ArrivalDate)
          : null,
        DeliveryDate: this.shipment.DeliveryDate
          ? new Date(this.shipment.DeliveryDate)
          : null,
        UpdateOrder: false,
        CustomerNote: this.shipment.CustomerNote,
        TaxPayer: this.shipment.TaxPayer,
        PaymetTime: this.shipment.PaymetTime,
        ExpressServiceType: this.shipment.ExpressServiceType,
        ExpressPackageType: this.shipment.ExpressPackageType,
        IOSS: this.shipment.IOSS,
        GoodsPrice: this.shipment.GoodsPrice,
        GoodsPriceCurrency: this.shipment.GoodsPriceCurrency,
        OrderDeliveryType: this.shipment.OrderDeliveryType,
        CargoCompanyName: this.shipment.CargoCompanyName,
        CargoTrackingNumber: this.shipment.CargoTrackingNumber,
        TransportCompanyName: this.shipment.TransportCompanyName,
        TransportPlate: this.shipment.TransportPlate,
        TransportDriverName: this.shipment.TransportDriverName,

        CountryOfManufacture: this.shipment.CountryOfManufacture,
        SubSupplier: this.shipment.SubSupplier,
        SubSupplierPackagingType: this.shipment.SubSupplierPackagingType,

        Expense: this.shipment.Expense,
        ExpenseCurrencyId: this.shipment.ExpenseCurrencyId,

        ProformaOption: this.shipment.ProformaOption,
        PurposeOfShipment: this.shipment.PurposeOfShipment,
        InsuranceType: this.shipment.InsuranceType,
        SignedDelivery: this.shipment.SignedDelivery,

        AdditionalExpense: this.shipment.AdditionalExpense,
        AdditionalExpenseCurrencyId: this.shipment.AdditionalExpenseCurrencyId,
      };

      this.requestDraft = requestDraft;

      if (!requestDraft) {
        this.$store.state.loadingStatus = true;
      }
      ShipmentService.updateShipment(body)
        .then((res) => {
          if (!this.requestDraft)
            window.showSuccess(
              this.$t('messages.successfulSave', {
                value: this.$t('buttons.orderDetail'),
              })
            );
        })
        .catch(window.showError)
        .finally(() => {
          if (!requestDraft) {
            this.$store.state.loadingStatus = false;
          }

          this.draftDialog = false;
        });
    },
    saveSubCompany() {
      const body = {
        Name: this.form.companyName,
        Address: this.form.companyAddress,
        ContactPhone: this.form.contactPhone,
        CountryId: this.form.countryCode,
        CityId: this.form.city,
        PostalCode: this.form.postalCode,
        StateCode: this.form.stateCode,
        Mail: this.form.email,
        IsHomeAddress: this.isHome,
        AuthorizedPerson: this.form.AuthorizedPerson,
        EoriCode: this.form.EoriCode,
      };

      if (this.editAddressModeModal) {
        body.Id = this.form.Id;
      }

      this.$refs.observer.validate().then((valid) => {
        if (!valid) {
          return;
        }
        this.$store.state.loadingStatus = true;
        const submitFunction = this.editAddressModeModal
          ? CompanyServices.updateCompany
          : CompanyServices.addSubCompany;
        submitFunction(body)
          .then((res) => {
            this.addSubCompany = false;
            this.editAddressModeModal = false;
            this.resetSubCompanyDialog();
            if (this.isSubCompanyLoading) {
              this.loadingCompany = res.data.Result;
              this.updateLoadingSubCompany();
            } else {
              this.destinationCompany = res.data.Result;
              this.updateDestinationSubCompany();
            }

            this.getSubCompanies();
          })
          .catch(window.showError)
          .finally(() => (this.$store.state.loadingStatus = false));
      });
    },
    openInfoWindow(item) {
      if (this.showCarrierInfo) {
        this.selectedEquipment = item;
      }

      this.selectedMarker = item;
      this.markerModalShow = true;

      this.infoWindowPosition = {
        lat: parseFloat(item.LastLatitude?.replace(',', '.')),
        lng: parseFloat(item.LastLongitude?.replace(',', '.')),
      };
    },
    getMarkerIcon(item) {
      let locationImagePath = '';
      let size = item.isLast
        ? { width: 40, height: 40, f: 'px', b: 'px' }
        : { width: 30, height: 30, f: 'px', b: 'px' };

      if (this.order.Shipment.ShipmentType === 1) {
        locationImagePath = !item.isLast
          ? require('../../public/img/plane.png')
          : require('../../public/img/planeOld.png');
      } else if (this.order.Shipment.ShipmentType === 2) {
        locationImagePath = !item.isLast
          ? require('../../public/img/land.png')
          : require('../../public/img/landOld.png');
      } else if (this.order.Shipment.ShipmentType === 3) {
        locationImagePath = !item.isLast
          ? require('../../public/img/ship.png')
          : require('../../public/img/shipOld.png');
      } else if (this.order.Shipment.ShipmentType === 4) {
        locationImagePath = !item.isLast
          ? require('../../public/img/express.png')
          : require('../../public/img/expressOld.png');
      } else if (this.order.Shipment.ShipmentType === 5) {
        locationImagePath = !item.isLast
          ? require('../../public/img/train.png')
          : require('../../public/img/trainOld.png');
      }

      return {
        url: locationImagePath,
        size: size,
        scaledSize: size,
      };
    },
    clusterClickHandler(event) {},

    getEquipments() {
      this.mapMarkers = [];
      this.$store.state.loadingStatus = true;
      EquipmentService.getEquipments(this.orderId, this.page, this.itemsPerPage)
        .then((res) => {
          this.carrierInfo = res.data.Result;
          this.totalItems = res.data.TotalItems;
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(() => {
          this.carrierInfo.forEach((item) => {
            if (item.LastLatitude && item.LastLongitude) {
              this.mapMarkers.push({
                position: {
                  lat: parseFloat(item.LastLatitude.replace(',', '.')),
                  lng: parseFloat(item.LastLongitude.replace(',', '.')),
                },
                ...item,
              });
            } else if (item.LastLocation) {
              this.geocodeLocation(item.LastLocation, item);
            }
          });
        });
    },

    geocodeLocation(location, item) {
      console.log('Geocode Location >> ', location);
      console.log('Geocode Item >> ', item);
      const addressObj = {
        city: location,
      };

      this.$geocoder.send(addressObj, (response) => {
        console.log('Geocode Response >> ', response);
        if (response && response.results[0]) {
          this.mapMarkers.push({
            position: {
              lat: response.results[0].geometry.location.lat,
              lng: response.results[0].geometry.location.lng,
            },
            ...item,
          });
        } else {
          console.error(
            'Geocode was not successful for the following reason: ' +
              response.status
          );
        }
      });
    },

    getEquipmentLocation(equipmentId) {
      EquipmentService.getEquipmentLocation(equipmentId)
        .then((res) => {})
        .catch((err) => {
          window.handleServiceError(err);
        });
    },

    getSubCompanies() {
      CompanyServices.getSubCompanies()
        .then((res) => {
          this.subCompanies = res.data.Result;

          this.subCompanies.forEach((subCompany) => {
            subCompany.displayName = `${subCompany.Name} - ${subCompany.City}/${subCompany.CountryCode}`;
            if (subCompany.Id === this.shipment.LoadingSubCompanyId) {
              this.loadingCompany = subCompany;
              this.updateLoadingSubCompany();
            }
            if (subCompany.Id === this.shipment.DestinationSubCompanyId) {
              this.destinationCompany = subCompany;
              this.updateDestinationSubCompany();
            }
          });
        })
        .catch(window.showError);
    },

    getShipmentDetails() {
      if (!this.order.Shipment) return;
      this.loadingCompany = null;
      this.destinationCompany = null;
      this.shipment = this.order.Shipment;

      const { Profit, PriceCurrency } = this.shipment;
      if (Profit && PriceCurrency) {
        this.shipment.totalProfit = `${Profit} ${PriceCurrency}`;
      }

      this.temporaryShipment = Object.assign({}, this.order.Shipment);

      if (this.shipment.SubSupplier) {
        this.getPackagingTypes();
        this.selectedLogisticsCompany =
          this.shipment.SubSupplier === 1 ? 'FedEx' : 'UPS';
      }

      if (!this.isSupplier && !this.$store.state.PortalUserId) {
        this.shipment.PaymentTime = 1;
        this.shipment.PaymentType = 2;
      }

      switch (this.shipment.ShipmentType) {
        case 3:
          this.calculateParameter = 1000;
          break;
        case 1:
          this.calculateParameter = 166.67;
          break;
        case 2:
          this.calculateParameter = 333;
          break;
      }

      this.shipment.Price =
        this.shipment.Price + ' ' + this.shipment.PriceCurrency;
      this.shipment.HazmatCodeDescription =
        this.shipment.HazmatCodeDescription == ''
          ? 'Tehlikeli Madde İçermiyor'
          : this.shipment.HazmatCodeDescription;

      this.shipmentBoxes = this.shipment.ShipmentBoxes;

      console.log('Box Checked => ', this.checkAllBoxes());

      this.getLoadingPorts();
    },

    getOrderDetails(id) {
      this.mandatoryFileTypes = [];
      if (!this.requestDraft) this.$store.state.loadingStatus = true;

      OrderService.getOrderById(id)
        .then((res) => {
          this.order = res.data.Result;
          this.order.IsInvoiceIssued = this.$t(
            `titles.${
              this.order.IsInvoiceIssued ? 'invoiceIssued' : 'invoiceNotIssued'
            }`
          );
          this.transportType = this.order.Shipment.TransportType;
          if (this.isSupplier && this.transportType === 2 && !this.slots[6]) {
            this.slots.push({
              name: 'subsupplier',
              title: this.$t('titles.subsupplier'),
            });
          }

          this.order.orderDate = convertToLocal(res.data.Result.CreatedOnUtc);
          this.orderStatus.name = this.orderStatuses[this.order.OrderStatus]
            ? this.orderStatuses[this.order.OrderStatus]
            : this.$t('labels.labelsOrderStatuses.other');

          this.fileStatus.name =
            this.fileStatuses[this.order.SupplierOrderStatus];

          this.shipmentId = res.data.Result.ShipmentId;
          this.paymentDate = res.data.Result.PaymentDate;
          this.orderFiles = res.data.Result.OrderFiles;
          this.carriageContract =
            this.orderFiles.find((x) => x.OrderFileType == 4) || {};
          this.loadingFile =
            this.orderFiles.find((x) => x.OrderFileType == 5) || null;

          const orderLogs = res.data.Result.OrderLogs.map(
            (orderLog, index) => ({
              date: convertToLocal(orderLog.CreatedOnUtc),
              hour: convertToLocalTime(orderLog.CreatedOnUtc),
              code: orderLog.StatusCode,
              location: orderLog.Location,
              status: orderLog.Description,
            })
          );
          this.orderLogs = orderLogs;
          this.isPending = this.order.SignPending;
          this.estimateDateLimit = this.order.Shipment.EstimatedLoadingDate
            ? new Date(this.order.Shipment.EstimatedLoadingDate)
            : null;
          this.order.SignStatus = this.order.SignPending
            ? this.order.SignPending
            : this.order.SignStatus;

          this.contractModal =
            this.order.OrderStatus == 9
              ? false
              : this.order.SignStatus
              ? false
              : true;

          if (!this.order.SignStatus && this.order.ContractId) {
            this.getContractDetail(this.order.ContractId);
          }
          this.statusControl();
          this.getShipmentDetails();
          this.getSubCompanies();
          this.nameSetter();
        })
        .catch((error) => {
          window.showError(error);
          //this.$router.go(-1);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getContractDetail(id) {
      this.$store.state.loadingStatus = true;
      if (!this.contractContent) {
        ContractService.getById(id)
          .then((res) => {
            this.contractContent = res.data.Result.HtmlVersion;
            this.$store.state.loadingStatus = false;
            this.contractModal = true;
          })
          .catch((err) => {
            this.$store.state.loadingStatus = false;
            window.showError(err);
          });
      } else {
        this.$store.state.loadingStatus = false;
        this.contractModal = true;
      }
    },
    handleTabChange(index) {
      if (this.activeTab === index) return;

      if (index == 4) {
        this.fetchEquipmentTypes();
        this.getLogs();
        this.getEquipments();
      }

      this.activeTab = index;
      this.sendDraft(true);
    },

    selectedFileTypeChanged() {
      let name = this.fileTypes.filter((f) => f.Id === this.selectedFileType)[0]
        .Name;
      this.fileName = name;
    },

    //Page setup
    setup() {
      this.mandatoryFileTypes = [];
      this.orderStatuses = [
        this.$t('labels.labelsOrderStatuses.detail'),
        this.$t('labels.labelsOrderStatuses.supplier'),
        this.$t('labels.labelsOrderStatuses.payment'),
        this.$t('labels.labelsOrderStatuses.approved'),
        this.$t('labels.labelsOrderStatuses.loaded'),
        this.$t('labels.labelsOrderStatuses.shipping'),
        this.$t('labels.labelsOrderStatuses.inDestination'),
        this.$t('labels.labelsOrderStatuses.delivered'),
        this.$t('labels.labelsOrderStatuses.archived'),
        this.$t('labels.labelsOrderStatuses.canceled'),
      ];
      this.fileStatuses = [
        this.$t('labels.fileStatuses.notPlanned'),
        this.$t('labels.fileStatuses.pickupPlanned'),
        this.$t('labels.fileStatuses.inWarehouse'),
        this.$t('labels.fileStatuses.loading'),
        this.$t('labels.fileStatuses.invoiced'),
        this.$t('labels.fileStatuses.atDestination'),
        this.$t('labels.fileStatuses.delivered'),
      ];

      this.paymentTypes = [
        {
          name: this.$t('labels.transfer'),
          value: 1,
        },
        {
          name: this.$t('labels.creditCardOrder'),
          value: 2,
        },
      ];

      this.packageTypes = [
        // {
        //   name: this.$t('labels.document'),
        //   value: 0,
        // },
        {
          name: this.$t('labels.package'),
          value: 1,
        },
        {
          name: this.$t('labels.envelope'),
          value: 2,
        },
      ];

      this.expressServiceTypes = [
        {
          name: this.$t('labels.ETGBExpress'),
          value: 0,
        },
        {
          name: this.$t('labels.ETGBEconomy'),
          value: 1,
        },
        {
          name: this.$t('labels.ExitWithDeclaration'),
          value: 2,
        },
        {
          name: this.$t('labels.Economic'),
          value: 3,
        },
        {
          name: this.$t('labels.sample'),
          value: 4,
        },
      ];

      this.fileTypes = [
        {
          Name: this.$t('labels.loadingInstruction'),
          Id: 5,
        },
        {
          Name: this.$t('labels.proformaFileType'),
          Id: 7,
        },
        {
          Name: this.$t('labels.eArchiveInvoice'),
          Id: 9,
        },
        {
          Name: this.$t('labels.etgbForm'),
          Id: 11,
        },
        {
          Name: this.$t('labels.eInvoice'),
          Id: 12,
        },
        {
          Name: this.$t('labels.otherFileType'),
          Id: 3,
        },
      ];

      this.taxPayers = [
        {
          name: this.$t('labels.dap'),
          value: 0,
        },
        {
          name: this.$t('labels.ddp'),
          value: 1,
        },
      ];

      this.buyerTypes = [
        {
          name: this.$t('labels.upFront'),
          value: 0,
        },
        {
          name: this.$t('labels.deferredPayment'),
          value: 1,
        },
      ];

      this.orderDeliveryTypes = [
        {
          name: this.$t('labels.selfSendCargo'),
          value: 0,
        },
        {
          name: this.$t('labels.selfSendHaul'),
          value: 1,
        },
        {
          name: this.$t('labels.receiveFromDoor'),
          value: 2,
        },
      ];

      (this.volumeHeaders = [
        {
          text: this.$t('labels.numOfContainers'),
          value: 'CapPieces',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('titles.totalKilo') + ' (kg)',
          value: 'Weight',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('titles.totalVolume') + ' (m3)',
          value: 'totalVolume',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.width'),
          value: 'Width',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.length'),
          value: 'Length',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.height'),
          value: 'Height',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.productType'),
          value: 'CarriageType',
          sortable: false,
          groupable: false,
          align: 'center',
        },
      ]),
        (this.pagination = {
          totalItems: 100,
          rowsPerPage: 15,
          page: 1,
        });
    },
    onBlocked() {
      this.$router.push('Orders');
    },
    getDateTime(dateString) {
      const date = new Date(dateString);

      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      const formattedDateTime = `${day}.${month}.${year} - ${hours}.${minutes}`;

      return formattedDateTime;
    },
  },
};
</script>

<style scoped>
.theme--light.v-messages {
  color: red;
}
.elevated-card {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  background-color: white;
}
.text-style {
  @media only screen and (min-width: 600px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 1281px) {
    font-size: 16px;
  }
}
</style>
