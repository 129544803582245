<template>
  <div>
    <!-- Page content -->
    <v-tabs v-model="localActiveTab" class="custom-tabs">
      <v-tab
        v-for="(slot, index) in slots"
        @click="changeTab(index)"
        :key="index"
        :style="{
          'border-top-left-radius': index === 0 ? '10px' : '0',
          'border-top-right-radius': index === slots.length - 1 ? '10px' : '0',

          'border-bottom':
            localActiveTab !== index ? '2px solid lightgray' : 'none',

          'border-top-width': index === localActiveTab ? '2px' : '1px',

          'border-right-width':
            index === localActiveTab && index === slots.length - 1
              ? '2px'
              : index === localActiveTab
              ? '1px'
              : index === slots.length - 1
              ? '1px'
              : '0px',

          'border-left-width': index === 0 ? '1px' : '1px',
          'border-left-width': index === localActiveTab ? '2px' : '1px',

          'font-weight': index === localActiveTab ? 'black' : 'normal',
          'font-size': index === localActiveTab ? '14px' : '12px',
        }"
        style="
          border-radius: 0px;
          background-color: #fff;
          border-width: 1px;
          border-bottom-width: 0px;
          border-color: lightgray;
          border-style: solid;
        "
      >
        {{ slot.title }}

        <v-badge
          v-if="slot.unSeenCount"
          style="cursor: pointer; margin-bottom: 45px !important"
          overlap
          :content="slot.unSeenCount"
          left
          offset-x="24"
          offset-y="8"
          color="#1d2e5b"
        ></v-badge>
      </v-tab>
    </v-tabs>
    <!-- eslint-disable -->
    <v-card style="border-radius: 0">
      <slot
        v-if="localActiveTab === index"
        v-for="(slot, index, key) in slots"
        :name="slot.name"
      >
      </slot>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    slots: {
      type: Array,
      required: true,
    },
    changeIndexFunction: {
      type: Function,
      default: () => {},
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      localActiveTab: this.activeTab,
    };
  },
  watch: {
    activeTab(newVal) {
      this.localActiveTab = newVal;
    },
    localActiveTab(newVal) {
      this.$emit('update:activeTab', newVal);
    },
  },
  methods: {
    changeTab(index) {
      this.localActiveTab = index;
      this.changeIndexFunction(index);
    },
  },
};
</script>

<style>
.custom-tabs .v-tabs-slider {
  display: none;
}
.v-slide-group__prev {
  display: flex !important;
}

@media (max-width: 600px) {
  .v-slide-group__prev {
    display: none !important;
  }
}
</style>
