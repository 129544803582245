// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'fast-shipping-search';

export default {
  /**
   * Get supplier's search history parameters.
   * @param {number} page
   * @param {number} pageSize
   * @returns
   */
  getFastShippingSearch(page = 1, pageSize = 10) {
    return axios.get(`${RESOURCE_NAME}/get`, {
      params: {
        page,
        pageSize,
      },
    });
  },

  /**
   * Delete fast shipping search.
   * @param {string} id
   * @returns {Promise<any>}
   */
  deleteFastShippingSearch(id) {
    return axios.delete(`${RESOURCE_NAME}/delete`, {
      params: {
        Id: id,
      },
    });
  },

  /**
   * Get fast shipping search.
   * @param {string} id
   * @returns {Promise<any>}
   */
  getFastShippingSearchById(id) {
    return axios.get(`${RESOURCE_NAME}/getById`, {
      params: {
        Id: id,
      },
    });
  },

  /**
   * Update fast shipping search.
   * @param {*} body
   * @returns {Promise<any>}
   */
  updateFastShippingSearch(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },

   /**
   * Update fast shipping search.
   * @param {*} body
   * @returns {Promise<any>}
   */
   archiveFastShipping(body) {
    return axios.put(`${RESOURCE_NAME}/archive`, body);
  },

  /**
   * Get searces from service.
   * @param {any} body
   * @returns {Promise<any>}
   */
  getSearches(body) {
    return axios.post(`price-search/Get`, body);
  },

  /**
   * Search again for supplier's offer price.
   * @param {number} page
   * @param {number} pageSize
   * @param {string} fastShippingSearchId
   * @returns {Promise<any>}
   */
  searchAgain(page = 1, pageSize = 10, fastShippingSearchId = '') {
    return axios.post(`price-search/GetByFastShippingId`, {
      page: page,
      pageSize: pageSize,
      FastShippingSearchId: fastShippingSearchId,
    });
  },
};
