import axios from '../javascript/custom-axios';

export default {
  /**
   * Get equipment data based on OrderId.
   * @param {string} orderId - The OrderId to filter equipment data.
   * @returns {Promise<any>} - A Promise that resolves with the response data.
   */
  getEquipments(orderId, page, pageSize) {
    return axios.get(`equipment/list`, {
      params: { OrderId: orderId, page: page, pageSize: pageSize },
    });
  },

  createEquipment(body) {
    return axios.post(`equipment/create`, body);
  },

  addEquipmentLocation(body) {
    return axios.post(`equipment/location/create`, body);
  },
  addEquipmentLocationWithExcel(body) {
    return axios.post(`equipment/location/addWithExcel`, body);
  },
  getEquipmentLocation(equipmentId) {
    return axios.get(`equipment/location/list`, {
      params: { EquipmentId: equipmentId },
    });
  },

  updateEquipment(body) {
    return axios.put(`equipment/update`, body);
  },
};
