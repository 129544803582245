// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
const RESOURCE_NAME = '/order';
const RESOURCE_NAME2 = '/country';
const RESOURCE_NAME3 = '/orderChecklist';
const RESOURCE_NAME4 = '/shipment_files';

export default {
  createETGB(body) {
    return axios.post(`${RESOURCE_NAME}/create-etgb`, body);
  },
  getOrderStatusLogs(OrderId) {
    return axios.get(`${RESOURCE_NAME}/GetOrderStatusLogs`, {
      params: { OrderId },
    });
  },
  getOrderStatusLogsSupplier(OrderId) {
    return axios.get(`${RESOURCE_NAME}/GetSupplierOrderStatusLogs`, {
      params: { OrderId },
    });
  },
  downloadOrders(
    startDate,
    endDate,
    orderStatus,
    supplierOrderStatus,
    transportType,
    from,
    to,
    hasBoxes,
    search,
    trackingNo,
    refNo
  ) {
    return axios
      .get(`${RESOURCE_NAME}/downloadOrders`, {
        responseType: 'blob',
        params: {
          StartDate: startDate,
          EndDate: endDate,
          OrderStatus: orderStatus,
          SupplierOrderStatus: supplierOrderStatus,
          TransportType: transportType,
          From: from,
          To: to,
          HasBoxes: hasBoxes,
          CompanyName: search,
          TrackingNo: trackingNo,
          RefNo: refNo,
        },
      })
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `WarehouseData.xlsx`);
        link.click();
      });
  },

  /**
   * Get Orders By Filter.
   * @param {{ Page: number, PageSize: number }} params 
   * @returns 
   */
  getOrders({ Page = 1, PageSize = 15, ...params }) {    
    return axios.get(`${RESOURCE_NAME}/getList`, {
      params:{
        Page: Page || 1,
        PageSize: PageSize || 15,
        ...params
      }
    });
  },

  getWarehouseOrders(
    PageSize = 15,
    Page = 1,
    startDate,
    endDate,
    orderStatus,
    additionalCostType,
    transportType,
    from,
    to,
    hasBoxes,
    search,
    trackingNo,
    refNo
  ) {
    return axios.get(`${RESOURCE_NAME}/getListForWarehouse`, {
      params: {
        PageSize,
        Page,
        StartDate: startDate,
        EndDate: endDate,
        WarehouseOrderStatus: orderStatus,
        TransportType: transportType,
        From: from,
        To: to,
        HasBoxes: hasBoxes,
        CompanyName: search,
        TrackingNo: trackingNo,
        RefNo: refNo,
        AdditionalCostType: additionalCostType,
      },
    });
  },

  updateWarehouseDownloadStatus(body) {
    return axios.put(`${RESOURCE_NAME4}/updateWarehouseDownloadStatus`, body);
  },

  getListForPayment(
    PaymentStatus = 1,
    PaymentType = 1,
    IsAdminApprovedPayment = false,
    Page = 1,
    PageSize = 15
  ) {
    return axios.get(`${RESOURCE_NAME}/getListForPayment`, {
      params: {
        PaymentStatus,
        PaymentType,
        IsAdminApprovedPayment,
        Page,
        PageSize,
      },
    });
  },

  getCountries() {
    return axios.get(`${RESOURCE_NAME2}/getCountries`);
  },
  getOrderById(id) {
    return axios.get(`${RESOURCE_NAME}/getById`, { params: { Id: id } });
  },
  getOrderDetail(orderId) {
    return axios.get(`orderProductDetail/getByOrderId`, {
      params: { OrderId: orderId },
    });
  },
  updateOrderStatus(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },
  archiveOrder(body) {
    return axios.put(`${RESOURCE_NAME}/archive`, body);
  },
  createOrderShipment(body) {
    return axios.post(`orderShipment/add`, body);
  },
  createOrder(body) {
    return axios.post(`${RESOURCE_NAME}/create`, body);
  },
  updateOrderShipment(body) {
    return axios.put(`orderShipment/update`, body);
  },
  globalSearch(search) {
    return axios.get(`product/globalSearch`, {
      params: { PageSize: 15, Page: 1, search: search },
    });
  },
  addOrderFile(body) {
    return axios.post(`shipment_files/add`, body);
  },

  async downloadFile(body, fileName, extension = 'pdf') {
    const response = await axios.post(`file/download`, body, {
      responseType: 'blob',
    });
    const url_2 = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url_2;
    link.setAttribute('download', `${fileName}.${extension}`);
    link.click();
  },

  addOrderLog(body) {
    return axios.post(`orderLog/add`, body);
  },

  cancelOrder(body) {
    return axios.put(`${RESOURCE_NAME}/cancel`, body);
  },

  updatePaymentStatus(body) {
    return axios.put(`${RESOURCE_NAME}/updatePayment`, body);
  },

  async downloadTag(id, fileName) {
    const response = await axios.post(`shipment_files/download/${id}`, null, {
      responseType: 'blob',
    });
    const url_2 = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url_2;
    link.setAttribute('download', `${fileName}.pdf`);
    link.click();
    // try {
    //   const response = await axios.post(`shipment_files/download/${id}`, {
    //     responseType: 'blob',
    //   });

    //   const fileName = id + '.pdf';

    //   const blob = new Blob([response.data]);

    //   const link = document.createElement('a');
    //   link.href = window.URL.createObjectURL(blob);
    //   link.download = fileName;

    //   link.click();
    // } catch (error) {
    //   console.error('Error downloading the file', error);
    // }
  },

  /**
   * Respond the payment which is successful.
   * @param {number} id
   * @returns {Promise<any>}
   */
  respondThePayment(id) {
    return axios.put(`${RESOURCE_NAME}/payment-update-cc`, {
      paymentId: id,
    });
  },

  getSignStatus(orderId) {
    return axios.get(`${RESOURCE_NAME}/get-sign-status`, {
      params: { OrderId: orderId },
    });
  },
  getOrderChecklist(orderId) {
    return axios.get(`${RESOURCE_NAME3}/get`, {
      params: { OrderId: orderId },
    });
  },

  checkboxToggle(body) {
    return axios.put(`${RESOURCE_NAME3}/update`, body);
  },

  /**
   * Get list for accounting.
   * @param {*} params
   * @returns {Promise}
   */
  getListForAccounting(params) {
    return axios.get(`${RESOURCE_NAME}/getListForAccounting`, { params });
  },
  getListForAdmin(
    PageSize = 15,
    Page = 1,
    startDate,
    endDate,
    orderStatus,
    supplierOrderStatus,
    transportType,
    from,
    to,
    hasBoxes,
    search,
    trackingNo,
    refNo
  ) {
    return axios.get(`${RESOURCE_NAME}/getListForAdmin`, {
      params: {
        PageSize,
        Page,
        StartDate: startDate,
        EndDate: endDate,
        OrderStatus: orderStatus,
        SupplierOrderStatus: supplierOrderStatus,
        TransportType: transportType,
        From: from,
        To: to,
        HasBoxes: hasBoxes,
        CompanyName: search,
        TrackingNo: trackingNo,
        RefNo: refNo,
      },
    });
  },

  /**
   * Get file list.
   * @param {string} id
   * @returns {Promise}
   */
  getFileList(id) {
    return axios.get(`${RESOURCE_NAME}/getOrderFilesForWarehouse?Id=${id}`);
  },
};
