<template>
  <v-card elevation="1" class="mt-0 py-lg-4" style="margin: 0 !important">
    <ConfirmationModal
      v-model="confirmationModal"
      :title="$t('labels.warning')"
      :message="$t('messages.changeTheExcelFile')"
      :confirm="finishExcelFileOperation"
    />
    <v-card-title style="color: var(--darkBlue)">
      <v-row>
        <v-col
          class="text-lg-left text-center d-flex align-center"
          cols="8"
          sm="10"
          md="10"
          lg="10"
          xl="10"
        >
          <v-icon
            large
            style="color: #0177d4 !important; margin-right: 5px"
            id="menu-activator"
            @click="goBack"
          >
            mdi-arrow-left
          </v-icon>
          <h4>{{ $t('buttons.backNavigate') }}</h4>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <v-card>
      <v-card-text style="padding: 0px; margin: 0px">
        <v-row class="px-4 pt-4">
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="6"
            xl="6"
            class="d-flex"
            style="padding: 0px"
          >
            <FormElement
              key="createdAt"
              :vertical="true"
              v-model="item.CreatedAt"
              :validationLabel="$t('title.tableHeaderCreateDate')"
              :topLabel="true"
              :labelShown="false"
              :cols="12"
              :lg="12"
              :xl="12"
              :disabled="true"
              :formType="{
                name: 'text',
              }"
              :placeholder="$t('title.tableHeaderCreateDate')"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="6"
            xl="6"
            class="d-flex"
            style="padding: 0px"
          >
            <FormElement
              key="logisticTypeName"
              :vertical="true"
              v-model="logisticTypeName"
              :validationLabel="$t('titles.subsupplier')"
              :topLabel="true"
              :labelShown="false"
              :cols="12"
              :lg="12"
              :xl="12"
              :disabled="true"
              :formType="{
                name: 'text',
              }"
              :placeholder="$t('titles.subsupplier')"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div style="position: relative; width: 100%">
      <div
        v-if="
          ($vuetify.breakpoint.xl ||
            $vuetify.breakpoint.lg ||
            $vuetify.breakpoint.md) &&
          isSupplier
        "
        class="d-flex mt-6 px-4"
        style="position: absolute; right: 0; top: 0; z-index: 1"
      >
        <v-btn
          style="border-radius: 12px !important"
          elevation="0"
          color="primary"
          class="button"
          dark
          :href="item.FileUrl"
          depressed
          download
          ><v-icon left> mdi-download </v-icon
          >{{ $t('buttons.downloadFile') }}</v-btn
        >
        <div class="ml-2">
          <v-btn
            style="border-radius: 12px !important"
            elevation="0"
            color="primary"
            class="button"
            dark
            depressed
            @click="onButtonClick"
          >
            <v-icon left> mdi-upload </v-icon>
            {{ $t('buttons.changeExcelFile') }}
          </v-btn>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="onFileChanged"
          />
        </div>
      </div>
      <TabScreen :slots="slots" class="px-4 pt-4">
        <template v-slot:outOfArea>
          <div
            v-if="outOfAreaData"
            style="
              border-radius: 0;
              border-style: solid;
              border-bottom-width: 1px;
              border-left-width: 2px;
              border-right-width: 2px;
              border-top-width: 0px;
              border-color: lightgray;
            "
          >
            <v-data-table
              :items="outOfAreaData"
              :item-key="'CoutryName'"
              @update:sort-desc="sort"
              :no-data-text="$t('messages.noData')"
              hide-default-footer
              :headers="[
                {
                  text: $t('labels.country'),
                  value: 'CoutryName',
                  sortable: true,
                  groupable: false,
                  align: 'center',
                },
                {
                  text: $t('labels.minZipCode'),
                  value: 'LowPostCode',
                  sortable: true,
                  groupable: false,
                  align: 'center',
                },
                {
                  text: $t('labels.maxZipCode'),
                  value: 'HighPostCode',
                  sortable: true,
                  groupable: false,
                  align: 'center',
                },
                {
                  text: $t('labels.pricePerKg'),
                  value: 'PricePerKg',
                  sortable: true,
                  groupable: false,
                  align: 'center',
                },
                {
                  text: $t('labels.minPrice'),
                  value: 'MinPrice',
                  sortable: true,
                  groupable: false,
                  align: 'center',
                },
              ]"
              :loading="$store.state.loadingStatus"
              :options="{
                itemsPerPage: itemsPerPageOutOfArea,
                page: pageOutOfArea,
                sortBy: ['CoutryName', 'Price'],
                sortDesc: [sortDesc],
              }"
            >
              <template v-slot:footer>
                <div class="d-flex justify-center mt-8">
                  <v-row>
                    <v-col cols="0" sm="1" md="1" lg="1" xl="1"> </v-col>
                    <v-col
                      cols="12"
                      sm="8"
                      md="8"
                      lg="9"
                      xl="9"
                      class="mx-auto"
                    >
                      <v-pagination
                        class="my-4"
                        @input="onPageChangeOutOfArea"
                        :value="pageOutOfArea"
                        :length="pageLengthOutOfArea"
                        circle
                      ></v-pagination>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      md="3"
                      lg="2"
                      xl="2"
                      class="ml-md-auto d-flex align-center pr-sm-6 px-6 px-sm-0 pb-6 pb-sm-0"
                    >
                      <v-select
                        v-model="itemsPerPageOutOfArea"
                        :items="[5, 10, 15, 20, 50, 100, 1000]"
                        :label="$t('labels.selectItemsPerPage')"
                        item-value="value"
                        item-text="name"
                        outlined
                        dense
                        hide-details
                        @change="changeItemsPerPageOutOfArea"
                        rounded
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </div>
              </template>
              <template v-slot:no-data>
                <div class="my-10 d-flex flex-column align-center">
                  <h4>{{ $t('messages.noSearch') }}</h4>
                  <v-btn
                    style="color: white; background-color: var(--darkBlue)"
                    class="button my-2"
                    @click="showCreateOffer = true"
                  >
                    {{ $t('buttons.search') }}
                    <v-icon dark right> mdi-magnify </v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </div>
          <div v-else-if="isSupplier" class="mt-4">
            <input
              ref="outOfAreaUploader"
              class="d-none"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              @change="onFileChangedOutOfArea"
            />
            <div class="d-flex justify-center">
              <v-btn
                style="border-radius: 12px !important"
                elevation="0"
                color="primary"
                class="button"
                dark
                depressed
                @click="addNewOutOfArea"
              >
                <v-icon left> mdi-upload </v-icon>
                {{ $t('labels.addNewFile') }}
              </v-btn>
            </div>
          </div>
        </template>
      </TabScreen>
    </div>
  </v-card>
</template>
<script>
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';
import TabScreen from '../components/TabScreen.vue';
import ConfirmationModal from '../components/General/ConfirmationModal.vue';
import ExpressCargoServices from '../services/ExpressCargoServices';
import { logisticsTypes } from '../utils/constants';

export default {
  data() {
    return {
      item: {},
      slots: [],
      outOfAreaData: null,
      logisticsTypes,
      logisticTypeName: '',
      isSupplier: false,
      itemsPerPageOutOfArea: 10,

      itemsPerPage: 10,
      pageOutOfArea: 1,
      itemsPerPageOutOfArea: 10,
      sortDesc: null,
      confirmationModal: false,
      file: null,
    };
  },
  components: { FormElement, TabScreen, ConfirmationModal },
  mounted() {
    this.isSupplier =
      this.$store.state.Type === 'Supplier' ||
      this.$store.state.Type === 'PendingSupplier';

    this.item = this.$route.params.item;
    if (!this.item) this.$router.push('out-of-area-entry');
    else {
      this.outOfAreaData = this.item.OutOfAreaPrices;
      this.logisticTypeName = logisticsTypes.find(
        (x) => x.Value === this.item.LogisticType
      ).Name;
      this.slots = [
        {
          name: 'outOfArea',
          title: this.$t('labels.outOfArea'),
          unSeenCount: 0,
        },
      ];

      this.setup();
    }
  },
  computed: {
    pageLengthOutOfArea() {
      return Math.ceil(this.outOfAreaData.length / this.itemsPerPageOutOfArea);
    },
  },
  methods: {
    async setup() {},

    goBack() {
      if (this.$router.getRoutes().length === 0) {
        this.$router.push({ name: 'outOfAreaEntry' });
      } else {
        this.$router.go(-1);
      }
    },

    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.file = e.target.files[0];
      this.confirmationModal = true;
    },
    finishExcelFileOperation() {
      let formData = new FormData();

      formData.append('File', this.file);
      formData.append('LogisticType', this.item.LogisticType);
      formData.append('OutOfAreaExcelId', this.item.Id);

      console.log('File >> ', this.file);
      console.log('LogisticType >> ', this.item.LogisticType);
      console.log('OutOfAreaExcelId >> ', this.item.Id);

      console.log(formData);

      this.$store.state.loadingStatus = true;
      ExpressCargoServices.updateOutOfArea(formData)
        .then((response) => {
          window.showSuccess(this.$t('messages.changeFileSuccessful'));
          this.$router.push({ name: 'outOfAreaEntry' });
        })
        .catch(this.onServiceError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    getFormattedDate(date) {
      return formatDate(date, '/');
    },
    sort(item) {
      this.sortDesc = !!item;
    },
    onPageChangeOutOfArea(newPage) {
      this.pageOutOfArea = newPage;
    },
    changeItemsPerPageOutOfArea(newValue) {
      this.pageOutOfArea = 1;
      this.itemsPerPageOutOfArea = newValue;
    },
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },
  },
};
</script>
<style>
.container {
  margin-top: 38px;
}

.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
}
</style>
