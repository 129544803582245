var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticStyle:{"padding-top":"60px","max-width":"240px"},attrs:{"permanent":"","absolute":"","color":"#1a305c"}},[_c('v-list',{attrs:{"nav":"","dense":"","expand":""}},[_c('v-list-item-group',[(_vm.$store.getters.isAuthenticated)?_c('v-list-item',{staticStyle:{"background-color":"#314a7e !important"},on:{"click":function($event){return _vm.navigateHome()}}},[_c('v-list-item-avatar',[(_vm.item.PhotoUrl)?_c('v-img',{attrs:{"src":_vm.item.PhotoUrl}}):_c('v-icon',[_vm._v("mdi-account-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-weight":"bold !important","color":"#ffffff"}},[_vm._v(_vm._s(_vm.item.FullName))]),_c('v-list-item-subtitle',{staticStyle:{"color":"#ffffff !important"}},[_vm._v(_vm._s(_vm.item.CompanyName)),(_vm.pendingStatus === 0)?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("("+_vm._s(_vm.$t('labels.waitingForApproval'))+")")]):_vm._e()])],1)],1):_vm._e(),_vm._l((_vm.menuItems),function(item,index){return (
          (item.roles.length === 0 && !item.excludeRoles) ||
          (item.excludeRoles
            ? item.roles.includes(_vm.$store.state.Type) &&
              !item.excludeRoles.includes(_vm.$store.state.Type)
            : item.roles.includes(_vm.$store.state.Type))
        )?_c('v-layout',{key:index},[(item.children.length < 1)?_c('v-list-item',{staticClass:"py-1",class:_vm.menuItems.length - 1 !== index && 'border-white',staticStyle:{"border-radius":"0% !important"},attrs:{"to":'/home/' + item.to,"link":""},on:{"click":function($event){return _vm.$emit('close-drawer')}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"navItemIcon",staticStyle:{"font-size":"28px"},attrs:{"color":"white"}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',{class:item.classes ? item.classes.join(' ') : '',staticStyle:{"font-size":"14px","color":"#ffffff"}},[_vm._v(_vm._s(_vm.$store.state.Type === 'Admin' && item.id == 'orders' ? _vm.$t('navigationDrawerMenu.ordersAdmin') : item.title ? item.title : _vm.$t('navigationDrawerMenu.' + item.id)))])],1):_vm._e(),(item.children.length > 0)?_c('v-list-group',{staticStyle:{"width":"100%","border-bottom":"inset white !important"},attrs:{"color":"white","append-icon":null,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{staticClass:"ml-1",staticStyle:{"font-size":"14px","color":"#ffffff !important"}},[_vm._v(_vm._s(item.title ? item.title : _vm.$t('navigationDrawerMenu.' + item.id)))]),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"#fcd06e"}},[_vm._v(_vm._s(_vm.menu[item.id] ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)]},proxy:true}],null,true),model:{value:(_vm.menu[item.id]),callback:function ($$v) {_vm.$set(_vm.menu, item.id, $$v)},expression:"menu[item.id]"}},[_c('v-icon',{staticStyle:{"font-size":"28px","color":"#ffffff !important"},attrs:{"slot":"prependIcon"},slot:"prependIcon"},[_vm._v(_vm._s(item.icon))]),_vm._l((item.children),function(child,childIndex){return _c('v-layout',{key:childIndex},[(
                (child.roles.length === 0 && !child.excludeRoles) ||
                (child.excludeRoles
                  ? child.roles.length > 0
                    ? child.roles.includes(_vm.$store.state.Type)
                    : !child.excludeRoles.includes(_vm.$store.state.Type)
                  : child.roles.includes(_vm.$store.state.Type))
              )?_c('v-list-item',{key:childIndex,class:item.children.length - 1 !== childIndex && 'border-white',staticStyle:{"padding-left":"12%","border-radius":"0% !important"},attrs:{"link":"","to":'/home/' + child.to}},[_c('v-list-item-icon',{staticStyle:{"margin-top":"auto","margin-bottom":"auto","margin-right":"10px"}},[_c('v-icon',{staticStyle:{"color":"#ffffff !important"}},[_vm._v(_vm._s(child.icon))])],1),_c('v-list-item-title',{staticStyle:{"font-size":"14px","color":"#ffffff !important"}},[_vm._v(_vm._s(child.title ? child.title : _vm.$t('navigationDrawerMenu.' + child.id)))])],1):_vm._e(),(child.children.length > 0)?_c('v-list-group',{staticClass:"v-list-group__subheader",staticStyle:{"width":"100%","border-bottom":"inset white !important"},attrs:{"no-action":"","value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{staticStyle:{"font-size":"14px","color":"#ffffff !important"}},[_vm._v(_vm._s(child.title ? child.title : _vm.$t('navigationDrawerMenu.' + child.id)))])]},proxy:true}],null,true)},[_c('v-icon',{staticStyle:{"color":"#ffffff !important"},attrs:{"slot":"prependIcon"},slot:"prependIcon"},[_vm._v(_vm._s(child.icon))]),_vm._l((child.children),function(lastChild,lastChildIndex){return _c('v-list-item',{key:lastChildIndex,class:item.children.length - 1 !== childIndex && 'border-white',staticStyle:{"padding-left":"12%","border-radius":"0% !important"},attrs:{"to":'/home/' + lastChild.to}},[_c('v-list-item-icon',{staticStyle:{"margin-top":"auto","margin-bottom":"auto","margin-right":"10px"}},[_c('v-icon',{staticStyle:{"color":"#ffffff !important"}},[_vm._v(_vm._s(lastChild.icon))])],1),_c('v-list-item-title',{staticStyle:{"font-size":"14px","color":"#ffffff !important"}},[_vm._v(_vm._s(lastChild.title ? lastChild.title : _vm.$t('navigationDrawerMenu.' + lastChild.id)))])],1)})],2):_vm._e()],1)})],2):_vm._e()],1):_vm._e()})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }