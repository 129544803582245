import axios from "../javascript/custom-axios";
let RESOURCE_NAME = "log";

export default {
  /**
   * Get log header list.
   * @param {*} body
   * @returns {Promise<any>}
   */
  getHeaders(body) {
    return axios.get(`${RESOURCE_NAME}/GetLogHeaders`, {
      params: body,
    });
  },

  /**
   * Get log level list.
   * @param {*} body
   * @returns {Promise<any>}
   */
  getLogLevels(body) {
    return axios.get(`${RESOURCE_NAME}/GetLogLevels`, {
      params: body,
    });
  },

  /**
   * Get log list.
   * @param {*} body
   * @returns {Promise<any>}
   */
  getLogs(body) {
    return axios.get(`${RESOURCE_NAME}/GetLogs`, {
      params: body,
    });
  },

  /**
   * Get log by id.
   * @param {*} id
   * @returns {Promise<any>}
   */
  getLogById(id) {
    return axios.get(`${RESOURCE_NAME}/GetLogById`, {
      params: {
        LogId: id,
      },
    });
  },
};
