var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"hide-scroll",attrs:{"elevation":"1"}},[_c('v-card-title',{staticClass:"popupHeadline",staticStyle:{"font-size":"24px","position":"relative"}},[_c('div',{staticClass:"text-sm-center popupHeadline",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.$t('labels.subscriptionManagement'))+" ")]),_c('v-btn',{staticStyle:{"position":"absolute","top":"10px","right":"10px"},attrs:{"icon":""},on:{"click":_vm.triggerSubscriptionModalUpdate}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider',{staticClass:"mb-4"}),(_vm.subscriptionPackages.length > 0)?_c('v-row',{staticClass:"pb-4 px-4 justify-center"},_vm._l((_vm.subscriptionPackages),function(packageItem){return _c('v-col',{key:packageItem.Id,attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-card',{staticClass:"popup-card d-flex flex-column space-between",staticStyle:{"height":"100%"},attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"d-flex align-center"},[(packageItem.PhotoUrl)?_c('v-img',{staticClass:"mr-3 rounded",attrs:{"src":packageItem.PhotoUrl,"alt":"Package Image","min-width":"150","max-width":"150","min-height":"150","max-height":"150"}}):_c('v-img',{staticClass:"mr-3 rounded",attrs:{"src":require("@/assets/no-image.png"),"alt":"Package Image","min-width":"150","max-width":"150","min-height":"150","max-height":"150"}}),_c('div',{staticClass:"text-center flex-grow-1"},[_c('div',[_vm._v(_vm._s(packageItem.Name))]),_c('v-card-subtitle',{staticClass:"text-center"},[_vm._v(" "+_vm._s(packageItem.Description)+" ")])],1)],1),_c('v-card-text',[_c('div',{staticClass:"d-flex text-h5 justify-center text-center"},[_c('div',{staticClass:"d-flex flex-column position-relative",style:({
                width: '100%',
                borderWidth: '1px',
                borderColor:
                  packageItem.selectedOption === 'monthly'
                    ? '#29B7B7'
                    : '#f0f0f4',
                borderStyle: 'solid',
                borderRadius: '20px',
                padding: '10px',
                position: 'relative',
                marginRight: '10px',
                cursor: 'pointer',
              }),on:{"click":function($event){return _vm.toggleSelection(packageItem.Id, 'monthly')}}},[_c('div',{staticStyle:{"text-align":"center"}},[(packageItem.IsFreePackage)?_c('span',{staticStyle:{"font-weight":"bold","font-size":"24px"}},[_vm._v(" "+_vm._s(_vm.$t('labels.free'))+" ")]):_c('span',{staticStyle:{"font-weight":"bold","font-size":"24px"}},[_vm._v(" "+_vm._s(packageItem.MonthlyPriceWithCurrency)+" ")])]),(!packageItem.IsFreePackage)?_c('div',[_c('span',{staticStyle:{"font-style":"italic","font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.$t('labels.monthlyPrice'))+" ")])]):_vm._e()]),(!packageItem.IsFreePackage)?_c('div',{staticClass:"d-flex flex-column position-relative",style:({
                width: '100%',
                borderWidth: '1px',
                borderColor:
                  packageItem.selectedOption === 'annual'
                    ? '#29B7B7'
                    : '#f0f0f4',
                borderStyle: 'solid',
                borderRadius: '20px',
                padding: '10px',
                position: 'relative',
                cursor: 'pointer',
              }),on:{"click":function($event){return _vm.toggleSelection(packageItem.Id, 'annual')}}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('span',{staticStyle:{"font-weight":"bold","font-size":"24px"}},[_vm._v(" "+_vm._s(packageItem.AnnualPriceWithCurrency)+" ")])]),_c('div',[_c('span',{staticStyle:{"font-style":"italic","font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.$t('labels.annualPrice'))+" ")])])]):_vm._e()]),_c('v-row',{staticClass:"mt-4 ml-4"},_vm._l((_vm.createInfoItems(packageItem)),function(item,idx){return _c('v-col',{key:idx,staticStyle:{"padding":"0px !important","padding-bottom":"5px !important"},attrs:{"cols":"6"}},[_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"20px"},attrs:{"small":"","color":"#29B7B7"}},[_vm._v(" mdi-check-decagram ")]),_vm._v(" "+_vm._s(item.label)+": "),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.value))])],1)}),1)],1),_c('v-card-actions',{staticClass:"justify-center mt-auto"},[_c('v-btn',{staticClass:"darken-1",staticStyle:{"color":"#fff","text-transform":"unset !important","background-color":"#0077d5","padding":"1vh 1vh","border-radius":"30px !important","font-weight":"bolder","font-size":"medium"},attrs:{"elevation":"0","type":"submit","disabled":_vm.currentSubscriptionId === packageItem.Id &&
              _vm.currentSubscriptionPeriod === packageItem.selectedOption},on:{"click":function($event){return _vm.selectPackage(packageItem)}}},[_vm._v(" "+_vm._s(_vm.currentSubscriptionId === packageItem.Id && _vm.currentSubscriptionPeriod === packageItem.selectedOption ? _vm.$t('labels.currentSubscription') : _vm.$t('labels.subscribe'))+" ")])],1)],1)],1)}),1):_c('div',{staticClass:"no-data d-flex justify-center align-center",staticStyle:{"width":"100%"}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert-circle")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('messages.noData')))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }