<template>
  <div
    class="fill-height pa-0 mt-0"
    :style="{
      'border-width': isSupport ? '1px' : '0',
      'border-color': isSupport ? '#f0f0f4' : 'transparent',
      'border-style': isSupport ? 'solid' : 'none',
      'border-radius': isSupport ? '20px' : '0',
    }"
  >
    <v-row
      :class="{ 'elevation-0': isSupport, 'elevation-4': !isSupport }"
      class="no-gutters"
      height="100vh"
    >
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive class="overflow-y-hidden fill-height" height="700">
          <v-card flat class="d-flex flex-column fill-height">
            <v-card-title v-if="!isHeaderHidden" style="color: #0177d4">
              <v-btn icon @click="goBack()" class="mr-2">
                <v-icon :color="'#0177d4'"> mdi-arrow-left </v-icon></v-btn
              >
              {{ chatHeader }}
              <v-spacer />
              <RefreshButton></RefreshButton>
            </v-card-title>
            <v-card-text
              class="flex-grow-1 overflow-y-auto"
              ref="messagesContainer"
            >
              <v-card v-for="message in messages" :key="message.time" flat>
                <v-list-item
                  :key="message.time"
                  v-if="message.UserId == $store.getters.getId ? false : true"
                  class=""
                >
                  <v-list-item-avatar class="align-self-start mr-2">
                    <v-avatar size="40">
                      <v-img :src="message.SenderAvatar"></v-img>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content class="received-message">
                    <v-card color="success" class="flex-none">
                      <v-card-text class="white--text pa-2 d-flex flex-column">
                        <span class="text-caption"
                          >{{ message.SenderName }} -
                          {{
                            isSupport
                              ? $t('labels.chatSupport')
                              : message.SenderCompany
                          }}
                        </span>
                        <span class="align-self-start text-subtitle-1">{{
                          message.MessageText
                        }}</span>
                        <span class="text-caption font-italic align-self-end">{{
                          message.SendedAt
                        }}</span>
                      </v-card-text>
                    </v-card>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-else :key="message.SendedAt">
                  <v-list-item-content class="sent-message justify-end">
                    <v-card color="primary" class="flex-none">
                      <v-card-text class="white--text pa-2 d-flex flex-column">
                        <span class="text-subtitle-1 chat-message">{{
                          message.MessageText
                        }}</span>
                        <span class="text-caption font-italic align-self-end">{{
                          message.SendedAt
                        }}</span>
                        <span class="align-self-end">
                          <v-icon
                            :color="
                              message.isRead == true ? 'var(--turquoise)' : ''
                            "
                            small
                          >
                            mdi-check-all
                          </v-icon>
                        </span>
                      </v-card-text>
                    </v-card>
                  </v-list-item-content>
                  <v-list-item-avatar class="align-self-start ml-2">
                    <v-img :src="message.SenderAvatar"></v-img>
                  </v-list-item-avatar>
                </v-list-item>
              </v-card>
            </v-card-text>
            <v-card-text class="flex-shrink-1">
              <v-text-field
                :disabled="disableProp"
                v-model="sendMessage.MessageText"
                :label="$t('labels.yourMessage')"
                type="text"
                no-details
                outlined
                append-outer-icon="send"
                @keyup.enter="
                  disableSendMessage
                    ? null
                    : isSupport
                    ? sendChatMessageSupport()
                    : sendChatMessage()
                "
                @click:append-outer="
                  disableSendMessage
                    ? null
                    : isSupport
                    ? sendChatMessageSupport()
                    : sendChatMessage()
                "
                hide-details
              />
            </v-card-text>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MessageServices from '../services/MessageServices';
import DemandService from '../services/DemandService';
import RefreshButton from '../components/RefreshButton.vue';

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    disableProp: {
      type: Boolean,
      default: false,
    },
    isSupport: {
      type: Boolean,
      default: false,
    },
    isHeaderHidden: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    messages: [],
    sendMessage: {},
    chatDetail: {},
    chatHeader: '',

    chatId: '',
  }),

  computed: {
    disableSendMessage() {
      if (this.sendMessage.MessageText) {
        return false;
      } else {
        return true;
      }
    },
  },

  watch: {},

  watch: {},
  mounted() {
    this.chatId = this.id !== '' ? this.id : this.$route.query.id;
    this.isSupport ? null : this.getChatDetails(this.chatId);
    this.isSupport
      ? this.getMessagesSupport(this.chatId)
      : this.getMessages(this.chatId);
  },
  components: { RefreshButton },
  methods: {
    scrollToBottom() {
      // Vue ref kullanarak elemente erişim
      const messagesContainer = this.$refs.messagesContainer;

      // Scroll'u en aşağıda tutmak için scrollTop değerini maksimuma ayarla
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    },
    getMessages(id) {
      this.$store.state.loadingStatus = true;
      MessageServices.getMessages(id)
        .then((res) => {
          this.sendMessage.ChatId = id;
          this.messages = [];
          this.messages = res.data.Result;
          console.log('mmmm >> ', this.messages);
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.scrollToBottom();
          this.$store.state.loadingStatus = false;
        });
    },
    getMessagesSupport(id) {
      this.$store.state.loadingStatus = true;
      DemandService.getDemandMessage(id)
        .then((res) => {
          this.sendMessage.ChatId = id;
          this.messages = [];
          this.messages = res.data.Result;
          console.log('MESSAGES >> ', this.messages);
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.scrollToBottom();
          this.$store.state.loadingStatus = false;
        });
    },
    getChatDetails(id) {
      this.$store.state.loadingStatus = true;
      MessageServices.getChats(null, null, 1, 1, id)
        .then((res) => {
          this.chatDetail = res.data.Result[0];
          this.chatHeader =
            this.chatDetail.Type == 0
              ? this.$t('labels.order') + ' / ' + this.chatDetail.OrderNo
              : this.chatDetail.Type == 1
              ? this.$t('labels.spotOffer') + ' / ' + this.chatDetail.OrderNo
              : this.$t('labels.ticket');
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    sendChatMessage() {
      this.$store.state.loadingStatus = true;
      MessageServices.sendMessage(this.sendMessage)
        .then((res) => {
          this.getMessages(this.chatId);
          this.sendMessage.MessageText = ' ';
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    sendChatMessageSupport() {
      this.$store.state.loadingStatus = true;
      const body = {
        MessageText: this.sendMessage.MessageText,
        DemandChatId: this.chatId,
      };
      DemandService.sendDemandMessage(body)
        .then((res) => {
          this.getMessagesSupport(this.chatId);
          this.sendMessage.MessageText = ' ';
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.chat-message {
  display: unset !important;
  white-space: break-spaces;
}
.chat-screen {
  max-height: 320px;
  overflow-y: auto;
}
.flex-none {
  flex: unset;
}
.received-message::after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 54px;
  right: auto;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #4caf50 transparent transparent transparent;
}
.sent-message::after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 54px;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #1976d2 transparent transparent transparent;
}
</style>
