<template>
  <div elevation="1">
    <!-- Page content -->
    <TabScreen :slots="slots" :changeIndexFunction="handleTabChange">
      <!-- Notifications tab -->
      <template v-slot:notifications>
        <v-card
          style="
            padding: 2vh;
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-top-width: 0px;
            border-color: lightgray;
          "
        >
          <v-col class="text-right" v-if="currentTab === 0" style="padding: 0">
            <v-btn
              style="color: white; background-color: var(--darkBlue)"
              class="button mr-2"
              @click="readAll()"
            >
              {{ $t('labels.readAll') }}
            </v-btn>
            <RefreshButton :refreshFunction="getNotifications"></RefreshButton>
          </v-col>
          <v-data-table
            :headers="notificationHeaders"
            :items="notifications"
            key="notification"
            @click:row="readNotification"
            hide-default-footer
            :options="{
              itemsPerPage: itemsPerPage,
            }"
            item-key="Id"
            :no-data-text="$t('description.noList')"
          >
            <!-- eslint-disable -->
            <template v-slot:item.Message="{ item }">
              <div
                :style="
                  item.isSeen ? 'font-weight: normal;' : 'font-weight: bold;'
                "
              >
                {{ item.Message }}
              </div>
            </template>
            <template v-slot:item.CreatedAt="{ item }">
              <div
                :style="
                  item.isSeen ? 'font-weight: normal;' : 'font-weight: bold;'
                "
              >
                {{ item.CreatedAt }}
              </div>
            </template>
            <template v-slot:item.isSeen="{ item }">
              <v-icon
                small
                class="mr-1"
                style="font-size: 20px"
                :color="item.isSeen == false ? 'var(--turquoise)' : ''"
                >mdi-bell</v-icon
              >
            </template>
            <template v-slot:footer>
              <div class="d-flex justify-center mt-8">
                <v-row align="center" justify="center">
                  <v-col
                    v-if="
                      $vuetify.breakpoint.md ||
                      $vuetify.breakpoint.lg ||
                      $vuetify.breakpoint.xl
                    "
                    md="2"
                  ></v-col>
                  <v-col cols="9" sm="12" md="7" lg="7" xl="7">
                    <v-pagination
                      v-if="pageLength > 0"
                      class="mr-2"
                      @input="onPageChange"
                      :value="page"
                      :length="pageLength"
                      :total-visible="15"
                      circle
                    ></v-pagination>
                  </v-col>
                  <v-col
                    cols="11"
                    sm="11"
                    md="3"
                    lg="3"
                    xl="3"
                    class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
                  >
                    <v-select
                      v-model="itemsPerPage"
                      :items="perPageValues"
                      :label="$t('labels.selectItemsPerPage')"
                      item-value="value"
                      item-text="name"
                      outlined
                      dense
                      @change="changeItemsPerPage"
                      rounded
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </div>
            </template>
            <!-- eslint-disable -->
          </v-data-table>
        </v-card>
      </template>
      <!-- Spot Messages tab -->
      <template v-slot:spotMessages>
        <v-card
          style="
            padding: 2vh;
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-top-width: 0px;
            border-color: lightgray;
          "
        >
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
            class="ml-auto mr-2"
            v-if="currentTab === 1"
          >
            <v-select
              class="custom-border"
              v-model="selectedChatStasus"
              :value="selectedChatStasus"
              :items="chatStasuses"
              :label="$t('labels.chatStatus')"
              item-text="name"
              item-value="value"
              hide-details
              outlined
              dense
              @change="getSpotChats()"
            >
            </v-select>
          </v-col>
          <v-data-table
            :headers="spotChatHeaders"
            key="spot"
            :items="spotChats"
            :loading="loadings.table"
            :hide-default-footer="false"
            item-key="Id"
            hide-default-footer
            @click:row="handleClick"
            :options="{
              itemsPerPage: itemsPerPage,
            }"
            :no-data-text="$t('description.noList')"
          >
            <!-- eslint-disable -->
            <template v-slot:item.CompanyName="{ item }">
              <div
                class="flex justify-center items-center text-center"
                :style="
                  item.IsSeen == true
                    ? 'font-weight: normal;'
                    : 'font-weight: bold;'
                "
              >
                {{ item.CompanyName ? item.CompanyName : '-' }}
              </div>
            </template>
            <template v-slot:item.LastMessageDate="{ item }">
              <div
                class="flex justify-center items-center text-center"
                :style="
                  item.IsSeen == true
                    ? 'font-weight: normal;'
                    : 'font-weight: bold;'
                "
              >
                {{ item.LastMessageDate ? item.LastMessageDate : '-' }}
              </div>
            </template>
            <template v-slot:item.OrderNo="{ item }">
              <div
                :style="
                  item.IsSeen == true
                    ? 'font-weight: normal;'
                    : 'font-weight: bold;'
                "
              >
                {{ item.OrderNo }}
              </div>
            </template>
            <template v-slot:item.IsRead="{ item }">
              <v-icon
                small
                class="mr-1"
                style="font-size: 20px"
                :color="item.IsSeen == false ? 'var(--turquoise)' : ''"
              >
                mdi-email
              </v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="text-start">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="item.Status == 0"
                      small
                      class="mr-1"
                      style="font-size: 20px"
                      color="#29B7B7"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="setSpotArcvhive(item)"
                    >
                      mdi-package-down
                    </v-icon>
                  </template>
                  <span>{{ $t('buttons.setArchive') }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="item.Status == 1"
                      color="#29B7B7"
                      small
                      class="mr-1"
                      style="font-size: 20px"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="setSpotArcvhive(item)"
                    >
                      mdi-package-up
                    </v-icon>
                  </template>
                  <span>{{ $t('buttons.unarchive') }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      color="#29B7B7"
                      class="mr-1"
                      style="font-size: 20px"
                      v-bind="attrs"
                      v-on="on"
                      @click="seeMessages(item.Id)"
                    >
                      mdi-message-text
                    </v-icon>
                  </template>
                  <span>{{ $t('buttons.seeMessages') }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      color="#29B7B7"
                      class="mr-1"
                      style="font-size: 20px"
                      v-bind="attrs"
                      v-on="on"
                      @click="goOrder(item)"
                    >
                      mdi-arrow-right
                    </v-icon>
                  </template>
                  <span>{{ $t('buttons.offerDetail') }}</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:footer>
              <div class="d-flex justify-center mt-8">
                <v-row align="center" justify="center">
                  <v-col
                    v-if="
                      $vuetify.breakpoint.md ||
                      $vuetify.breakpoint.lg ||
                      $vuetify.breakpoint.xl
                    "
                    md="2"
                  ></v-col>
                  <v-col cols="9" sm="12" md="7" lg="7" xl="7">
                    <v-pagination
                      v-if="pageLength > 0"
                      class="mr-2"
                      @input="onPageChange"
                      :value="page"
                      :length="pageLength"
                      :total-visible="15"
                      circle
                    ></v-pagination>
                  </v-col>
                  <v-col
                    cols="11"
                    sm="11"
                    md="3"
                    lg="3"
                    xl="3"
                    class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
                  >
                    <v-select
                      v-model="itemsPerPage"
                      :items="perPageValues"
                      :label="$t('labels.selectItemsPerPage')"
                      item-value="value"
                      item-text="name"
                      outlined
                      dense
                      @change="changeItemsPerPage"
                      rounded
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </div>
            </template>
            <!-- eslint-disable -->
          </v-data-table>
        </v-card>
      </template>
      <!-- Order Messages tab-->
      <template v-slot:orderMessages>
        <v-card
          style="
            padding: 2vh;
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-top-width: 0px;
            border-color: lightgray;
          "
        >
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
            class="ml-auto mr-2"
            v-if="currentTab === 2"
          >
            <v-select
              class="custom-border"
              v-model="selectedChatStasus"
              :value="selectedChatStasus"
              :items="chatStasuses"
              :label="$t('labels.chatStatus')"
              item-text="name"
              item-value="value"
              hide-details
              outlined
              dense
              @change="getOrderChats()"
            >
            </v-select>
          </v-col>
          <v-data-table
            :headers="orderChatHeaders"
            key="order"
            :items="orderChats"
            :loading="loadings.table"
            :hide-default-footer="false"
            item-key="Id"
            @click:row="handleClick"
            hide-default-footer
            :options="{
              itemsPerPage: itemsPerPage,
            }"
            :no-data-text="$t('description.noList')"
          >
            <!-- eslint-disable -->
            <template v-slot:item.CompanyName="{ item }">
              <div
                class="flex justify-center items-center text-center"
                :style="
                  item.IsSeen == true
                    ? 'font-weight: normal;'
                    : 'font-weight: bold;'
                "
              >
                {{ item.CompanyName ? item.CompanyName : '-' }}
              </div>
            </template>
            <template v-slot:item.LastMessageDate="{ item }">
              <div
                class="flex justify-center items-center text-center"
                :style="
                  item.IsSeen == true
                    ? 'font-weight: normal;'
                    : 'font-weight: bold;'
                "
              >
                {{ item.LastMessageDate ? item.LastMessageDate : '-' }}
              </div>
            </template>
            <template v-slot:item.OrderNo="{ item }">
              <div
                :style="
                  item.IsSeen == true
                    ? 'font-weight: normal;'
                    : 'font-weight: bold;'
                "
              >
                {{ item.OrderNo }}
              </div>
            </template>
            <template v-slot:item.IsRead="{ item }">
              <v-icon
                small
                class="mr-1"
                style="font-size: 20px"
                :color="item.IsSeen == false ? 'var(--turquoise)' : ''"
              >
                mdi-email
              </v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="text-start">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="item.Status == 0"
                      small
                      class="mr-1"
                      style="font-size: 20px"
                      color="#29B7B7"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="setArcvhive(item)"
                    >
                      mdi-package-down
                    </v-icon>
                  </template>
                  <span>{{ $t('buttons.setArchive') }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="item.Status == 1"
                      color="#29B7B7"
                      small
                      class="mr-1"
                      style="font-size: 20px"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="setArcvhive(item)"
                    >
                      mdi-package-up
                    </v-icon>
                  </template>
                  <span>{{ $t('buttons.unarchive') }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      color="#29B7B7"
                      class="mr-1"
                      style="font-size: 20px"
                      v-bind="attrs"
                      v-on="on"
                      @click="seeMessages(item.Id)"
                    >
                      mdi-message-text
                    </v-icon>
                  </template>
                  <span>{{ $t('buttons.seeMessages') }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      color="#29B7B7"
                      class="mr-1"
                      style="font-size: 20px"
                      v-bind="attrs"
                      v-on="on"
                      @click="goOrder(item)"
                    >
                      mdi-arrow-right
                    </v-icon>
                  </template>
                  <span>{{ $t('buttons.offerDetail') }}</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:footer>
              <div class="d-flex justify-center mt-8">
                <v-row align="center" justify="center">
                  <v-col
                    v-if="
                      $vuetify.breakpoint.md ||
                      $vuetify.breakpoint.lg ||
                      $vuetify.breakpoint.xl
                    "
                    md="2"
                  ></v-col>
                  <v-col cols="9" sm="12" md="7" lg="7" xl="7">
                    <v-pagination
                      v-if="pageLength > 0"
                      class="mr-2"
                      @input="onPageChange"
                      :value="page"
                      :length="pageLength"
                      :total-visible="15"
                      circle
                    ></v-pagination>
                  </v-col>
                  <v-col
                    cols="11"
                    sm="11"
                    md="3"
                    lg="3"
                    xl="3"
                    class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
                  >
                    <v-select
                      v-model="itemsPerPage"
                      :items="perPageValues"
                      :label="$t('labels.selectItemsPerPage')"
                      item-value="value"
                      item-text="name"
                      outlined
                      dense
                      @change="changeItemsPerPage"
                      rounded
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </div>
            </template>
            <!-- eslint-disable -->
          </v-data-table>
        </v-card>
      </template>
    </TabScreen>
  </div>
</template>

<script>
import MessageServices from '../services/MessageServices';
import NotificationService from '../services/NotificationService';
import RefreshButton from '../components/RefreshButton.vue';
import TabScreen from '../components/TabScreen.vue';
import { ref } from 'vue';
const spot = ref(0);

export default {
  data: () => ({
    notifications: [],
    spotChats: [],
    orderChats: [],
    expanded: [],
    tagName: [],
    tags: [],
    selectedChatStasus: 0,
    chatStasuses: [],
    slots: [],

    itemsPerPageOptions: [],
    itemsPerPage: 10,
    page: 1,
    totalItems: 0,

    currentTab: 0,

    perPageValues: [
      {
        name: '5',
        value: 5,
      },
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],

    notificationHeaders: [],
    orderChatHeaders: [],
    //Loading states
    loadings: {
      table: false,
      send: false,
    },
  }),
  mounted() {
    this.chatStasuses = [
      {
        name: this.$t('labels.All'),
        value: null,
      },
      {
        name: this.$t('labels.tooltipActive'),
        value: 0,
      },
      {
        name: this.$t('labels.archived'),
        value: 1,
      },
    ];

    this.slots = [
      {
        name: 'notifications',
        title: this.$t('labels.notifications'),
        unSeenCount: 0,
      },
      {
        name: 'spotMessages',
        title: this.$t('titles.spotOfferMessages'),
        unSeenCount: 0,
      },
      {
        name: 'orderMessages',
        title: this.$t('titles.orderMessages'),
        unSeenCount: 0,
      },
    ];

    this.perPageValues.push({
      name: this.$t('labels.All'),
      value: 9999,
    });

    this.setup();
    this.getUnSeenCount();
  },
  components: {
    TabScreen,
    RefreshButton,
  },
  computed: {
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    handleClick(value) {
      this.seeMessages(value.Id);
    },
    forceRerender() {
      spot.value += 1;
    },
    changeItemsPerPage() {
      this.page = 1;
      console.log('CURRENT TAB >> ', this.currentTab);

      if (this.currentTab == 0) this.getNotifications();
      else if (this.currentTab == 1) this.getSpotChats();
      else if (this.currentTab == 2) this.getOrderChats();
      // this.getNotifications();
    },
    onPageChange(newPage) {
      this.$store.state.loadingStatus = true;
      this.page = newPage;
      if (this.currentTab == 0) this.getNotifications();
      else if (this.currentTab == 1) this.getSpotChats();
      else if (this.currentTab == 2) this.getOrderChats();
    },
    getUnSeenCount() {
      this.$store.state.loadingStatus = true;
      MessageServices.getUnSeenCount()
        .then((res) => {
          this.unSeenCount = res.data.Result.Total;
          this.slots.find((x) => x.name === 'notifications').unSeenCount =
            res.data.Result.Notifications;
          this.slots.find((x) => x.name === 'spotMessages').unSeenCount =
            res.data.Result.SpotMessages;
          this.slots.find((x) => x.name === 'orderMessages').unSeenCount =
            res.data.Result.OrderMessages;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    handleTabChange(index) {
      this.page = 1;
      this.itemsPerPage = 10;
      if (index == 0) {
        this.currentTab = 0;
        this.selectedChatStasus = 0;
        this.getNotifications();
      }
      if (index == 1) {
        this.currentTab = 1;
        this.selectedChatStasus = 0;
        this.getSpotChats();
      }
      if (index == 2) {
        this.currentTab = 2;
        this.selectedChatStasus = 0;
        this.getOrderChats();
      }
    },

    setSpotArcvhive(item) {
      let body = {
        Id: item.Id,
        Status: item.Status == 0 ? 1 : 0,
      };
      this.$store.state.loadingStatus = true;
      let value = this.$t('labels.notificationMessage');

      MessageServices.setArchive(body)
        .then((res) => {
          this.$store.state.loadingStatus = false;

          if (body.Status == 1) {
            window.showSuccess(
              this.$t('messages.successfulArchive', { value: value })
            );
          } else {
            window.showSuccess(
              this.$t('messages.successfulUnarchive', { value: value })
            );
          }
          this.spotChats = [];
          this.getSpotChats();
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    setArcvhive(item) {
      let body = {
        Id: item.Id,
        Status: item.Status == 0 ? 1 : 0,
      };
      this.$store.state.loadingStatus = true;
      let value = this.$t('labels.notificationMessage');

      MessageServices.setArchive(body)
        .then((res) => {
          this.$store.state.loadingStatus = false;

          if (body.Status == 1) {
            window.showSuccess(
              this.$t('messages.successfulArchive', { value: value })
            );
          } else {
            window.showSuccess(
              this.$t('messages.successfulUnarchive', { value: value })
            );
          }
          this.orderChats = [];
          this.getOrderChats();
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    goOrder(item) {
      const id = item.OrderId;
      this.$store.state.Type === 'Supplier'
        ? this.$router.push({
            name: `spotPriceRequestsSupplier`,
          })
        : this.$router.push({ name: 'spotPriceRequests', params: { id } });
    },
    getSpotChats() {
      this.$store.state.loadingStatus = true;
      MessageServices.getChats(
        1,
        this.selectedChatStasus,
        this.page,
        this.itemsPerPage
      )
        .then((res) => {
          this.spotChats = res.data.Result;
          this.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getOrderChats() {
      this.$store.state.loadingStatus = true;
      this.orderChats = [];
      MessageServices.getChats(
        0,
        this.selectedChatStasus,
        this.page,
        this.itemsPerPage
      )
        .then((res) => {
          this.orderChats = res.data.Result;
          this.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    readAll() {
      this.$store.state.loadingStatus = true;
      NotificationService.readAllNotifications()
        .then((res) => {
          location.reload(true);
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    seeMessages(id) {
      this.$router.push({
        name: `messageDetail`,
        query: { id },
      });
    },
    readNotification(item) {
      if (item.isSeen == false) {
        let body = {
          Id: item.Id,
        };
        this.$store.state.loadingStatus = true;
        NotificationService.readNotification(body)
          .then((res) => {
            this.$store.state.loadingStatus = true;
            this.$router.push(item.ReleatedPage);
          })
          .catch((err) => {
            window.showError(err);
          })
          .then(() => {
            this.$store.state.loadingStatus = true;
          });
      } else {
        this.$router.push(item.ReleatedPage);
      }
    },
    getNotifications() {
      this.$store.state.loadingStatus = true;
      NotificationService.getNotifications(this.page, this.itemsPerPage)
        .then((res) => {
          this.unSeenCount = res.data.TotalUnSeenItems;
          this.notifications = res.data.Result;

          this.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    //Page setup
    setup() {
      this.getNotifications();

      this.notificationHeaders = [
        {
          text: '',
          value: 'isSeen',
          sortable: false,
        },
        {
          text: this.$t('labels.dateAsNoun'),
          value: 'CreatedAt',
          sortable: false,
        },
        {
          text: this.$t('labels.description'),
          value: 'Message',
          sortable: false,
        },
      ];
      this.orderChatHeaders = [
        {
          text: null,
          value: 'IsRead',
          sortable: false,
        },
        {
          text: this.$t('titles.messageCompanyName'),
          value: 'CompanyName',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('titles.messageDate'),
          value: 'LastMessageDate',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('titles.referenceCode'),
          value: 'OrderNo',
          sortable: false,
          align: 'center',
        },
        { text: this.$t('labels.actions'), value: 'actions' },
      ];
      this.spotChatHeaders = [
        {
          text: null,
          value: 'IsRead',
          sortable: false,
        },
        {
          text: this.$t('titles.messageCompanyName'),
          value: 'CompanyName',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('titles.messageDate'),
          value: 'LastMessageDate',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('titles.referenceCode'),
          value: 'OrderNo',
          sortable: false,
          align: 'center',
        },

        { text: this.$t('labels.actions'), value: 'actions' },
      ];
    },
  },
};
</script>
