import axios from '../javascript/custom-axios';
const RESOURCE_NAME = '/NotificationPermission';
const RESOURCE_NAME2 = '/languages';

export default {
  getPermissions() {
    return axios.get(`${RESOURCE_NAME}/getPermisssions`);
  },
  getSMSLanguagePref() {
    return axios.get(`${RESOURCE_NAME2}/get-sms-language`);
  },
  updatePermission(body) {
    return axios.put(`${RESOURCE_NAME}/updatePermisssion`, body);
  },
  updateSMSLanguagePref(body) {
    return axios.put(`${RESOURCE_NAME2}/set-language`, body);
  },

  /**
   * Update company's notifications by shipment types.
   * @param {*} body 
   * @returns {Promise}
   */
  updateCompanyNotifications(body){
    return axios.put(`${RESOURCE_NAME2}/update-company-notifications`, body);
  }
};
